import { getText } from '@/i18n';

import { getDayjs, getYear } from '@/app/bi/utils/formatDate.ts';

import { EOperationTypesFields } from '@/app/bi/models/reportsTypes.ts';
import { EServiceTypes } from '@/app/bi/models/serviceTypes.ts';

const MAX_DATE_DATEPICKER_REGISTRATION_DATE = getDayjs();

const MIN_DATE_DATEPICKER_REGISTRATION_DATE = getYear(1900);

const ARRAY_SERVICES: { [key in EServiceTypes]?: string } = {
  [EServiceTypes.Airline]: getText('reports:serviceType.air'),
  [EServiceTypes.Hotel]: getText('reports:serviceType.hotel'),
  [EServiceTypes.Transfer]: getText('reports:serviceType.transfer'),
};

const incoming = EOperationTypesFields.Incoming;
const outcoming = EOperationTypesFields.Outcoming;
const booking = EOperationTypesFields.Booking;
const change = EOperationTypesFields.Change;

const incomingValue = getText('reports:filterOperationTypes.incoming');
const outcomingValue = getText('reports:filterOperationTypes.outcoming');
const bookingValue = getText('reports:filterOperationTypes.booking');
const changeValue = getText('reports:filterOperationTypes.change');

const OPERATION_TYPE = new Map([
  [incoming, incomingValue],
  [outcoming, outcomingValue],
  [booking, bookingValue],
  [change, changeValue],
]);

const OPERATION_TYPE_MIRROR = new Map([
  [incomingValue, incoming],
  [outcomingValue, outcoming],
  [bookingValue, booking],
  [changeValue, change],
]);

export {
  MAX_DATE_DATEPICKER_REGISTRATION_DATE,
  MIN_DATE_DATEPICKER_REGISTRATION_DATE,
  ARRAY_SERVICES,
  OPERATION_TYPE,
  OPERATION_TYPE_MIRROR,
};
