import React, { useState } from 'react';
import { Button, Checkbox, Collapse } from 'yw-ui';
import { getText } from '@/i18n';

import { FILTER_DEFAULT_COUNT_ITEMS } from '@/app/bi/constants/common.ts';
import { SERVICES_NAME_ARRAY } from '@/app/bi/constants/trips.ts';

import { EServiceTypes } from '@/app/bi/models/serviceTypes.ts';

import styles from './index.module.css';

const LABELS = {
  OPEN_MORE: getText('trips:openMore'),
  HIDDEN_MORE: getText('trips:hiddenMore'),
};

interface FilterServicesItemProps {
  title: string;
  data: EServiceTypes[];
  serviceValues: EServiceTypes[],
  onChange(array: string[]): void,
}

const FilterServicesItem = ({
  data,
  serviceValues,
  title,
  onChange,
}:FilterServicesItemProps) => {
  const [
    openList,
    setOpenList,
  ] = useState<boolean>(false);

  const isActiveButton = data.length > FILTER_DEFAULT_COUNT_ITEMS;
  const listViewLength = !openList ? FILTER_DEFAULT_COUNT_ITEMS : data.length;

  const handleOpenList = () => setOpenList(!openList);

  const handleChange = (value: EServiceTypes) => {
    const valuesSet = new Set(serviceValues);

    if (valuesSet.has(value)) {
      valuesSet.delete(value);
    } else {
      valuesSet.add(value);
    }

    onChange([...valuesSet]);
  };

  const renderArrayItems = () => data.map((item, index) => {
    const checked = serviceValues.includes(EServiceTypes[item]);

    return index < listViewLength && (
      <Checkbox
        key={ `services_${item}_${index}` }
        className={ styles.checkbox }
        value={ checked }
        onChange={ () => handleChange(item) }
        themes='dark'
      >
        {SERVICES_NAME_ARRAY[item]}
      </Checkbox>);
  });

  const renderButtonMore = () => isActiveButton && (
    <Button type='text' className={ styles.button_more } onClick={ handleOpenList }>
      {openList ? LABELS.HIDDEN_MORE : LABELS.OPEN_MORE}
    </Button>
  );

  return (
    <Collapse label={ title } moreOpen={ openList }>
      {renderArrayItems()}
      {renderButtonMore()}
    </Collapse>
  );
};

export { FilterServicesItem };
