import React, { useState } from 'react';
import { Input } from 'yw-ui';

import styles from '@/app/pages/Settings/pages/Profile/styles/index.module.css';

interface InputPasswordProps {
  value: string;
  onChange(value: string): void;
  onBlur?(): void;
  error?: string;
}

const InputPassword = ({
  value,
  onChange,
  onBlur = () => {},
  error = '',
}: InputPasswordProps) => {
  const [showPassword, setShowPassword] = useState(false);

  const passwordType = showPassword ? 'type' : 'password';

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleChangePassword = (newValue: string) => {
    onChange(newValue);
  };

  const renderShowPassword = () => {
    const classes = `${styles.eyes} ${showPassword ? styles.showEyes : ''}`;

    return (
      <span onClick={ handleShowPassword } className={ classes } />
    );
  };

  return (
    <div className={ styles.password_group }>
      <Input
        type={ passwordType }
        value={ value }
        onChange={ handleChangePassword }
        className={ styles.input }
        onBlur={ onBlur }
        error={ error }
      />
      {value && renderShowPassword()}
    </div>
  );
};

export { InputPassword };
