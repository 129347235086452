import React from 'react';
import { NoResults, Text } from 'yw-ui';
import { getText } from '@/i18n';
import styles from './index.module.css';

const LABELS = {
  CHANGE_PARAMS_OF_SEARCH: getText('air:result.changeParamsOfSearch'),
  NOT_FOUND: getText('air:result.notFound'),
};

const NoResultsAirline = () => (
  <NoResults
    renderContent={ () => (
      <div className={ styles.no_results }>
        <Text type='bold_18'>
          { LABELS.NOT_FOUND }
        </Text>
        <Text className={ styles.subtext }>
          { LABELS.CHANGE_PARAMS_OF_SEARCH }
        </Text>
      </div>
    ) }
  />
);

export { NoResultsAirline };
