import React from 'react';
import { Text } from 'yw-ui';

import { IEmployeeUniversalCompany } from '@/app/bi/models/employees.ts';

interface ICompanyInfoProps {
  company: IEmployeeUniversalCompany | null;
}
const CompanyInfo = ({
  company,
}: ICompanyInfoProps) => {
  if (!company) return null;

  return (
    <Text type='NORMAL_14' fontWeight={ 600 }>
      {company.shortName}
    </Text>
  );
};

export { CompanyInfo };
