import React, { forwardRef, ReactNode } from 'react';
import { BookMark, BookMarkProps, ItemLoader } from 'yw-ui';

import styles from './index.module.css';

interface ItemPanelProps {
  loading: boolean,
  children: ReactNode,
  animationClass?: string,
  headerClass?: string,
  itemClass?: string,
  className?: string,
  bookmarks?: BookMarkProps[],
  warning?: boolean
  renderHeader(): JSX.Element,
  onAnimationEnd?(): void,
}

const CartPanel = forwardRef<HTMLDivElement, ItemPanelProps>(({
  loading = false,
  children,
  animationClass = '',
  headerClass = '',
  itemClass = '',
  className = '',
  warning = false,
  renderHeader,
  bookmarks = [],
  onAnimationEnd = () => {},
}, ref) => {
  const wrapClasses = [styles.wrap];
  const headerClasses = [styles.header];
  const itemClasses = [styles.item];

  if (className) {
    wrapClasses.push(className);
  }

  if (animationClass) {
    wrapClasses.push(animationClass);
  }

  if (loading) {
    wrapClasses.push(styles.loading);
  }

  if (itemClasses) {
    itemClasses.push(itemClass);
  }

  if (headerClass) {
    headerClasses.push(headerClass);
  }

  if (warning) {
    itemClasses.push(styles.warning);
  }

  const bookMarkExists = !!bookmarks.length;

  if (bookMarkExists) {
    wrapClasses.push(styles.bookmark_exists);
    itemClasses.push(styles.left_radius_none);
    headerClasses.push(styles.left_radius_none);
  }

  const renderBookMarks = () => {
    if (!bookmarks.length) return null;

    return (
      <div className={ styles.bookmark_wrapper }>
        {bookmarks.map((bookmarkProps, index) => (
          <BookMark
            { ...bookmarkProps }
            key={ index }
          />
        ))}
      </div>
    );
  };

  const loadingHtml = loading && <ItemLoader />;

  return (
    <div
      className={ wrapClasses.join(' ') }
      onAnimationEnd={ onAnimationEnd }
      ref={ ref }
    >
      { renderBookMarks() }
      { loadingHtml }
      <div className={ headerClasses.join(' ') } >
        { renderHeader() }
      </div>
      <div className={ itemClasses.join(' ') }>
        { children }
      </div>
    </div>
  );
});

export { CartPanel };
