import React, { ReactNode } from 'react';
import { Checkbox } from 'yw-ui';

import styles from './styles/index.module.css';

interface CheckBoxItemProps {
  checked: boolean;
  onChange(): void;
  children: ReactNode;
}

export const CheckBoxItem = ({
  checked,
  onChange,
  children,
}: CheckBoxItemProps) => (
  <Checkbox
    className={ styles.wrap }
    value={ checked }
    onChange={ onChange }
    themes='dark'
  >
    {children}
  </Checkbox>
);
