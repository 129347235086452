import React from 'react';
import { Text, IconButton, Stars, Rating } from 'yw-ui';
import { getText } from '@/i18n';

import { RATING_TYPES } from '@/app/bi/constants/hotels.ts';

import { ERatingType } from '@/app/bi/models/hotelSearch/hotelSearchEnum.ts';

import styles from './index.module.css';

const LABELS = {
  CLOSE_WINDOW: getText('components:hotelNameBlock.closeWindow'),
};

interface HotelNameBlockProps {
  name: string;
  stars: number;
  isDetails?: boolean;
  rating: number;
  ratingType: ERatingType;
  onClose?(): void;
}

const HotelNameBlock = ({
  stars,
  name,
  rating,
  ratingType,
  isDetails,
  onClose = undefined,
}: HotelNameBlockProps) => {
  if (isDetails) {
    const nameClasses = [styles.name];

    return (
      <div className={ styles.details_wrapper }>
        <div className={ styles.info }>
          <Text type='NORMAL_18' className={ nameClasses.join(' ') }>
            <span>{ name }</span>
            <div className={ styles.stars }>
              <Stars count={ stars } size={ 24 } color='blue1' />
            </div>
          </Text>
        </div>
        <div className={ styles.actions }>
          <IconButton
            className={ styles.close }
            onClick={ onClose }
            iconType='closeOff'
            iconColor='blue1'
          >
            <Text type='NORMAL_14'>
              { LABELS.CLOSE_WINDOW }
            </Text>
          </IconButton>
        </div>
      </div>
    );
  }

  const ratingText = RATING_TYPES[ratingType] || '';

  return (
    <div className={ styles.wrapper }>
      <Text type='bold_32' className={ styles.name }>
        <span>{name}</span>
        <Stars count={ stars } className={ styles.stars } color='blue1' />
      </Text>
      <div className={ styles.rating }>
        <Rating text={ ratingText } fullSize number={ rating } />
      </div>
    </div>
  );
};

export { HotelNameBlock };
