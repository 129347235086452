import { ManipulateType } from 'dayjs';

const DATE_FORMATS: { [n: string]: string } = {
  DATE: 'DD.MM.YYYY',
  DAY_MONTH: 'DD.MM',
  TIME: 'HH:mm',
};

type PatternType = { [n: string]: ManipulateType | string };
const PATTERN: PatternType = {
  YEAR_MONTH_DAY: 'YYYY-MM-DD',
  YEAR_MONTH_DAY_TIME: 'YYYY-MM-DDTHH:mm:ss',
  DATE_TIME_WITHOUT_SECONDS: 'DD.MM.YYYY HH:mm',
  DAY_MONTH_TIME_WITHOUT_SECONDS: 'DD.MM HH:mm',
  DATE_WITH_WEEK_DAY: 'DD.MM.YYYY, dd',
  DAY_OF_MONTH_WITH_YEAR: 'D MMMM YYYY',
  DAY_OF_MONTH: 'D MMMM',
  DAY_OF_MONTH_TIME: 'D MMMM HH:mm',
  WEEK: 'dd',
  FULL_DATE_WITH_TIME: 'DD.MM.YYYY HH:mm',
  HOUR: 'h',
  LOCAL_TIME_PATTERN: 'HH:mm',
  DEFAULT_PATTERN_FILTER: 'DD.MM.YY',
};

export { DATE_FORMATS, PATTERN };
