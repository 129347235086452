import React from 'react';
import { useNavigate } from 'react-router-dom';

import { searchAirlineSlice } from '../../../store/redusers/SearchAirlineSlice.ts';
import { useAppDispatch, useAppSelector } from '../../../store/hooks/redux.ts';

import { AirlineMainMenu } from './components/MainMenu';
import { AirlineSubMenu } from './components/SubMenu';

import ROUTES from '@/app/bi/constants/routes.ts';

interface AirlineSearchMenuProps {
  subMenu?: boolean;
  onSearch?(): void;
  onReturnToSearch?(): void;
}

const AirlineSearchMenu = ({
  subMenu = false,
  onSearch = () => {},
  onReturnToSearch = () => {},
}: AirlineSearchMenuProps) => {
  const navigate = useNavigate();

  const { isValid } = useAppSelector((state) => state.searchAirlineSlice);
  const {
    setTravellers,
    setFlightClass,
  } = searchAirlineSlice.actions;

  const dispatch = useAppDispatch();

  const handleChangeAdult = (value: number) => dispatch(setTravellers(value));

  const handleChangeFlightClass = (value: string) => dispatch(setFlightClass(value));

  const handleSearchSubMenu = async () => {
    if (!isValid) return null;

    return onSearch();
  };

  const handleSearch = async () => {
    if (!isValid) return null;

    return navigate(ROUTES.SEARCH.AIR_SEARCH);
  };

  const renderSubMenu = () => (
    <AirlineSubMenu
      onChangeAdult={ handleChangeAdult }
      onChangeFlightClass={ handleChangeFlightClass }
      onSearch={ handleSearchSubMenu }
      onReturnToSearch={ onReturnToSearch }
    />
  );

  const renderMainMenu = () => (
    <AirlineMainMenu
      onChangeAdult={ handleChangeAdult }
      onChangeFlightClass={ handleChangeFlightClass }
      onSearch={ handleSearch }
    />
  );

  return subMenu ? renderSubMenu() : renderMainMenu();
};

export { AirlineSearchMenu };
