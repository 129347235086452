import { ywApiPrivate } from './index.ts';

import { IAccount, ICitizenshipItem } from '@/app/bi/models/profile.ts';
import { IEmployeeObj } from '@/app/bi/models/employees.ts';
import { IAddEmployeeRequest, IUpdateEmployeeRequest } from '@/app/bi/models/employee.ts';
import { IEmployee } from '@/app/bi/models/cart.ts';

export const employeeApi = ywApiPrivate.injectEndpoints({
  endpoints: (build) => ({
    getEmployeeAccountCard: build.query<IAccount, void>({
      query: () => ({
        url: '/account',
        method: 'GET',
      }),
    }),
    getEmployeeById: build.query<IEmployeeObj, string>({
      query: (id: string) => ({
        url: `/employees/${id}`,
        method: 'GET',
      }),
    }),
    getCitizenship: build.query<ICitizenshipItem[], string>({
      query: (locale: string) => ({
        url: `/citizenship/${locale}`,
        method: 'GET',
      }),
    }),
    addEmployee: build.mutation<IEmployee, IAddEmployeeRequest>({
      query: (body: IAddEmployeeRequest) => ({
        url: '/employees/add',
        method: 'POST',
        body,
      }),
    }),
    updateEmployee: build.mutation<void, IUpdateEmployeeRequest>({
      query: (body: IUpdateEmployeeRequest) => ({
        url: '/employees',
        method: 'PUT',
        body,
      }),
    }),
  }),
});

export const {
  useLazyGetEmployeeByIdQuery,
  useLazyGetCitizenshipQuery,
  useUpdateEmployeeMutation,
  useAddEmployeeMutation,
} = employeeApi;
