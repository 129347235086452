import React from 'react';
import { Tag, Text } from 'yw-ui';
import { getText } from '@/i18n';

import styles from './index.module.css';

const LABELS = {
  ADDED_TO_TRIP: getText('cart:cartItem.addedToTrip'),
};
interface ICartDetailsProps {
  tripId: string;
  tripName: string;
  onUnpin(tripId: string): void;
}
const CartDetails = ({
  tripName,
  tripId,
  onUnpin,
}: ICartDetailsProps) => {
  const handleUnpin = () => {
    onUnpin(tripId);
  };

  return (
    <div className={ styles.wrapper }>
      <div className={ styles.row }>
        <Text type='NORMAL_16' fontWeight={ 600 }>
          {LABELS.ADDED_TO_TRIP}
        </Text>
        <Tag
          label={ tripName }
          onClick={ handleUnpin }
        />
      </div>
    </div>
  );
};

export { CartDetails };
