import i18next from 'i18next';
import dayjs from 'dayjs';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

import 'dayjs/locale/ru.js';
import 'dayjs/locale/en.js';
import 'dayjs/locale/tr.js';

import { resourcesI18 } from 'yw-ui/src';

import { ELanguages, namespaces, storageKeys } from './locales/consts';
import { resources } from './locales';

const events = {
  languageChanged: 'languageChanged',
};

const storageKey = storageKeys.userLanguage;

const currentLng = window.localStorage.getItem(storageKey) as ELanguages || ELanguages.Ru;

dayjs.locale(currentLng.toLowerCase());

const nsValues = Object.values(namespaces);
const lngValues = Object.values(ELanguages);

const resourcesWithUI = {
  En: {
    ...resources.en,
    common: { ...resources.en.common, ...resourcesI18.En.translation },
  },
  Ru: {
    ...resources.ru,
    common: { ...resources.ru.common, ...resourcesI18.Ru.translation },
  },
  Tr: {
    ...resources.tr,
    common: { ...resources.tr.common, ...resourcesI18.Tr.translation },
  },
};

const options = {
  debug: process.env.NODE_ENV !== 'production',

  resources: resourcesWithUI,
  lng: currentLng,
  fallbackLng: ELanguages.Ru,
  supportedLngs: lngValues,
  ns: nsValues,

  interpolation: {
    escapeValue: false,
  },
};

const changeLanguage = (lng: ELanguages) => {
  if (!!lng && lng !== currentLng) {
    dayjs.locale(lng.toLowerCase());
    window.localStorage.setItem(storageKey, lng);
    i18next.changeLanguage(lng).then(() => window.location.reload());
  }
};

const getText = (key: string, opt = {}) => i18next.t(key, opt);

const getTextArray = (key: string) => i18next.t(key, { returnObjects: true });

const lngCb = () => console.log('language change cb invoked!');

i18next.on(events.languageChanged, lngCb);

i18next
  .use(Backend)
  .use(initReactI18next)
  .init(options);

export {
  changeLanguage,
  getText,
  getTextArray,
  currentLng,
};

export default i18next;
