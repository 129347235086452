const throttle = <T extends any[], R>(
  func: (...args: T) => R,
  ms: number,
): (...args: T) => void => {
  let isThrottled = false;
  let savedArgs: T | null = null;
  let savedThis: typeof this | null = null;

  const wrapper = (...args: T) => {
    if (isThrottled) {
      savedArgs = args;
      savedThis = this;

      return;
    }

    func.apply(this, args);

    isThrottled = true;

    setTimeout(() => {
      isThrottled = false;
      if (savedArgs) {
        wrapper.apply(savedThis, savedArgs);
        savedArgs = null;
        savedThis = null;
      }
    }, ms);
  };

  return wrapper;
};

const throttleQueue = <T>(
  func: (item: T) => void,
  waitTime: number = 300,
) => {
  let isWaiting = false;
  const queue: T[] = [];

  const executeFunc = (params: T) => {
    isWaiting = true;

    func(params);

    setTimeout(() => {
      isWaiting = false;

      if (queue.length) {
        const execParams = queue.shift();

        if (execParams) executeFunc(execParams);
      }
    }, waitTime);
  };

  return (params: T) => {
    if (isWaiting) {
      queue.push(params);
    } else {
      executeFunc(params);
    }
  };
};

export { throttle, throttleQueue };
