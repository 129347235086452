import { getText } from '@/i18n';
import { EServiceTypes } from '@/app/bi/models/serviceTypes.ts';

export enum ECartError {
  CartEmpty = 'CartEmpty',
  BalanceNotEnoughMoney = 'BalanceNotEnoughMoney',
  BookingFailed = 'BookingFailed',
}

export const BookingErrorMessage: { [key in ECartError]: string } = {
  [ECartError.CartEmpty]: getText('booking:bookingErrorMessage.cartEmpty'),
  [ECartError.BalanceNotEnoughMoney]: getText('booking:bookingErrorMessage.balanceNotEnoughMoney'),
  [ECartError.BookingFailed]: getText('booking:bookingErrorMessage.bookingFailed'),
};

export const CART_ITEM_TITLE: { [key in EServiceTypes]?: string } = {
  [EServiceTypes.Airline]: getText('constants:typeItemServiceType.air'),
  [EServiceTypes.Hotel]: getText('constants:typeItemServiceType.hotel'),
  [EServiceTypes.Custom]: getText('constants:typeItemServiceType.transfer'),
};
