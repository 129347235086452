import React, { createRef, MutableRefObject, RefObject, useEffect, useRef } from 'react';
import { NavLink, useLocation, useNavigate, useParams } from 'react-router-dom';
import queryString from 'query-string';
import { Button, IconButton, NoResults, PageLoader, Text } from 'yw-ui';

import { currentLng, getText } from '@/i18n';

import { useAppDispatch, useAppSelector } from '@/app/store/hooks/redux.ts';
import { hotelSlice } from '@/app/store/redusers/HotelSlice.ts';
import { searchHotelsSlice } from '@/app/store/redusers/SearchHotelsSlice.ts';
import { useSearchHotelsQuery } from '@/app/bi/api/searchHotelsApi.ts';
import { useAddHotelMutation } from '@/app/bi/api/cartApi.ts';

import { HotelSearchMenu } from '../../components/Menu/HotelSearchMenu';
import { HotelNameBlock } from './components/HotelNameBlock';
import { ImageGallery } from '../../components/ImageGallery';
import { ShowOnMapButton } from '../../components/ShowOnMapButton';
import { NotificationItem } from '@/app/pages/AirlineResult/components/NotificationItem';
import { HotelCheckInCheckOutBlock } from '@/app/components/HotelCheckInCheckOutBlock';
import { Hotel } from './components/Hotel';

import textAbbreviation from '../../bi/utils/textAbbreviation.ts';
import { notification } from '@/app/bi/utils/not.ts';
import { mapStateToHotelPageSearchSettings } from '@/app/bi/utils/hotelsSearch.ts';
import MoneyFormat from '@/app/bi/utils/money.ts';
import { dayjsObject, formatDate } from '@/app/bi/utils/formatDate.ts';
import parseUnix from '@/app/bi/utils/parseDateTime.ts';
import { getSearchDatesDiff } from '@/app/bi/utils/hotels.ts';

import ROUTES from '@/app/bi/constants/routes.ts';
import { ENotificationActionType } from '@/app/bi/constants/settings.ts';

import { EPositionThumbnails } from '@/app/bi/models/hotelsTypes.ts';
import { EServiceTypes } from '@/app/bi/models/serviceTypes.ts';
import {
  IHotelSearchRequest,
  IInitSearchParams,
  IPrepareHotelProviderRate,
} from '@/app/bi/models/hotelSearch/hotelSearchTypes.ts';

import styles from './index.module.css';

const LABELS = {
  MORE_ABOUT_HOTEL: getText('hotels:hotelResult.moreAboutOfHotel'),
  SEARCH: getText('hotels:hotelResult.loader'),
  ADDRESS: getText('hotels:hotelResult.address'),
  DISTANCE_TO_CENTER: (city: string, distance: string) =>
    getText('hotels:hotelResult.distanceToCenter', { city, distance }),
  NOTIFICATIONS_CART: {
    ADD_TO_CART_SUCCESS: getText('hotels:hotelResult.notifications.toCart.success'),
    ADD_TO_CART_ERROR: getText('hotels:hotelResult.notifications.toCart.error'),
    TO_CART: getText('air:result.notifications.cart.toCart'),
  },
  BACK_TO_SEARCH: (city: string) => getText('hotels:hotelResult.backToSearch', { city }),
};

const useInitSearch = () => {
  const { guid: hotelId } = useParams();
  const dispatch = useAppDispatch();
  const { initSearch, resetStore } = searchHotelsSlice.actions;
  const location = useLocation();
  const queryParse = queryString.parse(location.search);
  const {
    checkInDate,
    checkOutDate,
    checkInTime = null,
    checkOutTime = null,
    guestsCount,
    travellersCount,
    regionName,
  } = queryParse;

  useEffect(() => {
    const initSearchParams: IInitSearchParams = {
      regionName: regionName as string,
      hotelId: hotelId as string,
      checkInDate: checkInDate as string,
      checkOutDate: checkOutDate as string,
      guestsCount: Number(guestsCount),
      travellersCount: Number(travellersCount),
      checkInTime: checkInTime as string ?? null,
      checkOutTime: checkOutTime as string ?? null,
    };

    dispatch(initSearch(initSearchParams));

    return () => {
      dispatch(resetStore());
    };
  }, []);
};

const useSearchHotel = () => {
  const { guid: hotelId } = useParams();
  const location = useLocation();
  const queryParse = queryString.parse(location.search);

  const {
    checkInDate,
    checkOutDate,
    checkInTime = null,
    checkOutTime = null,
    roomCount,
    travellersCount,
    regionId,
  } = queryParse;

  const body: IHotelSearchRequest = {
    checkInDate: checkInDate as string,
    checkOutDate: checkOutDate as string,
    checkInTime: checkInTime as string ?? null,
    checkOutTime: checkOutTime as string ?? null,
    guests: Number(travellersCount),
    roomCount: Number(roomCount),
    locale: currentLng,
    hotelId: hotelId as string,
    regionId: regionId as string,
  };

  const { isLoading, isFetching } = useSearchHotelsQuery(body);

  return { isLoading, isFetching };
};

const HotelResultPage = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {
    data,
  } = useAppSelector((state) => state.hotelSlice);
  const { updateSelectedRate } = hotelSlice.actions;

  const buttonsRefs: RefObject<HTMLButtonElement>[] = [createRef(), createRef()];
  const openDatePickerFrom: MutableRefObject<((mode: string) => void) | null> = useRef(null);
  const openDatePickerTo: MutableRefObject<((mode: string) => void) | null> = useRef(null);
  const moreAboutHotelRef: RefObject<HTMLDivElement | null> = useRef(null);

  const [addHotel] = useAddHotelMutation();
  const searchHotelsReducer = useAppSelector((state) => state.searchHotelsReducer);

  const {
    checkin,
    checkout,
    adult,
    region,
    customCheckin,
    customCheckout,
    travellersCount,
  } = searchHotelsReducer;

  useInitSearch();

  const { isLoading, isFetching } = useSearchHotel();

  const renderLoading = () => <PageLoader text={ LABELS.SEARCH } />;

  if (isLoading || isFetching || !data) {
    return renderLoading();
  }

  const {
    item: {
      checkin: hotelCheckin,
      checkout: hotelCheckout,
      stars,
      images,
      city,
      name: hotelName,
      distanceToCenter,
    },
    item,
    id,
  } = data;

  const goToCart = () => navigate(ROUTES.CART.MAIN);

  const handleAddToCart = async (rate: IPrepareHotelProviderRate) => {
    const checkinDate = formatDate(parseUnix(checkin));
    const checkoutDate = formatDate(parseUnix(checkout));
    const dateRangeString = `${checkinDate} — ${checkoutDate}`;
    const newPrice = rate.select.count as number * rate.total;
    const searchDatesDiff = getSearchDatesDiff(dayjsObject(checkin), dayjsObject(checkout));

    const notificationItem = () => (
      <NotificationItem
        hotelName={ hotelName }
        roomType={ rate.name }
        date={ dateRangeString }
        detail={ searchDatesDiff }
        price={ MoneyFormat.moneyWithDecimal(newPrice, true) }
        serviceType={ EServiceTypes.Hotel }
      />
    );

    const bodyAddToCart = {
      bookId: rate.bookId,
      searchId: id,
      roomCount: rate.select.count as number,
      locale: currentLng,
    };

    try {
      await addHotel(bodyAddToCart).unwrap();

      notification({
        title: LABELS.NOTIFICATIONS_CART.ADD_TO_CART_SUCCESS,
        buttonName: LABELS.NOTIFICATIONS_CART.TO_CART,
        content: notificationItem(),
        onClick: goToCart,
      });
    } catch (e) {
      notification({
        title: LABELS.NOTIFICATIONS_CART.ADD_TO_CART_ERROR,
        content: notificationItem(),
        type: ENotificationActionType.error,
        onClick: goToCart,
      });
    }
  };

  const handleChangeCount = (bookId: string, value: number) => {
    dispatch(updateSelectedRate({ bookId, value }));
  };

  const handleSetFnOpenDatePickerFrom = (fn: (mode: string) => void) => {
    openDatePickerFrom.current = fn;
  };

  const handleSetFnOpenDatePickerTo = (fn: (mode: string) => void) => {
    openDatePickerTo.current = fn;
  };

  const handleOpenDatePickerFrom = () => {
    if (openDatePickerFrom.current) {
      openDatePickerFrom.current('time');
    }
  };

  const handleOpenDatePickerTo = () => {
    if (openDatePickerTo.current) {
      openDatePickerTo.current('time');
    }
  };

  const scrollToAmenities = () => {
    if (moreAboutHotelRef.current) {
      window.scroll({
        top: moreAboutHotelRef.current.offsetTop - 150,
        left: 0,
        behavior: 'smooth',
      });
    }
  };

  const handleSearch = () => {
    if (region?.selected?.isRegion) {
      return navigate(ROUTES.SEARCH.HOTELS_REGION);
    }

    const searchParams = mapStateToHotelPageSearchSettings({
      checkin,
      checkout,
      adult,
      regionName: region?.selected?.name as string,
      regionId: data.searchRequest.regionId,
      customCheckin,
      customCheckout,
      travellersCount,
    });

    return navigate({
      pathname: `${ROUTES.SEARCH.HOTEL_PAGE}/${region?.selected?.id}`,
      search: `${queryString.stringify({ ...searchParams })}`,
    });
  };

  const renderSearchPanel = () => (
    <HotelSearchMenu
      subMenu
      onSearchSubMenu={ handleSearch }
      onOpenDatePickerFrom={ handleSetFnOpenDatePickerFrom }
      onOpenDatePickerTo={ handleSetFnOpenDatePickerTo }
      buttonsRefs={ buttonsRefs }
    />
  );

  const renderBackLink = () => (
    <NavLink to={ `${ROUTES.SEARCH.HOTELS_REGION}/${id}` }>
      <IconButton
        iconType='arrowLeft'
        iconColor='LB4'
        textColor='light-blue-4'
        className={ styles.back_link }
      >
        {LABELS.BACK_TO_SEARCH(city)}
      </IconButton>
    </NavLink>
  );

  const renderGallery = () => {
    const imgsList = images.map((img) => ({
      original: img.originUrl,
      thumbnail: img.thumbnailUrl,
    }));

    return (
      <ImageGallery
        autoPlay
        positionThumbnails={ EPositionThumbnails.Left }
        items={ imgsList }
        slideInterval={ 4000 }
      />
    );
  };

  const descriptionStr = item.description
    ? textAbbreviation(item?.description.substring(item?.description.indexOf('<p>')), 300)
    : '';

  const renderPage = () => {
    if (!(data && data.id)) return <NoResults />;

    return (
      <>
        { renderSearchPanel() }
        <div className={ styles.page }>
          {renderBackLink()}
          <HotelNameBlock
            stars={ stars }
            name={ item.name }
            rating={ item.rating }
            ratingType={ item.ratingType }
          />
          <div className={ styles.static }>
            <div className={ styles.gallery }>
              {renderGallery()}
            </div>
            <div className={ styles.info }>
              <div className={ styles.hotel_address }>
                <Text type='bold_16' color='gray-7'>{LABELS.ADDRESS}</Text>
                <Text type='NORMAL_14' color='gray-7'>{item.address}</Text>
                <Text type='NORMAL_14' className={ styles.distance }>
                  {LABELS.DISTANCE_TO_CENTER(city, distanceToCenter.toFixed(2))}
                </Text>
                <ShowOnMapButton onClick={ () => { } }/>
              </div>
              <div className={ styles.description }>
                <Text type='bold_16' color='gray-7'>{LABELS.MORE_ABOUT_HOTEL}</Text>
                <div
                  className={ styles.text }
                  dangerouslySetInnerHTML={ { __html: descriptionStr } }
                />
              </div>
              <Button type='link' onClick={ scrollToAmenities }>
                {LABELS.MORE_ABOUT_HOTEL}
              </Button>
            </div>
          </div>
          <HotelCheckInCheckOutBlock
            checkInTime={ hotelCheckin }
            checkOutTime={ hotelCheckout }
          />
          <div className={ styles.rates }>
            <Hotel
              hotelInfo={ item }
              buttonsRefs={ buttonsRefs }
              onAddToCart={ handleAddToCart }
              onChangeCount={ handleChangeCount }
              onOpenDatePickerFrom={ handleOpenDatePickerFrom }
              onOpenDatePickerTo={ handleOpenDatePickerTo }
              moreAboutHotelRef={ moreAboutHotelRef }
            />
          </div>
        </div>
      </>
    );
  };

  return (
    <div className={ styles.wrap }>
      {renderPage()}
    </div>
  );
};

export { HotelResultPage };
