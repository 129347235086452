import React from 'react';
import { Text } from 'yw-ui/src';
import { getText, getTextArray } from '@/i18n';

import toPlural from '@/app/bi/utils/toPlural';

import styles from './style.module.css';

const LABELS = {
  CONTINUE_SEARCH: getText('hotels:regionResult.progress.checkAnotherProviders'),
  HOTELS: getTextArray('hotels:regionResult.progress.hotels'),
  SEARCH: getTextArray('hotels:regionResult.progress.search'),
};

interface IHotelProgressProps {
  count: number,
}

const HotelProgress = ({ count }: IHotelProgressProps) => (
  <div className={ styles.wrapper }>
    <div className={ styles.text }>
      <Text className={ styles.count } type='bold_24' color='blue-1'>
        {count} {toPlural(count, LABELS.HOTELS)} {LABELS.SEARCH}
      </Text>
    </div>
  </div>
);

export { HotelProgress };
