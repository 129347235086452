import { IconType } from 'yw-ui/src/components/Icon/types.ts';
import { EServiceTypes } from '@/app/bi/models/serviceTypes.ts';

const SERVICE_TYPE = {
  AIR: 'Airline',
  HOTEL: 'Hotel',
  TRANSFER: 'Transfer',
  CUSTOM: 'Custom',
};

const NEW_SERVICES_ICON: { [key in EServiceTypes]: IconType } = {
  [EServiceTypes.Airline]: 'serviceAir',
  [EServiceTypes.Hotel]: 'serviceHotel',
  [EServiceTypes.Transfer]: 'serviceTransfer',
  [EServiceTypes.Custom]: 'serviceDocument',
};

export {
  SERVICE_TYPE,
  NEW_SERVICES_ICON,
};
