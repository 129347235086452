import React, { useCallback, useEffect, useState } from 'react';
import { Button } from 'yw-ui';
import { Dayjs } from 'dayjs';

import { getText } from '@/i18n';

import { useAppDispatch } from '@/app/store/hooks/redux.ts';
import { reportsSlice } from '@/app/store/redusers/ReportsSlice.ts';

import { FilterPanel } from '@/app/components/FilterPanel';
import { ItemFilter } from '@/app/components/ItemFilterList';
import {
  ItemFilterRegistrationDate,
} from '@/app/pages/Reports/components/Filter/components/ItemFilterRegistrationDate';

import { EModeViewFilterPanel, EItemFilterPanel } from '@/app/bi/constants/settings.ts';

import { IFilterItem } from '@/app/bi/models/app.ts';

import styles from './styles/index.module.css';

const LABELS = {
  COMPANIES: getText('reports:companies'),
  REGISTRATION_DATE: getText('reports:registrationDate'),
  PLACEHOLDER_NAME_COMPANY: getText('reports:placeholderNameCompany'),
  TYPE_OPERATION: getText('reports:typeOperation'),
  RESET_FILTERS: getText('trips:resetFilters'),
};

interface FilterInvoiceProps {
  companies: IFilterItem[];
  operationType: IFilterItem[];
  onChangeCompanies(selectItem: IFilterItem): void;
  onChangeOperationType(selectItem: IFilterItem): void;
}

const FilterInvoice = ({
  companies,
  operationType,
  onChangeCompanies,
  onChangeOperationType,
}: FilterInvoiceProps) => {
  const dispatch = useAppDispatch();

  const {
    setFilterMaxDate,
    setFilterMinDate,
    clearAllFilters,
  } = reportsSlice.actions;

  const { CheckBox } = EItemFilterPanel;

  const [inputValue, setInputValue] = useState<string>('');
  const [
    companyFilterName,
    setCompanyFilterName,
  ] = useState<IFilterItem[]>(companies);

  useEffect(() => {
    setCompanyFilterName(companies);
  }, [companies]);

  const handleChangeInputValue = useCallback((value: string) => {
    setInputValue(value);

    const filtered = companies.filter(({
      label,
    }) => label.toLowerCase().includes(value.toLowerCase()));

    const transformationArray = filtered.map(({
      label,
      checked,
    }) => ({
      label,
      checked,
    }));

    setCompanyFilterName(transformationArray);
  }, [companies]);

  const handleClearFilter = () => dispatch(clearAllFilters());

  const handleDateFrom = (value: Dayjs) => dispatch(setFilterMinDate(value));

  const handleDateTo = (value: Dayjs) => dispatch(setFilterMaxDate(value));

  const renderButtonClearFilter = () => (
    <Button onClick={ handleClearFilter } type='text' className={ styles.buttonMore }>
      {LABELS.RESET_FILTERS}
    </Button>
  );

  return (
    <div className={ styles.filterContainer }>
      <FilterPanel>
        <ItemFilter
          data={ companyFilterName }
          filterType={ EModeViewFilterPanel.SearchAndList }
          valueInput={ inputValue }
          onInputChange={ handleChangeInputValue }
          itemFilterType={ CheckBox }
          title={ LABELS.COMPANIES }
          placeholder={ LABELS.PLACEHOLDER_NAME_COMPANY }
          onChange={ onChangeCompanies }
        />
        <ItemFilter
          data={ operationType }
          filterType={ EModeViewFilterPanel.ViewList }
          itemFilterType={ CheckBox }
          title={ LABELS.TYPE_OPERATION }
          onChange={ onChangeOperationType }
        />
        <ItemFilterRegistrationDate
          nameFilter={ LABELS.REGISTRATION_DATE }
          handleDateFrom={ handleDateFrom }
          handleDateTo={ handleDateTo }
        />
        <div className={ styles.buttonMoreContainer }>
          {renderButtonClearFilter()}
        </div>
      </FilterPanel>
    </div>
  );
};

export { FilterInvoice };
