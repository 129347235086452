import { EPositionThumbnails } from '@/app/bi/models/hotelsTypes.ts';
import { IImageGalleryItem } from '@/app/components/ImageGallery/components/ImageThumbnailsItem';

export const getSlideStyle = ({
  index,
  previousIndex,
  items,
  infinite,
  currentIndex,
  offsetPercentage,
}: {
  index: number,
  previousIndex: number,
  items: IImageGalleryItem[],
  infinite: boolean,
  currentIndex: number,
  offsetPercentage: number,
}) => {
  const basetranslateX = -100 * currentIndex;
  const totalSlides = items.length - 1;

  let translateX = basetranslateX + (index * 100) + offsetPercentage;
  let zIndex = 1;

  if (infinite && items.length > 1) {
    if (currentIndex === 0 && index === totalSlides) {
      translateX = -100 + offsetPercentage;
    } else if (currentIndex === totalSlides && index === 0) {
      translateX = 100 + offsetPercentage;
    }
  }

  if (index === currentIndex) {
    zIndex = 3;
  } else if (index === previousIndex) {
    zIndex = 2;
  }

  const translate3d = `translate3d(${translateX}%, 0, 0)`;

  return {
    WebkitTransform: translate3d,
    MozTransform: translate3d,
    msTransform: translate3d,
    OTransform: translate3d,
    transform: translate3d,
    zIndex,
  };
};

export const getThumbnailStyle = (x: number, y: number, position: string) => {
  const translate3dY = `translate3d(0, ${y}px, 0)`;
  const translate3dX = `translate3d(${x}px, 0, 0)`;
  const translate3d = position === EPositionThumbnails.Left
    ? translate3dY
    : translate3dX;

  return {
    WebkitTransform: translate3d,
    MozTransform: translate3d,
    msTransform: translate3d,
    OTransform: translate3d,
    transform: translate3d,
  };
};
