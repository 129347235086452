import { ywApiPrivate } from './index.ts';

import { IMessage } from '@/app/bi/models/intercom.ts';

export const intercomApiApi = ywApiPrivate.injectEndpoints({
  endpoints: (build) => ({
    conversation: build.mutation<IMessage, any>({
      query(body) {
        return {
          url: '/intercom/conversation',
          method: 'POST',
          body,
        };
      },
    }),
  }),
});

export const { useConversationMutation } = intercomApiApi;
