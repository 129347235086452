import React, { useState } from 'react';
import { IconButton, Stars, Text } from 'yw-ui';

import MoneyFormat from 'yw-ui/src/utils/money.ts';
import { getText } from '@/i18n';

import { HotelCheckInCheckOutBlock } from '@/app/components/HotelCheckInCheckOutBlock';
import { MapDialog } from '@/app/components/Maps/MapDialog';
import { GoogleMapComponent } from '@/app/components/Maps/GoogleMap';
import { ImageGallery } from '@/app/components/ImageGallery';

import { HOTEL_TYPES } from '@/app/bi/constants/hotelsSearch.ts';

import { ICartResponseHotelItem } from '@/app/bi/models/cart.ts';
import { EPositionThumbnails } from '@/app/bi/models/hotelsTypes.ts';
import { IMapPoint } from '@/app/bi/models/maps.ts';

import styles from './index.module.css';

const SLIDE_INTERVAL = 4000;

const LABELS = {
  SHOW_ON_MAP: getText('components:hotelSidePanels.showOnMap'),
  MORE_ABOUT_OF_HOTEL: getText('components:hotelSidePanels.moreAboutOfHotel'),
  DISTANCE_TO_CENTER: (city: string, distance: string) =>
    getText('hotels:hotelResult.distanceToCenter', { city, distance }),
};

interface IHotelSidePanelsProps {
  item: ICartResponseHotelItem
}

const HotelSidePanels = ({
  item,
}: IHotelSidePanelsProps) => {
  const [openMap, setOpenMap] = useState(false);
  const [hotel, setHotel] = useState<IMapPoint>({
    lat: 0,
    lng: 0,
    price: '',
    hotelId: '',
  });

  const renderGallery = () => {
    const imgsList = item.images.map((img) => ({
      original: img,
      thumbnail: img,
    }));

    return (
      <ImageGallery
        positionThumbnails={ EPositionThumbnails.Bottom }
        items={ imgsList }
        slideInterval={ SLIDE_INTERVAL }
      />
    );
  };

  const {
    id,
    hotelStars,
    hotelName,
    hotelAddress,
    hotelCity,
    distanceToCenter,
    hotelCheckinTime,
    hotelCheckoutTime,
    hotelType,
    hotelDescription,
    price,
  } = item;

  const handleOpenMap = () => {

    setHotel({
      lat: item.latitude,
      lng: item.longitude,
      price: MoneyFormat.moneyWithDecimal(price, true),
      hotelId: id,
    });
    setOpenMap(true);
  };

  const handleCloseMap = (value: boolean) => {
    setOpenMap(value);
  };

  const renderMap = () => {
    const center = { lat: hotel.lat, lng: hotel.lng };

    return (
      <MapDialog show={ openMap } onChange={ handleCloseMap } renderDependsOnShow>
        <GoogleMapComponent
          center={ center }
          points={ [hotel] }
          zoom={ 15 }
        />
      </MapDialog>
    );
  };

  return (
    <>
      <div className={ styles.details_wrapper }>
        <div className={ styles.headers }>
          <Text type='NORMAL_24' fontWeight={ 700 }>
            {HOTEL_TYPES[hotelType]} {hotelName}
          </Text>
          {hotelStars > 0 && <Stars count={ hotelStars } size={ 24 }/> }
        </div>
        <div className={ styles.subheaders }>
          <div className={ styles.info }>
            <Text type='NORMAL_14'>{hotelAddress}</Text>
            <Text type='NORMAL_14' color='gray-5'>
              {LABELS.DISTANCE_TO_CENTER(hotelCity, distanceToCenter.toString())}
            </Text>
          </div>
          <div className={ styles.actions }>
            <IconButton
              iconType='location'
              iconColor='blue1'
              size={ 24 }
              onClick={ handleOpenMap }
            >
              <Text type='NORMAL_14' className={ styles.text }>
                {LABELS.SHOW_ON_MAP}
              </Text>
            </IconButton>
          </div>
        </div>
        <div className={ styles.images_gallery }>
          {renderGallery()}
        </div>
        <HotelCheckInCheckOutBlock
          checkInTime={ hotelCheckinTime }
          checkOutTime={ hotelCheckoutTime }
        />
        {hotelDescription && (<div className={ styles.description }>
          <Text type='NORMAL_16' fontWeight={ 600 }>{ LABELS.MORE_ABOUT_OF_HOTEL }</Text>
          <div className={ styles.description_item }>
            <Text type='NORMAL_16'>
              {hotelDescription}
            </Text>
          </div>
        </div>
        )}
      </div>
      {renderMap()}
    </>
  );
};
export { HotelSidePanels };
