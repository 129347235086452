import { getText } from '@/i18n';

import { ERatingType } from '@/app/bi/models/hotelSearch/hotelSearchEnum.ts';

// export const SORT_ITEMS = [
//   { label: getText('services:hotels.sort.priceUp'), value: EHotelSortedBystring.Price, type: null },
//   { label: getText('services:hotels.sort.distance'), value: EHotelSortedBystring.DistanceToCenter, type: null },
// ];

export const FILTER_STARS_KEYS = [5, 4, 3, 2, 1];

export const RATING: { [key in ERatingType]: string } = {
  [ERatingType.Perfectly]: getText('constants:hotelSearch.ratingRu.perfectly'),
  [ERatingType.Great]: getText('constants:hotelSearch.ratingRu.great'),
  [ERatingType.GoodEnough]: getText('constants:hotelSearch.ratingRu.goodEnough'),
  [ERatingType.Good]: getText('constants:hotelSearch.ratingRu.good'),
  [ERatingType.Other]: getText('constants:hotelSearch.ratingRu.other'),
};

export const RATING_TYPES: { [key in ERatingType]: string } = {
  [ERatingType.Other]: getText('constants:hotelSearch.rating.other'),
  [ERatingType.GoodEnough]: getText('constants:hotelSearch.rating.goodEnough'),
  [ERatingType.Good]: getText('constants:hotelSearch.rating.good'),
  [ERatingType.Great]: getText('constants:hotelSearch.rating.great'),
  [ERatingType.Perfectly]: getText('constants:hotelSearch.rating.perfectly'),
};

export const STICKY_PANEL_ADJUSTMENT = 120;
export const HIDDEN_PANEL_ADJUSTMENT = 900;
