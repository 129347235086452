import React from 'react';
import { Collapse, Datepicker, Text } from 'yw-ui';
import { Dayjs } from 'dayjs';

import { getText } from '@/i18n';

import { formatDate, getDayjs } from '@/app/bi/utils/formatDate';

import {
  MAX_DATE_DATEPICKER_REGISTRATION_DATE,
  MIN_DATE_DATEPICKER_REGISTRATION_DATE,
} from '@/app/bi/constants/invoice.ts';
import { PATTERN } from '@/app/bi/constants/dateFormats';

import styles from './styles/index.module.css';

const LABELS = {
  FROM: getText('reports:invoice.from'),
  TO: getText('reports:invoice.to'),
};

interface ItemFilterTravelPeriodProps {
  nameFilter: string;
  handleDateFrom(value: Dayjs): void;
  handleDateTo(value: Dayjs): void;
}

const ItemFilterRegistrationDate = ({
  nameFilter = '',
  handleDateFrom,
  handleDateTo,
}: ItemFilterTravelPeriodProps) => {
  const { DEFAULT_PATTERN_FILTER } = PATTERN;

  const DATEPICKER_PLACEHOLDER = formatDate(getDayjs(), DEFAULT_PATTERN_FILTER);

  const renderDatepicker = (label: string, onChange: (value: Dayjs) => void) => (
    <div className={ styles.dateItem }>
      <Text type={ 'NORMAL_14' } className={ styles.text }>{label}</Text>
      <Datepicker
        wrapperClassName={ styles.wrapDatePicker }
        inputClassName={ styles.input }
        onChange={ onChange }
        format={ DEFAULT_PATTERN_FILTER }
        placeholder={ DATEPICKER_PLACEHOLDER }
        max={ MAX_DATE_DATEPICKER_REGISTRATION_DATE }
        min={ MIN_DATE_DATEPICKER_REGISTRATION_DATE }
      />
    </div>
  );

  return (
    <Collapse label={ nameFilter }>
      <div className={ styles.dateContainer }>
        {renderDatepicker(LABELS.FROM, handleDateFrom)}
        {renderDatepicker(LABELS.TO, handleDateTo)}
      </div>
    </Collapse>
  );
};

export { ItemFilterRegistrationDate };
