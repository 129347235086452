import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { INIT_AIRLINE_POLICY, INIT_HOTEL_POLICY } from '@/app/bi/constants/travelPolicy.ts';

import { EPriceType, IAirlinePolicy, IHotelPolicy } from '@/app/bi/models/travelPolicies.ts';
import { ECurrencyCode } from '@/app/bi/models/hotelSearch/hotelSearchEnum.ts';

interface ITravelPolicyState {
  maxPriceChecked: boolean;
  maxPricePerNightChecked: boolean;
  maxPriceDifferenceChecked: boolean;
  maxDayByLimitChecked: boolean;
  airlinePolicy: IAirlinePolicy;
  hotelPolicy: IHotelPolicy;
}

const initialState: ITravelPolicyState = {
  maxPriceChecked: false,
  maxPricePerNightChecked: false,
  maxPriceDifferenceChecked: false,
  maxDayByLimitChecked: false,
  airlinePolicy: INIT_AIRLINE_POLICY,
  hotelPolicy: INIT_HOTEL_POLICY,
};

export const travelPolicySlice = createSlice({
  name: 'travelPolicySlice',
  initialState,
  reducers: {
    setPolicy: (state, action: PayloadAction<{ avia: IAirlinePolicy, hotel: IHotelPolicy }>) => {
      state.airlinePolicy = action.payload.avia;
      state.hotelPolicy = action.payload.hotel;
    },
    setClear: (state) => {
      state.airlinePolicy = initialState.airlinePolicy;
      state.hotelPolicy = initialState.hotelPolicy;
      state.maxPriceChecked = initialState.maxPriceChecked;
      state.maxPricePerNightChecked = initialState.maxPricePerNightChecked;
      state.maxPriceDifferenceChecked = initialState.maxPriceDifferenceChecked;
      state.maxDayByLimitChecked = initialState.maxDayByLimitChecked;
    },
    setMaxPricePerNight: (state, action: PayloadAction<number>) => {
      state.hotelPolicy.maxPricePerNight = action.payload;
    },
    setMaxPricePerNightChecked: (state, action: PayloadAction<boolean>) => {
      state.maxPricePerNightChecked = action.payload;
    },
    setMaxPricePerNightCurrency: (state, action: PayloadAction<ECurrencyCode>) => {
      state.hotelPolicy.maxPricePerNightCurrency = action.payload;
    },
    setEclc: (state, action: PayloadAction<boolean>) => {
      state.hotelPolicy.eclc = action.payload;
    },
    setRefundable: (state, action: PayloadAction<boolean>) => {
      state.hotelPolicy.refundable = action.payload;
    },
    setWeekdays: (state, action: PayloadAction<boolean>) => {
      state.hotelPolicy.weekdays = action.payload;
    },
    setWeekdaysAvia: (state, action: PayloadAction<boolean>) => {
      state.airlinePolicy.weekdays = action.payload;
    },
    setRefundableAvia: (state, action: PayloadAction<boolean>) => {
      state.airlinePolicy.refundable = action.payload;
    },
    setBaggageAvia: (state, action: PayloadAction<boolean>) => {
      state.airlinePolicy.baggage = action.payload;
    },
    setDaysBuyLimitAvia: (state, action: PayloadAction<number>) => {
      state.airlinePolicy.daysBuyLimit = action.payload;
    },
    setDaysBuyLimitCheckedAvia: (state, action: PayloadAction<boolean>) => {
      state.maxDayByLimitChecked = action.payload;
    },
    setMaxPriceAvia: (state, action: PayloadAction<number>) => {
      state.airlinePolicy.maxPrice = action.payload;
    },
    setMaxPriceCurrencyAvia: (state, action: PayloadAction<ECurrencyCode>) => {
      state.airlinePolicy.maxPriceCurrency = action.payload;
    },
    setMaxPricePerNightCheckedAvia: (state, action: PayloadAction<boolean>) => {
      state.maxPriceChecked = action.payload;
    },
    setPriceDifferenceLimitAvia: (state, action: PayloadAction<number>) => {
      state.airlinePolicy.priceDifferenceLimit = action.payload;
    },
    setPriceDifferenceLimitTypeAvia: (state, action: PayloadAction<EPriceType>) => {
      state.airlinePolicy.priceDifferenceLimitType = action.payload;
    },
    setMaxPriceDifferenceLimitCheckedAvia: (state, action: PayloadAction<boolean>) => {
      state.maxPriceDifferenceChecked = action.payload;
    },
    setDifferenceLimitCurrencyAvia: (state, action: PayloadAction<ECurrencyCode>) => {
      state.airlinePolicy.priceDifferenceLimitCurrency = action.payload;
    },
  },
});

export const {
  setEclc,
  setClear,
  setPolicy,
  setWeekdays,
  setRefundable,
  setBaggageAvia,
  setWeekdaysAvia,
  setMaxPriceAvia,
  setRefundableAvia,
  setDaysBuyLimitAvia,
  setMaxPricePerNight,
  setMaxPriceCurrencyAvia,
  setDaysBuyLimitCheckedAvia,
  setMaxPricePerNightChecked,
  setPriceDifferenceLimitAvia,
  setMaxPricePerNightCurrency,
  setDifferenceLimitCurrencyAvia,
  setMaxPricePerNightCheckedAvia,
  setPriceDifferenceLimitTypeAvia,
  setMaxPriceDifferenceLimitCheckedAvia,
} = travelPolicySlice.actions;
export default travelPolicySlice.reducer;
