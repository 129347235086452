import { getText } from '@/i18n';

const PLACEHOLDER_EMPLOYEE_INPUT = {
  FIRST_NAME: getText('settings:firstName'),
  LAST_NAME: getText('settings:lastName'),
  EMAIL: 'email@gmail.com',
};

export {
  PLACEHOLDER_EMPLOYEE_INPUT,
};
