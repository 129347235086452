import React from 'react';
import { Button, Dialog, Text, DotLoading } from 'yw-ui';

import { getText } from '@/i18n';

import styles from './index.module.css';
import { useGetCancelRulesQuery } from '@/app/bi/api/cartApi.ts';

const LABELS = {
  RULES: getText('checkout:rulesDialog.rules'),
  OK: getText('checkout:rulesDialog.ok'),
  NO_INFO: getText('checkout:rulesDialog.item.noInfo'),
  CONDITIONS: getText('checkout:rulesDialog.item.conditions'),
};
interface DialogRulesProps {
  show: boolean,
  onClose(): void,
}
const DialogRules = ({
  show,
  onClose,
}: DialogRulesProps) => {
  const { data = [], isLoading } = useGetCancelRulesQuery();

  const renderLoading = () => (
    <div className={ styles.loading }>
      <DotLoading />
    </div>
  );

  const renderRules = () => {
    if (!data.length) return LABELS.NO_INFO;

    return data.map(({ rules }, mainIdx) => rules.map((item, idx) => (
      <Text key={ `rules_${mainIdx}_${idx}` } color='gray-7' type='NORMAL_12'>{item}</Text>
    )));
  };

  const renderContent = () => {
    const rulesHtml = isLoading ? renderLoading() : renderRules();

    return (
      <div className={ styles.rules }>
        <Text type='NORMAL_14' fontWeight={ 600 }>{LABELS.CONDITIONS}</Text>
        <div className={ styles.info }>
          {rulesHtml}
        </div>
      </div>
    );
  };

  return (
    <Dialog
      showClosing
      show={ show }
      className={ styles.dialog }
      onChange={ onClose }
    >
      <div className={ styles.wrapper } >
        <div className={ styles.content }>
          <Text type='bold_20' fontWeight={ 700 } >{LABELS.RULES}</Text>
          {renderContent()}
        </div>
        <div className={ styles.action }>
          <Button
            type='secondary'
            onClick={ onClose }
          >
            {LABELS.OK}
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export { DialogRules };
