import React from 'react';
import { MultiSelect } from 'yw-ui';
import { getText } from '@/i18n';

import styles from '../../index.module.css';

const LABELS = {
  FLIGHT_NUMBERS: getText('air:result.filters.flightNumbers'),
};

interface FlightsNumbersProps {
  flightNumbers: string[],
  values: string[],
  onChange(checkedCities: string[]): void;
}
const FlightsNumbersFilter = ({
  flightNumbers,
  values,
  onChange,
}: FlightsNumbersProps) => {
  const handleChange = (value: string[]) => {
    onChange(value);
  };

  const list = flightNumbers.map((item) => ({
    label: item,
    value: item,
  }));

  return (
    <div className={ `${styles.item} ${styles['flight-numbers']}` }>
      <MultiSelect
        withLabel
        className={ styles['multi-select-wrap'] }
        placeholder={ LABELS.FLIGHT_NUMBERS }
        list={ list }
        values={ values }
        search
        searchPlaceholder={ LABELS.FLIGHT_NUMBERS }
        onChange={ handleChange }
      />
    </div>
  );
};

export { FlightsNumbersFilter };
