import { IHotelSearchImageResponse } from '@/app/bi/models/hotelSearch/hotelSearchTypes.ts';

const preloadOrigUrlAndUrl = async (img: IHotelSearchImageResponse) => {
  const { originUrl, thumbnailUrl } = img;

  try {
    await Promise.all([
      new Promise((res, rej) => {
        const imageOrigUrl = new Image();
        imageOrigUrl.src = originUrl;
        imageOrigUrl.onload = res;
        imageOrigUrl.onerror = rej;
      }),
      new Promise((res, rej) => {
        const imageUrl = new Image();
        imageUrl.src = thumbnailUrl;
        imageUrl.onload = res;
        imageUrl.onerror = rej;
      }),
    ]);

    return img;
  } catch {
    return null;
  }
};

const preloadOrigUrl = async (OriginUrl: string) => {
  try {
    const promise = await new Promise((res, rej) => {
      const imageOriginUrl: HTMLImageElement = new Image();
      imageOriginUrl.src = OriginUrl;
      imageOriginUrl.onload = res;
      imageOriginUrl.onerror = rej;
    });

    return promise;
  } catch {
    return null;
  }
};

export { preloadOrigUrlAndUrl, preloadOrigUrl };
