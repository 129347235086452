import React from 'react';
import { Checkbox, Collapse } from 'yw-ui';
import { getText } from '@/i18n';

import styles from '../../index.module.css';

const LABELS = {
  LABEL: getText('air:result.filters.listAirports'),
};

interface TransferAirportsFilterProps {
  airports: string[];
  values: string[];
  onChange(checkedCities: string[]): void;
}
const TransferAirportsFilter = ({
  airports,
  values,
  onChange,
}: TransferAirportsFilterProps) => {
  const handleChange = (value: string) => {
    const valuesSet = new Set(values);

    if (valuesSet.has(value)) {
      valuesSet.delete(value);

      return onChange([...valuesSet]);
    }

    valuesSet.add(value);

    return onChange([...valuesSet]);
  };

  const renderAirlines = () => [...airports].sort().map((name, index) => {
    const checked = values.includes(name);

    return (
      <Checkbox
        themes='dark'
        className={ styles.checkbox }
        key={ `check_airline_${index}` }
        value={ checked }
        onChange={ () => handleChange(name) }
      >
        { name }
      </Checkbox>
    );
  });

  return (
    <Collapse
      opened
      className={ styles.item }
      label={ LABELS.LABEL }
    >
      { renderAirlines() }
    </Collapse>
  );
};

export { TransferAirportsFilter };
