import { ESCAPECHARS, SPECIALCHARSBYGROUPS } from '../constants/regExp';

const splitWithoutRemovingSeparator = (
  string: string | never,
  separator: RegExp | string = SPECIALCHARSBYGROUPS,
  needConvertSeparatorToPattern?: boolean,
): string[] => {
  if (typeof string !== 'string' || (typeof separator !== 'string' && typeof separator !== 'object')) return [];

  let pattern;
  if (needConvertSeparatorToPattern) {
    const separatorString = separator instanceof RegExp ? separator.source : separator;
    const escapeQuery = separatorString.replace(ESCAPECHARS, '\\$&');
    pattern = new RegExp(`(${escapeQuery})`, 'ig');
  } else {
    pattern = separator;
  }

  return string.split(pattern).filter((element) => element !== '');
};

export default splitWithoutRemovingSeparator;
