import { ywApiPrivate } from './index.ts';

import { IBookResultResponse } from '@/app/bi/models/booking.ts';
import { ICartResponse } from '@/app/bi/models/cart.ts';

export const checkoutApi = ywApiPrivate.injectEndpoints({
  endpoints: (build) => ({
    getCheckoutCart: build.query<ICartResponse, void>({
      query: () => ({
        url: '/cart',
        method: 'GET',
      }),
    }),
    checkCartBeforeBooking: build.query<IBookResultResponse, void>({
      query: () => ({
        url: '/booking/checkout',
      }),
    }),
  }),
});

export const {
  useLazyGetCheckoutCartQuery,
} = checkoutApi;
