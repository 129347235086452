import { createSlice } from '@reduxjs/toolkit';
import { IAirlineSearchFilter, Items } from '../../bi/models/airlineTypes.ts';
import { ITag } from '@/app/bi/models/shared.ts';

interface AirlineState {
  loading: boolean,
  tags: ITag[],
  originFilters: IAirlineSearchFilter,
  filters: IAirlineSearchFilter,
  sortBy: string,
  items: Items[],
  paging: {
    total: number,
    current: number,
    count: number,
  }
}

// @ts-ignore
const initialState: AirlineState = {
  loading: true,
  items: [],
  sortBy: 'price_down',
  paging: {
    total: 0,
    current: 1,
    count: 15,
  },
};

export const airlineSlice = createSlice({
  name: 'airlineSlice',
  initialState,
  reducers: {},
});

export default airlineSlice.reducer;
