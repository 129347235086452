import React, { ReactNode } from 'react';

import styles from './index.module.css';

interface FilterPanelProps {
  children: ReactNode,
}

const FilterPanel = ({ children }: FilterPanelProps) => (
  <div className={ styles.filters }>
    { children }
  </div>
);

export { FilterPanel };
