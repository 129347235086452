import { ywApiPublic } from './index.ts';

interface ISearchAuth {
  email: string,
  password: string,
}

interface IResponseSearchAuth {
  token: string;
}

interface IRestorePassword {
  email: string,
}

interface ISetResetPasswordRequest {
  email: string
  password: string,
  token: string,
}
export const authApi = ywApiPublic.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation<IResponseSearchAuth, ISearchAuth>({
      query(body) {
        return {
          url: '/auth',
          method: 'POST',
          body,
        };
      },
      transformResponse(_, meta) {
        return { token: meta?.response?.headers.get('Authorization') || '' };
      },
    }),
    restorePassword: builder.mutation<void, IRestorePassword>({
      query(body: IRestorePassword) {
        return {
          url: 'user/restore-password',
          method: 'POST',
          body,
        };
      },
    }),
    setPassword: builder.mutation<any, ISetResetPasswordRequest>({
      query(body) {
        return {
          url: '/register/complete',
          method: 'POST',
          body,
        };
      },
    }),
    resetPassword: builder.mutation<void, ISetResetPasswordRequest>({
      query(body) {
        return {
          url: '/user/reset-password',
          method: 'POST',
          body,
        };
      },
    }),
    refresh: builder.mutation({
      query: () => ({ url: '/auth/refresh', method: 'POST' }),
    }),
    logout: builder.mutation<void, void>({
      query: () => ({ url: '/auth/logout', method: 'POST' }),
    }),
  }),
});

export const { useLoginMutation, useLogoutMutation } = authApi;
