import React from 'react';
import { Button, Checkbox, IconButton, Select, Text } from 'yw-ui';
import clsx from 'clsx';
import { getText } from '@/i18n';

import {
  useAppDispatch,
  useAppSelector,
} from '@/app/store/hooks/redux.ts';
import { searchAirlineSlice } from '@/app/store/redusers/SearchAirlineSlice.ts';

import { AirlineSearchRoute } from '../AirlineSearchRoute';

import { getClassOptions } from '@/app/bi/utils/airlineSearch.ts';

import { TRAVELLERS_OPTIONS } from '@/app/bi/constants/airline.ts';

import styles from './styles.module.css';

const LABELS = {
  ADD_FLIGHT: getText('components:menu.air.main.addFlight'),
  SEARCH: getText('common:search'),
  TRAVELLERS: getText('components:menu.air.main.passengers'),
  CLASS: getText('components:menu.air.main.class'),
  DIRECT: getText('components:menu.air.main.direct'),
  COMPLEX: getText('components:menu.air.main.complex'),
};

interface AirlineMainMenuProps {
  onChangeAdult(value: number): void;
  onChangeFlightClass(value: string): void;
  onSearch(): void;
  loading?: boolean;
}

const AirlineMainMenu = ({
  onChangeAdult,
  onChangeFlightClass,
  onSearch,
  loading = false,
}: AirlineMainMenuProps) => {
  const {
    routes,
    travellers,
    flightClass,
    isDirect,
    isComplex,
    isValid,
  } = useAppSelector((state) => state.searchAirlineSlice);
  const dispatch = useAppDispatch();
  const {
    setIsDirect,
    setIsComplex,
    addSearchRoute,
  } = searchAirlineSlice.actions;

  const handleChangeComplexCheckbox = (value: boolean) => dispatch(setIsComplex(value));

  const handleChangeDirectCheckbox = (value: boolean) => dispatch(setIsDirect(value));

  const renderLabel = (label: string, style = '') => (
    <Text type='NORMAL_14' color='white' className={ style }>{label}</Text>
  );

  const renderAddButton = () => (
    <div className={ styles.add_button }>
      <IconButton
        iconType='add'
        iconColor='blue1'
        onClick={ () => dispatch(addSearchRoute()) }
      >
        <Text type='NORMAL_16' color='blue-1'>{LABELS.ADD_FLIGHT}</Text>
      </IconButton>
    </div>
  );

  const addButtonHtml = isComplex && renderAddButton();
  const routesClass = clsx(styles.row_route, {
    [styles.complex]: isComplex,
  });

  const routesHtml = routes.map((route, idx) => (
    <AirlineSearchRoute
      key={ `airline__row__${idx}` }
      ind={ idx }
      route={ route }
      isComplex={ isComplex }
    />
  ));

  const handleSearch = async () => {
    onSearch();
  };

  const itemsClass = getClassOptions();

  return (
    <div className={ styles.wrapper }>
      <div className={ routesClass }>
        {routesHtml}
        <div className={ styles.action }>
          {addButtonHtml}
          <Button
            className={ styles.search_button }
            type='primary'
            size='large'
            onClick={ handleSearch }
            disabled={ !isValid }
            loading={ loading }
          >
            {LABELS.SEARCH}
          </Button>
        </div>
      </div>
      <div className={ styles.options }>
        <div className={ styles.part_options }>
          <div className={ styles.select }>
            <Text type='NORMAL_14' color='white'>{LABELS.TRAVELLERS}</Text>
            <div className={ styles.passengers }>
              <Select
                theme='default-small'
                items={ TRAVELLERS_OPTIONS }
                value={ travellers }
                onChange={ onChangeAdult }
                className={ styles.select_round_edges }
                contentClassName={ styles.dialog_wrapper }
              />
            </div>
          </div>
          <div className={ styles.select }>
            <Text type='NORMAL_14' color='white'>
              {LABELS.CLASS}
            </Text>
            <div className={ styles.fly_class }>
              <Select
                theme='default-small'
                items={ itemsClass }
                value={ flightClass }
                onChange={ onChangeFlightClass }
                className={ styles.select_round_edges }
                contentClassName={ styles.dialog_wrapper }
              />
            </div>
          </div>
        </div>
        <div className={ styles.part_options }>
          <Checkbox
            value={ isDirect }
            themes='light'
            onChange={ handleChangeDirectCheckbox }
          >
            {renderLabel(LABELS.DIRECT)}
          </Checkbox>
          <Checkbox
            value={ isComplex }
            themes='light'
            onChange={ handleChangeComplexCheckbox }
          >
            {renderLabel(LABELS.COMPLEX)}
          </Checkbox>
        </div>
      </div>
    </div>
  );
};

export { AirlineMainMenu };
