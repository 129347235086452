import React from 'react';
import { Input, Textarea } from 'yw-ui';

import { getText } from '@/i18n';

import { FieldLabel } from '@/app/components/FieldLabel';

import styles from './styles/index.module.css';

const LABELS = {
  NAME_TRAVEL_POLICY: getText('settings:travelPolicies.nameTravelPolicy'),
  WRITE_NAME_POLICY: getText('settings:travelPolicies.writeNamePolicy'),
  DESCRIPTION_TRAVEL_POLICY: getText('settings:travelPolicies.descriptionTravelPolicy'),
  WRITE_DESCRIPTION_POLICY: getText('settings:travelPolicies.writeDescriptionPolicy'),
};

interface PolicyNameProps {
  namePolicy: string;
  descriptionPolicy: string;
  setNamePolicy(value: string): void;
  setDescriptionPolicy(value: string): void;
}

export const PolicyName = ({
  namePolicy,
  descriptionPolicy,
  setNamePolicy,
  setDescriptionPolicy,
}: PolicyNameProps) => (
  <div className={ styles.wrap }>
    <div>
      <FieldLabel text={ LABELS.NAME_TRAVEL_POLICY } isRequired/>
      <Input
        value={ namePolicy }
        onChange={ setNamePolicy }
        placeholder={ LABELS.WRITE_NAME_POLICY }
        max={ 100 }
      />
    </div>
    <div className={ styles.description_policy }>
      <FieldLabel text={ LABELS.DESCRIPTION_TRAVEL_POLICY }/>
      <Textarea
        value={ descriptionPolicy }
        className={ styles.input }
        onChange={ setDescriptionPolicy }
        placeholder={ LABELS.WRITE_DESCRIPTION_POLICY }
      />
    </div>
  </div>
);
