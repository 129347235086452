import { Dayjs } from 'dayjs';
import { getText } from '@/i18n';

import { diffHours, diffMinutes } from './formatDate';
import { lpad } from './pad';

const LABELS = {
  COMMON_SECONDS_TO_LABEL: {
    NOT_HOURS: (min: number) => getText('utils:time.commonSecondsToLabel.notHours', { min }),
    NOT_MINUTES: (hours: number) => getText('utils:time.commonSecondsToLabel.notMinutes', { hours }),
    DEFAULT: (hours: number, minutes: number) => getText('utils:time.commonSecondsToLabel.default', { hours, minutes }),
  },
  SECONDS_TO_LABELS_WITH_DAYS: {
    NOT_HOURS: (days: number, min: string) => getText('utils:time.secondsToLabelsWithDays.notHours', { days, min }),
    NOT_MINUTES: (days: number, hours: number) => getText('utils:time.secondsToLabelsWithDays.notMinutes', { days, hours }),
    DEFAULT: (days: number, hours: number, min: string) => getText('utils:time.secondsToLabelsWithDays.default', { days, hours, min }),
  },
};

const commonSecondsToLabel = (hours: number, minutes: number) => {
  if (!hours) {
    return LABELS.COMMON_SECONDS_TO_LABEL.NOT_HOURS(minutes);
  }

  if (!minutes) {
    return LABELS.COMMON_SECONDS_TO_LABEL.NOT_MINUTES(hours);
  }

  return LABELS.COMMON_SECONDS_TO_LABEL.DEFAULT(hours, minutes);
};

const secondsToLabelsWithDays = (value: number) => {
  const minutes = Math.floor(value / 60) % 60;
  const hours = Math.floor(value / 3600) % 24;
  const days = Math.floor(value / 86400);

  if (!days) {
    return commonSecondsToLabel(hours, minutes);
  }

  if (!hours) {
    return LABELS.SECONDS_TO_LABELS_WITH_DAYS.NOT_HOURS(days, lpad(minutes));
  }

  if (!minutes) {
    return LABELS.SECONDS_TO_LABELS_WITH_DAYS.NOT_MINUTES(days, hours);
  }

  return LABELS.SECONDS_TO_LABELS_WITH_DAYS.DEFAULT(days, hours, lpad(minutes));
};

const secondsToLabel = (value: number, isDays = false) => {
  if (isDays) return secondsToLabelsWithDays(value);

  const minutes = Math.floor(value / 60) % 60;
  const hours = Math.floor(value / 3600);

  return commonSecondsToLabel(hours, minutes);
};

const diffMinutesAndHours = (first: Dayjs, second: Dayjs) => {
  const minutes = Number(diffMinutes(first, second)) % 60;
  const hours = diffHours(first, second);

  return commonSecondsToLabel(hours, minutes);
};

const getTimeDifferenceFormatted = (start: Dayjs, end: Dayjs): string => {
  const diff = end.valueOf() - start.valueOf();
  const minutes = Math.floor(diff / 60000);
  const hours = Math.floor(minutes / 60);

  const remainingMinutes = minutes % 60;

  return hours > 0
    ? commonSecondsToLabel(hours, remainingMinutes)
    : commonSecondsToLabel(0, remainingMinutes);
};

export {
  secondsToLabel,
  diffMinutesAndHours,
  commonSecondsToLabel,
  getTimeDifferenceFormatted,
};
