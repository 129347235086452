import {
  ICartResponse,
  ICartItemGroupByTrips,
  ICartWorkPlaceGroupByTrips, TCartResponseItem,
} from '@/app/bi/models/cart.ts';

const getCartTripItemGroupByTrips = (items: TCartResponseItem[]): ICartItemGroupByTrips[] =>
  items.reduce<ICartItemGroupByTrips[]>((acc, tripItem) => {
    const foundIndex = acc.findIndex((item) => item.tripId === tripItem.tripId);

    if (foundIndex !== -1) {
      acc[foundIndex] = {
        ...acc[foundIndex],
        totalPrice: acc[foundIndex].totalPrice + tripItem.price,
        items: [
          ...acc[foundIndex].items,
          tripItem,
        ],
      };

      if (!acc[foundIndex].serviceTypes.includes(tripItem.serviceType)) {
        acc[foundIndex].serviceTypes.push(tripItem.serviceType);
      }
    } else {
      acc.push({
        tripId: tripItem.tripId,
        tripName: tripItem.tripName,
        totalPrice: tripItem.price,
        serviceTypes: [tripItem.serviceType],
        items: [tripItem],
      });
    }

    return acc;
  }, []);

const prepareTripItemsByTripId = (response: ICartResponse): ICartWorkPlaceGroupByTrips => ({
  ...response,
  items: getCartTripItemGroupByTrips(response.items),
});

export {
  getCartTripItemGroupByTrips,
  prepareTripItemsByTripId,
};
