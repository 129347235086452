import React, { ReactNode } from 'react';
import { Icon, Price } from 'yw-ui';

import { IconType } from 'yw-ui/src/components/Icon/types.ts';

import clsx from 'clsx';
import { NEW_SERVICES_ICON } from '@/app/bi/constants/serviceType.ts';

import { EServiceTypes } from '@/app/bi/models/serviceTypes.ts';

import styles from './styles/index.module.css';

interface ItemLayoutProps {
  serviceType: EServiceTypes,
  className?: string
  price: number,
  children: ReactNode,
}
const ItemLayout = ({
  serviceType,
  className = '',
  price,
  children,
}: ItemLayoutProps) => {
  const wrapClassName = clsx([styles.wrap], {
    [className]: className,
  });

  const icon: IconType = NEW_SERVICES_ICON[serviceType];

  return (
    <div className={ wrapClassName }>
      <div className={ styles.content }>
        <div className={ styles.main }>
          <div className={ styles.icon }>
            <Icon
              type={ icon }
              size={ 40 }
              circleColor='gray1'
            />
          </div>
          <div className={ styles.children }>
            { children }
          </div>
        </div>
      </div>
      <div className={ styles.actions }>
        <div className={ styles.price }>
          <Price
            value={ price }
            marginSmall
            type='bold_20'
            typeCurrency='bold_20'
          />
        </div>
      </div>
    </div>
  );
};

export { ItemLayout };
