import { getText, getTextArray } from '@/i18n';
import { EAirlineClass } from '@/app/bi/models/airlineTypes.ts';

const LABELS = {
  SORT_BY_PRICE: getText('services:airline.sort.byPrice'),
  SORT_BY_DURATION: getText('services:airline.sort.byDuration'),
  SORT_BY_DEPARTURE_TIME: getText('services:airline.sort.byDepartureTime'),
  SORT_BY_TRANSFER_TIME: getText('services:airline.sort.byTransferTime'),
};

export const FLY_CLASS: { [key: string]: string } = {
  [EAirlineClass.Econom]: getText('constants:airline.flyClass.econom'),
  [EAirlineClass.Business]: getText('constants:airline.flyClass.business'),
  [EAirlineClass.First]: getText('constants:airline.flyClass.first'),
};

export const TRAVELLERS_OPTIONS = [
  { label: '1', value: 1 },
  { label: '2', value: 2 },
  { label: '3', value: 3 },
  { label: '4', value: 4 },
  { label: '5', value: 5 },
  { label: '6', value: 6 },
  { label: '7', value: 7 },
  { label: '8', value: 8 },
  { label: '9', value: 9 },
];

export const SORT_VALUE = {
  PRICE_DOWN: 'Price',
  DISTANCE_DOWN: 'FlightDuration',
  DEPARTURE_TIME_DOWN: 'DepartureDateTime',
  TRANSFER_TIME_DOWN: 'TransferDuration',
};

export const SORT_ITEMS = [
  { label: LABELS.SORT_BY_PRICE, value: SORT_VALUE.PRICE_DOWN, type: 1 },
  { label: LABELS.SORT_BY_DURATION, value: SORT_VALUE.DISTANCE_DOWN, type: 1 },
  { label: LABELS.SORT_BY_DEPARTURE_TIME, value: SORT_VALUE.DEPARTURE_TIME_DOWN, type: 1 },
  { label: LABELS.SORT_BY_TRANSFER_TIME, value: SORT_VALUE.TRANSFER_TIME_DOWN, type: 1 },
];

export const TECHNICAL_LANDINGS = {
  FIRST: 'AA',
  LAST: 'AD',
};

export const VALUE_TICKET = {
  INCLUDED: 'Included',
  CHARGE: 'Charge',
  NOT_OFFERED: 'NotOffered',
  NOT_DEFINED: 'NotDefined',
  NOT_PENALTY: 'NotPenalty',
};

export const REFUND_TICKET: { [key: string]: string } = {
  Included: getText('constants:airline.refundTicket.included'),
  NotOffered: getText('constants:airline.refundTicket.notOffered'),
  Charge: getText('constants:airline.refundTicket.charge'),
};

export const CHANGE_TICKET: { [key: string]: string } = {
  Included: getText('constants:airline.changeTicket.included'),
  NotOffered: getText('constants:airline.changeTicket.notOffered'),
  Charge: getText('constants:airline.changeTicket.charge'),
};

export const BAGGAGE: { [key: string]: string } = {
  Included: getText('constants:airline.baggage.included'),
  NotOffered: getText('constants:airline.baggage.notOffered'),
  Charge: getText('constants:airline.baggage.charge'),
};

export const TRANSFER = getTextArray('constants:airline.transferDeclines');

export const TICKETS = getTextArray('constants:airline.ticketDeclines');

export const DEFAULT_AIRLINE_SEARCH_FILTER = {
  searchSort: {
    sortedBy: SORT_VALUE.PRICE_DOWN,
    descending: false,
  },
  price: {
    min: 0,
    max: 0,
  },
  transferDuration: {
    min: 0,
    max: 0,
  },
  flightDuration: {
    min: 0,
    max: 0,
  },
  arrivalCity: '',
  departureCity: '',
  transfersCount: [],
  airlines: [],
  transferAirports: [],
  travelPolicyId: '',
  flightNumbers: [],
  routeFilters: [],
  baggage: [],
  page: 1,
};
