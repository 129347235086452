import { Dayjs } from 'dayjs';
import { dateWithoutDayjs, formatDate, dayjsObject } from './formatDate';
import { getText } from '@/i18n';

import CONFIG from '@/config.ts';

import { IAirlineSearchFilter, IRouteFilters, Segment } from '@/app/bi/models/airlineTypes.ts';
import MoneyFormat from '@/app/bi/utils/money.ts';
import { secondsToLabel } from '@/app/bi/utils/time.ts';

import { BAGGAGE } from '../constants/airline';
import { DATE_FORMATS, PATTERN } from '../constants/dateFormats';
import { AIR_FILTER_TYPE } from '@/app/bi/constants/tagsFilterType.ts';

import { IRoutes } from '../models/airlineSearchTypes.ts';
import { IAirlineRoute } from '@/app/bi/models/cart.ts';
import { IInfoTerminal, ITerminals } from '@/app/bi/models/airline.ts';
import { ITag } from '@/app/bi/models/shared.ts';

import parseUnix from '@/app/bi/utils/parseDateTime.ts';

const TAGS_LABELS = {
  PRICE_FROM_TO: (from: string, to: string) => getText('services:airline.store.airline.tags.priceFromTo', { from, to }),
  TRANSFER_TIME: (from: string, to: string) => getText('services:airline.store.airline.tags.transferTime', { from, to }),
  TRANSFER_COUNT: (count: string) => getText('services:airline.store.airline.tags.transferCount', { count }),
  WITHOUT_TRANSFERS: getText('services:airline.store.airline.tags.withoutTransfers'),
  FROM: (name: string) => getText('services:airline.store.airline.tags.from', { name }),
  TO: (name: string) => getText('services:airline.store.airline.tags.to', { name }),
  DEPARTURE_FROM_TO: (from: string, to: string) => getText('services:airline.store.airline.tags.departureFromTo', { from, to }),
  ARRIVAL_FROM_TO: (from: string, to: string) => getText('services:airline.store.airline.tags.arrivalFromTo', { from, to }),
  TRAVEL_TIME: (from: string, to: string) => getText('services:airline.store.airline.tags.travelTime', { from, to }),
  FLIGHT_NUMBERS: (numbers: string) => getText('services:airline.store.airline.tags.flightNumbers', { numbers }),
  TWO_AND_MORE: getText('services:airline.store.airline.twoAndMore'),
};

const infoTerminal: IInfoTerminal = {
  terminal: '',
  different: false,
};

const prevSegmentId = (index: number) => index - 1;

const nextSegmentId = (index: number) => index + 1;

const anotherSegment = (segments: any[], prevOrNext: number) => segments[prevOrNext];

const isDepartureChanged = (index: number, segment: Segment, segments: Segment[]) => {
  if (prevSegmentId(index) >= 0) {
    const arrival = segments[prevSegmentId(index)].arrivalAirport.code;
    const departure = segment.departureAirport.code;

    return arrival !== departure;
  }

  return false;
};

const isArrivalChanged = (index: number, segment: Segment, segments: Segment[]) => {
  if (nextSegmentId(index) < segments.length) {
    const arrival = segment.arrivalAirport.code;
    const departure = segments[nextSegmentId(index)].departureAirport.code;

    return arrival !== departure;
  }

  return false;
};

const getTerminals = (item: Segment, index: number, segments: Segment[]): ITerminals => {
  const arrival: IInfoTerminal = infoTerminal;
  const departure: IInfoTerminal = infoTerminal;
  const { arrivalTerminal, departureTerminal } = item;

  arrival.terminal = arrivalTerminal || '';
  departure.terminal = departureTerminal || '';

  if (segments.length > 1) {
    const nextSegment = anotherSegment(segments, nextSegmentId(index));
    const prevSegment = anotherSegment(segments, prevSegmentId(index));

    arrival.different = nextSegmentId(index) < segments.length
      && !!arrivalTerminal
      && !!nextSegment.departureTerminal
      && arrivalTerminal !== nextSegment.departureTerminal
      && !isArrivalChanged(index, item, segments);

    departure.different = prevSegmentId(index) >= 0
      && !!departureTerminal
      && !!prevSegment.arrivalTerminal
      && departureTerminal !== prevSegment.arrivalTerminal
      && !isDepartureChanged(index, item, segments);
  }

  return {
    arrival,
    departure,
  };
};

const getNameByFirstSegment = ({ marketingAirline }: Segment) => marketingAirline.name;

const segmentsArrivalCity = (segments: Segment[]) => segments.reduce<string[]>((acc, { arrivalAirport, arrivalCity }, index) => {
  if (index !== segments.length - 1
      && arrivalAirport.code !== segments[index + 1].departureAirport.code
      && arrivalCity === segments[index + 1].departureCity
  ) {
    return [...acc, arrivalCity];
  }

  return acc;
}, []);

export interface IMappedSegments {
  airlineId: string;
  departureAirportId: string | null;
  arrivalAirportId: string | null,
  departureTime: string;
  arrivalTime: string;
  departureDate: string;
  arrivalDate: string;
  departureWeek: string;
  arrivalWeek: string;
}

const mappedSegments = (segment: Segment): IMappedSegments => ({
  airlineId: segment.marketingAirline.code,
  departureAirportId: segment.departureAirport.code ? `, ${segment.departureAirport.code}` : null,
  arrivalAirportId: segment.arrivalAirport.code ? `, ${segment.arrivalAirport.code}` : null,
  departureTime: dateWithoutDayjs(segment.departureDateTime, DATE_FORMATS.TIME),
  arrivalTime: dateWithoutDayjs(segment.arrivalDateTime, DATE_FORMATS.TIME),
  departureDate: dateWithoutDayjs(segment.departureDateTime, PATTERN.DAY_OF_MONTH),
  arrivalDate: dateWithoutDayjs(segment.arrivalDateTime, PATTERN.DAY_OF_MONTH),
  departureWeek: dateWithoutDayjs(segment.departureDateTime, PATTERN.WEEK),
  arrivalWeek: dateWithoutDayjs(segment.arrivalDateTime, PATTERN.WEEK),
});

const getImageLogo = (id: string | number) => `${CONFIG.API_ROOT}/airline/logo/${id}`;

const validateSearchFields = (routes: IRoutes[]) =>
  routes.every(({ to, from, date }) =>
    to.selected && from.selected && to.selected.code !== from.selected.code && date,
  );

const getAmountValueByKey = (key = 0): string => {
  if (key === 2) {
    return TAGS_LABELS.TWO_AND_MORE;
  }

  return key.toString();
};

const updateDefaultFilters = (filters: IAirlineSearchFilter) => ({
  ...filters,
  transfersCount: [],
  airlines: [],
  transferAirports: [],
  flightNumbers: [],
  routeFilters: filters.routeFilters.map((item) => ({
    ...item,
    arrivalAirports: [],
    departureAirports: [],
  })),
  baggage: [],
});

const mapSingleRoute = (route: IRoutes) => {
  const {
    from,
    to,
    date,
  } = route;

  return {
    departureCode: from.selected?.code,
    departureName: from.label,
    arrivalCode: to.selected?.code,
    arrivalName: to.label,
    date: formatDate(date as Dayjs, PATTERN.YEAR_MONTH_DAY),
  };
};

const getRoutesList = (routes: IRoutes[] = [], isComplex = false) => {
  if (isComplex) {
    return routes.map(mapSingleRoute);
  }

  const route = routes[0];

  if (!route.dateBack) {
    return [mapSingleRoute(route)];
  }

  return [mapSingleRoute(route), mapSingleRoute({
    ...route,
    to: route.from,
    from: route.to,
    date: route.dateBack,
  })];
};

const createTags = (filters: IAirlineSearchFilter, filtersData: IAirlineSearchFilter) => {
  const tags = [];

  if ((filters.price.min !== filtersData.price.min || filters.price.max !== filtersData.price.max)) {
    tags.push({
      name: TAGS_LABELS.PRICE_FROM_TO(MoneyFormat.money(filtersData.price.min), MoneyFormat.money(filtersData.price.max)),
      key: AIR_FILTER_TYPE.PRICE,
      filter: AIR_FILTER_TYPE.PRICE,
    });
  }

  if (filtersData.transfersCount.length) {
    filtersData.transfersCount.forEach((count) => {
      tags.push({
        name: count !== 0
          ? TAGS_LABELS.TRANSFER_COUNT(getAmountValueByKey(count))
          : TAGS_LABELS.WITHOUT_TRANSFERS,
        filter: AIR_FILTER_TYPE.TRANSFERS_COUNT,
        key: count,
      });
    });
  }

  if (filtersData.flightNumbers.length) {
    tags.push({
      name: TAGS_LABELS.FLIGHT_NUMBERS(filtersData.flightNumbers.join(', ')),
      filter: AIR_FILTER_TYPE.FLIGHT_NUMBERS,
      key: AIR_FILTER_TYPE.FLIGHT_NUMBERS,
    });
  }

  if (filtersData.baggage.length) {
    filtersData.baggage.forEach((item) => {
      tags.push({
        name: BAGGAGE[item],
        filter: AIR_FILTER_TYPE.BAGGAGE,
        key: item,
      });
    });
  }

  if ((filters.flightDuration.min !== filtersData.flightDuration.min
    || filters.flightDuration.max !== filtersData.flightDuration.max)) {
    tags.push({
      name: TAGS_LABELS.TRAVEL_TIME(
        secondsToLabel(filtersData.flightDuration.min),
        secondsToLabel(filtersData.flightDuration.max),
      ),
      filter: AIR_FILTER_TYPE.FLIGHT_DURATION,
      key: AIR_FILTER_TYPE.ROUTE_TRAVEL_TIME_FIELD,
    });
  }

  if ((filters.transferDuration.min !== filtersData.transferDuration.min
    || filters.transferDuration.max !== filtersData.transferDuration.max)) {
    tags.push({
      name: TAGS_LABELS.TRANSFER_TIME(
        secondsToLabel(filtersData.transferDuration.min),
        secondsToLabel(filtersData.transferDuration.max),
      ),
      key: AIR_FILTER_TYPE.TRANSFER_DURATION,
      filter: AIR_FILTER_TYPE.TRANSFER_DURATION,
    });
  }

  filtersData.routeFilters.forEach((item, index) => {
    const {
      departureDateTime, arrivalDateTime, arrivalAirports, departureAirports,
    } = item;

    if (filters.routeFilters[index].departureDateTime.min !== departureDateTime.min
      || filters.routeFilters[index].departureDateTime.max !== departureDateTime.max) {
      tags.push({
        name: TAGS_LABELS.DEPARTURE_FROM_TO(
          parseUnix(departureDateTime.min).format(PATTERN.LOCAL_TIME_PATTERN),
          parseUnix(departureDateTime.max).format(PATTERN.LOCAL_TIME_PATTERN),
        ),
        filter: AIR_FILTER_TYPE.ROUTE_FILTERS,
        key: `departure_${index}`,
        field: 'departureDateTime',
        index,
      });
    }

    if (filters.routeFilters[index].arrivalDateTime.min !== arrivalDateTime.min
      || filters.routeFilters[index].arrivalDateTime.max !== arrivalDateTime.max) {
      tags.push({
        name: TAGS_LABELS.ARRIVAL_FROM_TO(
          dayjsObject(arrivalDateTime.min).format(PATTERN.LOCAL_TIME_PATTERN),
          dayjsObject(arrivalDateTime.max).format(PATTERN.LOCAL_TIME_PATTERN),
        ),
        filter: AIR_FILTER_TYPE.ROUTE_FILTERS,
        key: `arrival_${index}`,
        field: 'arrivalDateTime',
        index,
      });
    }

    if (departureAirports.length) {
      departureAirports.forEach((airport) => {
        tags.push({
          name: TAGS_LABELS.FROM(airport),
          filter: AIR_FILTER_TYPE.ROUTE_FILTERS,
          key: airport,
          field: 'departureAirports',
          index,
        });
      });
    }

    if (arrivalAirports.length) {
      arrivalAirports.forEach((airport) => {
        tags.push({
          name: TAGS_LABELS.TO(airport), //
          filter: AIR_FILTER_TYPE.ROUTE_FILTERS,
          key: airport,
          field: 'arrivalAirports',
          index,
        });
      });
    }
  });

  if (filtersData.airlines.length) {
    filtersData.airlines.forEach((airline) => {
      tags.push({
        name: airline,
        filter: AIR_FILTER_TYPE.AIRLINES,
        key: airline,
      });
    });
  }

  if (filtersData.transferAirports.length) {
    filtersData.transferAirports.forEach((airport) => {
      tags.push({
        name: airport,
        filter: AIR_FILTER_TYPE.TRANSFER_AIRPORTS,
        key: airport,
      });
    });
  }

  return tags;
};

export const updateTags = (tag: ITag, filters: IAirlineSearchFilter, prepareFilter: IAirlineSearchFilter) => {
  switch (tag.filter) {
    case AIR_FILTER_TYPE.BAGGAGE:
    case AIR_FILTER_TYPE.AIRLINES:
    case AIR_FILTER_TYPE.TRANSFER_AIRPORTS:
    case AIR_FILTER_TYPE.TRANSFERS_COUNT: {
      const newFilter = (filters[tag.filter as keyof IAirlineSearchFilter] as string[] | number[])
        .filter((item) => item !== tag.key);
      const newFilters = ({ ...filters, [tag.filter]: newFilter });

      return newFilters;
    }
    case AIR_FILTER_TYPE.PRICE:
    case AIR_FILTER_TYPE.FLIGHT_DURATION:
    case AIR_FILTER_TYPE.TRANSFER_DURATION: {
      const newFilters = ({ ...filters, [tag.filter]: prepareFilter[tag.filter as keyof IAirlineSearchFilter] });

      return newFilters;
    }
    case AIR_FILTER_TYPE.ROUTE_FILTERS: {
      const newRouteFilters = [...filters.routeFilters];

      if (tag.field === 'arrivalDateTime' || tag.field === 'departureDateTime') {
        const defaultRouteFilters = prepareFilter.routeFilters[tag.index as number];

        newRouteFilters[tag.index as number] = {
          ...newRouteFilters[tag.index as number],
          [tag.field as string]: defaultRouteFilters[tag.field as keyof typeof defaultRouteFilters],
        };

      }

      if (tag.field === 'departureAirports' || tag.field === 'arrivalAirports') {
        newRouteFilters[tag.index as number] = {
          ...newRouteFilters[tag.index as number],
          [tag.field as string]: (newRouteFilters[tag.index as number][tag.field as keyof IRouteFilters] as string[] | number[])
            .filter((item) => item !== tag.key),
        };
      }
      const newFilters = ({ ...filters, routeFilters: newRouteFilters });

      return newFilters;
    }
    case AIR_FILTER_TYPE.FLIGHT_NUMBERS: {
      const newFilters = ({ ...filters, [tag.filter]: [] });

      return newFilters;
    }
    default: {
      return filters;
    }
  }
};

export const getFirstAndLastSegments = ({ segments }: IAirlineRoute) => {
  const firstSegment = segments[0];
  const lastSegment = segments[segments.length - 1];

  return {
    firstSegment,
    lastSegment,
  };
};

export const getMappedDates = (route: IAirlineRoute) => {
  const {
    firstSegment: { departureDate },
    lastSegment: { arrivalDate },
  } = getFirstAndLastSegments(route);

  const { duration } = route;
  const newDuration = duration && duration > 0 ? secondsToLabel(duration, true) : null;

  return {
    departureTime: dateWithoutDayjs(departureDate, DATE_FORMATS.TIME),
    arrivalTime: dateWithoutDayjs(arrivalDate, DATE_FORMATS.TIME),
    departureDate: dateWithoutDayjs(departureDate, PATTERN.DEFAULT_PATTERN_FILTER),
    arrivalDate: dateWithoutDayjs(arrivalDate, PATTERN.DEFAULT_PATTERN_FILTER),
    departureWeek: dateWithoutDayjs(departureDate, PATTERN.WEEK),
    arrivalWeek: dateWithoutDayjs(arrivalDate, PATTERN.WEEK),
    duration: newDuration,
  };
};

export {
  getTerminals,
  isArrivalChanged,
  isDepartureChanged,
  getNameByFirstSegment,
  segmentsArrivalCity,
  mappedSegments,
  getImageLogo,
  validateSearchFields,
  updateDefaultFilters,
  createTags,
  mapSingleRoute,
  getRoutesList,
};
