import React from 'react';
import { Checkbox, Collapse } from 'yw-ui';
import { getText } from '@/i18n';

import styles from '../../index.module.css';

const LABELS = {
  LABEL: getText('air:result.filters.baggage.label'),
};

const BAGGAGE: { [key: string]: string } = {
  Included: getText('constants:airline.baggage.included'),
  NotOffered: getText('constants:airline.baggage.notOffered'),
  Charge: getText('constants:airline.baggage.charge'),
};

interface BaggageFilterProps {
  baggage: string[],
  values: string[],
  onChange(array: string[]): void,
}
const BaggageFilter = ({ baggage, values, onChange }: BaggageFilterProps) => {
  const handleChange = (value: string) => {
    const valuesSet = new Set(values);

    if (valuesSet.has(value)) {
      valuesSet.delete(value);

      return onChange([...valuesSet]);
    }

    valuesSet.add(value);

    return onChange([...valuesSet]);
  };

  const renderBaggage = () => baggage.map((name) => {
    const checked = values.includes(name);

    return (
      <Checkbox
        key={ name }
        className={ styles.checkbox }
        onChange={ () => handleChange(name) }
        value={ checked }
        themes='dark'
      >
        { BAGGAGE[name] }
      </Checkbox>
    );
  });

  return (
    <Collapse
      opened
      className={ styles.item }
      label={ LABELS.LABEL }
    >
      {renderBaggage()}
    </Collapse>
  );
};

export { BaggageFilter };
