import React, { useCallback, useEffect, useState } from 'react';
import { IconButton, Suggest, Text } from 'yw-ui';
import { SuggestItems } from 'yw-ui/components/Suggest/types';

import { getText } from '@/i18n';

import { useAppDispatch } from '@/app/store/hooks/redux.ts';
import { employeeSlice } from '@/app/store/redusers/EmployeeSlice.ts';

import { FieldLabel } from '@/app/components/FieldLabel';
import { FormContainer } from '@/app/pages/Employee/components/FormContainer';

import { IEmployeePolicyItem } from '@/app/bi/models/employee.ts';

import styles from './styles/index.module.css';

const LABELS = {
  TRAVEL_POLICY: getText('settings:travelPolicy'),
  SELECT_TRAVEL_POLICY: getText('settings:selectTravelPolicy'),
};

interface TravelPolicyBlockProps {
  policyValue: IEmployeePolicyItem;
  travelPolicies: IEmployeePolicyItem[];
}

export const TravelPolicyBlock = ({
  policyValue,
  travelPolicies,
}: TravelPolicyBlockProps) => {
  const dispatch = useAppDispatch();

  const setTravelPolicy = employeeSlice.actions.setTravelPolicy;

  const [
    policyInput,
    setPolicyInput,
  ] = useState<string>(policyValue.label);
  const [
    filteredItems,
    setFilteredItems,
  ] = useState<IEmployeePolicyItem[]>([]);

  useEffect(() => {
    setPolicyInput(policyValue.label);
  }, [policyValue.label]);

  const handleChangePolicy = (value: string) => {
    setPolicyInput(value);

    const filtered = travelPolicies.filter(({
      label,
    }) => label.toLowerCase().includes(value.toLowerCase()));

    setFilteredItems(filtered);
  };

  const handleSelectPolicy = useCallback((selectPolicy: SuggestItems) => {
    const selectedPolicy = travelPolicies.find(
      item => item.label === selectPolicy.label,
    );

    if (selectedPolicy) {
      dispatch(setTravelPolicy(selectedPolicy));
    }
  }, [travelPolicies, dispatch]);

  const handleClearPolicy = () => {
    setPolicyInput('');
    dispatch(setTravelPolicy({ label: '', value: null }));
  };

  const renderButtonClearPolicy = () => (policyInput.length ? (
    <IconButton iconType='closeOff' size={ 16 } onClick={ handleClearPolicy } />
  ) : null);

  return (
    <div className={ styles.block_travel_policy }>
      <Text type='bold_20'>
        {LABELS.TRAVEL_POLICY}
      </Text>
      <FormContainer >
        <div className={ styles.item }>
          <FieldLabel text={ LABELS.TRAVEL_POLICY }/>
          <div className={ styles.input_container }>
            <Suggest
              preventTab={ false }
              theme='border'
              withLabel={ false }
              value={ policyInput }
              items={ filteredItems as never[] }
              onChange={ handleChangePolicy }
              onSelect={ handleSelectPolicy }
              inputClassName={ styles.selector }
              placeholder={ LABELS.SELECT_TRAVEL_POLICY }
            />
            {renderButtonClearPolicy()}
          </div>
        </div>
      </FormContainer>
    </div>
  );
};
