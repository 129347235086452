import React, { useState } from 'react';
import { Button } from 'yw-ui';
import { getText } from '@/i18n';

import { FilterPanel } from '@/app/components/FilterPanel';
import {
  EmployeeFilterItem,
} from '@/app/pages/Settings/pages/Employees/components/EmployeeFilter/components/EmployeeFilterItem';
import {
  CompanyFilterItem,
} from '@/app/pages/Settings/pages/Employees/components/EmployeeFilter/components/CompanyFilterItem';

import { IEmployeeFilter } from '@/app/bi/models/employees.ts';
import { ICompany } from '@/app/bi/models/app.ts';

import styles from './styles/index.module.css';

const LABELS = {
  SEARCH_EMPLOYEES: getText('settings:searchEmployees'),
  SEARCH_TYPE_PLACEHOLDER: getText('settings:searchTypePlaceholder'),
  ORGANIZATION: getText('settings:organization'),
  SEARCH_TO_NAME: getText('settings:searchToName'),
  ACCESS_RIGHTS: getText('settings:accessRights'),
  ADD_EMPLOYEES: getText('settings:addEmployee'),
  EMPLOYEE: getText('settings:employee'),
  RESET_FILTERS: getText('settings:resetFilters'),
};

interface FilterEmployeesProps {
  filterValues: IEmployeeFilter;
  companyValues: ICompany[];
  inputValue: string;
  setFilters(value: IEmployeeFilter): void;
  setInputValues(value: string): void;
  onChangeClearFilter(): void;
}

const FilterEmployees = ({
  filterValues,
  companyValues,
  inputValue,
  setFilters,
  setInputValues,
  onChangeClearFilter,
}: FilterEmployeesProps) => {
  const [
    companyInputValue,
    setCompanyInputValue,
  ] = useState<string>('');

  const handleInputCompany = (value: string) => {
    setCompanyInputValue(value);
  };

  const handleChangeCompany = (value: number[]) => {
    setFilters(({ ...filterValues, companies: value, page: 1 }));
  };

  const filteredCompanies = companyValues.filter(({ name }) =>
    name.toLowerCase().includes(companyInputValue.toLowerCase()),
  );

  return (
    <div className={ styles.wrap }>
      <FilterPanel>
        <EmployeeFilterItem
          title={ LABELS.SEARCH_EMPLOYEES }
          inputValue={ inputValue }
          placeholder={ LABELS.SEARCH_TYPE_PLACEHOLDER }
          onChangeInput={ setInputValues }
        />
        <div className={ styles.separator } />
        <CompanyFilterItem
          title={ LABELS.ORGANIZATION }
          data={ filteredCompanies }
          inputValue={ companyInputValue }
          companiesValues={ filterValues.companies }
          placeholder={ LABELS.SEARCH_TO_NAME }
          onChange={ handleChangeCompany }
          onChangeInput={ handleInputCompany }
        />
        <div className={ styles.buttonMoreContainer }>
          <Button onClick={ onChangeClearFilter } type='text' className={ styles.buttonMore }>
            {LABELS.RESET_FILTERS}
          </Button>
        </div>
      </FilterPanel>
    </div>
  );
};

export { FilterEmployees };
