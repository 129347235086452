import React from 'react';
import { Text, Tooltip, Price } from 'yw-ui';
import clsx from 'clsx';

import FareDetails from '../../../FareDetails';

import SYMBOLS from '../../../../bi/constants/symbols.ts';

import { Fares } from '@/app/bi/models/airlineTypes.ts';

import styles from './index.module.css';

interface FareItemProps {
  fare: Fares,
  currentFareId?: string | null,
  currentPrice: number,
  isChangeAirTrip?: boolean,
  onFareClick(item: Fares): void,
}
const FareItem = ({
  fare,
  currentPrice,
  currentFareId = null,
  isChangeAirTrip = false,
  onFareClick,
}: FareItemProps) => {
  const { id, name, price: { totalPrice } } = fare;
  const currentFare = currentFareId === id;
  const color = currentFare ? 'default' : 'accent';
  const price = currentFare ? currentPrice : totalPrice - currentPrice;
  const symbolHtml = !currentFare && totalPrice - currentPrice > 0 && (
    <Text type='NORMAL_14' color={ color }>
      {SYMBOLS.PLUS.value}
      &thinsp;
    </Text>
  );

  const tooltip = () => (
    <Text
      type='NORMAL_14_130'
      color='white'
      className={ styles.tooltip }
    >
      <FareDetails fare={ fare } />
    </Text>
  );

  const renderPrice = () => (!isChangeAirTrip && (
    <div className={ styles.price }>
      { symbolHtml }
      <Price
        marginSmall
        type='NORMAL_14'
        typeCurrency='NORMAL_14'
        color={ color }
        value={ price }
      />
    </div>
  ));

  return (
    <Tooltip
      position='bottom'
      renderContent={ tooltip }
      className={ styles.wrapper }
    >
      <div
        className={ clsx(styles.content, { [styles.active]: currentFare }) }
        onClick={ () => onFareClick(fare) }
      >
        <div className={ styles.fare }>
          <Text type='NORMAL_14'>{ name }</Text>
          { renderPrice() }
        </div>
      </div>
    </Tooltip>
  );
};

export default FareItem;
