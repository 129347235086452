import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { BackLink, Button, PageLoader, Text } from 'yw-ui';

import { getText } from '@/i18n';

import {
  useAddTravelPolicyMutation,
  useGetTravelPolicyQuery,
  useUpdateTravelPolicyMutation,
} from '@/app/bi/api/travelPoliciesApi.ts';
import { useAppDispatch, useAppSelector } from '@/app/store/hooks/redux.ts';
import { setClear, setPolicy } from '@/app/store/redusers/TravelPolicy.ts';

import { PolicyName } from '@/app/pages/TravelPolicy/components/PolicyName';
import { BlockPoliciesAirline } from '@/app/pages/TravelPolicy/components/BlockPoliciesAirline';
import { BlockPoliciesHotel } from '@/app/pages/TravelPolicy/components/BlockPoliciesHotel';

import { notification } from '@/app/bi/utils/not.ts';

import ROUTES from '@/app/bi/constants/routes.ts';
import { INIT_TRAVEL_POLICY } from '@/app/bi/constants/travelPolicy.ts';
import { ENotificationActionType } from '@/app/bi/constants/settings.ts';

import {
  IAddTravelPolicyRequest,
  ITravelPolicy,
  IUpdateTravelPolicyRequest,
} from '@/app/bi/models/travelPolicies.ts';

import styles from './styles/index.module.css';

const LABELS = {
  CREATE_TRAVEL_POLICY: getText('settings:travelPolicies.createTravelPolicy'),
  SAVE: getText('common:save'),
  UNDO: getText('common:undo'),
  SETTINGS: getText('components:header.items.settings'),
  NOTIFICATION: (policy: string) => getText('settings:travelPolicies.policy.notification', { policy }),
};

export const TravelPolicy = () => {
  const dispatch = useAppDispatch();
  const { policyId = '' } = useParams();
  const navigate = useNavigate();

  const isCreate = !policyId;
  const { SETTINGS } = ROUTES;

  const {
    maxPriceChecked,
    maxDayByLimitChecked,
    maxPricePerNightChecked,
    maxPriceDifferenceChecked,
    hotelPolicy,
    airlinePolicy,
  } = useAppSelector((state) => state.travelPolicySlice);

  const {
    data = INIT_TRAVEL_POLICY,
    isLoading,
  } = useGetTravelPolicyQuery({ policyId }, { skip: isCreate });

  const [addTravelPolicy] = useAddTravelPolicyMutation();

  const [updateTravelPolicy] = useUpdateTravelPolicyMutation();

  const [
    preparePolicies,
    setPreparePolicies,
  ] = useState<ITravelPolicy>(data);

  const isFillingButtonSave = !preparePolicies.name.length;

  useEffect(() => {
    if (data) {
      setPreparePolicies(data);
      dispatch(setPolicy({
        avia: data.airlinePolicy,
        hotel: data.hotelPolicy,
      }));
    }
  }, [data]);

  if (isLoading) {
    return <PageLoader />;
  }

  const handleAddPolicies = async () => {
    const {
      maxPricePerNight,
    } = hotelPolicy;
    const {
      maxPrice,
      daysBuyLimit,
      priceDifferenceLimit,
    } = airlinePolicy;

    const objRequestPolicy: IAddTravelPolicyRequest = {
      name: preparePolicies.name,
      description: preparePolicies.description,
      airlinePolicy: {
        ...airlinePolicy,
        maxPrice: maxPriceChecked ? maxPrice : 0,
        daysBuyLimit: maxDayByLimitChecked ? daysBuyLimit : 0,
        priceDifferenceLimit: maxPriceDifferenceChecked ? priceDifferenceLimit : 0,
      },
      airlineExceptions: [],
      hotelPolicy: {
        ...hotelPolicy,
        maxPricePerNight: maxPricePerNightChecked ? maxPricePerNight : 0,
      },
      hotelExceptions: [],
    };

    try {
      await addTravelPolicy(objRequestPolicy).unwrap();

      notification({
        title: LABELS.NOTIFICATION(preparePolicies.name),
        type: ENotificationActionType.success,
      });

      handleGoBack();
    } catch (e) {
      notification({
        title: LABELS.NOTIFICATION(preparePolicies.name),
        type: ENotificationActionType.error,
      });
    }
  };

  const handleSavePolicies = async () => {
    const {
      maxPricePerNight,
    } = hotelPolicy;
    const {
      maxPrice,
      daysBuyLimit,
      priceDifferenceLimit,
    } = airlinePolicy;

    const objRequestPolicy: IUpdateTravelPolicyRequest = {
      policyId: preparePolicies.id,
      name: preparePolicies.name,
      description: preparePolicies.description,
      accountId: preparePolicies.accountId,
      creatorUserId: preparePolicies.creatorUserId,
      updateBaseAirlinePolicy: {
        ...airlinePolicy,
        maxPrice: maxPriceChecked ? maxPrice : 0,
        daysBuyLimit: maxDayByLimitChecked ? daysBuyLimit : 0,
        priceDifferenceLimit: maxPriceDifferenceChecked ? priceDifferenceLimit : 0,
      },
      addedAirlineExceptions: [],
      updateAirlineExceptions: [],
      deleteAirlineExceptionIds: [],
      updateBaseHotelPolicy: {
        ...hotelPolicy,
        maxPricePerNight: maxPricePerNightChecked ? maxPricePerNight : 0,
      },
      addedHotelExceptions: [],
      updateHotelExceptions: [],
      deleteHotelExceptionIds: [],
    };

    try {
      await updateTravelPolicy(objRequestPolicy).unwrap();

      notification({
        title: LABELS.NOTIFICATION(preparePolicies.name),
        type: ENotificationActionType.success,
      });

      handleGoBack();
    } catch (e) {
      notification({
        title: LABELS.NOTIFICATION(preparePolicies.name),
        type: ENotificationActionType.error,
      });
    }
  };

  const isCreatingOrUpdatingCard = isCreate ? handleAddPolicies : handleSavePolicies;

  const handleBackLink = () => {
    navigate(SETTINGS.TRAVEL_POLICIES);
    dispatch(setClear());
  };

  const handleGoBack = () => {
    navigate(-1);
    dispatch(setClear());
  };

  const handleEditNamePolicy = (value: string) => setPreparePolicies({ ...preparePolicies, name: value });

  const handleEditDescriptionPolicy = (value: string) => setPreparePolicies({ ...preparePolicies, description: value });

  return (
    <div className={ styles.wrap }>
      <BackLink
        text={ LABELS.SETTINGS }
        textColor='blue-1'
        iconColor='blue1'
        onClick={ handleBackLink }
        link={ SETTINGS.TRAVEL_POLICIES }
      />
      <div className={ styles.policy_info } >
        <Text type='bold_24' color='gray-7'>
          {LABELS.CREATE_TRAVEL_POLICY}
        </Text>
        <PolicyName
          namePolicy={ preparePolicies.name }
          descriptionPolicy={ preparePolicies.description }
          setNamePolicy={ handleEditNamePolicy }
          setDescriptionPolicy={ handleEditDescriptionPolicy }
        />
      </div>
      <div className={ styles.policy_content }>
        <BlockPoliciesAirline isCreate={ isCreate } />
        <BlockPoliciesHotel isCreate={ isCreate } />
      </div>
      <div className={ styles.action_panel }>
        <div className={ styles.content }>
          <div className={ styles.actions }>
            <Button
              type='secondary'
              size='small'
              onClick={ isCreatingOrUpdatingCard }
              disabled={ isFillingButtonSave }
            >
              {LABELS.SAVE}
            </Button>
            <Button type='text' onClick={ handleBackLink }>
              {LABELS.UNDO}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
