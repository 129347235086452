import React from 'react';
import { Text } from 'yw-ui';
import { getText } from '@/i18n';

import { HotelGallery } from '@/app/pages/HotelResult/components/Hotel/components/RoomGroup/components/HotelGallery';

import {
  IHotelSearchImageResponse,
  IHotelSearchRoomGroupResponse,
} from '@/app/bi/models/hotelSearch/hotelSearchTypes.ts';

import styles from './index.module.css';

const LABELS = {
  MORE_INFO: getText('hotels:hotelResult.item.roomGroup.moreInfo'),
  MORE_ABOUT_OF_HOTEL: getText('hotels:hotelResult.moreAboutOfHotel'),
};
interface DetailsProps {
  room: IHotelSearchRoomGroupResponse;
  amenities: string[];
  allImageLoadFailed: boolean;
  Images: IHotelSearchImageResponse[];
  loadedImgs: IHotelSearchImageResponse[];
  details: boolean;
  onGallerySlideLeft(): void;
  onGallerySlideRight(): void;
  handleShowRoomDetails(): void;
}
const Details = ({
  room,
  amenities,
  allImageLoadFailed,
  Images,
  loadedImgs,
  details,
  onGallerySlideLeft,
  onGallerySlideRight,
  handleShowRoomDetails,
}: DetailsProps) => {
  const { name, description } = room;

  const renderAmenities = () => {
    if (!amenities || !amenities.length) return null;

    const amenitiesHtml = amenities.map((item, idx) => (
      <div key={ `services_${idx}` } className={ styles.amenities_item }>
        <div className={ styles.amenities_values }>
          {item}
        </div>
      </div>
    ));

    return (
      <div className={ styles.amenities }>
        <Text type='bold_16'>{ LABELS.MORE_ABOUT_OF_HOTEL }</Text>
        <div className={ styles.amenities_content }>
          {amenitiesHtml}
        </div>
      </div>
    );
  };

  return (
    <div className={ styles.details_wrapper }>
      <div className={ styles.header }>
        <Text type='bold_24'>{ name }</Text>
      </div>
      <div className={ styles.wrap_gallery }>
        <div className={ styles.gallery }>
          <HotelGallery
            autoPlay
            allImageLoadFailed={ allImageLoadFailed }
            Images={ Images }
            loadedImgs={ loadedImgs }
            details={ details }
            onGallerySlideLeft={ onGallerySlideLeft }
            onGallerySlideRight={ onGallerySlideRight }
            handleShowRoomDetails={ handleShowRoomDetails }
          />
        </div>
      </div>
      <div className={ styles.description }>
        <Text type='bold_16' color='gray-7'>{LABELS.MORE_INFO}</Text>
        { description && (
          <Text type='NORMAL_16_140'>
            <div dangerouslySetInnerHTML={ { __html: description } } />
          </Text>
        ) }
      </div>
      {renderAmenities()}
    </div>
  );
};

export { Details };
