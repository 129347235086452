import { ECartItemErrorType, ICartWorkPlace } from '@/app/bi/models/cart.ts';
import { ECartError } from '@/app/bi/constants/booking.ts';

export enum EBookProgressStatus {
  CartValidation = 'CartValidation',
  Reservation = 'Reservation',
  CreateTripsAndOperations = 'CreateTripsAndOperations',
  Booked = 'Booked',
  Failed = 'Failed',
  GeneralError = 'GeneralError',
}

export enum EBookStatus {
  Ready = 'Ready',
  Started = 'Started',
  Busy = 'Busy',
}

export enum EProgressSpeed {
  Medium = 'medium',
  Slow = 'slow',
}

export enum EBookingError {
  CartEmpty = 'CartEmpty',
  BalanceNotEnoughMoney = 'BalanceNotEnoughMoney',
  BookingFailed = 'BookingFailed',
}

export const BOOKING_STEP_VALUE = {
  CartValidation: 0.3,
  Reservation: 0.4,
  CreateTripsAndOperations: 0.5,
  Booked: 1,
  Failed: 1,
  GeneralError: 1,
};

export interface ICartItemError {
  itemId: string;
  errors: ECartItemErrorType[];
}

export interface IBookResultResponse {
  hasErrors: boolean;
  errors: ECartError[];
  itemErrors: ICartItemError[];
}

export interface IBookResult {
  hasErrors: boolean,
  errors: EBookingError[],
  cart: ICartWorkPlace
}

export interface IBookingProgress {
  bookId: string,
  bookResult: IBookResult,
  progressStatus: EBookProgressStatus,
}

export interface ICheckoutData {
  canBook: boolean,
  errors: string[],
}
