import React from 'react';
import { Tag } from 'yw-ui';

import { sortedFilterStatus } from '@/app/bi/utils/trip.ts';

import { BUTTON_CLEAR } from '@/app/bi/constants/settings.ts';

import { EFilterItemType } from '@/app/bi/models/tripsTypes.ts';
import { ITagFilter } from '@/app/bi/models/shared.ts';

import styles from './index.module.css';

interface FilterStatusProps {
  items: ITagFilter[];
  onClick(status: ITagFilter): void;
  onReset(): void;
}

export const FilterStatus = ({
  items,
  onClick,
  onReset,
}: FilterStatusProps) => {
  if (!items.length) return null;

  const sortedListStatuses = sortedFilterStatus(items);

  return (
    <div className={ styles.filter_status_container }>
      {sortedListStatuses.map((statusItem, index) => (
        <Tag
          key={ `tag_${statusItem.value}_${index}` }
          label={ statusItem.label }
          onClick={ () => onClick(statusItem) }
          readOnly={ statusItem.typeFilter === EFilterItemType.TripDates }
        />
      ))}
      {sortedListStatuses.length > 1 && (
        <Tag
          label={ BUTTON_CLEAR }
          deleteAll
          className={ styles.button_clear_filter }
          onClick={ onReset }
        />
      )}
    </div>
  );
};
