import React from 'react';
import { NoPhoto } from 'yw-ui';

import { ImageGallery } from '@/app/components/ImageGallery';
import { IImageGalleryItem } from '@/app/components/ImageGallery/components/ImageThumbnailsItem';

import { IHotelSearchImageResponse } from '@/app/bi/models/hotelSearch/hotelSearchTypes.ts';

import styles from './index.module.css';

interface HotelGalleryProps {
  autoPlay?: boolean;
  allImageLoadFailed: boolean;
  Images: IHotelSearchImageResponse[];
  loadedImgs: IHotelSearchImageResponse[];
  details?: boolean;
  onGallerySlideLeft(): void;
  onGallerySlideRight(): void;
  handleShowRoomDetails(): void;
}
const HotelGallery = ({
  autoPlay = false,
  allImageLoadFailed,
  Images,
  loadedImgs,
  details = false,
  onGallerySlideLeft,
  onGallerySlideRight,
  handleShowRoomDetails,
}: HotelGalleryProps) => {
  if (!Images?.length || allImageLoadFailed) {
    return (
      <div className={ styles.images_wrapper }>
        <NoPhoto />
      </div>
    );
  }

  const imgs = loadedImgs?.length ? loadedImgs : Images;

  const imgsList: IImageGalleryItem[] = imgs.map((img) => ({
    original: img?.originUrl,
    thumbnail: img?.thumbnailUrl,
  }));

  const galleryProps = details
    ? { slideInterval: 4000, autoPlay: false, originalImageStyles: { maxHeight: '600px' } }
    : { theme: 'small' };

  return (
    <div className={ styles.images_wrapper }>
      <ImageGallery
        autoPlay={ autoPlay }
        showThumbnails
        items={ imgsList }
        onSlideLeft={ onGallerySlideLeft }
        onSlideRight={ onGallerySlideRight }
        onClick={ handleShowRoomDetails }
        originalImageStyles={ galleryProps }
      />
    </div>
  );
};

export { HotelGallery };
