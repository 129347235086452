import React from 'react';
import { Text } from 'yw-ui';
import { getText } from '@/i18n';

import CONFIG from '@/config.ts';

import { secondsToLabel } from '@/app/bi/utils/time.ts';
import toDecline from '@/app/bi/utils/toDecline.ts';
import { getMappedDates } from '@/app/bi/utils/airline.ts';

import { TRANSFER } from '@/app/bi/constants/airline.ts';

import { CartResponseAirlineItemRoute, IAirlineRouteSegment } from '@/app/bi/models/cart.ts';

import styles from './styles/index.module.css';

const airlineNames = (segments: IAirlineRouteSegment[], firstSegment: IAirlineRouteSegment) => {
  const filterAirlines = segments.filter(
    segment => segment.marketingAirlineCode === firstSegment.marketingAirlineCode,
  );
  const flightNumbers = segments.map((segment) => `${segment.marketingAirlineCode} ${segment.flightNumber}`)
    .join(', ');

  if (
    segments.length === 1 ||
    (segments.length === 2 && filterAirlines.length === 2)
  ) {
    return `${
      firstSegment.marketingAirlineName
    } (${flightNumbers})`;
  }

  return flightNumbers;
};

const LABELS = {
  WITHOUT_TRANSFERS: getText('components:airlineSearchItem.airItem.withoutTransfers'),
};

interface AirlineItemProps {
  route: CartResponseAirlineItemRoute,
}

const getImageLogo = (id: string | number) => `${CONFIG.API_ROOT}/airline/logo/${id}`;

const AirlineItem = ({
  route,
}: AirlineItemProps) => {

  const segments = route.segments;
  const firstSegment = segments[0];
  const lastSegment = segments[segments.length - 1];

  const {
    departureTime,
    arrivalTime,
    departureDate,
    arrivalDate,
    departureWeek,
    arrivalWeek,
    duration,
    // @ts-ignore
  } = getMappedDates(route);

  const airlineNameWithNumbers = airlineNames(
    // @ts-ignore
    segments,
    firstSegment,
  );
  const renderIcons = () => {
    const reducedSegmentsList =
      route.segments.reduce<{ code: string, name: string }[]>((acc, {
        marketingAirlineCode,
        marketingAirlineName,
      }) => {
        if (acc.findIndex(({ code }) => marketingAirlineCode === code) >= 0) {
          return acc;
        }

        return [
          ...acc,
          {
            code: marketingAirlineCode,
            name: marketingAirlineName,
          },
        ];

      }, []);

    if (reducedSegmentsList.length === 1) {
      return renderSingleIcon({ ...reducedSegmentsList[0] });
    }

    return renderMultipleIcons(reducedSegmentsList);
  };

  const renderSingleIcon = ({ code, name }: { code: string, name: string }) => (
    <div className={ styles.img_wrap }>
      <img
        src={ getImageLogo(code) }
        title={ name }
        alt={ name }
      />
    </div>
  );

  const renderMultipleIcons = (list: { code: string, name: string }[] = []) => (
    <div className={ styles.image_list }>
      {list.map(({ code, name }) => (
        <img
          key={ code }
          className={ styles.small_img }
          src={ getImageLogo(code) }
          title={ name }
          alt={ name }
        />
      ))}
    </div>
  );

  const sumChangeDuration = segments
    .reduce((sum, { transferDuration }) => sum + transferDuration, 0);
  const changeCount = segments.length - 1;

  const renderTransfer = (transferCount: number, transferDuration: number) => {
    if (transferCount) {
      return `${transferCount} ${toDecline(changeCount, TRANSFER)} (${secondsToLabel(transferDuration)})`;
    }

    return !route.segments[0].technicalStop?.length && (LABELS.WITHOUT_TRANSFERS);
  };

  const transferHtml = renderTransfer(changeCount, sumChangeDuration);

  const renderdDate = () => (departureDate === arrivalDate
    ? `${departureDate}, ${departureWeek}`
    : `${departureDate}, ${departureWeek} — ${arrivalDate}, ${arrivalWeek}`);

  return (
    <div className={ styles.item }>
      <div className={ styles.content }>
        <Text type='normal_20' fontWeight={ 700 } className={ styles.title }>
          {`${firstSegment.departureCity}, ${firstSegment.departureAirportCode} — `}
          {`${lastSegment.arrivalCity}, ${lastSegment.arrivalAirportCode}`}
        </Text>
        <div className={ styles.header }>
          { renderIcons() }
          <Text type='NORMAL_16' color='gray-6'>{airlineNameWithNumbers}</Text>
        </div>
        <div className={ styles.ticked_info_container }>
          <div className={ styles.travel_container }>
            <Text type='bold_18' color='gray-7' className={ styles.travel_time }>
              {`${departureTime} — ${arrivalTime}`}
            </Text>
            <Text type='NORMAL_16' color='gray-7' className={ styles.travel_info }>
              <Text type='NORMAL_16' fontWeight={ 600 } color='gray-7'>
                {`${duration} ${transferHtml}`}
              </Text>
            </Text>
          </div>
          <div className={ styles.route_info }>
            <Text type='NORMAL_16' color='gray-6'>
              {renderdDate()}
            </Text>
          </div>
        </div>
      </div>
    </div>
  );
};

export { AirlineItem };
