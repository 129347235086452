import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  IHotelSearchRateResponse,
  IHotelSearchRateCurrencyGroupResponse,
  IHotelSearchRoomGroupResponse,
  IHotelSearchResponse,
  IPrepareHotelProviderRate,
} from '@/app/bi/models/hotelSearch/hotelSearchTypes.ts';

import { searchHotelsApi } from '@/app/bi/api/searchHotelsApi.ts';

const CURRENCY_NAME = 'USD';

const createSelectData = (
  rate: IHotelSearchRateResponse,
  roomCount: number,
  reservedCount: number,
): IPrepareHotelProviderRate => {
  const { freeRooms } = rate;

  const free = freeRooms || 1;
  const newFreeRooms = free - reservedCount;

  const items: { label: string, value: number }[] = [];

  for (let i = 1; i <= newFreeRooms; i++) {
    items.push({ label: i.toString(), value: i });
  }

  const count = items.length ? items[0].value : null;
  const countValue = roomCount > 1 && items.length >= roomCount ? Number(roomCount) : count;

  return {
    ...rate,
    reservedCount,
    select: {
      items,
      count: countValue,
    },
  };
};

const updateRoomGroups = (
  roomGroups: IHotelSearchRoomGroupResponse[],
  roomCount: number,
): IHotelSearchRoomGroupResponse[] =>
  roomGroups.map((roomGroup) => {
    const updatedRates: IHotelSearchRateCurrencyGroupResponse[] = roomGroup.currencyRateGroups
      // .filter(({ currencyCode }) => currencyCode === CURRENCY_NAME)
      .map(({ rates, currencyCode }) => ({
        currencyCode,
        rates: rates.map((rate) => createSelectData(rate, roomCount, 0)),
      }));

    return {
      ...roomGroup,
      currencyRateGroups: updatedRates,
    };
  });

const prepareHotelSearchResult = (data: IHotelSearchResponse): IHotelSearchResponse => {
  const newResponse = { ...data };
  newResponse.item.roomGroups = updateRoomGroups(newResponse.item.roomGroups, newResponse.searchRequest.roomCount);

  return newResponse;
};

const initialState: { data: IHotelSearchResponse | null } = {
  data: null,
};

const updateSelectedRate = (
  roomGroups: IHotelSearchRoomGroupResponse[],
  { bookId, value }: { bookId: string, value: number },
) => roomGroups.map((roomGroup) => {
  // @ts-ignore
  const updatedRates: IHotelSearchRateCurrencyGroupResponse[] = roomGroup.currencyRateGroups.map(({
    rates, currencyCode,
  }) => ({
    currencyCode,
    rates: rates
      .filter(({ currency }) => currency === CURRENCY_NAME)
      .map((rate) => {
        if (rate.bookId !== bookId) return rate;

        return {
          ...rate,
          select: {
            // @ts-ignore
            ...rate.select,
            count: value,
          },
        };
      }),
  }));

  return {
    ...roomGroup,
    currencyRateGroups: updatedRates,
  };
});

export const hotelSlice = createSlice({
  name: 'hotelSlice',
  initialState,
  reducers: {
    updateSelectedRate(
      state,
      action: PayloadAction<{ bookId: string, value: number }>,
    ) {
      // @ts-ignore
      state.data.item.roomGroups = updateSelectedRate(state.data.item.roomGroups, action.payload);
    },
    resetStore: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      searchHotelsApi.endpoints.searchHotels.matchFulfilled,
      (state, { payload }) => {
        state.data = prepareHotelSearchResult(payload);
      },
    );
  },
});

export default hotelSlice.reducer;
