import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IPrepareUser } from '@/app/bi/models/app.ts';
import { getPayload } from '@/app/bi/utils/login.ts';

interface AuthState {
  accessToken: string | null
  isAuth: boolean,
  user: IPrepareUser | null;
}

const initialState: AuthState = {
  accessToken: '',
  isAuth: false,
  user: null,
};

export const authSlice = createSlice({
  name: 'authSlice',
  initialState,
  reducers: {
    setCredentials: (state, action: PayloadAction<string>) => {
      const payloadData = getPayload(action.payload);
      state.user = payloadData;
      state.accessToken = action.payload;
      state.isAuth = true;
    },
    logout: () => initialState,
  },
});
export const { setCredentials, logout } = authSlice.actions;
export default authSlice.reducer;
