import React from 'react';
import { LinkButton, Text } from 'yw-ui';
import { getText } from '@/i18n';

import MoneyFormat from '@/app/bi/utils/money.ts';

import { useAppSelector } from '@/app/store/hooks/redux.ts';

const LABELS = {
  AVAILABLE: getText('components:header.balance.available'),
};
const Balance = () => {
  const {
    headers: {
      balance: {
        available,
      },
    },
  } = useAppSelector((state) => state.appSlice);

  const availableHtml = `${LABELS.AVAILABLE} ${MoneyFormat.moneyWithDecimal(available)} $`;

  return (
    <LinkButton>
      <Text type='NORMAL_14'>
        {availableHtml}
      </Text>
    </LinkButton>
  );

};

export { Balance };
