import { Dayjs } from 'dayjs';
import { getText, getTextArray } from '@/i18n';

import toDecline from './toDecline';
import { SELECT_TRAVELLERS } from '../constants/hotelsSearch.ts';
import MoneyFormat from '@/app/bi/utils/money.ts';

const RZ_PV_FIELDS = ['customCheckin', 'customCheckout'];

const LABELS = {
  customCheckin: getText('utils:hotel.earlyIn'),
  customCheckout: getText('utils:hotel.lateOut'),
  AND: getText('utils:hotel.and'),
  INCLUDES: (rzpv: string) => getText('utils:hotel.includes', { rzpv }),
  ROOM_DECLINES: getTextArray('utils:hotel.roomDeclines'),
  NIGHT_DECLINES: getTextArray('utils:hotel.nightDeclines'),
};

const getRzPvModifiers = (searchSettings: any) =>
  // @ts-ignore
  RZ_PV_FIELDS.reduce((r, f) => {
    if (searchSettings[f]) {
      return [...r, LABELS[f as keyof typeof LABELS]];
    }

    return r;
    // @ts-ignore
  }, []).join(LABELS.AND);

interface PrepareHotelPriceReturn {
  countString: string;
  daysString: string;
  base: string;
  rzpvString: string;
  price: string;
}

const prepareHotelPrice = (
  count: number,
  totalPrice: number,
  daysCount: number,
  customCheckin: Dayjs | null,
  customCheckout: Dayjs | null,
): PrepareHotelPriceReturn => {
  const selectCount = count || 1;
  const price = `${selectCount * totalPrice}`;
  const countString = count > 1 ? `${count} ${toDecline(count, LABELS.ROOM_DECLINES)} x ` : '';
  const daysString = daysCount > 1 ? `${daysCount} ${toDecline(daysCount, LABELS.NIGHT_DECLINES)} x ` : '';
  const base = countString || daysString ? MoneyFormat.money(totalPrice, true) : '';
  const includesRzpv = LABELS.INCLUDES(getRzPvModifiers({}));
  const rzpvHtml = customCheckin || customCheckout ? includesRzpv : '';
  const rzpvString = daysString || countString
    ? rzpvHtml
    : `${daysCount} ${toDecline(daysCount, LABELS.NIGHT_DECLINES)} ${includesRzpv}`;

  return {
    countString,
    daysString,
    base,
    rzpvString,
    price,
  };
};

const roomCountFinder = (travellersCount: number, adult: number) =>
  SELECT_TRAVELLERS[travellersCount].find(({ value }) => value === adult)?.roomCount || 1;

export {
  prepareHotelPrice,
  getRzPvModifiers,
  roomCountFinder,
};
