import React from 'react';
import { Text } from 'yw-ui';
import { getText } from '@/i18n';

import { AirItem } from './components/AirItem';

import { preparedDetailsByAirlineVersion } from '@/app/bi/utils/fares.ts';

import { ICartResponseAirlineItem } from '@/app/bi/models/cart.ts';

import styles from './index.module.css';

// const getDetailsList = (actualVersion: IAirlineVersion) => {
//   const detailsList = preparedDetailsByAirlineVersion(actualVersion);
//
//   const minimumLinesInAColumn = Math.ceil(detailsList.length / 2);
//   const columns = [];
//
//   for (let i = 0; i < detailsList.length; i += minimumLinesInAColumn) {
//     columns.push(detailsList.slice(i, i + minimumLinesInAColumn));
//   }
//
//   return columns;
// };

const LABELS = {
  FLIGHT_DETAILS: getText('components:airlineSidePanel.title'),
  RATE: getText('components:airlineSidePanel.rate'),
};

interface AirlineSidePanelProps {
  item: ICartResponseAirlineItem,
}
const AirlineSidePanel = ({ item }: AirlineSidePanelProps) => {
  const renderAirItem = () => item.routes.map((route) => (
    <AirItem
      key={ `airline_route_${route.id}` }
      duration={ item.duration }
      route={ route }
    />
  ));

  const renderDetails = () => {
    // const airlineClass = `${LABELS.RATE} ${FLY_CLASS[actualVersion.class]}`;
    // const columns = getDetailsList(actualVersion);
    const detailsList = preparedDetailsByAirlineVersion(item);

    return (
      <div className={ styles.details_list } >
        <Text type='NORMAL_14' fontWeight={ 600 } line-height={ '105%' }>
          {LABELS.RATE}&nbsp;
          {/* &laquo;{ item.fareName }&raquo; */}
        </Text>
        <div className={ styles.details_content } >
          <div className={ styles.column }>
            {detailsList.map((detail, index) => (
              <Text key={ index } className={ styles.row } type='NORMAL_14'>
                {detail}</Text>
            ))}
          </div>
          {/* {columns.map((column, i) => ( */}
          {/*   <div key={ i } className={ styles.column }> */}
          {/*     {column.map((line, j) => ( */}
          {/*       <Text key={ j } className={ styles.row } type='NORMAL_14'>{line}</Text> */}
          {/*     ))} */}
          {/*   </div> */}
          {/* ))} */}
        </div>
      </div>
    );
  };

  return (
    <div className={ styles.details_wrapper }>
      <div className={ styles.header }>
        <Text type='bold_24'>{LABELS.FLIGHT_DETAILS}</Text>
      </div>
      <div className={ styles.content } >
        {renderAirItem()}
      </div>
      {renderDetails()}
    </div>
  );
};

export { AirlineSidePanel };
