import React, {
  ReactNode,
  type RefObject,
  useCallback,
  useState,
} from 'react';
import queryString from 'query-string';
import { Dayjs } from 'dayjs';
import { useNavigate } from 'react-router-dom';
import { currentLng } from '@/i18n';

import {
  createSuggests,
  mapStateToHotelPageSearchSettings,
} from '@/app/bi/utils/hotelsSearch.ts';
import { debounce } from '@/app/bi/utils/debounce.ts';

import {
  CHECKIN,
  CHECKOUT,
  THROTTLE_DELAY_AUTOCOMPLETE,
} from '../../../bi/constants/hotelsSearch';

import { useAppDispatch, useAppSelector } from '../../../store/hooks/redux.ts';

import { IPrepareHotelStaticAutocompleteItem, useLazyHotelAutocompleteQuery } from '@/app/bi/api/searchHotelsApi.ts';
import { searchHotelsSlice } from '@/app/store/redusers/SearchHotelsSlice.ts';

import { PanelHotelSearchMenu } from '@/app/components/Menu/HotelSearchMenu/components/PanelHotelSearchMenu';
import { SubHotelSearchMenu } from '@/app/components/Menu/HotelSearchMenu/components/SubHotelSearchMenu';
import ROUTES from '@/app/bi/constants/routes.ts';

const DIRECTION_TYPES = {
  FROM: 'from',
  TO: 'to',
};

interface HotelSearchMenuProps {
  subMenu?: boolean;
  disableRegion?: boolean;
  onOpenDatePickerTo?(mode: (mode: string) => void): void;
  onOpenDatePickerFrom?(mode: (mode: string) => void): void;
  onSearchSubMenu?(): void;
  onChangeForm?(): void;
  buttonsRefs?: Array<RefObject<HTMLElement>>;
}

const HotelSearchMenu = ({
  subMenu = false,
  onOpenDatePickerFrom = () => {},
  onOpenDatePickerTo = () => {},
  onSearchSubMenu = () => {},
  onChangeForm = () => {},
  buttonsRefs = [],
}: HotelSearchMenuProps) => {
  const {
    setAdult,
    setTravellersCount,
    updateLabel,
    setRegion,
    setCheckinDate,
    setCustomCheckinDate,
    setCheckoutDate,
    setCustomCheckoutDate,
  } = searchHotelsSlice.actions;
  const {
    region,
    checkin,
    checkout,
    adult,
    customCheckin,
    customCheckout,
    travellersCount,
  } = useAppSelector((state) => state.searchHotelsReducer);

  const dispatch = useAppDispatch();

  const navigate = useNavigate();
  const [
    triggerAutocomplete,
    { data = {
      regions: [],
      hotels: [],
    }, isFetching },
  ] = useLazyHotelAutocompleteQuery();

  const itemsSuggests = createSuggests(data);

  const throttleAutocomplete = useCallback(
    debounce(triggerAutocomplete, THROTTLE_DELAY_AUTOCOMPLETE),
    [triggerAutocomplete],
  );

  const [datePickersOpened, setDatePickersOpened] = useState({
    from: false,
    to: false,
  });

  const handleChangeQuery = async (query: string) => {
    dispatch(updateLabel(query));
    await throttleAutocomplete({ locale: currentLng, query });
  };

  const handleSuggestSelected = (suggest: IPrepareHotelStaticAutocompleteItem) => {
    if (suggest !== null) {
      dispatch(setRegion(suggest));
      setOpenedDatepicker(DIRECTION_TYPES.FROM, true);
    }
  };

  const handleChangeAdult = (value: number) => {
    dispatch(setAdult(value));
  };

  const handleChangeCheckinDate = (value: Dayjs | null, withTime: boolean) => {
    dispatch(withTime ? setCustomCheckinDate(value) : setCheckinDate(value));

    if (!withTime) dispatch(setCustomCheckinDate(null));
  };

  const handleChangeCheckoutDate = (value: Dayjs | null, withTime: boolean) => {
    dispatch(withTime ? setCustomCheckoutDate(value) : setCheckoutDate(value));

    if (!withTime) dispatch(setCustomCheckoutDate(null));
  };

  const handleChangeHours = (value: string | number, base: Dayjs, field: string) => {
    const newValue = typeof value === 'number'
      ? base.clone().set('hours', value).set('minutes', 0)
      : null;

    if (field === CHECKIN) {
      dispatch(setCustomCheckinDate(newValue));
    }

    if (field === CHECKOUT) {
      dispatch(setCustomCheckoutDate(newValue));
    }
  };

  const renderPanelMenu = (): ReactNode =>
    <PanelHotelSearchMenu
      itemsSuggests={ itemsSuggests }
      isFetching={ isFetching }
      datePickersOpened={ datePickersOpened }
      onChangeAdult={ handleChangeAdult }
      onSuggestSelected={ handleSuggestSelected }
      onChangeQuery={ handleChangeQuery }
      onChangeCheckinDate={ handleChangeCheckinDate }
      onChangeCheckoutDate={ handleChangeCheckoutDate }
      onOpenedDatepicker={ setOpenedDatepicker }
      onChangeHours={ handleChangeHours }
      onChangeTravellersCount={ handleChangeTravellersCount }
      onSearch={ search }
    />;

  const renderSubMenu = (): ReactNode =>
    (
      <SubHotelSearchMenu
        itemsSuggests={ itemsSuggests }
        isFetching={ isFetching }
        buttonsRefs={ buttonsRefs }
        onChangeQuery={ handleChangeQuery }
        onChangeForm={ onChangeForm }
        onSuggestSelected={ handleSuggestSelected }
        onChangeAdult={ handleChangeAdult }
        onChangeTravellersCount={ handleChangeTravellersCount }
        onSearch={ onSearchSubMenu }
        onOpenDatePickerFrom={ onOpenDatePickerFrom }
        onOpenDatePickerTo={ onOpenDatePickerTo }
      />
    );

  const search = () => {
    if (region?.selected?.isRegion) {
      navigate(ROUTES.SEARCH.HOTELS_REGION);
    } else {
      const searchParams = mapStateToHotelPageSearchSettings({
        checkin,
        checkout,
        adult,
        regionId: region?.selected?.id as string,
        regionName: region?.selected?.name as string as string,
        customCheckin,
        customCheckout,
        travellersCount,
      });

      navigate({
        pathname: `${ROUTES.SEARCH.HOTEL_PAGE}/${region?.selected?.id}`,
        search: `${queryString.stringify({ ...searchParams })}`,
      });
    }
  };

  const setOpenedDatepicker = (field: string, value: boolean) => {
    setDatePickersOpened({
      ...datePickersOpened,
      [field]: value,
    });
  };

  const handleChangeTravellersCount = (value: number) => dispatch(setTravellersCount(value));

  return subMenu ? renderSubMenu() : renderPanelMenu();
};

export { HotelSearchMenu };
