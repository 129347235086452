import { ARRAY_SERVICES } from '@/app/bi/constants/invoice.ts';

import { ICompany } from '@/app/bi/models/employees.ts';
import { EServiceTypes } from '@/app/bi/models/serviceTypes.ts';

export const getServiceNameByType = (typeService: EServiceTypes | EServiceTypes[]) => {
  if (typeService && Array.isArray(typeService)) {
    const newArrayServices = typeService.map((service: EServiceTypes) => ARRAY_SERVICES[service]);

    return newArrayServices.join(', ');
  }

  return ARRAY_SERVICES[typeService];
};

export const sortedFilterCompanies = (data: ICompany[], selectedCompanies: number[]) => [...data].sort((
  companyA,
  companyB,
) => {
  const isCompanyASelected = selectedCompanies.includes(companyA.id) ? -1 : 1;
  const isCompanyBSelected = selectedCompanies.includes(companyB.id) ? -1 : 1;

  return isCompanyASelected - isCompanyBSelected;
});
