import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { appApi } from '@/app/bi/api/appApi.ts';

import { IAccount, IHeaders, IUserLocale } from '@/app/bi/models/app.ts';
import { ELanguages } from '@/i18n/locales/consts';

// const DEFAULT_USER = {
//   email: '',
//   userId: '',
//   accountId: '',
//   isBackofficeManager: false,
//   employeeId: '',
// };

const DEFAULT_HEADER = {
  balance: {
    available: 0,
  },
  cartItemsCount: 0,
};

interface AppState {
  account: IAccount;
  userId: string;
  locale: ELanguages;
  // user: IPrepareUser;
  headers: IHeaders;
}

const initialState: AppState = {
  account: {
    id: null,
    name: null,
    companies: [],
  },
  userId: '',
  locale: ELanguages.Tr,
  // user: DEFAULT_USER,
  headers: DEFAULT_HEADER,
};

export const appSlice = createSlice({
  name: 'appSlice',
  initialState,
  reducers: {
    setLocale: (state, action: PayloadAction<IUserLocale>) => {
      const { userId, locale } = action.payload;
      state.userId = userId;
      state.locale = locale;
    },
    updateCartItemsCount(state, action: PayloadAction<any>) {
      state.headers.cartItemsCount = action.payload;
    },
    resetStore: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      appApi.endpoints.getAccount.matchFulfilled,
      (state, { payload }) => {
        const { account } = payload;
        state.account = { ...account };
      },
    );
    builder.addMatcher(
      appApi.endpoints.getHeaders.matchFulfilled,
      (state, { payload }) => {
        const {
          balance: {
            available,
          },
          cartCount,
        } = payload;
        state.headers.balance.available = available;
        state.headers.cartItemsCount = cartCount;
      },
    );
  },
});

export default appSlice.reducer;
