import React, { ReactNode } from 'react';
import { StyledWrapper, Text } from 'yw-ui';

import styles from './styles/index.module.css';

import logoCompany from '../../../svg/logo/yolwise.svg';

const NAME_LOGO = 'Yolwise';

interface LoginDialogProps {
  title: string,
  children: ReactNode,
}
const LoginDialogWrapper = ({
  title,
  children,
}: LoginDialogProps) => (
  <div className={ styles.wrap }>
    <StyledWrapper className={ styles.wrapper }>
      <div className={ styles.logo }>
        <img src={ logoCompany } alt={ NAME_LOGO } title={ NAME_LOGO } />
      </div>
      <Text className={ styles.label } type='bold_20'>
        { title }
      </Text>
      {children}
    </StyledWrapper>
  </div>
);

export { LoginDialogWrapper };
