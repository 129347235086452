import React from 'react';
import { Select } from 'yw-ui';

import { SortArrowOption } from './components/SortArrowOption';

import styles from './styles/index.module.css';

interface SortSelectProps {
  items: { label: string, value: string, type: number }[],
  value: string
  onChange(value: number | string): void,
}

const SortSelect = ({ items, value, onChange }: SortSelectProps) => (
  <Select
    theme='default-border'
    items={ items }
    value={ value }
    // @ts-ignore
    renderLabel={ (item) => <SortArrowOption item={ item } /> } // TODO разобраться с типами
    // @ts-ignore
    renderItem={ (item) => <SortArrowOption item={ item } /> }
    onChange={ onChange }
    className={ styles.select }
  />
);

export { SortSelect };
