import React from 'react';
import { Outlet } from 'react-router-dom';

import { RecentTrips } from './components/RecentTrips';
import { Tabs } from './components/Tabs';

import styles from './index.module.css';

const Homepage = () => (
  <div className={ styles.wrap }>
    <div className={ styles.search }>
      <Tabs />
      <div className={ styles.search }>
        <Outlet />
      </div>
    </div>
    <div className={ styles.trips }>
      <RecentTrips />
    </div>
  </div>
);

export { Homepage };
