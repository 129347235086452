import React from 'react';
import { SidePanel } from 'yw-ui';

import { useAppDispatch, useAppSelector } from '@/app/store/hooks/redux.ts';
import { sideSlice } from '@/app/store/redusers/SideSlice.ts';

const SidePanelWrapper = () => {
  const {
    show,
    renderFn,
  } = useAppSelector((state) => state.sideSlice);

  const {
    setShow,
  } = sideSlice.actions;
  const dispatch = useAppDispatch();

  return (
    <SidePanel
      show={ show }
      onClose={ () => dispatch(setShow(false)) }
    >
      { renderFn() }
    </SidePanel>
  );
};

export { SidePanelWrapper };
