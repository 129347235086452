import React, { useCallback, useEffect, useRef } from 'react';
import { GoogleMap, useLoadScript } from '@react-google-maps/api';
import { MarkerClusterer } from '@googlemaps/markerclusterer';
import { PageLoader } from 'yw-ui';

import styles from './index.module.css';

import CONFIG from '@/config.ts';
import { IMapPoint } from '@/app/bi/models/maps.ts';

const DEFAULT_ZOOM = 12;

const containerStyle = {
  width: '100%',
  height: '100%',
};

type Point = {
  lat: number;
  lng: number;
};

interface MapWithClustersProps {
  points: IMapPoint[];
  zoom?: number;
  center: Point,
  updateSelectHotel?(hotelId: string | null): void
}

const libraries = ['places', 'marker'];

const GoogleMapComponent = ({
  points,
  zoom = DEFAULT_ZOOM,
  center,
  updateSelectHotel = () => {},
}: MapWithClustersProps) => {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: CONFIG.GOOGLE_MAPS.API_KEY,
    version: 'weekly',
    // @ts-ignore
    libraries,
  });

  const mapRef = useRef<google.maps.Map | null>(null);
  const markersRef = useRef<google.maps.marker.AdvancedMarkerElement[]>([]);
  // const [markerClusterer, setMarkerClusterer] = useState(null);

  useEffect(() => {
    if (mapRef.current) {
      updateMarkers();
    }
  }, [points]);

  const updateMarkers = async () => {

    if (mapRef.current) {
      markersRef.current = points.map(({ lat, lng, price, hotelId }) => {
        const priceTag = document.createElement('div');
        priceTag.className = styles.price_tag;
        priceTag.textContent = price;
        priceTag.id = hotelId.toString();
        priceTag.style.pointerEvents = 'auto';

        const marker = new google.maps.marker.AdvancedMarkerElement({
          position: new google.maps.LatLng(lat, lng),
          map: mapRef.current,
          content: priceTag,
        });

        const handleMouseover = (e: any) => {
          e.preventDefault();
          priceTag.classList.add(styles.active);
          updateSelectHotel(e.target.id);
        };

        const handleMouseout = (e: any) => {
          e.preventDefault();
          priceTag.classList.remove(styles.active);
          updateSelectHotel(null);
        };

        priceTag.addEventListener('mouseover', handleMouseover);

        priceTag.addEventListener('mouseout', handleMouseout);

        // const handleMouseOver = () => {
        //   priceTag.style.backgroundColor = 'yellow';
        // }
        // // Функция для обработки события снятия курсора
        // const handleMouseOut = () => {
        //   priceTag.style.backgroundColor = '';
        // }

        return marker;
      });

      // eslint-disable-next-line no-new
      new MarkerClusterer({
        markers: markersRef.current,
        map: mapRef.current,
      });
    }
  };

  const handleOnLoad = useCallback((map: google.maps.Map) => {
    mapRef.current = map;
    if (points.length) {
      updateMarkers();
    }
  }, [points]);

  if (loadError) {
    return <div>Error loading maps</div>;
  }

  if (!isLoaded) {
    return (
      <PageLoader />
    );
  }

  return (
    <GoogleMap
      mapContainerStyle={ containerStyle }
      center={ center }
      zoom={ zoom }
      onLoad={ handleOnLoad }
      options={ { mapId: CONFIG.GOOGLE_MAPS.MAP_ID } }
    />
  );
};

export { GoogleMapComponent };
