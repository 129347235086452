import { useEffect } from 'react';
import CONFIG from '@/config.ts';
import { useAppSelector } from '@/app/store/hooks/redux.ts';

const APPID = CONFIG.INTERCOM.APPID;

declare global {
  interface Window {
    Intercom: {
      apply: (...args: unknown[]) => void;
      boot: () => void;
      update: () => void;
      (...args: unknown[]): void;
    };
  }
}

const useIntercomeInitialisator = () => {
  const { initializeIntercom, closeSession } = useIntercom();
  useEffect(() => {
    if (!window.Intercom) {
      (function (w, d, id, s, x) {
        function i() {
          // eslint-disable-next-line prefer-rest-params
          i.c(arguments);
        }

        // @ts-ignore
        i.q = [];
        // @ts-ignore
        i.c = function (args) {
          i.q.push(args);
        };
        // @ts-ignore
        w.Intercom = i;
        // @ts-ignore
        // eslint-disable-next-line no-param-reassign
        s = d.createElement('script');
        // @ts-ignore
        s.src = `https://widget.intercom.io/widget/${id}`;
        // @ts-ignore
        // eslint-disable-next-line no-param-reassign
        x = d.getElementsByTagName('script')[0];
        // @ts-ignore
        x.parentNode.insertBefore(s, x);
      }(window, document, APPID));
    }
    initializeIntercom();

    return () => {
      console.log('useIntercomeInitialisator -- close');
      closeSession();
    };
  }, []);
};

const useIntercom = () => {
  const { user } = useAppSelector((state) => state.auth);

  const initializeIntercom = () => {
    window.Intercom('boot', {
      app_id: APPID,
      email: user?.email,
      // name: companyName,
      company: {
        id: user?.accountId,
        //  name: companyName,
        plan: 'NEW DESIGN',
      },
    });
  };

  const hideIntercom = () => {
    window.Intercom('hide');
  };

  const showIntercom = () => {
    window.Intercom('show');
  };

  const closeSession = () => {
    console.log('closeSession --->>>');
    if (window.Intercom) {
      window.Intercom('shutdown');
    }
  };

  const showMessages = () => {
    if (window.Intercom) {
      window.Intercom('showMessages');
    }
  };

  return { hideIntercom, showIntercom, showMessages, closeSession, initializeIntercom };
};

export { useIntercom, useIntercomeInitialisator };
