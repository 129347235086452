import React, { RefObject } from 'react';
import { Select, Switcher, Tag, Text } from 'yw-ui';
import { getText } from '@/i18n';

import { useAppDispatch, useAppSelector } from '@/app/store/hooks/redux.ts';
import { hotelsSlice } from '@/app/store/redusers/HotelsSlice.ts';

import { StickyHidingPanel } from '@/app/components/StickyHiding';
import { HotelProgress } from '@/app/pages/HotelsResultPage/components/HotelProgress';

import { HIDDEN_PANEL_ADJUSTMENT, STICKY_PANEL_ADJUSTMENT } from '@/app/bi/constants/hotels.ts';
import { CURRENCY_CODE_ITEMS } from '@/i18n/locales/consts';

import { ITag } from '@/app/bi/models/shared.ts';
import { IHotelSearchFilter } from '@/app/bi/models/hotelSearch/hotelSearchTypes.ts';
import { ECurrencyCode } from '@/app/bi/models/hotelSearch/hotelSearchEnum.ts';

import styles from './styles.module.css';

const LABELS = {
  SHOW_HOTELS_ON_MAP: getText('hotels:regionResult.mapButton.showHotelsOnMap'),
  ANOTHER: getText('hotels:regionResult.another'),
  CLEAR_ALL: getText('common:clearAll'),
};

interface IHeaderProps {
  pageHeaderRef: RefObject<HTMLDivElement | null>;
  onDeleteTag(tag: ITag): void;
  onResetFilters(): void;
  // onUpdateFilters(filters: IHotelSearchFilter): void;
}

export const Header = ({
  pageHeaderRef,
  onDeleteTag,
  onResetFilters,
  // onUpdateFilters,
}: IHeaderProps) => {
  const {
    isMapMode,
    tags,
    filters,
    paging,
  } = useAppSelector((state) => state.hotelsSlice);
  const dispatch = useAppDispatch();
  const { changeMapMode, updateFilters } = hotelsSlice.actions;

  // const handleSortSearchResult = (value: string) => {
  //   const newSearchSort = ({ ...filters?.searchSort, sortedBy: value });
  //   onUpdateFilters({ ...filters, searchSort: newSearchSort } as IHotelSearchFilter);
  // };

  const handleChangeMapMode = () => dispatch(changeMapMode());

  const handleChangeCurrencyCode = (value: ECurrencyCode) =>
    dispatch(updateFilters({ ...filters, currencyCode: value } as IHotelSearchFilter));

  // const renderSortItem = ({
  //   label,
  //   value,
  // }: {
  //   label: string,
  //   value: number | string
  // }) => {
  //   const currentItem = SORT_ITEMS?.find((item) => item.value === value);
  //
  //   const labelContent = (
  //     <Text type='NORMAL_16' color='gray'>
  //       {label}
  //     </Text>
  //   );
  //
  //   if (currentItem?.type !== null) {
  //     const iconType = currentItem?.type === 1 ? 'arrowThinUp' : 'arrowThinDown';
  //
  //     return (
  //       <div className={ styles.dropdown_item }>
  //         {labelContent}
  //         <Icon
  //           className={ styles.icon }
  //           size={ 16 }
  //           type={ iconType }
  //         />
  //       </div>
  //     );
  //   }
  //
  //   return labelContent;
  // };

  const renderTags = () => {
    if (!tags.length) return null;

    const tagsHtml = !!tags.length && tags.map((tag) => {
      const { key, name } = tag;

      return (
        <Tag
          className={ styles.tag }
          label={ name }
          key={ key }
          onClick={ () => onDeleteTag(tag) }
        />
      );
    });

    return (
      <div className={ styles.tags }>
        {tagsHtml}
        <Tag
          label={ LABELS.CLEAR_ALL }
          onClick={ onResetFilters }
          key='clear-all'
          deleteAll
          className={ styles.tag }
        />
      </div>
    );
  };

  // TODO - было в renderHeaderContent()
  // const listItems = SORT_ITEMS.map(({
  //   label,
  //   value,
  // }) => ({
  //   label,
  //   value,
  // }));

  const renderHeaderContent = () => (
    <>
      <div className={ styles.loading_map_btn_wrapper }>
        <HotelProgress count={ paging.total } />
        <div className={ styles.filters_and_modes_wrapper }>
          <div className={ styles.modes_wrapper }>
            <Text type='NORMAL_14'>{LABELS.SHOW_HOTELS_ON_MAP}</Text>
            <Switcher value={ isMapMode } onChange={ handleChangeMapMode }/>
          </div>
          <div>
            <Select
              theme='default-border'
              items={ CURRENCY_CODE_ITEMS }
              value={ filters?.currencyCode }
              onChange={ handleChangeCurrencyCode }
            />
          </div>
          {/* <div className={ styles.filters }> */}
          {/*  <div className={ styles.select }> */}
          {/*    <Select */}
          {/*      renderItem={ renderSortItem } */}
          {/*      renderLabel={ renderSortItem } */}
          {/*      items={ listItems } */}
          {/*      value={ filters?.searchSort.sortedBy } */}
          {/*      theme='default-border' */}
          {/*      onChange={ handleSortSearchResult } */}
          {/*    /> */}
          {/*  </div> */}
          {/* </div> */}
        </div>
      </div>
      {renderTags()}
    </>
  );

  return (
    <div className={ styles.header } ref={ pageHeaderRef as RefObject<HTMLDivElement> }>
      <StickyHidingPanel
        adjustment={ STICKY_PANEL_ADJUSTMENT }
        hiddenAdjustment={ HIDDEN_PANEL_ADJUSTMENT }
        fixedClass={ styles.sticky_panel_fixed }
        className={ styles.sticky_panel }
        tags={ tags }
      >
        <div className={ styles.main }>
          {renderHeaderContent()}
        </div>
      </StickyHidingPanel>
    </div>
  );
};
