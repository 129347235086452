import React from 'react';
import { Collapse, RadioButton } from 'yw-ui';

import { getText } from '@/i18n';

import { DEFAULT_ITEM_POLICY } from '@/app/bi/constants/settings.ts';

import { ITravelPolicy } from '@/app/bi/models/travelPolicies.ts';

import styles from './index.module.css';

const LABELS = {
  FILTER_NAME: getText('components:travelPolicies.filterName'),
};

interface TravelPolicyFilterProps {
  listPolicies: ITravelPolicy[];
  selectPolicyId: string | null;
  selectPolicy: { id: string, policyName: string, isActive: boolean };
  setIsSelectPolicy(value: ITravelPolicy): void;
  onChange(value: ITravelPolicy): void;
}

export const TravelPolicyFilter = ({
  listPolicies,
  selectPolicyId,
  selectPolicy,
  setIsSelectPolicy,
  onChange,
}: TravelPolicyFilterProps) => {
  const handleEmptyPolicy = () => setIsSelectPolicy(DEFAULT_ITEM_POLICY);

  const renderListPolicies = () => listPolicies.map((policy, index) => {
    const { id, name } = policy;
    const checked = selectPolicyId === id;

    return (
      <RadioButton
        key={ `policy_${name}_${index}` }
        className={ styles.checkbox }
        onChange={ () => onChange(policy) }
        value={ id }
        checked={ checked }
      >
        {name}
      </RadioButton>
    );
  });

  return (
    <Collapse
      opened
      className={ styles.item }
      label={ LABELS.FILTER_NAME }
    >
      <RadioButton
        className={ styles.checkbox }
        onChange={ handleEmptyPolicy }
        value={ DEFAULT_ITEM_POLICY.id }
        checked={ selectPolicy.isActive }
      >
        {DEFAULT_ITEM_POLICY.name}
      </RadioButton>
      {renderListPolicies()}
    </Collapse>
  );
};
