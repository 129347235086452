import { getText } from '@/i18n';

import { dayjsSubPeriod, getDateZeroTime, getDayjs } from '@/app/bi/utils/formatDate.ts';

import {
  ESortedByTrips,
  ETripStatus,
  ITripsFilter,
} from '@/app/bi/models/tripsTypes.ts';
import { EServiceTypes } from '@/app/bi/models/serviceTypes.ts';

const MAX_COUNT_DATA_BEFORE_SCROLL = 12;

const SERVICE_ICON: { [key: string]: string } = {
  [EServiceTypes.Airline]: 'serviceAir',
  [EServiceTypes.Hotel]: 'serviceHotel',
  [EServiceTypes.Custom]: 'serviceTransfer',
};

const SERVICES_NAME_ARRAY: { [key: string]: string } = {
  [EServiceTypes.Airline]: getText('homePage:menuItems.air'),
  [EServiceTypes.Hotel]: getText('homePage:menuItems.hotel'),
  [EServiceTypes.Custom]: getText('homePage:menuItems.transfer'),
};

const STATUS_NAME_ARRAY = {
  [ETripStatus.Ended]: getText('trips:filterOperationStatus.ended'),
  [ETripStatus.AwaitingBooking]: getText('trips:filterOperationStatus.awaitingBooking'),
  [ETripStatus.Booked]: getText('trips:filterOperationStatus.booked'),
  [ETripStatus.Canceled]: getText('trips:filterOperationStatus.cancelled'),
};

const DEFAULT_DATE_STATE = {
  max: getDateZeroTime(),
  min: dayjsSubPeriod(getDateZeroTime(), 1, 'month'),
};

const DEFAULT_FILTERS_TRIPS: ITripsFilter = {
  serviceTypes: [],
  tripStatuses: [],
  employees: [],
  createdOnlyByMe: false,
  bookingDate: getDayjs(),
  dateInTripPeriod: getDayjs(),
  tripDates: DEFAULT_DATE_STATE,
  page: 1,
  cities: [],
  sort: {
    sortedBy: ESortedByTrips.CreatedDate,
    descending: true,
  },
};

const INPUT_EMPLOYEE_DEBOUNCE_MS = 300;

const PLACEHOLDER_INPUT_SEND_EMAIL = 'user@yolwise.com';

export {
  MAX_COUNT_DATA_BEFORE_SCROLL,
  SERVICE_ICON,
  DEFAULT_DATE_STATE,
  DEFAULT_FILTERS_TRIPS,
  INPUT_EMPLOYEE_DEBOUNCE_MS,
  SERVICES_NAME_ARRAY,
  STATUS_NAME_ARRAY,
  PLACEHOLDER_INPUT_SEND_EMAIL,
};
