import React, { useState } from 'react';
import { Button, Dialog, IconButton, RadioButton, Suggest, Text } from 'yw-ui';

import { getText } from '@/i18n';

import { FieldLabel } from '@/app/components/FieldLabel';

import { INIT_ROUTE_AIRLINE } from '@/app/bi/constants/travelPolicy.ts';

import {
  ERestrictionActionAirline,
  IRoutes,
  ITravelPolicies,
} from '@/app/bi/models/travelPolicies.ts';

import styles from './styles/index.module.css';

interface DialogAviaProps {
  isShow: boolean;
  policies: ITravelPolicies;
  onCloseDialog(): void;
}

const LABELS = {
  ADD: getText('common:add'),
  ONLY_TURKEY: getText('settings:travelPolicies.policy.onlyTurkey'),
  PURCHASE_WITHOUT_APPROVAL: getText('settings:travelPolicies.purchaseWithoutApproval'),
  SAVE: getText('common:save'),
  UNDO: getText('common:undo'),
  RESTRICTION_DIRECTIONS: getText('settings:travelPolicies.restrictionDirections'),
  ONLY_SPECIFIED_DESTINATIONS: getText('settings:travelPolicies.onlySpecifiedDestinations'),
  FROM: getText('settings:travelPolicies.from'),
  TO: getText('settings:travelPolicies.to'),
  AND_BACK: getText('settings:travelPolicies.andBack'),
};

export const DialogAvia = ({
  isShow,
  policies,
  onCloseDialog,
}: DialogAviaProps) => {
  const [
    restrictionOnDirection,
    setRestrictionOnDirection,
  ] = useState(policies.airline.restrictionOnDirections);

  const {
    onlySpecifiedDestinations,
    onlyTurkey,
    routes,
  } = restrictionOnDirection;

  const handleAddRoute = () => {
    setRestrictionOnDirection({
      ...restrictionOnDirection,
      routes: [...routes, INIT_ROUTE_AIRLINE],
    });
  };

  const handleDeleteRoute = (indexRoute: number) => {
    const newRoutes = routes.filter((
      _,
      index,
    ) => index !== indexRoute);

    setRestrictionOnDirection({
      ...restrictionOnDirection,
      routes: newRoutes,
    });
  };

  const handleRestrictionChange = (action: ERestrictionActionAirline) => {
    setRestrictionOnDirection(({
      ...restrictionOnDirection,
      onlyTurkey: action === ERestrictionActionAirline.OnlyTurkey ? true : !onlyTurkey,
      onlySpecifiedDestinations: action === ERestrictionActionAirline.OnlySpecifiedDestinations ? true : !onlySpecifiedDestinations,
    }));
  };

  const handleOnlyTurkey = () => handleRestrictionChange(ERestrictionActionAirline.OnlyTurkey);

  const handleOnlySpecifiedDestinations = () => handleRestrictionChange(
    ERestrictionActionAirline.OnlySpecifiedDestinations,
  );

  const handleChange = () => {};

  const handleSelect = () => {};

  const renderItemRoute = ({ from, to }: IRoutes, index: number) => (
    <div key={ `routes_${index}` } className={ styles.container_field_routes }>
      <div className={ styles.item_route }>
        <FieldLabel text={ LABELS.FROM } isRequired/>
        <Suggest
          preventTab={ false }
          theme='border'
          withLabel={ false }
          value={ from.label }
          items={ [
            {
              value: 1,
              label: 'Станбул',
            },
          ] }
          onChange={ handleChange }
          onSelect={ handleSelect }
          placeholder={ LABELS.FROM }
        />
      </div>
      <div className={ styles.item_route }>
        <FieldLabel text={ LABELS.TO } isRequired/>
        <Suggest
          preventTab={ false }
          theme='border'
          withLabel={ false }
          value={ to.label }
          items={ [
            {
              value: 1,
              label: 'Москва',
            },
          ] }
          onChange={ handleChange }
          onSelect={ handleSelect }
          placeholder={ LABELS.TO }
        />
      </div>
      <Text className={ styles.accompanying_text } type='NORMAL_14'>
        {LABELS.AND_BACK}
        {routes.length > 1 && (
          <IconButton
            iconType='closeOff'
            iconColor='gray4'
            size={ 16 }
            onClick={ () => handleDeleteRoute(index) }
          />
        )}
      </Text>
    </div>
  );

  const renderContent = () => onlySpecifiedDestinations && (
    <div className={ styles.routes }>
      {routes?.map((route, index) => (
        renderItemRoute(route, index)
      ))}
      <IconButton
        iconType='add'
        iconColor='blue1'
        disabled={ routes.length >= 10 }
        onClick={ handleAddRoute }
      >
        {LABELS.ADD}
      </IconButton>
    </div>
  );

  return (
    <Dialog
      className={ styles.dialog }
      showClosing
      show={ isShow }
      onChange={ onCloseDialog }
    >
      <Text type='bold_20'>{LABELS.RESTRICTION_DIRECTIONS}</Text>
      <Text type='NORMAL_16'>
        {LABELS.PURCHASE_WITHOUT_APPROVAL}
      </Text>
      <div className={ styles.content_container } >
        <RadioButton checked={ onlyTurkey } onChange={ handleOnlyTurkey }>
          {LABELS.ONLY_TURKEY}
        </RadioButton>
        <RadioButton checked={ onlySpecifiedDestinations } onChange={ handleOnlySpecifiedDestinations }>
          {LABELS.ONLY_SPECIFIED_DESTINATIONS}
        </RadioButton>
      </div>
      {renderContent()}
      <div className={ styles.buttons_interactive }>
        <Button type='secondary'>
          {LABELS.SAVE}
        </Button>
        <Button type='text' onClick={ onCloseDialog }>
          {LABELS.UNDO}
        </Button>
      </div>
    </Dialog>
  );
};
