import React, { useEffect, useState } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { PageLoader } from 'yw-ui';

import { changeLanguage } from '@/i18n';

import { appSlice } from '@/app/store/redusers/AppSlice.ts';
import { useAppDispatch, useAppSelector } from '@/app/store/hooks/redux.ts';
import { useSignalRConnection, useSignalROnHandlers } from '@/app/hooks/useSignalR.ts';
import {
  useLazyGetAccountQuery,
  useLazyGetHeadersQuery,
  useLazyGetLocaleQuery,
} from '@/app/bi/api/appApi.ts';

import { SettingsPage } from '@/app/pages/Settings';
import { ReportPage } from '@/app/pages/Report';
import { TripsPage } from '@/app/pages/Trips';
import { FavoritesPage } from '@/app/pages/Favorites';
import { Homepage } from '@/app/pages/Home';
import { AirlineResultPage } from '@/app/pages/AirlineResult';
import { LoginPage } from '@/app/pages/Login';
import { RestorePassword } from '@/app/pages/RestorePassword';
import { HotelsResultPage } from '@/app/pages/HotelsResultPage';
import { HotelResultPage } from '@/app/pages/HotelResult';
import { TripsDetailPage } from '@/app/pages/TripsDetail';
import { Employees } from '@/app/pages/Settings/pages/Employees';
import { CompanyDetails } from '@/app/pages/Settings/pages/CompanyDetails';
import { ProfileInfo } from '@/app/pages/Settings/pages/Profile';
import { Reports } from '@/app/pages/Reports';
import { Employee } from '@/app/pages/Employee';
import { CartPage } from '@/app/pages/Cart';
import { CheckoutPage } from '@/app/pages/Checkout';
import { AirlineSearchMenu } from './components/Menu/AirlineSearchMenu';
import { HotelSearchMenu } from './components/Menu/HotelSearchMenu';
import { ProtectedLayout } from '@/app/protectedLayout';
import { CheckEmail } from '@/app/pages/CheckEmail';
import { SetPassword } from '@/app/pages/SetPassword';
import { ResetPassword } from '@/app/pages/ResetPassword';
import { SuccessBooking } from '@/app/pages/SuccessBooking';
import { TransferSearchMenu } from '@/app/components/Menu/TransferSearchMenu';
import { TravelPolicies } from '@/app/pages/Settings/pages/TravelPolicies';
import { TravelPolicy } from '@/app/pages/TravelPolicy';

import CONFIG from '@/config.ts';

import './styles/global/index.css';
import styles from './styles/index.module.css';

const URL = `${CONFIG.API_ROOT}/signalr/base`;

const App = () => {
  const dispatch = useAppDispatch();
  const { accessToken } = useAppSelector((state) => state.auth);
  const [loading, setLoading] = useState(!!accessToken);
  const { setLocale } = appSlice.actions;
  const [getAccount] = useLazyGetAccountQuery();
  const [getLocale] = useLazyGetLocaleQuery();
  const [getHeaders] = useLazyGetHeadersQuery();

  const loadAppResources = async () => {
    setLoading(true);
    try {
      await getAccount().unwrap();
      await getHeaders().unwrap();

      const res = await getLocale().unwrap();
      dispatch(setLocale(res));

      changeLanguage(res.locale);

      setLoading(false);
    } catch {}
  };

  const signalRConnection = useSignalRConnection(URL, accessToken);
  useSignalROnHandlers(signalRConnection);

  useEffect(() => {
    if (accessToken) {
      loadAppResources();
    }
  }, [accessToken]);

  const renderAppLoading = () => (
    <div className={ styles.app_loading_wrap }>
      <PageLoader />
    </div>
  );

  if (loading) {
    return renderAppLoading();
  }

  return (
    <div className={ styles.app }>
      <Routes>
        <Route
          path='/'
          element={ <ProtectedLayout /> }
        >
          <Route element={ <Homepage /> }>
            <Route index element={ <AirlineSearchMenu /> } />
            <Route path='airline' element={ <AirlineSearchMenu /> } />
            <Route
              path='hotels'
              element={ <HotelSearchMenu/> }
            />
            <Route path='transfer' element={ <TransferSearchMenu /> } />
          </Route>
          <Route path='/airline/search/:guid?' element={ <AirlineResultPage /> } />

          <Route path='/hotels/search/:guid?' element={ <HotelsResultPage /> } />
          <Route path='/hotel/:guid' element={ <HotelResultPage /> } />

          <Route path='reports' element={ <Reports /> } />

          <Route path='settings/' element={ <SettingsPage /> }>
            <Route path='employees' element={ <Employees /> } />
            <Route path='travel-policies' element={ <TravelPolicies /> } />
            <Route path='company-details' element={ <CompanyDetails /> } />
            <Route path='profile' element={ <ProfileInfo /> } />
          </Route>

          <Route path='employee/' element={ <Employee /> } />
          <Route path='employee/:employeeId' element={ <Employee /> } />

          <Route path='travel-policy/' element={ <TravelPolicy /> } />
          <Route path='travel-policy/:policyId' element={ <TravelPolicy /> } />

          <Route path='reports' element={ <ReportPage /> } />

          <Route path='trips' element={ <TripsPage /> } />
          <Route path='/trip/:tripId' element={ <TripsDetailPage /> } />

          <Route path='favorites' element={ <FavoritesPage /> } />
          <Route path='cart' element={ <CartPage /> } />
          <Route path='/cart/checkout' element={ <CheckoutPage /> } />
          <Route path='/cart/success/:bookId' element={ <SuccessBooking /> } />

          <Route
            path='*'
            element={ <Navigate to='/' replace /> }
          />
        </Route>
        <Route path='/login' element={ <LoginPage /> } />
        <Route path='/restore-password' element={ <RestorePassword /> } />
        <Route path='/check-email' element={ <CheckEmail /> } />
        <Route path='/set-password' element={ <SetPassword /> } />
        <Route path='/reset-password' element={ <ResetPassword /> } />
      </Routes>
    </div>
  );
};

export default App;
