import React from 'react';
import { IconButton, Text } from 'yw-ui';
import { getText } from '@/i18n';

import styles from './styles/index.module.css';

interface ShowOnMapButtonProps {
  onClick(): void,
}

const LABELS = {
  TITLE: getText('components:showOnMapButton.title'),
};

const ShowOnMapButton = ({
  onClick,
}: ShowOnMapButtonProps) => (
  <div
    className={ styles.wrapper }
    onClick={ onClick }
  >
    <IconButton iconType='location' iconColor='LB4' size={ 16 }>
      <Text type='NORMAL_14' color='light-blue-4' className={ styles.text }>
        { LABELS.TITLE }
      </Text>
    </IconButton>
  </div>
);

export { ShowOnMapButton };
