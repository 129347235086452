import React from 'react';
import { Dayjs } from 'dayjs';
import { Icon, IconButton, Price, StatusMarker, Text } from 'yw-ui';

import { IconType } from 'yw-ui/components/Icon/types';

import { getText } from '@/i18n';

import { EditableText } from '@/app/components/EditableText';

import { formatDate } from '@/app/bi/utils/formatDate.ts';

import { PATTERN } from '@/app/bi/constants/dateFormats.ts';

import { ETripStatus } from '@/app/bi/models/tripsTypes.ts';

import styles from './index.module.css';

const LABELS = {
  TRAVEL_HELPER: getText('components:header.headerItems.travelHelper'),
  NAME_OF_TRIP_PLACEHOLDER: getText('trip:header.nameOfTrip'),
  CREATED: getText('trips:created'),
};

interface TripHeaderProps {
  onTripRename: (value: string) => void
  tripStatus: ETripStatus
  price: string;
  createDate: Dayjs;
  checkinDate: Dayjs;
  checkoutDate: Dayjs;
  name: string | null;
  servicesIcon: string[];
}

const TripHeader = ({
  onTripRename,
  tripStatus,
  price,
  createDate,
  checkinDate,
  checkoutDate,
  name,
  servicesIcon = [],
}: TripHeaderProps) => {
  const titleTripHeader = name || '';

  const formationDate = () => {
    const formatCheckinDate = formatDate(checkinDate);
    const formatCheckoutDate = formatDate(checkoutDate);

    if (formatCheckinDate === formatCheckoutDate) {
      return formatCheckinDate;
    }

    return `${formatCheckinDate} — ${formatCheckoutDate}`;
  };

  const formingCreateDate = `${LABELS.CREATED}: ${formatDate(createDate, PATTERN.DATE_TIME_WITHOUT_SECONDS)}`;

  const renderServicesIcon = servicesIcon.map((icon, index) => (
    <div key={ `${icon}_${index}` } className={ styles.icon_wrapper }>
      <Icon type={ icon as IconType } size={ 24 }/>
    </div>
  ));

  const renderPreview = () => (
    <IconButton iconType='pen' iconColor='blue1' iconSide='right'>
      <Text type='bold_18'>{name}</Text>
    </IconButton>
  );

  return (
    <div className={ styles.wrapper }>
      <div className={ styles.header }>
        <StatusMarker status={ tripStatus } />
        <div className={ styles.icon_container }>{renderServicesIcon}</div>
      </div>
      <div className={ styles.container_info }>
        <div>
          <EditableText
            renderPreview={ renderPreview }
            value={ titleTripHeader }
            placeholder={ titleTripHeader }
            onChange={ onTripRename }
          />
          <div className={ styles.date }>{formationDate()}</div>
        </div>
        <div className={ styles.travel_info_container }>
          <Price type='bold_18' color='gray-8' value={ price } />
          <div className={ styles.travel_create }>{formingCreateDate}</div>
          <div className={ styles.travel_support }>{LABELS.TRAVEL_HELPER}</div>
        </div>
      </div>
    </div>
  );
};

export { TripHeader };
