import { ywApiPrivate } from './index.ts';

import { IEmployeeFilter, IEmployeeFilterResponse } from '@/app/bi/models/employees.ts';

export const employeesApi = ywApiPrivate.injectEndpoints({
  endpoints: (build) => ({
    getEmployeesFilter: build.query<IEmployeeFilterResponse, IEmployeeFilter>({
      query: (body) => ({
        url: '/employees/filter',
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const {
  useGetEmployeesFilterQuery,
} = employeesApi;
