import React, { useCallback, useEffect, useState } from 'react';
import { Dayjs } from 'dayjs';
import { Datepicker, Input, MultiSelect, Suggest, Text } from 'yw-ui';
import { SuggestItem, SuggestItems } from 'yw-ui/components/Suggest/types';

import { getText } from '@/i18n';

import { employeeSlice } from '@/app/store/redusers/EmployeeSlice.ts';
import { useAppDispatch } from '@/app/store/hooks/redux.ts';

import { FormWrapper } from '@/app/components/FormWrapper';
import { FieldLabel } from '@/app/components/FieldLabel';
import { SexSwitcher } from '@/app/components/SexSwitcher';
import { InputPhone } from '@/app/components/InputPhone';
import { BlockFormFio } from '@/app/pages/Employee/components/FormContainer/Components/BlockFormFio';

import { isValidateEmail } from '@/app/bi/utils/login.ts';
import { dayjsObject } from '@/app/bi/utils/formatDate.ts';

import {
  DATEPICKER_BIRTH_DAY_PLACEHOLDER,
  EEmployeeFormParam,
  EEmployeeNameParam,
  ETypesSex,
  MAX_DATE,
} from '@/app/bi/constants/settings.ts';
import { PLACEHOLDER_EMPLOYEE_INPUT } from '@/app/bi/constants/employee.ts';

import { IEmployeeGeneralDataState } from '@/app/bi/models/employee.ts';
import { ICitizenshipItem } from '@/app/bi/models/profile.ts';

import styles from './styles/index.module.css';

const LABELS = {
  CONTACT_DATA: getText('settings:contactData'),
  EMAIL: getText('settings:employees.employee.fieldNames.email'),
  MOBILE_PHONE: getText('settings:employees.employee.fieldNames.mobilePhone'),
  BIRTH_DAY: getText('settings:employees.employee.fieldNames.birthday'),
  SEX: getText('settings:employees.employee.fieldNames.sex'),
  CITIZENSHIP: getText('settings:employees.employee.citizenship'),
  ORGANIZATION: getText('settings:organization'),
  INPUT_NAME: getText('settings:inputName'),
  EMPLOYEE_FORM_ERROR: getText('settings:validationError'),
  INVALID_EMAIL_FORMAT: getText('settings:validationError'),
};

interface GeneralStepProps {
  data: IEmployeeGeneralDataState;
  companies: SuggestItem[];
  citizenshipData: ICitizenshipItem[];
}

interface IValidError {
  birthDay: string;
  email: string;
  phoneNumber: string;
  citizenship: string;
}

const GeneralStep = ({ data, companies, citizenshipData }: GeneralStepProps) => {
  const {
    firstName,
    lastName,
    birthday,
    sex,
    citizenship,
    contactData: {
      phoneNumber,
      email,
      organization,
    },
  } = data;

  const {
    setNameGeneral,
    setBirthDay,
    setSex,
    setContactDataGeneral,
    setCitizenship,
    setOrganization,
  } = employeeSlice.actions;

  const dispatch = useAppDispatch();

  const [error, setError] = useState<IValidError>({
    birthDay: '', email: '', phoneNumber: '', citizenship: '',
  });
  const [inputValue, setInputValue] = useState(citizenship.name);
  const [
    filteredItems,
    setFilteredItems,
  ] = useState<{ label: string, value: string }[]>([]);
  const classnameInputExpiredDate = error.birthDay.length ? styles.datepicker_error : styles.datepicker;

  useEffect(() => {
    setInputValue(citizenship.name);
  }, [citizenship]);

  const validateBirthDay = (day: Dayjs | null): boolean => day?.isBefore(MAX_DATE) ?? false;

  const validateField = useCallback((value: string, type: EEmployeeFormParam): string => {
    switch (type) {
      case EEmployeeFormParam.Email:
        return isValidateEmail(value) ? '' : LABELS.INVALID_EMAIL_FORMAT;
      case EEmployeeFormParam.PhoneNumber:
        return value.length ? '' : LABELS.EMPLOYEE_FORM_ERROR;
      case EEmployeeFormParam.BirthDay:
        return validateBirthDay(dayjsObject(value)) ? '' : LABELS.EMPLOYEE_FORM_ERROR;
      case EEmployeeFormParam.Citizenship:
        return value.length ? '' : LABELS.EMPLOYEE_FORM_ERROR;
      default:
        return value.trim().length > 0 ? '' : LABELS.EMPLOYEE_FORM_ERROR;
    }
  }, []);

  const handleChangeFirstName = (value: string) => {
    dispatch(setNameGeneral({ value, type: EEmployeeNameParam.First }));
  };

  const handleChangeLastName = (value: string) => {
    dispatch(setNameGeneral({ value, type: EEmployeeNameParam.Last }));
  };

  const handleBirthDay = (value: Dayjs | null) => {
    dispatch(setBirthDay(value));

    handleOnBlur(String(value), EEmployeeFormParam.BirthDay);
  };

  const handleSex = (value: ETypesSex) => dispatch(setSex(value));

  const handleOnBlur = useCallback((value: string, type: EEmployeeFormParam): void => {
    const errorMessage = validateField(value, type);

    setError(prevError => ({
      ...prevError,
      [type]: errorMessage,
    }));
  }, [validateField]);

  const handleContactData = useCallback((value: string, type: EEmployeeFormParam): void => {
    dispatch(setContactDataGeneral({ value, type }));

    handleOnBlur(value, type);
  }, [dispatch, validateField]);

  const handleSuggestCitizenship = useCallback((selectCitizenship: SuggestItems) => {
    const selectedCitizenship = citizenshipData.find(
      item => item.name === selectCitizenship.label,
    );

    if (selectedCitizenship) {
      dispatch(setCitizenship(selectedCitizenship));
    }

    const citizenshipName = selectedCitizenship?.name || '';

    handleOnBlur(citizenshipName, EEmployeeFormParam.Citizenship);
  }, [citizenshipData, dispatch, setCitizenship]);

  const handleOrganization = (value: number[]) => dispatch(setOrganization(value));

  const handleSuggestChange = (value: string) => {
    setInputValue(value);

    const filtered = citizenshipData.filter(({
      name,
    }) => name.toLowerCase().includes(value.toLowerCase()));

    const transformationArray = filtered.map(({
      name,
      code3,
    }) => ({ label: name, value: code3 }));

    setFilteredItems(transformationArray);

    handleOnBlur(inputValue, EEmployeeFormParam.Citizenship);
  };

  const renderBirthDayCitizenshipSex = () => (
    <FormWrapper className={ styles.row }>
      <div className={ styles.item }>
        <FieldLabel text={ LABELS.BIRTH_DAY } isRequired />
        <Datepicker
          closeOnTabOut
          inputClassName={ classnameInputExpiredDate }
          value={ birthday }
          max={ MAX_DATE }
          onChange={ handleBirthDay }
          onBlur={ () => handleOnBlur(String(birthday), EEmployeeFormParam.BirthDay) }
          placeholder={ DATEPICKER_BIRTH_DAY_PLACEHOLDER }
          error={ error.birthDay }
        />
      </div>
      <div className={ styles.item }>
        <FieldLabel text={ LABELS.CITIZENSHIP } isRequired />
        <Suggest
          preventTab={ false }
          theme='border'
          withLabel={ false }
          value={ inputValue }
          items={ filteredItems as never[] }
          onChange={ handleSuggestChange }
          onSelect={ handleSuggestCitizenship }
          error={ error.citizenship }
        />
      </div>
      <div className={ styles.item }>
        <FieldLabel text={ LABELS.SEX } isRequired />
        <SexSwitcher sex={ sex } onChange={ handleSex } />
      </div>
    </FormWrapper>
  );

  const renderContactsData = (
    <FormWrapper className={ styles.row }>
      <div className={ styles.item }>
        <FieldLabel text={ LABELS.MOBILE_PHONE } isRequired />
        <InputPhone
          value={ phoneNumber }
          onChange={ (value) => handleContactData(value, EEmployeeFormParam.PhoneNumber) }
          onBlur={ () => handleOnBlur(phoneNumber, EEmployeeFormParam.PhoneNumber) }
          error={ error.phoneNumber }
        />
      </div>
      <div className={ styles.item }>
        <FieldLabel text={ LABELS.EMAIL } isRequired />
        <Input
          type='email'
          value={ email }
          onChange={ (value: string) => handleContactData(value, EEmployeeFormParam.Email) }
          onBlur={ () => handleOnBlur(email, EEmployeeFormParam.Email) }
          placeholder={ PLACEHOLDER_EMPLOYEE_INPUT.EMAIL }
          error={ error.email }
        />
      </div>
      <div className={ styles.item }>
        <FieldLabel text={ LABELS.ORGANIZATION } isRequired />
        <MultiSelect
          theme='border'
          values={ organization }
          list={ companies }
          onChange={ handleOrganization }
          className={ styles.inputOrganization }
          overflow
          placeholder={ LABELS.INPUT_NAME }
        />
      </div>
    </FormWrapper>
  );

  return (
    <div className={ styles.main }>
      <BlockFormFio
        firstName={ firstName }
        lastName={ lastName }
        onChangeFirstName={ handleChangeFirstName }
        onChangeLastName={ handleChangeLastName }
      />
      {renderBirthDayCitizenshipSex()}
      <div className={ styles.subTitleContact }>
        <Text type='bold_18'>{LABELS.CONTACT_DATA}</Text>
      </div>
      {renderContactsData}
    </div>
  );
};

export { GeneralStep };
