import React, { useState } from 'react';
import { Button, Dialog, IconButton, PageLoader, Text } from 'yw-ui';
import { useNavigate } from 'react-router-dom';

import { getText } from '@/i18n';

import {
  useGetTravelPoliciesQuery,
  useDeleteTravelPolicyMutation,
} from '@/app/bi/api/travelPoliciesApi.ts';

import { Table } from '@/app/components/Table';
import { EmptyDataPanel } from '@/app/components/EmptyDataPanel';

import { notification } from '@/app/bi/utils/not.ts';

import ROUTES from '@/app/bi/constants/routes.ts';
import {
  ENotificationActionType,
  ARRAY_COLUMN_TRAVEL_POLICY,
} from '@/app/bi/constants/settings.ts';

import styles from './styles/index.module.css';

const LABELS = {
  TRAVEL_POLICY: getText('settings:dynamicPages.travelPolicies'),
  BUTTON_CREATE_POLICY: getText('settings:travelPolicies.buttonCreatePolicy'),
  EDITING: getText('common:editing'),
  REMOVE: getText('common:remove'),
  SUBMIT: getText('settings:submit'),
  UNDO: getText('common:undo'),
  DELETE_TRAVEL_POLICY_SELECT_EMPLOYEE: getText('settings:travelPolicies.deleteTravelPolicySelectEmployee'),
  DELETE_TRAVEL_POLICY: getText('settings:travelPolicies.deleteTravelPolicies'),
  DELETE_TRAVEL_POLICIES: getText('settings:travelPolicies.deleteTravelPolicies'),
  YES_DELETE: getText('settings:travelPolicies.yesDelete'),
  WANT_DELETE_TRAVEL_POLICY: (policyName: string) => getText('settings:travelPolicies.wantDeleteTravelPolicy', { policyName }),
  EMPTY_PANEL: {
    TRAVEL_POLICY_NOT_FOUND: getText('settings:travelPolicies.emptyPanel.travelPoliciesNotFound'),
    TRAVEL_POLICY_CREATE_BUTTON: getText('settings:travelPolicies.emptyPanel.travelPoliciesCreateButton'),
  },
};

const TravelPolicies = () => {
  const navigate = useNavigate();

  const {
    data,
    isLoading,
  } = useGetTravelPoliciesQuery();

  const [deletePolicy] = useDeleteTravelPolicyMutation();

  const [
    deletedPolicyObj,
    setDeletedPolicyObj,
  ] = useState({
    policyId: '',
    name: '',
    showModal: false,
  });

  if (isLoading) {
    return <PageLoader />;
  }

  const handleDeleteModalClose = () => setDeletedPolicyObj({ ...deletedPolicyObj, showModal: false });

  const handleAddPolicy = () => navigate(ROUTES.SETTINGS.TRAVEL_POLICY);

  if (data && !data.length) {
    return (
      <div className={ styles.empty_panel }>
        <EmptyDataPanel
          title={ LABELS.EMPTY_PANEL.TRAVEL_POLICY_NOT_FOUND }
          buttonTitle={ LABELS.EMPTY_PANEL.TRAVEL_POLICY_CREATE_BUTTON }
          onChangeButton={ handleAddPolicy }
        />
      </div>
    );
  }

  const handleEditPolicy = (policyId: string) => navigate(ROUTES.SETTINGS.TRAVEL_POLICY_ID(policyId));

  const handleShowModalDeletePolicy = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    policyId: string,
    name: string,
  ) => {
    event.stopPropagation();

    setDeletedPolicyObj({
      policyId,
      name,
      showModal: true,
    });
  };

  const handlePolicy = (policyId: string) => navigate(ROUTES.SETTINGS.TRAVEL_POLICY_ID(policyId));

  const handleDeletePolicy = async () => {
    const {
      policyId,
      name,
    } = deletedPolicyObj;

    try {
      await deletePolicy({ policyId, isActive: false });

      notification({
        title: `Travel Policy ${name} deleted`,
        type: ENotificationActionType.success,
      });

      window.location.reload();
    } catch (e) {
      notification({
        title: `Travel Policy ${name} not deleted`,
        type: ENotificationActionType.error,
      });

      console.log(`Error ${e}`);
    }
  };

  const renderTableRows = () => data?.map(({
    id,
    name,
    description,
  }) => (
    <tr key={ id } className={ styles.table } onClick={ () => handlePolicy(id) }>
      <td>{name}</td>
      <td>{description}</td>
      <td className={ styles.event_row }>
        <Button type='text' className={ styles.button_edit } onClick={ () => handleEditPolicy(id) }>
          {LABELS.EDITING}
        </Button>
        <Button
          type='text'
          className={ styles.button_delete }
          onClick={ (event) => handleShowModalDeletePolicy(event, id, name) }
        >
          {LABELS.REMOVE}
        </Button>
      </td>
    </tr>
  ));

  return (
    <div className={ styles.container }>
      <div className={ styles.heading }>
        <Text type='bold_20'>{LABELS.TRAVEL_POLICY}</Text>
        <IconButton iconType='add' iconColor='blue1' onClick={ handleAddPolicy }>
          {LABELS.BUTTON_CREATE_POLICY}
        </IconButton>
      </div>
      <Table
        column={ ARRAY_COLUMN_TRAVEL_POLICY }
        rows={ renderTableRows }
      />
      <Dialog
        className={ styles.dialog }
        showClosing
        show={ false }
        onChange={ () => {} }
      >
        <Text type='bold_20'>{LABELS.DELETE_TRAVEL_POLICIES}</Text>
        <Text type='NORMAL_16'>
          {LABELS.DELETE_TRAVEL_POLICY_SELECT_EMPLOYEE}
        </Text>
        <div className={ styles.button_ok }>
          <Button type='secondary' onClick={ () => {} }>
            {LABELS.SUBMIT}
          </Button>
        </div>
      </Dialog>
      <Dialog
        className={ styles.dialog }
        showClosing
        show={ deletedPolicyObj.showModal }
        onChange={ handleDeleteModalClose }
      >
        <Text type='bold_20'>{LABELS.DELETE_TRAVEL_POLICY}</Text>
        <Text type='NORMAL_16'>
          {LABELS.WANT_DELETE_TRAVEL_POLICY(deletedPolicyObj.name)}
        </Text>
        <div className={ styles.buttons_interactive }>
          <Button type='secondary' onClick={ handleDeletePolicy }>
            {LABELS.YES_DELETE}
          </Button>
          <Button type='text' onClick={ handleDeleteModalClose }>
            {LABELS.UNDO}
          </Button>
        </div>
      </Dialog>
    </div>
  );
};

export { TravelPolicies };
