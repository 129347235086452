import { Text } from 'yw-ui';
import React from 'react';

import { EServiceTypes } from '@/app/bi/models/serviceTypes.ts';

import styles from './index.module.css';

interface INotificationItemProps {
  locations?: string;
  hotelName?: string;
  roomType?: string;
  serviceType: EServiceTypes;
  date: string;
  detail: string;
  price: string;
}

const NotificationItem = ({
  locations = '',
  hotelName = '',
  roomType = '',
  date,
  detail,
  price,
  serviceType,

}: INotificationItemProps) => {

  const renderHotelItem = () => (
    <>
      <Text type='NORMAL_14' fontWeight={ 600 }>{hotelName}</Text>
      <Text type='NORMAL_14' fontWeight={ 600 }>{roomType}</Text>
    </>
  );

  const renderAirItem = () => (
    <Text type='NORMAL_16'>{locations}</Text>
  );

  const renderContent = () => {
    if (serviceType === EServiceTypes.Hotel) return renderHotelItem();

    return renderAirItem();
  };

  return (
    <div className={ styles.wrap }>
      {renderContent()}
      <Text className={ styles.details }>
        <span>{date}</span>
        <span className={ styles.dot }>{detail}</span>
        <span className={ styles.dot }>{price}</span>
      </Text>
    </div>
  );
};

export { NotificationItem };
