import dayjs, { Dayjs } from 'dayjs';

import { FLY_CLASS } from '../constants/airline.ts';
import DIRECTIONS from '@/app/bi/constants/directions.ts';

import { IRoutes, ISuggestion, ISuggestionItem } from '@/app/bi/models/airlineSearchTypes.ts';
import { ISearchOptionsRoute } from '@/app/bi/models/airlineTypes.ts';

const mapRoute = (route: ISearchOptionsRoute, dateBack: string | null = null): IRoutes => ({
  from: {
    label: route.departureName,
    selected: {
      code: route.departureCode,
      city: route.departureName,
    },
  },
  to: {
    label: route.arrivalName,
    selected: {
      code: route.arrivalCode,
      city: route.arrivalName,
    },
  },
  minDate: dayjs().startOf('d'),
  date: dayjs(route.date).startOf('d'),
  dateBack: dateBack ? dayjs(dateBack).startOf('d') : dateBack as Dayjs | null,
});

const getPrepareRoutes = (routes: ISearchOptionsRoute[]): IRoutes[] => {
  let newRoutes = [];
  switch (routes.length) {
    case 1: {
      newRoutes.push(mapRoute(routes[0]));
      break;
    }
    case 2: {
      const firstRoute = routes[0];
      const secondRoute = routes[1];

      if (firstRoute.departureCode === secondRoute.arrivalCode && firstRoute.arrivalCode === secondRoute.departureCode) {
        newRoutes.push(mapRoute(routes[0], secondRoute.date));
      } else {
        // @ts-ignore
        newRoutes = routes.map(mapRoute);
      }

      break;
    }
    default: {
      // @ts-ignore
      newRoutes = routes.map(mapRoute);
      break;
    }
  }

  return newRoutes;
};

const createRoute = (
  date: Dayjs | null = dayjs().startOf('d'),
  from: { label: string; selected: null | ISuggestionItem } = { label: '', selected: null },
): IRoutes => ({
  from,
  to: {
    label: '',
    selected: null,
  },
  date,
  minDate: dayjs().startOf('d'),
  dateBack: null,
});

const getClassOptions = () => Object.keys(FLY_CLASS)
  .map((flyClass) => ({ value: flyClass, label: FLY_CLASS[flyClass] }));

const formatDefaultSuggests = (list: ISuggestion[], field: string, route: IRoutes) => {
  const oppositeSelected = field === DIRECTIONS.FROM ? route.to.selected : route.from.selected;

  const indexToRemove = oppositeSelected && oppositeSelected.code
    ? list.findIndex(suggest => suggest.code === oppositeSelected.code)
    : -1;

  const clonedItems = indexToRemove === -1
    ? list.filter((_, index) => index !== 4)
    : list.filter((_, index) => index !== indexToRemove);

  return clonedItems;
};

export {
  createRoute,
  getClassOptions,
  getPrepareRoutes,
  formatDefaultSuggests,
};
