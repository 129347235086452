import React from 'react';
import { Button, NoResults, Text } from 'yw-ui';
import { getText } from '@/i18n';

import styles from './style.module.css';

const LABELS = {
  CHANGE_PARAMS_OF_FILTERS: getText('hotels:regionResult.changeParamsOfFilters'),
  NOT_FOUND: getText('hotels:regionResult.notFound'),
  RESET_FILTERS: getText('hotels:regionResult.resetFilters'),
};

interface IEmptyFilterProps {
  onResetFilters(): void;
}

const EmptyFilter = ({
  onResetFilters,
}: IEmptyFilterProps) => {
  const renderFilterEmptyPanel = () => {
    const renderContent = () => (
      <div className={ styles.not_found_content }>
        <Text type='bold_18' color='gray' className={ styles.title }>
          {LABELS.NOT_FOUND}
        </Text>
        <Text type='NORMAL_14' color='gray' className={ styles.hint }>
          {LABELS.CHANGE_PARAMS_OF_FILTERS}
        </Text>
        <Button
          type='outline'
          size='small'
          className={ styles.button }
          onClick={ onResetFilters }
        >
          {LABELS.RESET_FILTERS}
        </Button>
      </div>
    );

    return <NoResults renderContent={ renderContent } styles={ { marginTop: '36px' } } />;
  };

  return (
    <div className={ styles.no_result }>
      <div className={ `${styles.empty}` }>
        {renderFilterEmptyPanel()}
      </div>
    </div>
  );
};

export { EmptyFilter };
