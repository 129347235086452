import { getText } from '@/i18n';

const PASSPORTS = {
  PASSPORT: {
    TYPE: 'Passport',
    VALUE: getText('constants:passport.passport'),
  },
  ID_CARD: {
    TYPE: 'IdCard',
    VALUE: getText('constants:passport.idCard'),
  },
};

export { PASSPORTS };
