import { getText } from '@/i18n';

import { getDayjs } from '@/app/bi/utils/formatDate.ts';

import { EPriceType, ITravelPolicy } from '@/app/bi/models/travelPolicies.ts';
import { ECurrencyCode } from '@/app/bi/models/hotelSearch/hotelSearchEnum.ts';
import { EAirlineClass } from '@/app/bi/models/airlineTypes.ts';

const LABELS = {
  NAME: getText('settings:travelPolicies.tablePolicy.name'),
  DESCRIPTION: getText('settings:travelPolicies.tablePolicy.description'),
  ACTIONS: getText('settings:travelPolicies.tablePolicy.action'),
  NOT_SPECIFIED: getText('settings:travelPolicies.notSpecified'),
};

export const BUTTON_CLEAR = getText('settings:clearAllFilter');

const TURKEY_LOCALE_CODE = 'TUR';

const MAX_COUNT_DOCUMENT = 10;

const MAX_COUNT_PASSPORT_LENGTH = 9;

const MAX_COUNT_ID_CARD_LENGTH = 11;

const INPUT_DEBOUNCE = 0;

const MAX_DATE = getDayjs();

const INPUT_PLACEHOLDER_ID_CARD = '___________';

const INPUT_PLACEHOLDER_PASSPORT = '_ _________';

const DATEPICKER_BIRTH_DAY_PLACEHOLDER = '__.__.____';

const PASSWORD_PLACEHOLDER = '******';

const MIN_LENGTH_PASSWORD = 4;

const MAX_LENGTH_PASSWORD = 100;

const TAB_INFO_SETTINGS = [
  {
    label: getText('settings:dynamicPages.employees'),
    path: 'employees',
  },
  {
    label: getText('settings:dynamicPages.travelPolicies'),
    path: 'travel-policies',
  },
  {
    label: getText('settings:dynamicPages.companyDetails'),
    path: 'company-details',
  },
  {
    label: getText('settings:dynamicPages.myProfile'),
    path: 'profile',
  },
];

const ARRAY_COLUMN_TRAVEL_POLICY = [
  LABELS.NAME,
  LABELS.DESCRIPTION,
  LABELS.ACTIONS,
];

const DEFAULT_ITEM_POLICY: ITravelPolicy = {
  id: '0',
  name: LABELS.NOT_SPECIFIED,
  creatorUserId: '',
  description: '',
  accountId: 0,
  isActive: false,
  airlinePolicy: {
    id: '',
    maxPrice: 0,
    maxPriceCurrency: ECurrencyCode.TRY,
    priceDifferenceLimit: 0,
    priceDifferenceLimitType: EPriceType.Percent,
    priceDifferenceLimitCurrency: ECurrencyCode.TRY,
    class: [EAirlineClass.Econom],
    refundable: false,
    daysBuyLimit: 1,
    baggage: false,
    weekdays: false,
    flightHours: 0,
    directions: [],
    basePolicyId: '',
    exceptions: [],
  },
  hotelPolicy: {
    id: '',
    maxPricePerNight: 0,
    maxPricePerNightCurrency: ECurrencyCode.TRY,
    eclc: false,
    refundable: false,
    weekdays: false,
    countryCodes: [],
    cities: [],
    basePolicyId: '',
    exceptions: [],
  },
};

enum EModeViewFilterPanel {
  ViewList = 'List',
  SearchAndList = 'SearchAndList',
  SearchOnly = 'SearchOnly',
}

enum EItemFilterPanel {
  CheckBox = 'CheckBox',
  Radio = 'Radio',
}

export enum EEmployeeNameParam {
  First = 'firstName',
  Last = 'lastName',
}

export enum EEmployeeNameTransliterate {
  FirstNameTranslit = 'firstNameTranslit',
  LastNameTranslit = 'lastNameTranslit',
}

export enum EEmployeeFormParam {
  PhoneNumber = 'phoneNumber',
  BirthDay = 'birthDay',
  Email = 'email',
  Citizenship = 'citizenship',
}

enum ETypesSex {
  Man = 'Male',
  Woman = 'Female',
}

enum ENotificationActionType {
  success = 'success',
  error = 'error',
}

export {
  EModeViewFilterPanel,
  EItemFilterPanel,
  PASSWORD_PLACEHOLDER,
  MIN_LENGTH_PASSWORD,
  MAX_LENGTH_PASSWORD,
  MAX_COUNT_DOCUMENT,
  INPUT_DEBOUNCE,
  MAX_DATE,
  INPUT_PLACEHOLDER_PASSPORT,
  MAX_COUNT_PASSPORT_LENGTH,
  DATEPICKER_BIRTH_DAY_PLACEHOLDER,
  TAB_INFO_SETTINGS,
  INPUT_PLACEHOLDER_ID_CARD,
  MAX_COUNT_ID_CARD_LENGTH,
  ENotificationActionType,
  ETypesSex,
  ARRAY_COLUMN_TRAVEL_POLICY,
  DEFAULT_ITEM_POLICY,
  TURKEY_LOCALE_CODE,
};
