import React from 'react';
import { IconButton, Text, Tooltip } from 'yw-ui';
import { getText } from '@/i18n';

import styles from './index.module.css';

const LABELS = {
  DELETE: getText('cart:cartItem.delete'),
  ADD_TO_TRIP: getText('cart:cartItem.addToTrip'),
  ADD_TO_TRIP_TOOLTIP: getText('cart:addingTripDialog.tooltip'),
};

interface CartHeaderProps {
  disableAddInTrip: boolean,
  onDeleteItem(): void,
  onShowAddInTripDialog(): void,
}

const CartHeader = ({
  disableAddInTrip,
  onDeleteItem,
  onShowAddInTripDialog,
}: CartHeaderProps) => {

  const renderTooltip = () => (
    <div className={ styles.add_trip_tooltip }>
      <Text
        type='NORMAL_14_130'
        color='white'
      >
        {LABELS.ADD_TO_TRIP_TOOLTIP}
      </Text>
    </div>
  );

  return (
    <>
      <div>
        <Tooltip
          show={ disableAddInTrip }
          renderContent={ renderTooltip }
        >
          <IconButton
            iconType='plus'
            iconColor='blue1'
            disabled={ disableAddInTrip }
            onClick={ onShowAddInTripDialog }
          >
            <Text type='NORMAL_14' color='light-blue-4'>
              {LABELS.ADD_TO_TRIP}
            </Text>
          </IconButton>
        </Tooltip>
      </div>
      <IconButton
        iconType='delete'
        iconColor='blue1'
        onClick={ onDeleteItem }
        disabled={ false }
      >
        <Text type='NORMAL_14' color='light-blue-4'>
          {LABELS.DELETE}
        </Text>
      </IconButton>
    </>
  );
};
export { CartHeader };
