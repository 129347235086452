import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query/react';
import { Mutex } from 'async-mutex';

import { RootState } from '@/app/store/types.ts';

import CONFIG from '@/config.ts';
import { logout, setCredentials } from '@/app/store/redusers/AuthSlice.ts';

export const YW_API_KEY_PUBLIC = 'ywApiPublic';
export const YW_API_KEY_PRIVATE = 'ywApiPrivate';

const mutex = new Mutex();

const baseQuery = fetchBaseQuery({
  baseUrl: CONFIG.API_ROOT,
  credentials: 'include',
  headers: {
    accept: 'application/json',
    'Content-Type': 'application/json',
  },

  prepareHeaders: (headers, { getState, endpoint }) => {
    const { accessToken } = (getState() as RootState).auth;

    if (accessToken && endpoint !== 'refresh') {
      headers.set('Authorization', `Bearer ${accessToken}`);
    }

    return headers;
  },
});

const baseQueryWithReauth: BaseQueryFn<
string | FetchArgs,
unknown,
FetchBaseQueryError
> = async (args, api, extraOptions) => {
  await mutex.waitForUnlock();
  let result = await baseQuery(args, api, extraOptions);
  if (result.error && result.error.status === 401) {
    if (!mutex.isLocked()) {
      const release = await mutex.acquire();
      try {
        const refreshResult = await baseQuery(
          {
            url: '/auth/refresh',
            method: 'POST',
          },
          api,
          extraOptions,
        );

        const token = refreshResult.meta?.response?.headers.get('Authorization');

        if (token) {
          api.dispatch(setCredentials(token));
          result = await baseQuery(args, api, extraOptions);
        } else {
          api.dispatch(logout());
          window.location.reload();
        }
      } finally {
        release();
      }
    } else {
      await mutex.waitForUnlock();
      result = await baseQuery(args, api, extraOptions);
    }
  }

  return result;
};
const ywApiPrivate = createApi({
  reducerPath: YW_API_KEY_PRIVATE,
  baseQuery: baseQueryWithReauth,
  keepUnusedDataFor: -1,
  endpoints: () => ({}),
});

const ywApiPublic = createApi({
  reducerPath: YW_API_KEY_PUBLIC,
  baseQuery: fetchBaseQuery({
    baseUrl: CONFIG.API_ROOT,
    credentials: 'include',
    headers: {
      accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }),
  keepUnusedDataFor: -1,
  endpoints: () => ({}),
});

export { ywApiPrivate, ywApiPublic };
