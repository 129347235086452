import React from 'react';
import { Icon, Text } from 'yw-ui';

import { getText } from '@/i18n';

import { formatDate } from '@/app/bi/utils/formatDate.ts';

import { PATTERN } from '@/app/bi/constants/dateFormats.ts';

import { IRegionSearchResponseRate } from '@/app/bi/models/hotelSearch/hotelSearchTypes.ts';

import styles from './styles.module.css';

interface HotelItemRateProps {
  rate: IRegionSearchResponseRate,
}

const LABELS = {
  FREE_CANCELLATION: (time: string) => getText('hotels:regionResult.item.freeCancellation', { time }),
  NOT_MEAL: getText('hotels:regionResult.item.noMeal'),
  NOT_HAS_CANCELLATION: getText('hotels:hotelResult.item.roomGroup.cancellation.nonFree'),
  WITH_BREAKFAST: getText('hotels:hotelResult.item.generalList.withBreakfast'),
};

const HotelItemRate = ({
  rate: {
    name,
    breakfast,
    refundable,
    deadLine,
  },
}: HotelItemRateProps) => {
  const deadline = refundable
    ? LABELS.FREE_CANCELLATION(formatDate(deadLine, PATTERN.DAY_MONTH_TIME_WITHOUT_SECONDS))
    : LABELS.NOT_HAS_CANCELLATION;

  const renderMeal = () => {
    const text = breakfast ? LABELS.WITH_BREAKFAST : LABELS.NOT_MEAL;
    const color = breakfast ? 'blue-1' : 'red';

    return (
      <div className={ styles.meal }>
        <Text type='NORMAL_12' color={ color }>{text}</Text>
        {breakfast && <Icon type='cup' size={ 16 }/>}
      </div>
    );
  };

  return (
    <div className={ styles.room_info_wrapper }>
      <Text type='SEMIBOLD_16'>{name}</Text>
      <div className={ styles.room_info }>
        { renderMeal() }
        <Text type='NORMAL_12'>{ deadline }</Text>
      </div>
    </div>
  );
};

export { HotelItemRate };
