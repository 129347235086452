import React from 'react';
import { Tag, Text } from 'yw-ui';
import { getText, getTextArray } from '@/i18n';

import { SortSelect } from '@/app/components/SortSelect';
import { StickyHidingPanel } from '@/app/components/StickyHiding';

import toPlural from '@/app/bi/utils/toPlural';

import { SORT_ITEMS } from '@/app/bi/constants/airline.ts';

import { ITag } from '@/app/bi/models/shared.ts';

import styles from './index.module.css';

const STICKY_PANEL_ADJUSTMENT = 124;
const HIDDEN_PANEL_ADJUSTMENT = 900;

const LABELS = {
  SEARCH: getText('air:result.search'),
  FLIGHT_ARRAY: getTextArray('air:result.flights'),
  CHANGE_PARAMS_OF_SEARCH: getText('air:result.changeParamsOfSearch'),
  CHANGE_PARAMS_OF_FILTERS: getText('air:result.changeParamsOfFilters'),
  NOT_FOUND: getText('air:result.notFound'),
  RESET_FILTERS: getText('air:result.resetFilters'),
  FLIGHT: getText('air:result.flight'),
  CLEAR_ALL: getText('common:clearAll'),
};

interface StickyPanelProps {
  sortedBy: string;
  tags: ITag[];
  totalCount: number;
  onSortSearchResult(value: string): void;
  onDeleteTags(tag: ITag): void;
  onResetFilters(): void;
}
const AirlinesStickyPanel = ({
  sortedBy,
  tags,
  totalCount,
  onSortSearchResult,
  onDeleteTags,
  onResetFilters,
}: StickyPanelProps) => {

  const renderTags = () => {
    if (!tags.length) return null;

    const tagsHtml = !!tags.length && tags.map((tag) => {
      const { name, key } = tag;

      return (
        <Tag
          label={ name }
          onClick={ () => onDeleteTags(tag) }
          key={ key }
          className={ styles.tag }
        />
      );
    });

    return (
      <>
        {tagsHtml}
        <Tag
          label={ LABELS.CLEAR_ALL }
          onClick={ onResetFilters }
          key='clear-all'
          deleteAll
          className={ styles.tag }
        />
      </>
    );
  };

  return (
    <StickyHidingPanel
      adjustment={ STICKY_PANEL_ADJUSTMENT }
      hiddenAdjustment={ HIDDEN_PANEL_ADJUSTMENT }
      fixedClass={ styles.sticky_panel_fixed }
      tags={ tags }
    >
      <div className={ styles.header }>
        <div className={ styles.main }>
          <div className={ styles.search_text }>
            <Text type='bold_24' color='blue-1' strong>
              {totalCount} {toPlural(totalCount, LABELS.FLIGHT_ARRAY)} {LABELS.SEARCH}
            </Text>
          </div>
          <div className={ styles.select }>
            <SortSelect
              items={ SORT_ITEMS }
              value={ sortedBy as string }
              onChange={ onSortSearchResult }
            />
          </div>
        </div>
        <div className={ styles.tags }>
          { renderTags() }
        </div>
      </div>
    </StickyHidingPanel>
  );
};

export { AirlinesStickyPanel };
