import { ywApiPrivate } from './index.ts';

import {
  IAddTravelPolicyRequest,
  ITravelPolicy,
  IUpdateTravelPolicyRequest,
} from '@/app/bi/models/travelPolicies.ts';

export const travelPoliciesApi = ywApiPrivate.injectEndpoints({
  endpoints: (build) => ({
    getTravelPolicies: build.query<ITravelPolicy[], void>({
      query: () => ({
        url: '/travelpolicies',
        method: 'GET',
      }),
    }),
    getTravelPolicy: build.query<ITravelPolicy, { policyId: string }>({
      query: ({ policyId }) => ({
        url: `/travelpolicies/${policyId}`,
        method: 'GET',
      }),
    }),
    updateTravelPolicy: build.mutation<ITravelPolicy, IUpdateTravelPolicyRequest>({
      query: (body) => ({
        url: '/travelpolicies',
        method: 'PUT',
        body,
      }),
    }),
    deleteTravelPolicy: build.mutation<ITravelPolicy, { policyId: string, isActive: boolean }>({
      query: ({ policyId, isActive }) => ({
        url: `/travelpolicies/${policyId}/${isActive}`,
        method: 'PUT',
      }),
    }),
    addTravelPolicy: build.mutation<ITravelPolicy, IAddTravelPolicyRequest>({
      query: (body) => ({
        url: '/travelpolicies',
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const {
  useGetTravelPolicyQuery,
  useGetTravelPoliciesQuery,
  useAddTravelPolicyMutation,
  useDeleteTravelPolicyMutation,
  useUpdateTravelPolicyMutation,
} = travelPoliciesApi;
