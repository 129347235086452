import { ywApiPrivate } from './index.ts';

import {
  IHotelSearchFilter,
  IHotelSearchRegionRequest,
  IHotelSearchRequest,
  IHotelSearchResponse,
  IRegionSearchResponse,
} from '@/app/bi/models/hotelSearch/hotelSearchTypes.ts';
import { ELanguages } from '@/i18n/locales/consts';

export interface IHotelAutocompleteRequest {
  locale: string,
  query: string
}
export interface IHotelStaticAutocompleteItem {
  id: string;
  name: string;
  parentName: string;
}

export interface IPrepareHotelStaticAutocompleteItem extends IHotelStaticAutocompleteItem {
  isRegion: boolean;
}

export interface IPrepareHotelStaticAutocomplete {
  title: string;
  items: IPrepareHotelStaticAutocompleteItem[];
}
export interface IHotelStaticAutocompleteResponse {
  regions: IHotelStaticAutocompleteItem[];
  hotels: IHotelStaticAutocompleteItem[];
}

export const searchHotelsApi = ywApiPrivate.injectEndpoints({
  endpoints: (build) => ({
    hotelAutocomplete: build.query<IHotelStaticAutocompleteResponse, IHotelAutocompleteRequest>({
      query: ({ locale, query }) => ({
        url: `/hotels-static/autocomplete/${locale}/${query}`,
        method: 'GET',
      }),
    }),
    searchHotels: build.query<IHotelSearchResponse, IHotelSearchRequest>({
      query: (body: IHotelSearchRequest) => ({
        url: '/hotels/search',
        method: 'POST',
        body,
      }),
    }),
    searchHotelsBySearchId: build.query<IHotelSearchResponse, { searchId: string, locale: ELanguages }>({
      query: ({ searchId, locale }) => ({
        url: `/hotels/search/${searchId}`,
        method: 'POST',
        params: { locale },
      }),
    }),
    searchRegion: build.query<IRegionSearchResponse, IHotelSearchRegionRequest>({
      query: (body: IHotelSearchRegionRequest) => ({
        url: '/hotels/search/region',
        method: 'POST',
        body,
      }),
    }),
    searchRegionBySearchId: build.query<IRegionSearchResponse, { searchId: string, locale: ELanguages, body: IHotelSearchFilter } >({
      query: ({ searchId, locale, body }) => ({
        url: `/hotels/search/region/${searchId}`,
        method: 'POST',
        body,
        params: { locale },
      }),
    }),
    getSearchRegionBySearchId: build.query<IRegionSearchResponse, { searchId: string, locale: ELanguages } >({
      query: ({ searchId, locale }) => ({
        url: `/hotels/search/region/${searchId}`,
        method: 'GET',
        params: { locale },
      }),
    }),
  }),
});

export const {
  useLazyHotelAutocompleteQuery,
  useSearchHotelsQuery,
  useLazySearchRegionQuery,
  useLazyGetSearchRegionBySearchIdQuery,
  useSearchRegionBySearchIdQuery,
} = searchHotelsApi;
