import { ywApiPrivate } from './index.ts';
import { IAccountResponse, IUserLocale, IWpHeaders } from '@/app/bi/models/app.ts';
import { ELanguages } from '@/i18n/locales/consts';

export const appApi = ywApiPrivate.injectEndpoints({
  endpoints: (build) => ({
    getAccount: build.query<IAccountResponse, void>({
      query: () => ({
        url: '/account',
      }),
    }),
    getHeaders: build.query<IWpHeaders, void>({
      query: () => ({
        url: '/account/headers',
      }),
    }),
    getLocale: build.query<IUserLocale, void>({
      query: () => ({
        url: '/user/locale',
      }),
    }),
    setLocale: build.mutation<IUserLocale, ELanguages>({
      query: (locale: ELanguages) => ({
        url: `/user/locale/?locale=${locale}`,
        method: 'PUT',
      }),
    }),
  }),
});

export const {
  useLazyGetAccountQuery,
  useLazyGetHeadersQuery,
  useLazyGetLocaleQuery,
  useSetLocaleMutation,
} = appApi;
