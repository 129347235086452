import { getCurrentLocale } from '@/app/bi/utils/formatDate';

const toPlural = (n: number, plural: string | object): string => {
  const locale = getCurrentLocale();
  if (typeof plural === 'string') {
    return plural;
  }

  const pluralRules = new Intl.PluralRules(locale);
  const key = pluralRules.select(n);

  if (key in plural) {
    return plural[key as keyof typeof plural];
  }

  return '';
};

export default toPlural;
