import React from 'react';
import { Dayjs } from 'dayjs';
import { Button, Datepicker, Select, Suggest, Text } from 'yw-ui';

import { DATE_FORMATS, PATTERN } from '@/app/bi/constants/dateFormats.ts';
import {
  CHECKIN, CHECKOUT,
  CUSTOM_CHECKIN_VALUES, CUSTOM_CHECKOUT_VALUES,
  DEFAULT_TIME_VALUE,
  SELECT_TRAVELLERS,
} from '@/app/bi/constants/hotelsSearch.ts';
import { generateTravelers } from '@/app/bi/constants/travelers.ts';
import { useAppSelector } from '@/app/store/hooks/redux.ts';
import { getText } from '@/i18n';
import { getKey } from '@/app/bi/utils/hotelsSearch.ts';
import { HotelSuggestion } from '@/app/components/Menu/HotelSearchMenu/components/HotelSuggestion';

import {
  IPrepareHotelStaticAutocomplete,
  IPrepareHotelStaticAutocompleteItem,
} from '@/app/bi/api/searchHotelsApi.ts';

import styles from './index.module.css';

const LABELS = {
  SUGGEST_PLACEHOLDER: getText('components:menu.hotel.cityOrHotel'),
  DATE_IN_PLACEHOLDER: getText('components:menu.hotel.dateIn'),
  DATE_OUT_PLACEHOLDER: getText('components:menu.hotel.dateOut'),
  TIME: getText('components:menu.hotel.time'),
  SEARCH: getText('common:search'),
  PLACING: getText('components:menu.hotel.placing'),
  GUESTS: getText('components:menu.hotel.guests'),
  EARLY_IN: getText('components:menu.hotel.earlyIn'),
  LATE_OUT: getText('components:menu.hotel.lateOut'),
  WITHOUT_EARLY_IN_OR_LATE_OUT: getText('components:menu.hotel.withoutEarlyInOrLateOut'),
};

const TRAVELERS = generateTravelers(6);

const DIRECTION_TYPES = {
  FROM: 'from',
  TO: 'to',
};

const getHours = (preparedData: Dayjs | null, format: string) => (
  format === DATE_FORMATS.DATE ? DEFAULT_TIME_VALUE : preparedData?.get('hour')
);

interface IPanelHotelSearchMenuProps {
  isFetching: boolean;
  datePickersOpened: { from: boolean, to: boolean },
  itemsSuggests: IPrepareHotelStaticAutocomplete[];
  onChangeAdult(value: number): void;
  onSuggestSelected(suggest: IPrepareHotelStaticAutocompleteItem): void;
  onChangeQuery(query: string): void;
  onChangeCheckinDate(value: Dayjs | null, withTime: boolean): void;
  onChangeCheckoutDate(value: Dayjs | null, withTime: boolean): void;
  onOpenedDatepicker(field: string, value: boolean): void;
  onChangeHours(value: string | number, base: Dayjs, field: string): void;
  onChangeTravellersCount(value: number): void;
  onSearch(): void;
}

const PanelHotelSearchMenu = ({
  onChangeAdult,
  datePickersOpened,
  itemsSuggests,
  isFetching,
  onSuggestSelected,
  onChangeQuery,
  onChangeCheckinDate,
  onChangeCheckoutDate,
  onOpenedDatepicker,
  onChangeHours,
  onChangeTravellersCount,
  onSearch,
}: IPanelHotelSearchMenuProps) => {
  const {
    region,
    checkin,
    checkout,
    customCheckin,
    customCheckout,
    checkinMinDate,
    checkoutMinDate,
    travellersCount,
    adult,
    isValid,
  } = useAppSelector((state) => state.searchHotelsReducer);

  const preparedCheckin = customCheckin || checkin;
  const preparedCheckout = customCheckout || checkout;
  const checkinFormat = (customCheckin != null) ? PATTERN.FULL_DATE_WITH_TIME : DATE_FORMATS.DATE;
  const checkoutFormat = (customCheckout != null) ? PATTERN.FULL_DATE_WITH_TIME : DATE_FORMATS.DATE;

  const placingHtml = travellersCount > 1 && (
    <div className={ styles.placing }>
      <Text type='NORMAL_14' color='white' className={ styles.text }>
        {LABELS.PLACING}
      </Text>
      <div>
        <Select
          className={ styles.select_round_edges }
          theme='default-small'
          items={ SELECT_TRAVELLERS[travellersCount] }
          value={ adult }
          onChange={ onChangeAdult }
        />
      </div>
    </div>
  );

  const renderSuggestion = ({
    title = '',
    items = [],
    name = '',
    parentName = '',
  }: any): JSX.Element => {
    const { label: query } = region;

    return (
      <HotelSuggestion
        key={ title }
        isNested={ !!items.length }
        title={ title }
        name={ name }
        parentName={ parentName }
        query={ query }
      />
    );
  };

  return (
    <div className={ styles.search }>
      <div className={ styles.menu }>
        <div className={ styles.suggest }>
          <Suggest
            isFetching={ isFetching }
            contentClassName={ styles.suggest_animation }
            inputClassName={ styles.suggest_border }
            placeholder={ LABELS.SUGGEST_PLACEHOLDER }
            value={ region.label }
            // @ts-ignore
            items={ itemsSuggests }
            // @ts-ignore
            onSelect={ onSuggestSelected }
            onChange={ onChangeQuery }
            renderItem={ renderSuggestion }
            keyExtractor={ getKey }
            isCursorSelect
          />
        </div>
        <div className={ styles.date }>
          <Datepicker
            closeOnTabOut
            open={ datePickersOpened.from }
            withLabel
            placeholder={ LABELS.DATE_IN_PLACEHOLDER }
            type='dateTimeList'
            inputTheme='open'
            value={ preparedCheckin }
            onChange={ onChangeCheckinDate }
            onBlur={ () => { onOpenedDatepicker(DIRECTION_TYPES.TO, true); } }
            min={ checkinMinDate }
            format={ checkinFormat }
            direction='from'
            getCurrentOpen={ (value: boolean) => { onOpenedDatepicker(DIRECTION_TYPES.FROM, value); } }
            wrapperClassName={ styles.wrapper }
            inputClassName={ styles.input }
            wrapperDialogClassName={ styles.datepicker_dialog_class }
            isDuration
            durationDates={ [preparedCheckin as Dayjs, preparedCheckout] }
          />
        </div>
        <div className={ styles.date }>
          <Datepicker
            closeOnTabOut
            open={ datePickersOpened.to }
            placeholder={ LABELS.DATE_OUT_PLACEHOLDER }
            withLabel
            type='dateTimeList'
            inputTheme='open'
            value={ preparedCheckout }
            onChange={ onChangeCheckoutDate }
            min={ checkoutMinDate }
            format={ checkoutFormat }
            direction='to'
            getCurrentOpen={ (value: boolean) => { onOpenedDatepicker(DIRECTION_TYPES.TO, value); } }
            wrapperClassName={ styles.wrapper }
            inputClassName={ styles.input }
            wrapperDialogClassName={ styles.datepicker_dialog_class }
            isDuration
            durationDates={ [preparedCheckin as Dayjs, preparedCheckout] }
          />
        </div>
        <div className={ styles.action }>
          <Button
            type='primary'
            size='large'
            onClick={ onSearch }
            className={ styles.search_btn }
            disabled={ !isValid }
          >
            {LABELS.SEARCH}
          </Button>
        </div>
      </div>
      <div className={ styles.options }>
        <div className={ styles.placing_wrapper }>
          <div className={ styles.adults }>
            <Text type='NORMAL_14' color='white'>
              {LABELS.GUESTS}
            </Text>
            <Select
              className={ styles.select_round_edges }
              theme='default-small'
              items={ TRAVELERS }
              value={ travellersCount }
              onChange={ onChangeTravellersCount }
              contentClassName={ styles.dialog_wrapper }
            />
          </div>
          {placingHtml}
        </div>
        <div className={ styles.custom_checkin_checkout_wrapper }>
          <div className={ styles.custom_checkin_wrapper }>
            <Text type='NORMAL_14' color='white'>
              {LABELS.EARLY_IN}
            </Text>
            <Select
              theme='default-small'
              items={ CUSTOM_CHECKIN_VALUES }
              value={ getHours(preparedCheckin, checkinFormat) }
              onChange={ (value) => {
                onChangeHours(value, preparedCheckin as Dayjs, CHECKIN);
              } }
              renderLabel={ ({ value, label }: { value: string, label: string }) => {
                if (value === DEFAULT_TIME_VALUE) {
                  return LABELS.WITHOUT_EARLY_IN_OR_LATE_OUT;
                }

                return label;
              } }
              className={ styles.select_round_edges }
              contentClassName={ styles.dialog_wrapper }
            />
          </div>
          <div className={ styles.custom_checkout_wrapper }>
            <Text type='NORMAL_14' color='white' className={ styles.text }>
              {LABELS.LATE_OUT}
            </Text>
            <Select
              className={ styles.select_round_edges }
              theme='default-small'
              items={ CUSTOM_CHECKOUT_VALUES }
              value={ getHours(preparedCheckout, checkoutFormat) }
              onChange={ (value) => {
                onChangeHours(value, preparedCheckout as Dayjs, CHECKOUT);
              } }
              renderLabel={ ({ value, label }: { value: string, label: string }) => {
                if (value === DEFAULT_TIME_VALUE) {
                  return LABELS.WITHOUT_EARLY_IN_OR_LATE_OUT;
                }

                return label;
              } }
              contentClassName={ styles.dialog_wrapper }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export { PanelHotelSearchMenu };
