import { Button, NoResults, Text } from 'yw-ui';
import React from 'react';

import styles from './index.module.css';

import { getText } from '@/i18n';

const LABELS = {
  NOT_FOUND: getText('air:result.notFound'),
  CHANGE_PARAMS_OF_SEARCH: getText('air:result.changeParamsOfSearch'),
  RESET_FILTERS: getText('air:result.resetFilters'),
};

interface IFilterEmptyPanelProps {
  showReset?: boolean;
  onResetFilters(): void;
}

const FilterEmptyPanel = ({
  showReset = false,
  onResetFilters,
}:IFilterEmptyPanelProps) => (
  <NoResults
    renderContent={ () => (
      <div className={ styles.no_results }>
        <Text type='bold_18'>
          { LABELS.NOT_FOUND }
        </Text>
        <Text className={ styles.subtext }>
          { LABELS.CHANGE_PARAMS_OF_SEARCH }
        </Text>
        {showReset && (
          <Button
            type='outline'
            onClick={ onResetFilters }
          >
            { LABELS.RESET_FILTERS }
          </Button>
        )}
      </div>
    ) }
  />
);

export { FilterEmptyPanel };
