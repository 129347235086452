import { Dayjs } from 'dayjs';
import { DATE_FORMATS } from 'yw-ui/src/constants/formatDate.ts';
import { getText } from '@/i18n';

import {
  IHotelStaticAutocompleteItem,
  IHotelStaticAutocompleteResponse,
  IPrepareHotelStaticAutocomplete,
  IPrepareHotelStaticAutocompleteItem,
} from '@/app/bi/api/searchHotelsApi.ts';

import { Region } from '@/app/bi/models/hotelSearch/hotelSearchTypes.ts';
import { formatDate } from '@/app/bi/utils/formatDate.ts';
import { PATTERN } from '@/app/bi/constants/dateFormats.ts';
import { roomCountFinder } from '@/app/bi/utils/hotel.ts';

const LABELS = {
  REGIONS: getText('components:menu.hotel.tags.regions'),
  HOTELS: getText('components:menu.hotel.tags.hotels'),
};

const prepareHotelAutocompleteItems = (
  items: IHotelStaticAutocompleteItem[],
  isRegion = false,
): IPrepareHotelStaticAutocompleteItem[] =>
  items.map((item) => ({
    ...item,
    isRegion,
  }));

const createSuggests = (data: IHotelStaticAutocompleteResponse): IPrepareHotelStaticAutocomplete[] => {
  const result = [];

  if (data.regions.length) {
    result.push({
      title: LABELS.REGIONS,
      items: prepareHotelAutocompleteItems(data.regions, true),
    });
  }

  if (data.hotels.length) {
    result.push({
      title: LABELS.HOTELS,
      items: prepareHotelAutocompleteItems(data.hotels, false),
    });
  }

  return result;
};
interface ISearchFormIsValid {
  searchMenuType?: string,
  region: Region,
  checkin: Dayjs | null,
  checkout: Dayjs | null,
  customCheckin: Dayjs | null
  customCheckout: Dayjs | null,
}
const searchFormIsValid = ({
  region,
  checkin,
  checkout,
  customCheckin,
  customCheckout,
}: ISearchFormIsValid): boolean => {

  const startDate = customCheckin || checkin;
  const endDate = customCheckout || checkout;

  const isDatesValid =
    !!startDate && startDate.isValid() &&
    !!endDate && endDate.isValid() &&
    endDate.isAfter(startDate);

  return !!region.selected && !!region?.selected?.id && isDatesValid;
};

const getKey = ({ items = [], title, Id }: any): string => {
  if (items.length) {
    return title;
  }

  return Id;
};

const prepareCheckinCheckoutParams = (checkin: Dayjs | null, checkout: Dayjs | null) => {
  const customParams: ({ checkInTime?: string, checkOutTime?:string }) = {};
  if (checkin) {
    customParams.checkInTime = formatDate(checkin, DATE_FORMATS.TIME);
  }
  if (checkout) {
    customParams.checkOutTime = formatDate(checkout, DATE_FORMATS.TIME);
  }

  return customParams;
};

const mapStateToHotelPageSearchSettings = ({
  checkin,
  checkout,
  adult,
  regionId,
  regionName,
  customCheckin,
  customCheckout,
  travellersCount,
}: {
  regionId: string;
  regionName: string;
  travellersCount: number,
  adult: number,
  checkin: Dayjs | null,
  checkout: Dayjs | null,
  customCheckin: Dayjs | null
  customCheckout: Dayjs | null,
}) => ({
  checkInDate: formatDate(checkin as Dayjs, PATTERN.YEAR_MONTH_DAY),
  checkOutDate: formatDate(checkout as Dayjs, PATTERN.YEAR_MONTH_DAY),
  ...prepareCheckinCheckoutParams(
    customCheckin,
    customCheckout,
  ),
  regionName,
  guestsCount: adult,
  travellersCount,
  roomCount: roomCountFinder(travellersCount, adult),
  regionId,
});

export {
  createSuggests,
  searchFormIsValid,
  getKey,
  mapStateToHotelPageSearchSettings,
};
