import { ywApiPrivate } from './index.ts';

import {
  ITrip,
  ITripFilterDefault,
  ITripFilterResponse,
  ITripsFilterRequest,
  ITripLite,
  ITripByStatusesRequest,
} from '@/app/bi/models/tripsTypes.ts';
import { IEmployeeObj } from '@/app/bi/models/employees.ts';

const prepareTripsItemsByStatuses = (items: ITripLite[]) => items.map(({
  id,
  name,
}) => ({
  label: name,
  value: id,
}));

export const tripApi = ywApiPrivate.injectEndpoints({
  endpoints: (build) => ({
    getTripById: build.query<ITrip, string>({
      query: (guid: string) => ({
        url: `/trips/${guid}`,
        method: 'GET',
      }),
    }),
    getTrips: build.query<ITripFilterResponse, ITripsFilterRequest>({
      query: (body: ITripsFilterRequest) => ({
        url: '/trips/filter',
        method: 'POST',
        body,
      }),
    }),
    getDefaultTripsFilter: build.query<ITripFilterDefault, void>({
      query: () => ({
        url: '/trips/filter',
        method: 'GET',
      }),
    }),
    employeesAutocomplete: build.query<IEmployeeObj[], string>({
      query: (query) => ({
        url: `/employees/autocomplete?query=${query}`,
        method: 'GET',
      }),
    }),
    citiesAutocomplete: build.query<string[], string>({
      query: (query) => ({
        url: `trips/filter/cities?query=${query}`,
        method: 'GET',
      }),
    }),
    getTripByStatuses: build.query<{
      label: string | null,
      value: string,
    }[], ITripByStatusesRequest>({
      query: (body: ITripByStatusesRequest) => ({
        url: '/trips/filter/lite',
        method: 'POST',
        body,
      }),
      transformResponse: (response: ITripLite[]) => (response.length
        ? prepareTripsItemsByStatuses(response)
        : []),
    }),
  }),
});

export const {
  useGetTripByIdQuery,
  useEmployeesAutocompleteQuery,
  useCitiesAutocompleteQuery,
  useGetTripByStatusesQuery,
  useGetDefaultTripsFilterQuery,
  useGetTripsQuery,
} = tripApi;
