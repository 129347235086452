import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { tripApi } from '@/app/bi/api/tripApi.ts';

import { getDateRange } from '@/app/bi/utils/trip.ts';

import { DEFAULT_FILTERS_TRIPS } from '@/app/bi/constants/trips.ts';

import { ITripsFilter } from '@/app/bi/models/tripsTypes.ts';

interface ITripsSliceStore {
  filter: ITripsFilter | null;
}

const initialState: ITripsSliceStore = {
  filter: null,
};

export const tripsSlice = createSlice({
  name: 'tripsSlice',
  initialState,
  reducers: {
    updateFilter(state, action: PayloadAction<ITripsFilter | null>) {
      state.filter = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      tripApi.endpoints.getDefaultTripsFilter.matchFulfilled,
      (state, { payload }) => {
        if (payload.tripFilter.tripDates) {
          state.filter = { ...DEFAULT_FILTERS_TRIPS, tripDates: getDateRange(payload.tripFilter.tripDates) };
        }
      },
    );
  },
});

export default tripsSlice.reducer;
