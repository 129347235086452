import React, { useState } from 'react';
import {
  Select,
  Button,
  Text,
} from 'yw-ui';

import { getText } from '@/i18n';

import ANIMATION from '../../../../../../bi/constants/animation';
import { PATTERN } from '@/app/bi/constants/dateFormats.ts';

import { GeneralInfoList } from './components/GeneralInfoList';

import trimTimezone from '../../../../../../bi/utils/trimTimezone';
import { dateUtcFormat } from '@/app/bi/utils/formatDate.ts';
import { prepareHotelPrice } from '@/app/bi/utils/hotel.ts';
import { formatCurrency } from '@/app/bi/utils/money.ts';

import { SearchSettings } from '@/app/bi/models/hotelsTypes.ts';
import { IPrepareHotelProviderRate } from '@/app/bi/models/hotelSearch/hotelSearchTypes.ts';
import { ECurrencyCode } from '@/app/bi/models/hotelSearch/hotelSearchEnum.ts';

import styles from './index.module.css';

const LABELS = {
  TEXT_TO_CART: getText('hotels:hotelResult.item.rate.toCart'),
  TEXT_ADDED: getText('hotels:hotelResult.item.rate.added'),
  ROOMS: getText('hotels:hotelResult.item.rate.rooms'),
  LAST_ROOM: getText('hotels:hotelResult.item.rate.lastRoom'),
};

interface RateProps {
  rate: IPrepareHotelProviderRate;
  searchSettings: SearchSettings;
  currencyCode: ECurrencyCode;
  onToCart(rate: IPrepareHotelProviderRate): void,
  onChangeCount?(bookId: string, value: any): void,
}

const Rate = ({
  rate,
  searchSettings,
  currencyCode,
  onToCart,
  onChangeCount,
}: RateProps) => {
  const {
    deadLine,
    refundable,
    meal,
    select: {
      items,
      count,
    },
    total,
  } = rate;

  const { daysCount, customCheckin, customCheckout } = searchSettings;
  const [animatedClass, setAnimatedClass] = useState('');

  const handleAnimationEnd = () => setAnimatedClass('');

  const handleAddToCart = (e: React.MouseEvent) => {
    e.preventDefault();

    setAnimatedClass(ANIMATION.MOVE_TO_CART);
    onToCart(rate);
  };

  const handleSelect = (value: number) => onChangeCount?.(rate.bookId, value);

  const renderAction = () => {
    const hasFreeRooms = !!items.length;
    const labelHtml = hasFreeRooms ? LABELS.TEXT_TO_CART : LABELS.TEXT_ADDED;

    const selectHtml = hasFreeRooms && items.length > 1 && (
      <div className={ styles.rooms }>
        <Text type='NORMAL_14' color='gray-7'>{ LABELS.ROOMS }</Text>
        <Select
          theme='default-border'
          className={ styles.select }
          items={ items }
          value={ count as number }
          onChange={ handleSelect }
        />
      </div>
    );

    return (
      <div className={ styles.book }>
        <div className={ styles.book_content }>
          { selectHtml }
          <Button
            type='secondary'
            size='small'
            onClick={ handleAddToCart }
            disabled={ !hasFreeRooms }
            className={ styles.button }
          >
            { labelHtml }
          </Button>
        </div>
        { !selectHtml && <Text type='SEMIBOLD_14' color='red' >{ LABELS.LAST_ROOM }</Text> }
      </div>
    );
  };

  const {
    countString,
    daysString,
    base: baseStr,
    rzpvString,
    price: newPrice,
  } = prepareHotelPrice(
    count as number,
    total,
    daysCount,
    customCheckin,
    customCheckout,
  );

  const actionHtml = renderAction();
  const newDeadLine = deadLine
    ? dateUtcFormat(trimTimezone(deadLine), PATTERN.DAY_OF_MONTH_TIME)
    : '';

  const priceHtml = count && count > 1 ? (
    <>
      <Text className={ styles.addendums } type='NORMAL_12'>
        { countString } { daysString } { baseStr }
        { rzpvString } =
      </Text>
      <div className={ styles.total }>
        <Text type='bold_24'>
          {formatCurrency(Number(newPrice), currencyCode)}
        </Text>
      </div>
    </>
  ) : (
    <div className={ styles.total }>
      <Text type='bold_24'>
        {formatCurrency(Number(newPrice), currencyCode)}
      </Text>
    </div>
  );

  return (
    <div
      className={ `${styles.wrapper} ${animatedClass}` }
      onAnimationEnd={ handleAnimationEnd }
    >
      <div className={ styles.rate_info }>
        <GeneralInfoList
          refundable={ refundable }
          meal={ meal }
          date={ newDeadLine }
        />
      </div>
      <div className={ styles.actions }>
        <div className={ styles.price }>
          {priceHtml}
        </div>
        { actionHtml }
      </div>
    </div>
  );
};

export { Rate };
