import React from 'react';
import { NotificationsLayout } from 'yw-ui';
import { useNavigate, useOutlet } from 'react-router-dom';

import { SidePanelWrapper } from '@/app/protectedLayout/components/SidePanel';
import { MainHeader } from '@/app/components/Header';
import ErrorBoundary from '@/app/protectedLayout/components/ErrorBoundary';

import { useIntercomeInitialisator } from '@/app/hooks/useIntercom.ts';

import styles from '@/app/protectedLayout/index.module.css';

const MainLayout = () => {
  useIntercomeInitialisator();

  const outlet = useOutlet();

  return (
    <div className={ styles.layout }>
      <SidePanelWrapper />
      <MainHeader />
      <div className={ styles.page }>
        <ErrorBoundary navigate={ useNavigate() }>
          {outlet}
        </ErrorBoundary>
      </div>
      <NotificationsLayout />
    </div>
  );

};
export { MainLayout };
