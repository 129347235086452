import React from 'react';
import { Checkbox } from 'yw-ui';

import styles from './index.module.css';

interface FilterCreateOnlyMeItemProps {
  title: string;
  value: boolean;
  onChange(value: boolean): void,
}

const FilterCreateOnlyMeItem = ({ title, value, onChange }: FilterCreateOnlyMeItemProps) => (
  <>
    <div className={ styles.separator } />
    <Checkbox
      className={ styles.checkbox }
      value={ value }
      onChange={ () => onChange(value) }
      themes='dark'
    >
      {title}
    </Checkbox>
    <div className={ styles.separator } />
  </>
);

export { FilterCreateOnlyMeItem };
