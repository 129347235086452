import React from 'react';
import { Text } from 'yw-ui';
import { TextColor } from 'yw-ui/src/components/Text/types.ts';
import { diffMinutesAndHours } from '@/app/bi/utils/time.ts';

import { TECHNICAL_LANDINGS } from '@/app/bi/constants/airline.ts';
import { dayjsObjectUTC } from '@/app/bi/utils/formatDate.ts';
import { StopDetails } from '@/app/bi/models/airlineTypes.ts';
import { DATE_FORMATS } from '@/app/bi/constants/dateFormats.ts';

const LABELS = {
  TECHNICAL_STOP: (text: string) => `Техническая посадка: ${text}`,
};

const getText = (details: never[]) => {
  let result = '';
  let dateAA;
  let dateAD;

  details.forEach(({ DateQualifier, Date, LocationId }) => {
    if (DateQualifier === TECHNICAL_LANDINGS.FIRST) {
      dateAA = dayjsObjectUTC(Date);
      // @ts-ignore
      result += `${LocationId.split(',')[0]}, ${dayjsObjectUTC(dateAA).format(DATE_FORMATS.TIME)} – `;
    }

    if (DateQualifier === TECHNICAL_LANDINGS.LAST) {
      dateAD = dayjsObjectUTC(Date);
      result += `${dayjsObjectUTC(dateAD).format(DATE_FORMATS.TIME)} `;
    }
  });
  // @ts-ignore
  const diffMinutesAndHoursText = diffMinutesAndHours(dateAA, dateAD);
  result += `(${diffMinutesAndHoursText})`;

  return result;
};

interface TechnicalStopTextProps {
  technicalStop: StopDetails[],
  className: string,
  textColor?: TextColor,
}
const TechnicalStopText = ({
  technicalStop,
  className,
  textColor = 'gray',
}: TechnicalStopTextProps) => {
  const renderText = () =>
    // @ts-ignore
    technicalStop.map(({ Details }) => {
      const text = getText(Details);

      return (
        <div key={ Details.Date } className={ className }>
          <Text
            type='NORMAL_14'
            color={ textColor }
          >
            {LABELS.TECHNICAL_STOP(text)}
          </Text>
        </div>
      );
    });

  return (<>{renderText()}</>
  );
};

export { TechnicalStopText };
