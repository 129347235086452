import { IBookResult, IBookResultResponse, ICartItemError, ICheckoutData } from '@/app/bi/models/booking.ts';
import { ICartErrors, ICartErrorsItem } from '@/app/bi/models/cart.ts';
import { BookingErrorMessage } from '@/app/bi/constants/booking.ts';
import { PASSPORTS } from '@/app/bi/constants/passport.ts';
import { EEmployeeDocumentType } from '@/app/bi/models/employees.ts';
import { CART_ITEM_ERROR_MESSAGE } from '@/app/bi/constants/cart.ts';

const getTripItemsErrors = (tripItems: ICartItemError[]): ICartErrorsItem[] =>
  tripItems.reduce<ICartErrorsItem[]>((acc, item) => {
    if (item.errors.length) {
      const bookingErrors = item.errors.map((error) => ({
        error,
        message: CART_ITEM_ERROR_MESSAGE[error],
      }),
      );
      acc.push({
        ...item,
        errors: bookingErrors,
      });
    }

    return acc;
  }, []);

export const getCartErrors = (bookResult: IBookResultResponse): ICartErrors | never[] => {
  const { hasErrors, errors, itemErrors } = bookResult;

  if (!hasErrors) return [];

  const bookingErrors = errors.length
    ? errors.map((bookingError) => BookingErrorMessage[bookingError])
    : [];

  return {
    hasErrors,
    bookingErrors,
    cartItemErrors: itemErrors.length ? getTripItemsErrors(itemErrors) : [],
  };
};

export const checkBookingAvailability = (bookResult: IBookResult): ICheckoutData => {
  if (!bookResult.hasErrors) {
    return {
      canBook: true,
      errors: [],
    };
  }

  if (bookResult.errors.length) {
    const errors = bookResult.errors.map((bookingError) => BookingErrorMessage[bookingError]);

    return {
      canBook: false,
      errors,
    };
  }

  return {
    canBook: true,
    errors: [],
  };
};

export const getPassportType = (type: EEmployeeDocumentType) => {
  switch (type) {
    case EEmployeeDocumentType.IdCard: {
      return PASSPORTS.ID_CARD.VALUE;
    }

    default: {
      return PASSPORTS.PASSPORT.VALUE;
    }
  }
};
