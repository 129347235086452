import { ywApiPrivate } from './index.ts';

import { IReportFilter, IReportFilterResponse } from '@/app/bi/models/reportsTypes.ts';

export const reportsApi = ywApiPrivate.injectEndpoints({
  endpoints: (build) => ({
    getReports: build.query<IReportFilterResponse, IReportFilter>({
      query: (data) => ({
        url: '/reports/filter',
        method: 'POST',
        body: data,
      }),
    }),
  }),
});

export const {
  useLazyGetReportsQuery,
} = reportsApi;
