import React, { useState } from 'react';
import { Button, Checkbox, Collapse, Input } from 'yw-ui';

import { getText } from '@/i18n';

import { sortedListCities } from '@/app/bi/utils/trip.ts';

import { FILTER_DEFAULT_COUNT_ITEMS } from '@/app/bi/constants/common.ts';

import styles from './index.module.css';

const LABELS = {
  OPEN_MORE: getText('trips:openMore'),
  HIDDEN_MORE: getText('trips:hiddenMore'),
};

interface FilterCitiesItemProps {
  title: string;
  data: string[];
  citiesValues: string[];
  inputValue: string;
  placeholder: string;
  onChange(array: string[]): void;
  onChangeInput(value: string): void;
}

const FilterCitiesItem = ({
  title,
  data,
  citiesValues,
  inputValue,
  placeholder,
  onChange,
  onChangeInput,
}: FilterCitiesItemProps) => {
  const [
    openList,
    setOpenList,
  ] = useState<boolean>(false);

  const isActiveButton = data.length > FILTER_DEFAULT_COUNT_ITEMS;
  const listViewLength = !openList ? FILTER_DEFAULT_COUNT_ITEMS : data.length;
  const sortedData = sortedListCities(citiesValues, data);

  const handleOpenList = () => setOpenList(!openList);

  const handleChange = (value: string) => {
    const valuesSet = new Set(citiesValues);

    if (valuesSet.has(value)) {
      valuesSet.delete(value);
    } else {
      valuesSet.add(value);
    }

    onChange([...valuesSet]);
  };

  const renderItem = (
    item: string,
    index: number,
    checked: boolean,
  ) => (
    <Checkbox
      className={ styles.checkbox }
      key={ `${item}_${index}` }
      value={ checked }
      onChange={ () => handleChange(item) }
      themes='dark'
    >
      {item}
    </Checkbox>
  );

  const renderArrayItems = () => sortedData.slice(0, listViewLength).map((city, index) => {
    const checked = citiesValues.includes(city);

    return renderItem(city, index, checked);
  });

  const renderButtonMore = () => isActiveButton && (
    <Button type='text' className={ styles.button_more } onClick={ handleOpenList }>
      {openList ? LABELS.HIDDEN_MORE : LABELS.OPEN_MORE}
    </Button>
  );

  return (
    <Collapse label={ title } moreOpen={ openList } className={ styles.container } >
      <div className={ styles.search_container }>
        <Input
          isCleansing
          className={ styles.search }
          value={ inputValue }
          placeholder={ placeholder }
          onChange={ onChangeInput }
        />
      </div>
      {renderArrayItems()}
      {renderButtonMore()}
    </Collapse>
  );
};

export { FilterCitiesItem };
