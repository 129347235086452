import React from 'react';
import { StyledWrapper, Text } from 'yw-ui';
import { getText } from '@/i18n';

import styles from './styles/style.module.css';

const LABELS = {
  PASSWORD_RECOVERY: getText('auth:passwordRecovery'),
  CHECK_EMAIL: getText('auth:checkEmail'),
};

const CheckEmail = () => (
  <div className={ styles.wrapper }>
    <StyledWrapper className={ styles.container }>
      <div className={ styles.check_email }>
        <h1 className={ styles.title }>{LABELS.PASSWORD_RECOVERY}</h1>
        <Text>{LABELS.CHECK_EMAIL}</Text>
      </div>
    </StyledWrapper>
  </div>
);

export { CheckEmail };
