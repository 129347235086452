import React from 'react';
import { Collapse } from 'yw-ui';
import { getText } from '@/i18n';

import { TimeSlider } from '@/app/components/TimeSlider';

import { getDayjs } from '@/app/bi/utils/formatDate.ts';

import { PATTERN } from '@/app/bi/constants/dateFormats.ts';

import { IFlightDuration } from '@/app/bi/models/airlineTypes.ts';

import styles from '../../index.module.css';

const LABELS = {
  TRAVEL_TIME: getText('air:result.filters.travelTime'),
};

interface FlightDurationFilterProps {
  arrivalCity: string,
  departureCity: string,
  flightDuration: IFlightDuration,
  values: IFlightDuration,
  onChange(value: {
    min: number,
    max: number,
  }): void;
}

const FlightDurationFilter = ({
  arrivalCity,
  departureCity,
  flightDuration: {
    min,
    max,
  },
  values,
  onChange,
}: FlightDurationFilterProps) => {
  if (min !== max) {
    return (
      <Collapse
        opened
        className={ styles.item }
        key={ `flight_duration_${min}_${max}}` }
        label={ `${LABELS.TRAVEL_TIME} ${departureCity} - ${arrivalCity}, ${getDayjs().format(PATTERN.DAY_OF_MONTH)}` }
      >
        <TimeSlider
          min={ min }
          max={ max }
          from={ values.min }
          to={ values.max }
          onSlide={ onChange }
        />
      </Collapse>
    );
  }

  return null;
};

export { FlightDurationFilter };
