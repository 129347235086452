import { getText } from '@/i18n';
import { ECartItemErrorType } from '@/app/bi/models/cart.ts';

export const CART_ITEM_ERROR_MESSAGE = {
  [ECartItemErrorType.EmptyCompany]: getText('cart:cartItemErrorMessage.emptyCompany'),
  [ECartItemErrorType.EmptyDocument]: getText('cart:cartItemErrorMessage.emptyDocument'),
  [ECartItemErrorType.EmptyEmployee]: getText('cart:cartItemErrorMessage.emptyEmployee'),
  [ECartItemErrorType.PriceChanged]: getText('cart:cartItemErrorMessage.priceChanged'),
  [ECartItemErrorType.ReserveFailed]: getText('cart:cartItemErrorMessage.reserveFailed'),
};
