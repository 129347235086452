import { EFilterItemType } from '@/app/bi/models/tripsTypes.ts';
import { EEmployeeFilterItem } from '@/app/bi/models/employees.ts';

export interface IPaging {
  total: number;
  current: number;
  count: number;
}

export interface ITag {
  name: string;
  filter: string;
  key: string | number;
  index?: number;
  field?: string;
}

export interface ITagFilter {
  typeFilter: EFilterItemType | EEmployeeFilterItem;
  value: string | number;
  label: string;
}

export enum EInputTypes {
  Text = 'text',
  Number = 'number',
}
