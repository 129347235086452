import { ywApiPrivate } from './index.ts';

import { ICompanyDetail } from '@/app/bi/models/companyDetails.ts';

export const companyDetailsApi = ywApiPrivate.injectEndpoints({
  endpoints: (build) => ({
    getCompanies: build.query<ICompanyDetail[], void>({
      query: () => ({
        url: '/company',
        method: 'GET',
      }),
    }),
  }),
});

export const { useGetCompaniesQuery } = companyDetailsApi;
