import { ru } from './ru';
import { en } from './en';
import { tr } from './tr';

const resources = {
  ru,
  en,
  tr,
};

export { resources };
