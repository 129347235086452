import React, { useState } from 'react';

import { getText } from '@/i18n';

import { DialogHotel } from '@/app/pages/TravelPolicy/components/DialogHotel';
import { WrapperPolicies } from '@/app/pages/TravelPolicy/components/WrapperPolicies';
import { DialogExceptionsHotel } from '@/app/pages/TravelPolicy/components/DialogExceptionsHotel';
import { HotelPolicies } from '@/app/pages/TravelPolicy/components/BlockPoliciesHotel/components/HotelPolicies/HotelPolicies.tsx';

const LABELS = {
  NAME: getText('settings:travelPolicies.policy.hotel.name'),
  TEXT_EXCEPTION: getText('settings:travelPolicies.policy.hotel.textException'),
  SELECT_CITIES: getText('settings:travelPolicies.selectCities'),
  ONLY_CLASS: getText('settings:travelPolicies.policy.onlyClass'),
};

interface BlockPoliciesHotelProps {
  isCreate: boolean;
}

export const BlockPoliciesHotel = ({
  isCreate,
}: BlockPoliciesHotelProps) => {
  const [
    dialogDestination,
    setDialogDestination,
  ] = useState(false);

  const [
    dialogExceptions,
    setDialogExceptions,
  ] = useState(false);

  const handleOnlyTurkeyDialogClose = () => setDialogDestination(false);

  const handleDialogExceptionsClose = () => setDialogExceptions(false);

  return (
    <WrapperPolicies name={ LABELS.NAME }>
      <HotelPolicies isCreate={ isCreate } />
      {/* <CheckBoxItem */}
      {/*  checked={ direction } */}
      {/*  onChange={ () => {} } */}
      {/* > */}
      {/*  <div className={ styles.policy_city }> */}
      {/*    <Text type='NORMAL_14'> */}
      {/*      {LABELS.ONLY_CLASS} */}
      {/*    </Text> */}
      {/*    <Button type='text' className={ styles.button_city } onClick={ handleOnlyTurkeyDialogOpen } > */}
      {/*      {LABELS.SELECT_CITIES} */}
      {/*    </Button> */}
      {/*  </div> */}
      {/* </CheckBoxItem> */}
      <DialogHotel
        isShow={ dialogDestination }
        onCloseDialog={ handleOnlyTurkeyDialogClose }
      />
      <DialogExceptionsHotel
        isShow={ dialogExceptions }
        isCreate={ isCreate }
        onCloseDialog={ handleDialogExceptionsClose }
      />
    </WrapperPolicies>
  );
};
