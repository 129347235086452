import { createSlice } from '@reduxjs/toolkit';

interface IntercomState {
  show: boolean,
  sendingMessageToAdmin: boolean,
  isRequesting: boolean,
}

const initialState: IntercomState = {
  show: false,
  sendingMessageToAdmin: false,
  isRequesting: false,
};

export const intercomSlice = createSlice({
  name: 'intercomSlice',
  initialState,
  reducers: {},
});

export default intercomSlice.reducer;
