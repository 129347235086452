import React, { ReactNode } from 'react';

interface FormWrapperProps {
  children: ReactNode;
  className: string;
  onSubmit?(): void;
}

const FormWrapper = ({
  children,
  className = '',
  onSubmit = () => {},
}: FormWrapperProps) => {
  const handleSubmitForm = (event: React.FormEvent) => {
    event.preventDefault();
    onSubmit();
  };

  return (
    <form autoComplete='off' className={ className } onSubmit={ handleSubmitForm }>
      { children }
    </form>
  );
};

export { FormWrapper };
