export const debounce = <T extends any[], U>(
  fn: (...args: T) => U,
  timer: number,
): ((...args: T) => Promise<U>) => {
  let timeout: ReturnType<typeof setTimeout> | null = null;

  return (...args: T) => new Promise((resolve) => {
    const later = () => {
      timeout = null;
      const result = fn(...args);
      resolve(result);
    };

    if (timeout !== null) {
      clearTimeout(timeout);
    }

    timeout = setTimeout(later, timer);
  });
};
