const SPECIALCHARSBYGROUPS = /([-!$%^&\s*()_+@|~=`\\#{}[\]:";'<>?,./])/ig;
const ESCAPECHARS = /[-/\\^$*+?.()|[\]{}]/g;
const SPACEORHYPHEN = /[-|\s]/g;

const ID_CARD_NAME = /[^\p{L}\u002D\s]/gu;
const ID_CARD_NUMBER = (value: number) => new RegExp(`^\\d{0,${value}}$`);

const LATIN_NAME = /^[a-zA-Z\s-]*$/;
const PASSPORT_NAME = /[^a-zA-Z\s-]/g;
const PASSPORT_NUMBER = /^[A-Za-z](\d{0,8}?)$/;

const PHONE_NUMBER = /[^\d]/g;

export {
  SPECIALCHARSBYGROUPS,
  ESCAPECHARS,
  SPACEORHYPHEN,
  ID_CARD_NUMBER,
  PASSPORT_NUMBER,
  PHONE_NUMBER,
  ID_CARD_NAME,
  PASSPORT_NAME,
  LATIN_NAME,
};
