import { Dayjs } from 'dayjs';

import { ITripPreview } from '@/app/bi/models/tripsTypes.ts';
import { IFilterItem } from '@/app/bi/models/app.ts';

export enum EOperationTypesFields {
  Incoming = 'Incoming',
  Outcoming = 'Outcoming',
  Booking = 'Booking',
  Change = 'Change',
}

export type TSortedByFields = 'TripName' | 'OperationType' | 'Company' | 'OperationDate' | 'Amount';

export interface IReportFilterResponse {
  items: IReportItem[] | null;
  filterRequest: IReportFilter;
  page: number;
  pages: number;
  pageLimit: number;
  itemsCount: number;
}

export interface IReportItem {
  number: string | null;
  companyId: number;
  companyName: string | null;
  trip: ITripPreview | null;
  operationId: string;
  operationDate: Dayjs;
  operationType: EOperationTypesFields;
  amount: number;
}

export interface IReportFilter {
  filterSort: {
    sortedBy: TSortedByFields;
    descending: boolean;
  },
  companies: string[],
  operationTypes: EOperationTypesFields[];
  operationDate: {
    min: Dayjs | null;
    max: Dayjs | null;
  },
  page: number
}

export interface IInitialStateReportFilter {
  filterSort: {
    sortedBy: TSortedByFields;
    descending: boolean;
  },
  companies: IFilterItem[],
  operationTypes: IFilterItem[];
  operationDate: {
    min: Dayjs | null;
    max: Dayjs | null;
  },
  page: number
}
