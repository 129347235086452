import { ISearchQuery, ISuggestion } from '../models/airlineSearchTypes.ts';
import {
  IAirlineSearchFilter,
  IAirlineSearchResponse,
  ISearchResponse,
} from '../models/airlineTypes.ts';
import { ywApiPrivate } from './index.ts';

export interface IAirlineAutocompleteRequest {
  locale: string,
  query: string
}

export const searchAirlineApi = ywApiPrivate.injectEndpoints({
  endpoints: (build) => ({
    airlineAutocomplete: build.query<ISuggestion[], IAirlineAutocompleteRequest>({
      query: ({ locale, query }) => ({
        url: `/airline/autocomplete/${locale}/${query}`,
        method: 'GET',
      }),
      transformResponse: (response: ISuggestion[]) => response.map((item) => ({
        ...item,
        items: [...item.childLocation],
      })),
    }),
    search: build.query<ISearchResponse, ISearchQuery>({
      query: (body: ISearchQuery) => ({
        url: '/airline/search',
        method: 'POST',
        body,
      }),
    }),
    searchBySearchId: build.query<ISearchResponse, string>({
      query: (searchId: string) => ({
        url: `/airline/search/${searchId}`,
        method: 'GET',
      }),
    }),
    updateFilters: build.query<IAirlineSearchResponse, { searchId: string, data: IAirlineSearchFilter }>({
      query: ({ searchId, data }) => ({
        url: `/airline/search/${searchId}`,
        method: 'POST',
        body: data,
      }),
    }),
  }),
});

export const { useLazyAirlineAutocompleteQuery } = searchAirlineApi;
