import { ywApiPrivate } from './index.ts';

import {
  ICreateRequestReportDocument,
  ICreateRequestTripDocument,
  ISendAirlineVoucherRequest,
} from '@/app/bi/models/documents.ts';

export const documentApi = ywApiPrivate.injectEndpoints({
  endpoints: (build) => ({
    getReportId: build.query<{ id: number }, ICreateRequestReportDocument>({
      query: (data) => ({
        url: '/documents/invoice',
        method: 'POST',
        body: data,
      }),
    }),
    getTripAirlineId: build.query<{ id: number }, ICreateRequestTripDocument>({
      query: (data) => ({
        url: '/documents/voucher',
        method: 'POST',
        body: data,
      }),
    }),
    download: build.mutation<Blob, { id: number }>({
      query: (data) => ({
        url: '/documents/download',
        method: 'POST',
        body: data,
        responseHandler: (response) => response.blob(),
      }),
    }),
    sendEmailAirline: build.mutation<void, ISendAirlineVoucherRequest>({
      query: (data) => ({
        url: '/email/voucher',
        method: 'POST',
        body: data,
      }),
    }),
  }),
});

export const {
  useLazyGetReportIdQuery,
  useLazyGetTripAirlineIdQuery,
  useSendEmailAirlineMutation,
  useDownloadMutation,
} = documentApi;
