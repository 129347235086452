import { namespaces } from '../consts';

import common from './namespaces/common.json';
import components from './namespaces/components.json';
import booking from './namespaces/booking.json';
import constants from './namespaces/constants.json';
import hotels from './namespaces/hotels.json';
import air from './namespaces/air.json';
import auth from './namespaces/auth.json';
import reports from './namespaces/reports.json';
import cart from './namespaces/cart.json';
import checkout from './namespaces/checkout.json';
import settings from './namespaces/settings.json';
import trip from './namespaces/trip.json';
import trips from './namespaces/trips.json';
import utils from './namespaces/utils.json';
import homePage from './namespaces/homePage.json';
import services from './namespaces/services.json';

const en = {
  [namespaces.common]: common,
  [namespaces.components]: components,
  [namespaces.booking]: booking,
  [namespaces.constants]: constants,
  [namespaces.hotels]: hotels,
  [namespaces.air]: air,
  [namespaces.auth]: auth,
  [namespaces.reports]: reports,
  [namespaces.cart]: cart,
  [namespaces.checkout]: checkout,
  [namespaces.settings]: settings,
  [namespaces.trip]: trip,
  [namespaces.trips]: trips,
  [namespaces.utils]: utils,
  [namespaces.homePage]: homePage,
  [namespaces.services]: services,
} as const;

export { en };
