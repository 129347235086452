import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { createTags, deleteFilter, resetFilters } from '@/app/bi/utils/hotels.ts';

import { DEFAULT_PAGING_OBJ } from '@/app/bi/constants/hotelsSearch.ts';

import { searchHotelsApi } from '@/app/bi/api/searchHotelsApi.ts';
import {
  IDisplayFilters,
  IHotelSearchFilter,
  IRegionSearchResponseItem,
  IRegionSearchRequest,
} from '@/app/bi/models/hotelSearch/hotelSearchTypes.ts';
import { IPaging, ITag } from '@/app/bi/models/shared.ts';
import { IGeoPoint } from '@/app/bi/models/maps.ts';

interface IHotelsStore {
  items: IRegionSearchResponseItem[];
  mapItems: IRegionSearchResponseItem[];
  itemsCount: number;
  isMapMode: boolean;
  paging: IPaging;
  tags: ITag[];
  filters: IHotelSearchFilter | null;
  displayFilters: IDisplayFilters | null;
  searchId: string,
  searchProviderRequest: IRegionSearchRequest | null;
  regionLocation: IGeoPoint;
  regionName: string;
  isEditFilter: boolean;
  selectHotelOnMap: IRegionSearchResponseItem | null;
}

const initialState: IHotelsStore = {
  items: [],
  mapItems: [],
  itemsCount: 0,
  isMapMode: false,
  paging: DEFAULT_PAGING_OBJ,
  tags: [],
  filters: null,
  displayFilters: null,
  searchId: '',
  searchProviderRequest: null,
  regionName: '',
  regionLocation: {
    lat: 0,
    lng: 0,
  },
  isEditFilter: false,
  selectHotelOnMap: null,
};

export const hotelsSlice = createSlice({
  name: 'hotelsSlice',
  initialState,
  reducers: {
    updateFilters(
      state,
      action: PayloadAction<IHotelSearchFilter>,
    ) {
      state.filters = action.payload;
      state.tags = createTags(state.displayFilters as IDisplayFilters, state.filters);

      if (!state.isEditFilter) {
        state.isEditFilter = true;
      }
    },
    changeMapMode(state) {
      state.isMapMode = !state.isMapMode;
    },
    updateIsEditFilter(state, action: PayloadAction<boolean>) {
      state.isEditFilter = action.payload;
    },
    resetFilters(state) {
      state.filters = resetFilters(state.displayFilters as IDisplayFilters);
      state.tags = [];
    },
    deleteTag(state, action: PayloadAction<ITag>) {
      state.filters = deleteFilter(
        action.payload,
        state.filters as IHotelSearchFilter,
        state.displayFilters as IDisplayFilters,
      );
      state.tags = createTags(state.displayFilters as IDisplayFilters, state.filters as IHotelSearchFilter);
    },
    updateSelectHotelOnMap(state, action) {
      state.selectHotelOnMap = action.payload;
    },
    resetStore: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      searchHotelsApi.endpoints.searchRegion.matchFulfilled,
      (state, { payload }) => {
        state.items = payload.items;
        state.mapItems = payload.mapItems;
        state.filters = {
          ...payload.filter,
          hotelName: '',
          stars: [],
          rating: [],
          hotelTypes: [],
          amenities: [],
        };
        state.displayFilters = {
          hotelTypes: payload.filter.hotelTypes,
          searchSort: payload.filter.searchSort,
          distanceToCenter: payload.filter.distanceToCenter,
          priceForNight: payload.filter.priceForNight,
          currencyCode: payload.filter.currencyCode,
          rating: payload.filter.rating,
        };
        state.searchId = payload.searchId;
        state.searchProviderRequest = payload.searchProviderRequest;
        state.regionName = payload.regionName;
        state.regionLocation = {
          lat: payload.regionLatitude,
          lng: payload.regionLongitude,
        };
        state.paging = {
          ...state.paging,
          total: payload.maxCount,
          current: payload.page,
        };
        window.history.pushState(null, '', `/hotels/search/${payload.searchId}`);
      },
    );
    builder.addMatcher(
      searchHotelsApi.endpoints.getSearchRegionBySearchId.matchFulfilled,
      (state, { payload }) => {
        state.items = payload.items;
        state.mapItems = payload.mapItems;
        state.filters = {
          ...payload.filter,
          hotelName: '',
          stars: [],
          rating: [],
          hotelTypes: [],
          amenities: [],
        };
        state.displayFilters = {
          hotelTypes: payload.filter.hotelTypes,
          searchSort: payload.filter.searchSort,
          distanceToCenter: payload.filter.distanceToCenter,
          priceForNight: payload.filter.priceForNight,
          currencyCode: payload.filter.currencyCode,
          rating: payload.filter.rating,
        };
        state.searchId = payload.searchId;
        state.searchProviderRequest = payload.searchProviderRequest;
        state.regionName = payload.regionName;
        state.regionLocation = {
          lat: payload.regionLatitude,
          lng: payload.regionLongitude,
        };
        state.paging = {
          ...state.paging,
          total: payload.maxCount,
          current: payload.page,
        };
      },
    );
    builder.addMatcher(
      searchHotelsApi.endpoints.searchRegionBySearchId.matchFulfilled,
      (state, { payload }) => {
        state.items = payload.items;
        state.paging = {
          ...state.paging,
          total: payload.maxCount,
          current: payload.page,
        };
      },
    );
  },
});

export default hotelsSlice.reducer;
