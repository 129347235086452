import React, { useState } from 'react';
import { Select, Text } from 'yw-ui';
import { getText } from '@/i18n';

import { getEmployeeFullName } from '@/app/bi/utils/employees.ts';
import { getPassportType } from '@/app/bi/utils/cart.ts';

import { ICartResponseEmployeeDocument } from '@/app/bi/models/cart.ts';

import styles from './index.module.css';

interface IPrepareItem {
  passport: string,
  name: string
  value: string,
}

const LABELS = {
  SELECT_PASSPORT: getText('components:employeePassport.selectPassport'),
};

const prepareDocuments = (documents: ICartResponseEmployeeDocument[]): IPrepareItem[] =>
  documents.map(({
    id,
    number,
    type,
    firstName,
    lastName,
  }) => {
    const name = ` ${getEmployeeFullName({
      firstName,
      lastName,
    })}`;
    const passportNumber = `${number.substring(0, 4)} ${number.substring(4)}`;
    const passport = `${getPassportType(type)}:  ${passportNumber}`;

    return {
      passport,
      name,
      value: id,
    };
  });

interface EmployeePassportProps {
  document: ICartResponseEmployeeDocument | null,
  documents: ICartResponseEmployeeDocument[],
  onChangeDocument(documentId: string): void
}

const EmployeePassport = ({
  document,
  documents,
  onChangeDocument,
}: EmployeePassportProps) => {
  const [loading, setLoading] = useState(false);
  const documentsList = prepareDocuments(documents);

  const handleChange = async (documentId: string) => {
    setLoading(true);

    try {
      await onChangeDocument(documentId);
    } finally {
      setLoading(false);
    }
  };

  const renderLabelSelect = ({ passport, name }: IPrepareItem) => (
    <div className={ styles['select-item'] }>
      <Text type='NORMAL_14' fontWeight={ 600 }>{ passport }</Text>
      <Text className={ styles.hint } color='gray-7' type='NORMAL_12'>
        {name}
      </Text>
    </div>
  );

  const renderItemSelect = ({ passport, name }: IPrepareItem) => (
    <div className={ styles['select-item'] }>
      <Text type='NORMAL_14' fontWeight={ 600 }>{ passport }</Text>
      <Text className={ styles.hint } color='gray-7' type='NORMAL_12'>
        {name}
      </Text>
    </div>
  );

  const renderPassportInfo = () => {
    if (!document) return null;
    const { type, number, firstName, lastName } = document;

    const passportType = getPassportType(type);
    const passportNumber = `${number.substring(0, 4)} ${number.substring(4)}`;
    const fullName = getEmployeeFullName({
      firstName,
      lastName,
    });

    return (
      <div className={ styles.wrapper }>
        <div className={ styles.passport_info }>
          <Text type='NORMAL_14' fontWeight={ 600 }>{`${passportType}: ${passportNumber}` }</Text>
          <Text className={ styles.hint } color='gray-7' type='NORMAL_12'>
            {fullName}
          </Text>
        </div>
      </div>
    );
  };

  const renderSelectPassport = () => (
    <Select
      excludeValue
      placeholder={ LABELS.SELECT_PASSPORT }
      value={ document?.id }
      // @ts-ignore
      items={ documentsList }
      onChange={ handleChange }
      className={ styles.select }
      // @ts-ignore
      renderLabel={ renderLabelSelect }
      // @ts-ignore
      renderItem={ renderItemSelect }
      loading={ loading }
    />
  );

  const renderContent = () => {
    if (!document || documentsList.length > 1) {
      return renderSelectPassport();
    }

    return renderPassportInfo();
  };

  return (
    <div className={ styles.wrapper }>
      {renderContent()}
    </div>
  );
};

export { EmployeePassport };
