import React, { useState } from 'react';

import { getText } from '@/i18n';

import { DialogAvia } from '@/app/pages/TravelPolicy/components/DialogAvia';
import { WrapperPolicies } from '@/app/pages/TravelPolicy/components/WrapperPolicies';
import { DialogExceptionsAvia } from '@/app/pages/TravelPolicy/components/DialogExceptionsAvia';
import { AviaPolicies } from '@/app/pages/TravelPolicy/components/BlockPoliciesAirline/components/AviaPolicies';

import { INIT_TRAVEL_POLICIES } from '@/app/bi/constants/travelPolicy.ts';

const LABELS = {
  NAME: getText('settings:travelPolicies.policy.airline.name'),
  TEXT_EXCEPTION: getText('settings:travelPolicies.policy.airline.textException'),
  SELECT_DIRECTION: getText('settings:travelPolicies.selectDirection'),
  ONLY_CLASS: getText('settings:travelPolicies.policy.onlyClass'),
};

interface BlockPoliciesAirlineProps {
  isCreate: boolean;
}

export const BlockPoliciesAirline = ({
  isCreate,
}: BlockPoliciesAirlineProps) => {
  const [
    dialogDestination,
    setDialogDestination,
  ] = useState(false);

  const [
    dialogExceptions,
    setDialogExceptions,
  ] = useState(false);

  const handleOnlyTurkeyDialogClose = () => setDialogDestination(false);

  const handleDialogExceptionsClose = () => setDialogExceptions(false);

  return (
    <WrapperPolicies name={ LABELS.NAME }>
      <AviaPolicies isCreate={ isCreate } />
      {/* <CheckBoxItem checked={ directionActive } onChange={ () => {} }> */}
      {/*  <div className={ styles.policy_content }> */}
      {/*    <Text type='NORMAL_14'> */}
      {/*      {LABELS.ONLY_CLASS} */}
      {/*    </Text> */}
      {/*    <Button type='text' className={ styles.button_city } onClick={ handleOnlyTurkeyDialogOpen } > */}
      {/*      {LABELS.SELECT_DIRECTION} */}
      {/*    </Button> */}
      {/*  </div> */}
      {/* </CheckBoxItem> */}
      <DialogAvia
        isShow={ dialogDestination }
        policies={ INIT_TRAVEL_POLICIES }
        onCloseDialog={ handleOnlyTurkeyDialogClose }
      />
      <DialogExceptionsAvia
        isShow={ dialogExceptions }
        isCreate={ isCreate }
        onCloseDialog={ handleDialogExceptionsClose }
      />
    </WrapperPolicies>
  );
};
