import { ECurrencyCode } from '@/app/bi/models/hotelSearch/hotelSearchEnum.ts';
import { EAirlineClass } from '@/app/bi/models/airlineTypes.ts';

export enum EPriceType {
  Percent = 'Percent',
  Currency = 'Currency',
}

export enum ERestrictionActionAirline {
  OnlyTurkey = 'onlyTurkey',
  OnlySpecifiedDestinations = 'onlySpecifiedDestinations',
}

export enum ERestrictionActionHotel {
  SelectCountry = 'onlyTurkey',
  OnlySpecifiedCities = 'onlySpecifiedCities',
}

export interface ITravelPolicy {
  id: string;
  name: string;
  description: string;
  creatorUserId: string;
  accountId: number;
  isActive: boolean;
  airlinePolicy: IAirlinePolicy;
  hotelPolicy: IHotelPolicy;
}

export interface IAirlinePolicy {
  id: string;
  maxPrice: number;
  maxPriceCurrency: ECurrencyCode;
  priceDifferenceLimit: number;
  priceDifferenceLimitType: EPriceType;
  priceDifferenceLimitCurrency: ECurrencyCode;
  class: EAirlineClass[];
  refundable: boolean;
  daysBuyLimit: number;
  baggage: boolean;
  weekdays: boolean;
  flightHours: number;
  directions: string[];
  basePolicyId: string;
  exceptions: ITravelPolicy[];
}

export interface IHotelPolicy {
  id: string;
  maxPricePerNight: number;
  maxPricePerNightCurrency: ECurrencyCode;
  eclc: boolean;
  refundable: boolean;
  weekdays: boolean;
  countryCodes: string[];
  cities: string[];
  basePolicyId: string;
  exceptions: ITravelPolicy[];
}

export interface ITravelPoliciesPrepare extends ITravelPolicy {
  isEdit: boolean;
}

// TODO Убрать после правок бэка
export interface ITravelPolicies {
  airline: {
    restrictionOnDirections: IRestrictionOnDirectionsAirLine;
  };
  hotel: {
    restrictionOnDirections: IRestrictionOnDirectionsHotel;
  };
}

export interface IRestrictionOnDirectionsAirLine {
  onlyTurkey: boolean;
  onlySpecifiedDestinations: boolean;
  routes: IRoutes[];
}

export interface IRestrictionOnDirectionsHotel {
  selectCountry: boolean;
  onlySpecifiedCities: boolean;
  cities: IRestriction[];
  countries: IRestriction[];
}

export interface IRestriction {
  name: string;
  code: string;
  code3: string;
}

export interface IRoutesFromTo {
  label: string;
  selected: null | number;
}

export interface IRoutes {
  from: IRoutesFromTo,
  to: IRoutesFromTo,
}

export interface IAddAirlinePolicyRequest {
  maxPrice: number;
  maxPriceCurrency: ECurrencyCode;
  priceDifferenceLimit: number;
  priceDifferenceLimitType: EPriceType;
  priceDifferenceLimitCurrency: ECurrencyCode;
  class: EAirlineClass[];
  refundable: boolean;
  daysBuyLimit: number;
  baggage: boolean;
  weekdays: boolean;
  flightHours: number;
  directions: string[];
  basePolicyId: string;
  exceptions: ITravelPolicy[];
}

export interface IAddHotelPolicyRequest {
  maxPricePerNight: number;
  maxPricePerNightCurrency: ECurrencyCode;
  eclc: boolean;
  refundable: boolean;
  weekdays: boolean;
  countryCodes: string[];
  cities: string[];
  basePolicyId: string;
  exceptions: ITravelPolicy[];
}

export interface IUpdateTravelPolicyRequest {
  policyId: string;
  name: string;
  description: string | null;
  accountId: number;
  creatorUserId: string;
  updateBaseAirlinePolicy: IAirlinePolicy;
  addedAirlineExceptions: IAddAirlinePolicyRequest[];
  updateAirlineExceptions: IAirlinePolicy[];
  deleteAirlineExceptionIds: string[];
  updateBaseHotelPolicy: IHotelPolicy;
  addedHotelExceptions: IAddHotelPolicyRequest[];
  updateHotelExceptions: IHotelPolicy[];
  deleteHotelExceptionIds: string[];
}

export interface IAddTravelPolicyRequest {
  name: string;
  description: string | null;
  airlinePolicy: IAddAirlinePolicyRequest;
  airlineExceptions: IAddAirlinePolicyRequest[];
  hotelPolicy: IAddHotelPolicyRequest;
  hotelExceptions: IAddHotelPolicyRequest[];
}
