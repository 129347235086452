import React, { ReactNode, RefObject, useState } from 'react';
import { Text, Button, NoResults } from 'yw-ui';
import { getText } from '@/i18n';

import { sideSlice } from '@/app/store/redusers/SideSlice.ts';
import { useAppDispatch, useAppSelector } from '@/app/store/hooks/redux.ts';

import Filters from './components/Filters';
import { RoomGroup } from './components/RoomGroup';
import { HotelServices } from '@/app/components/HotelServices';

import { DATE_FORMATS } from '@/app/bi/constants/dateFormats.ts';

import { ECurrencyCode } from '@/app/bi/models/hotelSearch/hotelSearchEnum.ts';
import {
  IHotelSearchItemResponse,
  IPrepareHotelProviderRate,
} from '@/app/bi/models/hotelSearch/hotelSearchTypes.ts';

import styles from './index.module.css';

const LABELS = {
  AVAILABLE_ROOMS: getText('hotels:hotelResult.item.availableRooms'),
  ADD_EARLY_IN: getText('hotels:hotelResult.item.addEarlyIn'),
  LATE_OUT: getText('hotels:hotelResult.item.lateOut'),
  ADD_LATE_OUT: getText('hotels:hotelResult.item.addLateOut'),
  NOT_FOUND_HOTELS: getText('hotels:hotelResult.item.notFoundHotel'),
  EARLY_IN: (date: string) => getText('hotels:hotelResult.item.earlyIn', { date }),
  LATE_OUT_WITH_DATE: (date: string) => getText('hotels:hotelResult.item.lateOutWithDate', { date }),
};

interface IHotelProps {
  buttonsRefs: RefObject<HTMLButtonElement>[];
  hotelInfo: IHotelSearchItemResponse;
  onAddToCart(rate:IPrepareHotelProviderRate): void;
  onChangeCount(bookId: string, value: number): void;
  onOpenDatePickerFrom(): void;
  onOpenDatePickerTo(): void;
  moreAboutHotelRef: RefObject<HTMLDivElement | null>;
}

const Hotel = ({
  buttonsRefs,
  hotelInfo,
  onAddToCart,
  onChangeCount,
  onOpenDatePickerFrom,
  onOpenDatePickerTo,
  moreAboutHotelRef,
}: IHotelProps) => {
  const {
    checkin,
    checkout,
    customCheckin,
    customCheckout,
    // travellersCount,
  } = useAppSelector((state) => state.searchHotelsReducer);
  const {
    setShow,
    setRenderFn,
  } = sideSlice.actions;
  const dispatch = useAppDispatch();

  const [currencyCode, setCurrencyCode] = useState(ECurrencyCode.USD);

  const showRoomDetails = (renderFn: () => ReactNode) => {
    dispatch(setShow(true));
    dispatch(setRenderFn(renderFn));
  };

  const renderRoomGroup = () => {
    if (!hotelInfo?.roomGroups.length) {
      return (
        <div className={ styles.not_found }>
          <NoResults
            className={ styles.not_found }
            renderContent={ () => (
              <div className={ styles.content_wrapper }>
                <Text type='bold_24'>
                  { LABELS.NOT_FOUND_HOTELS }
                </Text>
              </div>
            ) }
          />
        </div>
      );
    }

    const searchSettings = {
      customCheckin,
      customCheckout,
      daysCount: checkout?.diff(checkin, 'days'),
      checkin,
      checkout,
    };

    return hotelInfo?.roomGroups.map((room, idx) => (
      <RoomGroup
        key={ `${room.name}-${room.roomType}-${idx}` }
        room={ room }
        amenities={ hotelInfo.amenities }
        currencyCode={ currencyCode }
        onToCart={ onAddToCart }
        onChangeCount={ onChangeCount }
        // @ts-ignore
        searchSettings={ searchSettings }
        onShowRoomDetails={ showRoomDetails }
      />
    ));
  };
  const renderFilters = () => {
    const filters = {
      online: true,
      breakfast: false,
      hasCancellation: false,
      currencyCode,
    };

    return (
      <Filters
        filters={ filters }
        // updateFilters={ () => {} }
        updateCurrencyCode={ setCurrencyCode }
        disabled={ false }
      />
    );
  };

  const renderCustomTimeSelect = () => {
    const textTo = customCheckin
      ? LABELS.EARLY_IN(customCheckin.format(DATE_FORMATS.TIME))
      : LABELS.ADD_EARLY_IN;
    let textFrom;
    if (customCheckout) {
      textFrom = LABELS.LATE_OUT_WITH_DATE(customCheckout.format(DATE_FORMATS.TIME));
    } else {
      textFrom = customCheckin
        ? LABELS.ADD_LATE_OUT
        : LABELS.LATE_OUT;
    }

    return (
      <div className={ styles.custom_time_wrapper }>
        <Button
          type='link'
          className={ styles.item }
          onClick={ onOpenDatePickerFrom }
          ref={ buttonsRefs[0] }
        >
          { textTo }
        </Button>
        <div className={ `${styles.divider} ${styles.item}` } />
        <Button
          type='link'
          className={ styles.item }
          onClick={ onOpenDatePickerTo }
          ref={ buttonsRefs[1] }
        >
          { textFrom }
        </Button>
      </div>
    );
  };

  const renderHeader = () => (
    <div className={ styles.title }>
      <Text type='bold_24' color='gray-7'>
        { LABELS.AVAILABLE_ROOMS }
      </Text>
      { renderCustomTimeSelect() }
    </div>
  );

  return (
    <div className={ styles.wrapper }>
      { renderHeader() }
      { renderFilters() }
      <div className={ styles.rates }>
        { renderRoomGroup() }
      </div>
      <div
        className={ styles.amenities }
        // @ts-ignore
        ref={ moreAboutHotelRef }
      >
        <HotelServices info={ hotelInfo } />
      </div>
    </div>
  );
};

export { Hotel };
