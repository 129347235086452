import React from 'react';
import { Checkbox, Text, Stars } from 'yw-ui';

import { getText } from '@/i18n';

import { useAppSelector } from '@/app/store/hooks/redux.ts';

import { FilterPanel } from '@/app/components/FilterPanel';
import { SliderComponent } from '@/app/components/SliderComponent';
import { CollapseWrap } from '@/app/pages/HotelsResultPage/components/CollapseWrap';
import { TravelPolicyFilter } from '@/app/pages/AirlineResult/components/Filters/components/TravelPolicy';
import { HotelNameFilter } from '@/app/pages/HotelsResultPage/components/Filters/HotelName';

import { changeFilter } from '@/app/bi/utils/hotels.ts';

import { HOTEL_TYPES } from '@/app/bi/constants/hotelsSearch.ts';
import { FILTER_STARS_KEYS, RATING } from '@/app/bi/constants/hotels.ts';
import { EnumCurrencySymbol } from '@/app/bi/constants/travelPolicy.ts';

import { IHotelSearchFilter } from '@/app/bi/models/hotelSearch/hotelSearchTypes.ts';
import { EHotelTypes, ERatingType } from '@/app/bi/models/hotelSearch/hotelSearchEnum.ts';
import { ITravelPolicy } from '@/app/bi/models/travelPolicies.ts';
import { EInputTypes } from '@/app/bi/models/shared.ts';

import styles from './style.module.css';

const LABELS = {
  COST_PER_NIGHT: getText('hotels:regionResult.filters.labels.costPerNight'),
  TRAVEL_POLICY: getText('hotels:regionResult.filters.labels.travelPolicy'),
  FAVORITE: getText('hotels:regionResult.filters.labels.favorite'),
  STARS: getText('hotels:regionResult.filters.labels.stars'),
  HOTEL_CHAIN: getText('hotels:regionResult.filters.labels.hotelChain'),
  RATING: getText('hotels:regionResult.filters.labels.rating'),
  TYPE: getText('hotels:regionResult.filters.labels.type'),
  AMENITIES: getText('hotels:regionResult.filters.labels.amenities'),
  DISTANCE_TO_CENTER: getText('hotels:regionResult.filters.labels.distanceToCenter'),
  DISTANCE_TO_POINT: getText('hotels:regionResult.filters.labels.distanceToPoint'),
  MEAL_INCLUDED: getText('hotels:regionResult.filters.mealIncluded'),
  FREE_CANCELLATION: getText('hotels:regionResult.filters.freeCancellation'),
  ONLY_ONLINE: getText('hotels:regionResult.filters.onlyOnline'),
  REQUEST: getText('hotels:regionResult.filters.request'),
  TO: getText('hotels:regionResult.item.to'),
  KM: getText('components:hotelItem.km'),
  USD: '$',
};

interface IHotelsSearchFilterProps {
  listTravelPolicies: ITravelPolicy[];
  selectPolicy: { id: string, policyName: string, isActive: boolean };
  setIsSelectPolicy(value: { id: string, policyName: string, isActive: boolean }): void;
  onUpdateFilters(filters: IHotelSearchFilter): void;
}

const HotelsSearchFilter = ({
  listTravelPolicies,
  selectPolicy,
  setIsSelectPolicy,
  onUpdateFilters,
}: IHotelsSearchFilterProps) => {
  const {
    filters,
    displayFilters,
  } = useAppSelector((state) => state.hotelsSlice);

  if (!filters) return null;

  const {
    distanceToCenter,
    stars,
    priceForNight,
    breakfastEnabled,
    onlyOnline,
    refundable,
    rating,
  } = filters;

  const handleChangeFilters = (valueFilter: Partial<IHotelSearchFilter>) => {
    onUpdateFilters({ ...filters, ...valueFilter });
  };

  const updatePriceFilter = ([min, max]: number[]) => {
    handleChangeFilters({ priceForNight: { min, max } });
  };

  const updateHotelName = (value: string) => {
    handleChangeFilters({ hotelName: value });
  };

  const updateStarsFilter = (checkboxType: string) => {
    handleChangeFilters({ stars: changeFilter<number>(filters.stars, +checkboxType) });
  };

  // const updateAmenitiesFilter = (checkboxType: string, value: boolean) => {};

  const updateCancellationFilter = (value: boolean) => {
    handleChangeFilters({ refundable: value });
  };

  const updateOnlineFilter = (value: boolean) => {
    handleChangeFilters({ onlyOnline: value });
  };

  const updateBreakfastFilter = (value: boolean) => {
    handleChangeFilters({ breakfastEnabled: value });
  };

  const updateTypeFilter = (value: EHotelTypes) => {
    handleChangeFilters({ hotelTypes: changeFilter<EHotelTypes>(filters.hotelTypes, value) });
  };

  const updateRatingFilter = (checkboxType: ERatingType) => {
    handleChangeFilters({ rating: changeFilter<ERatingType>(filters.rating, checkboxType) });
  };

  const updateDistanceToCenterFilter = ([min, max]: number[]) => {
    handleChangeFilters({ distanceToCenter: { ...filters.distanceToCenter, min, max } });
  };

  const updateIsSelectTravelPolicy = (policy: ITravelPolicy) => {
    handleChangeFilters(({ travelPolicyId: null }));
    setIsSelectPolicy({ id: policy.id, policyName: policy.name, isActive: true });
  };

  const updateTravelPolicy = (policy: ITravelPolicy) => {
    handleChangeFilters(({ travelPolicyId: policy.id }));
    setIsSelectPolicy({ id: policy.id, policyName: policy.name, isActive: false });
  };

  const renderStarsCheckbox = () => FILTER_STARS_KEYS.map((starCount, index) => {
    const selectedStars = stars && stars.includes(starCount);

    return (
      <div className={ styles.row } key={ `star_${index}` }>
        <Checkbox
          key={ starCount }
          value={ !!selectedStars }
          contentClassName={ styles.checkbox_content }
          onChange={ () => updateStarsFilter(starCount.toString()) }
        >
          <Stars
            count={ starCount }
            size={ 24 }
            color='blue1'
            className={ styles.imgs }
          />
        </Checkbox>
      </div>
    );
  });

  const renderRatingCheckbox = () => Object.keys(ERatingType).map((item) => {
    const selectedRating = rating && rating.includes(item as ERatingType);

    return (
      <div className={ styles.row } key={ item }>
        <Checkbox
          value={ !!selectedRating }
          onChange={ () => updateRatingFilter(item as ERatingType) }
          contentClassName={ styles.checkbox_content }
        >
          <Text type='NORMAL_14'>{RATING[item as ERatingType]}</Text>
        </Checkbox>
      </div>
    );
  });

  const renderTypeCheckbox = () => displayFilters?.hotelTypes.map((hotelType, index) => {
    const selectedType = filters.hotelTypes && filters.hotelTypes
      .find((checkobox) => checkobox === hotelType);

    return (
      <div className={ styles.row } key={ index }>
        <Checkbox
          key={ hotelType }
          value={ !!selectedType }
          onChange={ () => updateTypeFilter(hotelType) }
          contentClassName={ styles.checkbox_content }
        >
          <Text type='NORMAL_14'>{ HOTEL_TYPES[hotelType] }</Text>
        </Checkbox>
      </div>
    );
  });

  const renderDistanceFromCenter = () => {
    const label = `${LABELS.DISTANCE_TO_CENTER}, ${LABELS.KM}`;

    return (
      <CollapseWrap label={ label } opened>
        <SliderComponent
          inputType={ EInputTypes.Number }
          min={ displayFilters?.distanceToCenter.min as number }
          max={ displayFilters?.distanceToCenter.max as number }
          start={ distanceToCenter.min }
          end={ distanceToCenter.max }
          onSlide={ updateDistanceToCenterFilter }
          subText={ LABELS.KM }
          validateFn={ (prop: string) => Number(prop) > 100 }
        />
      </CollapseWrap>
    );
  };

  const renderPricePerNight = () => (
    <CollapseWrap label={ `${LABELS.COST_PER_NIGHT}, ${EnumCurrencySymbol[filters.currencyCode]}` } opened>
      <SliderComponent
        min={ displayFilters?.priceForNight.min as number }
        max={ displayFilters?.priceForNight.max as number }
        start={ priceForNight.min }
        end={ priceForNight.max }
        subText={ EnumCurrencySymbol[filters.currencyCode] }
        onSlide={ updatePriceFilter }
      />
    </CollapseWrap>
  );

  const renderRefundability = () => (
    <div className={ styles.filter }>
      <div className={ styles.checkbox_content }>
        <Checkbox
          value={ refundable }
          onChange={ updateCancellationFilter }
        >
          <Text type='NORMAL_14'>
            {LABELS.FREE_CANCELLATION}
          </Text>
        </Checkbox>
      </div>
    </div>
  );

  const renderOnline = () => (
    <div className={ styles.filter }>
      <div className={ styles.checkbox_content }>
        <Checkbox
          value={ onlyOnline }
          onChange={ updateOnlineFilter }
        >
          <Text type='NORMAL_14'>
            { LABELS.ONLY_ONLINE }
          </Text>
        </Checkbox>
      </div>
    </div>
  );

  const renderBreakfast = () => (
    <div className={ styles.filter }>
      <div className={ styles.checkbox_content }>
        <Checkbox
          value={ breakfastEnabled }
          onChange={ updateBreakfastFilter }
        >
          <Text type='NORMAL_14'>
            { LABELS.MEAL_INCLUDED }
          </Text>
        </Checkbox>
      </div>
    </div>
  );

  const renderStars = () => (
    <CollapseWrap label={ LABELS.STARS } opened>
      <div className={ styles.col_container }>
        { renderStarsCheckbox() }
      </div>
    </CollapseWrap>
  );

  const renderRating = () => (
    <CollapseWrap label={ LABELS.RATING } opened>
      <div className={ styles.col_container }>
        { renderRatingCheckbox() }
      </div>
    </CollapseWrap>
  );

  const renderType = () => (
    <CollapseWrap label={ LABELS.TYPE } opened>
      <div className={ styles.col_container }>
        { renderTypeCheckbox() }
      </div>
    </CollapseWrap>
  );

  // const renderAmenitiesCheckbox = () => {
  //   const checkboxName = Object.keys(amenities);
  //
  //   return checkboxName.map((checkbox, index) => (
  //     <div className={ styles.row } key={ index }>
  //       <Checkbox
  //         key={ checkbox }
  //         value={ amenities[checkbox as keyof Amenities] as boolean }
  //         onChange={ (value: boolean) => updateAmenitiesFilter(checkbox, value) }
  //         contentClassName={ styles['checkbox-content'] }
  //       >
  //         <Text type='NORMAL_14'>{ AMENITITESRU[checkbox] }</Text>
  //       </Checkbox>
  //     </div>
  //   ));
  // };

  // const renderAmenities = () => (
  //   <CollapseWrap label={ LABELS.AMENITIES } opened>
  //     <div className={ styles.col_container }>
  //       { renderAmenitiesCheckbox() }
  //     </div>
  //   </CollapseWrap>
  // );

  const renderTravelPolicyFilter = () => {
    if (!listTravelPolicies.length) return null;

    return (
      <TravelPolicyFilter
        selectPolicyId={ filters.travelPolicyId }
        listPolicies={ listTravelPolicies }
        selectPolicy={ selectPolicy }
        setIsSelectPolicy={ updateIsSelectTravelPolicy }
        onChange={ updateTravelPolicy }
      />
    );
  };

  return (
    <div className={ styles.wrap }>
      <FilterPanel>
        { renderTravelPolicyFilter() }
        { renderDistanceFromCenter() }
        { renderPricePerNight() }
        { renderBreakfast() }
        { renderRefundability() }
        { renderOnline() }
        <HotelNameFilter
          hotelName={ filters?.hotelName }
          onChange={ updateHotelName }
        />
        { renderStars() }
        { renderRating() }
        { renderType() }
        {/* { renderAmenities() } */}
      </FilterPanel>
    </div>
  );
};

export { HotelsSearchFilter };
