import React from 'react';
import { Collapse } from 'yw-ui';
import { getText } from '@/i18n';

import { TimeSlider } from '@/app/components/TimeSlider';

import { IFilterTransferDuration } from '@/app/bi/models/airlineTypes.ts';

import styles from '../../index.module.css';

const LABELS = {
  TRANSFER_DURATION: getText('air:result.filters.transferDuration'),
};

interface TransferDurationFilterProps {
  transferDuration: IFilterTransferDuration,
  values: IFilterTransferDuration
  onChange(value: { min: number; max: number }): void,
}
const TransferDurationFilter = ({
  transferDuration: {
    min,
    max,
  },
  values,
  onChange,
}: TransferDurationFilterProps) => {
  if (min !== max) {
    return (
      <Collapse
        opened
        className={ styles.item }
        key={ `flight_duration_${min}_${max}}` }
        label={ LABELS.TRANSFER_DURATION }
      >
        <TimeSlider
          min={ min }
          max={ max }
          from={ values.min }
          to={ values.max }
          onSlide={ onChange }
        />
      </Collapse>
    );
  }

  return null;
};

export { TransferDurationFilter };
