import React from 'react';
import { Text } from 'yw-ui';
import MediaQuery from 'react-responsive';
import { getText } from '@/i18n';

import { Balance } from '@/app/components/Header/components/Balance';
import ROUTES from '../../../../bi/constants/routes.ts';

import { ItemWrap } from '../ItemWrap';

import { useIntercom } from '@/app/hooks/useIntercom.ts';

import styles from './index.module.css';

const LABELS = {
  TRAVEL_HELPER: getText('components:header.items.travelHelper'),
  TRIPS: getText('components:header.items.trips'),
  INVOICES: getText('components:header.items.invoices'),
  SETTINGS: getText('components:header.items.settings'),
};

const LeftHeaderItems = () => {
  const { showIntercom } = useIntercom();
  const renderTrip = () => {
    const label = (
      <MediaQuery minWidth={ 750 }>{LABELS.TRIPS}</MediaQuery>
    );

    return (
      <div className={ styles.item }>
        <ItemWrap
          subItem
          href={ ROUTES.TRIPS.MAIN }
          getIcon={ () => 'pen' }
          label={ label }
          className={ styles.note_link }
        />
      </div>
    );
  };

  const renderInvoices = () => {
    const label = (
      <MediaQuery minWidth={ 750 }>{LABELS.INVOICES}</MediaQuery>
    );

    return (
      <div className={ styles.item }>
        <ItemWrap
          subItem
          href={ ROUTES.REPORTS.MAIN }
          getIcon={ () => 'invoice' }
          label={ label }
          className={ styles.note_link }
        />
      </div>
    );
  };

  const handleOpenTravelChat = () => showIntercom();
  const renderMenu = (
    <>
      <div className={ styles.item }>
        <Balance />
      </div>
      <MediaQuery minWidth={ 820 }>
        <div className={ `${styles.item}` }>
          <div onClick={ handleOpenTravelChat }>
            <Text className={ styles.travel_helper } type='NORMAL_14'>
              {LABELS.TRAVEL_HELPER}
            </Text>
          </div>
          {/* {intercomWarning} */}
        </div>
      </MediaQuery>
      {renderTrip()}
      {renderInvoices()}
      <div className={ styles.item }>
        <ItemWrap
          subItem
          href={ ROUTES.SETTINGS.EMPLOYEES }
          getIcon={ () => 'setup' }
          label={ LABELS.SETTINGS }
        />
      </div>
    </>
  );

  return (
    <nav className={ styles.wrapper }>
      {renderMenu}
    </nav>
  );
};

export { LeftHeaderItems };
