import React, { useState } from 'react';
import { Button, Dialog, IconButton, RadioButton, Suggest, Text } from 'yw-ui';

import { getText } from '@/i18n';

import { FieldLabel } from '@/app/components/FieldLabel';

import { INIT_COUNTRIES_HOTEL, INIT_TRAVEL_POLICIES } from '@/app/bi/constants/travelPolicy.ts';

import {
  ERestrictionActionHotel,
  IRestriction,
} from '@/app/bi/models/travelPolicies.ts';

import styles from './styles/index.module.css';

interface DialogHotelProps {
  isShow: boolean;
  onCloseDialog(): void;
}

const LABELS = {
  ADD: getText('common:add'),
  ONLY_TURKEY: getText('settings:travelPolicies.policy.hotel.name'),
  PURCHASE_WITHOUT_APPROVAL: getText('settings:travelPolicies.purchaseWithoutApprovalHostel'),
  SAVE: getText('common:save'),
  UNDO: getText('common:undo'),
  RESTRICTION_DIRECTIONS: getText('settings:travelPolicies.restrictionDirections'),
  ONLY_SPECIFIED_CITIES: getText('settings:travelPolicies.onlySpecifiedCities'),
  FROM: getText('settings:travelPolicies.from'),
  WRITE_CITY_PLACEHOLDER: getText('settings:travelPolicies.writeCityPlaceholder'),
  CITY: getText('settings:travelPolicies.city'),
};

export const DialogHotel = ({
  isShow,
  onCloseDialog,
}: DialogHotelProps) => {
  const [
    restrictionOnDirection,
    setRestrictionOnDirection,
  ] = useState(INIT_TRAVEL_POLICIES.hotel.restrictionOnDirections);

  const {
    onlySpecifiedCities,
    selectCountry,
    cities,
  } = restrictionOnDirection;

  const handleAddRoute = () => {
    setRestrictionOnDirection({
      ...restrictionOnDirection,
      cities: [...cities, INIT_COUNTRIES_HOTEL],
    });
  };

  const handleDeleteCity = (indexRoute: number) => {
    const newRoutes = cities.filter((
      _,
      index,
    ) => index !== indexRoute);

    setRestrictionOnDirection({
      ...restrictionOnDirection,
      cities: newRoutes,
    });
  };

  const handleRestrictionChange = (action: ERestrictionActionHotel) => {
    setRestrictionOnDirection(({
      ...restrictionOnDirection,
      selectCountry: action === ERestrictionActionHotel.SelectCountry ? true : !selectCountry,
      onlySpecifiedCities: action === ERestrictionActionHotel.OnlySpecifiedCities ? true : !onlySpecifiedCities,
    }));
  };

  const handleOnlyTurkey = () => handleRestrictionChange(ERestrictionActionHotel.SelectCountry);

  const handleOnlySpecifiedDestinations = () => handleRestrictionChange(
    ERestrictionActionHotel.OnlySpecifiedCities,
  );

  const handleChange = () => {

  };

  const handleSelect = () => {

  };

  const renderItemCity = ({ name }: IRestriction, index: number) => (
    <div key={ `routes_${index}` } className={ styles.container_cities }>
      <div className={ styles.item_city }>
        <FieldLabel text={ LABELS.CITY } isRequired/>
        <Suggest
          preventTab={ false }
          theme='border'
          withLabel={ false }
          value={ name }
          items={ [
            {
              value: 1,
              label: 'Станбул',
            },
          ] }
          onChange={ handleChange }
          onSelect={ handleSelect }
          placeholder={ LABELS.WRITE_CITY_PLACEHOLDER }
        />
      </div>
      {cities.length > 1 && (
        <div className={ styles.button_close }>
          <IconButton iconType='closeOff' size={ 16 } onClick={ () => handleDeleteCity(index) } />
        </div>
      )}
    </div>
  );

  const renderContent = () => onlySpecifiedCities && (
    <div className={ styles.cities }>
      {cities?.map((city, index) => (
        renderItemCity(city, index)
      ))}
      <IconButton
        iconType='add'
        iconColor='blue1'
        disabled={ cities.length >= 10 }
        onClick={ handleAddRoute }
      >
        {LABELS.ADD}
      </IconButton>
    </div>
  );

  return (
    <Dialog
      className={ styles.dialog }
      showClosing
      show={ isShow }
      onChange={ onCloseDialog }
    >
      <Text type='bold_20'>{LABELS.RESTRICTION_DIRECTIONS}</Text>
      <Text type='NORMAL_16'>
        {LABELS.PURCHASE_WITHOUT_APPROVAL}
      </Text>
      <div className={ styles.content_container } >
        <RadioButton checked={ selectCountry } onChange={ handleOnlyTurkey }>
          {LABELS.ONLY_TURKEY}
        </RadioButton>
        <RadioButton checked={ onlySpecifiedCities } onChange={ handleOnlySpecifiedDestinations }>
          {LABELS.ONLY_SPECIFIED_CITIES}
        </RadioButton>
      </div>
      {renderContent()}
      <div className={ styles.buttons_interactive }>
        <Button type='secondary'>
          {LABELS.SAVE}
        </Button>
        <Button type='text' onClick={ onCloseDialog }>
          {LABELS.UNDO}
        </Button>
      </div>
    </Dialog>
  );
};
