import React, { ReactNode, useState } from 'react';
import { IconType, Icon, Text } from 'yw-ui';

import styles from './index.module.css';

interface CollapseWrapProps {
  children: ReactNode;
  label?: string;
  opened?: boolean;
}
const CollapseWrap = ({
  opened = true,
  children,
  label = '',
}: CollapseWrapProps) => {
  const [open, setOpen] = useState<boolean>(opened);

  const iconHtml = () => {
    const arrowUp = 'caretUp';
    const arrowDown = 'caretDown';
    const iconType: IconType = open ? arrowUp : arrowDown;
    const iconColor = open ? 'blue1' : 'black';

    return <Icon type={ iconType } color={ iconColor } />;

  };

  const showInfo = () => {
    const actualOpen = !open;
    setOpen(actualOpen);

    // const preparedStyles = {
    //   height: actualOpen ? `${moreOpen ? 'auto' : `${height}px`}` : '0px',
    //   overflow: 'hidden',
    //   minHeight: actualOpen ? 'min-content' : '0px',
    // };
    //
    // setStyles(preparedStyles);
    //
    // setTimeout(() => {
    //   setStyles({ ...preparedStyles, overflow: actualOpen ? 'initial' : 'hidden' });
    // }, 250);
  };

  const renderChildren = () => {
    if (!open) {
      return null;
    }

    return (
      <div className={ styles.wrapper_children }>
        { children }
      </div>
    );
  };

  return (
    <div className={ styles.wrapper } >
      <div className={ styles.label } onClick={ showInfo }>
        <Text type='SEMIBOLD_14' color='blue-1' className={ styles.text }>
          { label }
        </Text>
        {iconHtml()}
      </div>
      { renderChildren() }
    </div>
  );
};

export { CollapseWrap };
