const AMOUNT = 2;
const CHARACTER = '0';

export const lpad = (n: number, m = AMOUNT, c = CHARACTER) => {
  let temp = `${n}`;
  let newM = m - temp.length;

  while (newM-- > 0) temp = c + temp;

  return temp;
};
