import { ywApiPrivate } from './index.ts';

import { ITripPreview } from '@/app/bi/models/tripsTypes.ts';

export const homeApi = ywApiPrivate.injectEndpoints({
  endpoints: (build) => ({
    getLatestEditTrips: build.query<ITripPreview[], void>({
      query: () => ({
        url: '/trips/latest',
        method: 'GET',
      }),
    }),
  }),
});

export const { useGetLatestEditTripsQuery } = homeApi;
