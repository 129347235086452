import { Dayjs } from 'dayjs';
import { getText } from '@/i18n';

import { dayjsObjectUTC, formatDate, isSameOrBefore } from '@/app/bi/utils/formatDate.ts';

import { PASSPORTS } from '@/app/bi/constants/passport.ts';
import { PATTERN } from '@/app/bi/constants/dateFormats.ts';
import { DEFAULT_DATE_STATE, SERVICES_NAME_ARRAY, STATUS_NAME_ARRAY } from '@/app/bi/constants/trips.ts';

import { EFilterItemType, ITripDates, ITripsFilter } from '@/app/bi/models/tripsTypes.ts';
import { ITagFilter } from '@/app/bi/models/shared.ts';
import { EEmployeeDocumentType, IEmployeeUniversal } from '@/app/bi/models/employees.ts';
import { IAirlineEmployees } from '@/app/bi/models/airline.ts';

const LABELS = {
  MINE_CHECKBOX: getText('trips:mineCheckbox'),
};

interface IEmployeeName {
  firstName: string,
  lastName: string
}

export const isEmptyTripsScreen = ({
  createdOnlyByMe,
  employees,
  serviceTypes,
  tripStatuses,
  cities,
}: ITripsFilter): boolean => (
  !createdOnlyByMe &&
  !employees.length &&
  !serviceTypes.length &&
  !tripStatuses.length &&
  !cities.length
);

export const formationObjectFilterEmployee = ({
  firstName,
  lastName,
}: IEmployeeName) => {
  const firstInitial = firstName.charAt(0) || null;

  return `${lastName} ${firstInitial}.`;
};

export const updateFilterStatus = (
  {
    employees,
    createdOnlyByMe,
    serviceTypes,
    tripStatuses,
    tripDates,
    cities,
  }: ITripsFilter,
  citiesValues: string[],
) => {
  const newFilterStatus: ITagFilter[] = [];

  if (citiesValues.length) {
    cities.forEach((city) => {
      newFilterStatus.push({
        typeFilter: EFilterItemType.TripCities,
        value: city,
        label: city,
      });
    });
  }

  if (employees.length) {
    employees.forEach(({
      id,
      actualVersion: {
        firstName,
        lastName,
      },
    }) => {
      newFilterStatus.push({
        typeFilter: EFilterItemType.TripEmployees,
        value: id,
        label: formationObjectFilterEmployee({
          firstName,
          lastName,
        }),
      });
    });
  }

  if (createdOnlyByMe) {
    newFilterStatus.push({
      typeFilter: EFilterItemType.CreatedOnlyByMe,
      value: EFilterItemType.CreatedOnlyByMe,
      label: LABELS.MINE_CHECKBOX,
    });
  }

  if (tripStatuses) {
    tripStatuses.forEach((status) => {
      const statusName = STATUS_NAME_ARRAY[status];

      if (statusName) {
        newFilterStatus.push({
          typeFilter: EFilterItemType.TripStatus,
          value: status,
          label: statusName,
        });
      }
    });
  }

  if (serviceTypes) {
    serviceTypes.forEach((service) => {
      const serviceName = SERVICES_NAME_ARRAY[service];

      if (serviceName) {
        newFilterStatus.push({
          typeFilter: EFilterItemType.TripServices,
          value: service,
          label: serviceName,
        });
      }
    });
  }

  if (tripDates) {
    const formatMinDate = formatDate(tripDates.min, PATTERN.DEFAULT_PATTERN_FILTER);
    const formatMaxDate = formatDate(tripDates.max, PATTERN.DEFAULT_PATTERN_FILTER);

    const filterName = `${formatMinDate} — ${formatMaxDate}`;

    newFilterStatus.push({
      typeFilter: EFilterItemType.TripDates,
      value: filterName,
      label: filterName,
    });
  }

  return newFilterStatus;
};

export const deleteFilterValues = (
  {
    typeFilter,
    value,
  }: ITagFilter,
  filterValues: ITripsFilter,
) => {
  switch (typeFilter) {
    case EFilterItemType.TripCities:
      return {
        ...filterValues,
        cities: filterValues.cities.filter(status => value !== status),
      };
    case EFilterItemType.TripEmployees:
      return {
        ...filterValues,
        employees: filterValues.employees.filter(status => value !== status.id),
      };
    case EFilterItemType.CreatedOnlyByMe:
      return {
        ...filterValues,
        createdOnlyByMe: !filterValues.createdOnlyByMe,
      };
    case EFilterItemType.TripServices:
      return {
        ...filterValues,
        serviceTypes: filterValues.serviceTypes.filter(status => status !== value),
      };
    case EFilterItemType.TripStatus:
      return {
        ...filterValues,
        tripStatuses: filterValues.tripStatuses.filter(status => status !== value),
      };
    case EFilterItemType.TripDates:
      return {
        ...filterValues,
        tripDates: DEFAULT_DATE_STATE,
      };
    default:
      return filterValues;
  }
};

export const deleteFilterStatus = ({ typeFilter, value }: ITagFilter, filterStatus: ITagFilter[]): ITagFilter[] => filterStatus.filter(
  item => !(item.typeFilter === typeFilter && item.value === value),
);

export const sortedFilterStatus = (statusList: ITagFilter[]) => statusList.sort((
  currentItem,
  nextItem,
) => {
  if (currentItem.typeFilter === EFilterItemType.TripDates) return -1;
  if (nextItem.typeFilter === EFilterItemType.TripDates) return 1;

  return 0;
});

export const getDateRange = ({ min, max }: ITripDates) => ({
  max: dayjsObjectUTC(max).endOf('day'),
  min: dayjsObjectUTC(min).startOf('day'),
});

export const sortedListCities = (autocompleteCitiesList: string[], citiesList: string[]) => [...citiesList].sort((a, b) => {
  const aSelected = autocompleteCitiesList.includes(a) ? -1 : 0;
  const bSelected = autocompleteCitiesList.includes(b) ? -1 : 0;

  return aSelected - bSelected;
});

export const formattedDateFromTo = (from: Dayjs, to: Dayjs) => {
  const isDatesMatch = isSameOrBefore(from, to);

  if (isDatesMatch) return formatDate(from, PATTERN.DATE_WITH_WEEK_DAY);

  return `${formatDate(from)} - ${formatDate(to)}`;
};

export const getPassportValue = (type: EEmployeeDocumentType) => {
  switch (type) {
    case EEmployeeDocumentType.IdCard: {
      return PASSPORTS.ID_CARD.VALUE;
    }

    default: {
      return PASSPORTS.PASSPORT.VALUE;
    }
  }
};

export const transformEmployeesUniversal = (
  arrayEmployee: IAirlineEmployees[],
): IEmployeeUniversal[] => arrayEmployee.map(({
  employeeDocumentVersion: {
    id,
    firstName,
    lastName,
    type,
    number,
  },
}) => ({
  id,
  lastName,
  firstName,
  number,
  type,
  company: null,
}));
