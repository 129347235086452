import { getText } from '@/i18n';
import { VALUE_TICKET } from '../../bi/constants/airline.ts';

const LABELS = {
  included: getText('components:fareDetails.included'),
  notIncluded: getText('components:fareDetails.notIncluded'),
  includedWithPlaces: (places: string) => getText('components:fareDetails.includedWithPlaces', { places }),
  charge: getText('components:fareDetails.charge'),
  notAvailable: getText('components:fareDetails.notAvailable'),
  withoutTaxes: getText('components:fareDetails.withoutTaxes'),
  noPenaltyCompany: getText('components:fareDetails.noPenaltyCompany'),
  withTax: getText('components:fareDetails.withTax'),
  nonReturnRate: getText('components:fareDetails.nonReturnRate'),
  restricted: getText('components:fareDetails.restricted'),
  restrict: getText('components:fareDetails.restrict'),
  allowed: getText('components:fareDetails.allowed'),
};

const CARRYON_PLACES_DEFAULT_VALUE = 'включена';

const prepareBaggage = (value: string, places: string | null) => {
  switch (value) {
    case VALUE_TICKET.INCLUDED:
      return places ? LABELS.includedWithPlaces(places) : LABELS.included;
    case VALUE_TICKET.NOT_DEFINED:
      return '';
    default:
      return LABELS.notIncluded;
  }
};

const prepareCarryOn = (value: string, places: string | null) => {
  switch (value) {
    case VALUE_TICKET.INCLUDED:
      return places || CARRYON_PLACES_DEFAULT_VALUE;
    case VALUE_TICKET.CHARGE:
      return LABELS.charge;
    default:
      return '';
  }
};

const prepareRefundable = (value: string) => {
  switch (value) {
    case VALUE_TICKET.NOT_OFFERED:
      return LABELS.notAvailable;
    case VALUE_TICKET.INCLUDED:
      return LABELS.withoutTaxes;
    case VALUE_TICKET.CHARGE:
      return LABELS.withTax;
    case VALUE_TICKET.NOT_DEFINED:
      return LABELS.nonReturnRate;
    case VALUE_TICKET.NOT_PENALTY:
      return LABELS.noPenaltyCompany;
    default:
      return '';
  }
};

const prepareChangeable = (value: string) => {
  switch (value) {
    case VALUE_TICKET.NOT_OFFERED:
      return LABELS.restricted;
    case VALUE_TICKET.INCLUDED:
      return LABELS.withoutTaxes;
    case VALUE_TICKET.CHARGE:
      return LABELS.withTax;
    case VALUE_TICKET.NOT_PENALTY:
      return LABELS.noPenaltyCompany;
    default:
      return '';
  }
};

const prepareRefundableAfterDeparture = (value: string) => {
  switch (value) {
    case VALUE_TICKET.INCLUDED:
      return LABELS.allowed;
    case VALUE_TICKET.NOT_OFFERED:
      return LABELS.restrict;
    case VALUE_TICKET.NOT_PENALTY:
      return LABELS.noPenaltyCompany;
    case VALUE_TICKET.CHARGE:
      return LABELS.withTax;
    default:
      return '';
  }
};

const prepareChangeableAfterDeparture = (value: string) => {
  switch (value) {
    case VALUE_TICKET.INCLUDED:
      return LABELS.withoutTaxes;
    case VALUE_TICKET.CHARGE:
      return LABELS.withTax;
    case VALUE_TICKET.NOT_OFFERED:
      return LABELS.restricted;
    case VALUE_TICKET.NOT_PENALTY:
      return LABELS.noPenaltyCompany;
    default:
      return '';
  }
};

export {
  prepareBaggage,
  prepareCarryOn,
  prepareRefundable,
  prepareChangeable,
  prepareRefundableAfterDeparture,
  prepareChangeableAfterDeparture,
};
