import { IDownloadFileParams } from '@/app/bi/models/documents.ts';

const downloadFile = async ({
  fileBlob,
  fileType,
  filenamePrefix,
  identifier = '',
}: IDownloadFileParams) => {
  const fileExtension = fileType.toLowerCase();
  const filename = `${filenamePrefix} - #${identifier}.${fileExtension}`;

  const url = window.URL.createObjectURL(fileBlob);
  const link = document.createElement('a');

  link.href = url;
  link.setAttribute('download', filename);

  document.body.appendChild(link);
  link.click();

  link.remove();
  window.URL.revokeObjectURL(url);
};

export { downloadFile };
