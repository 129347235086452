import { Dayjs } from 'dayjs';
import { IEmployeeDocumentVersion, IEmployeeVersion } from '@/app/bi/models/employees.ts';
import { EServicePlatform, IOperationDetails } from '@/app/bi/models/tripsTypes.ts';

export enum EPositionThumbnails {
  Bottom = 'bottom',
  Left = 'left',
}

enum EIHotelProvider {
  Expedia = 'Expedia',
}

export interface SearchSettings {
  checkin?: Dayjs;
  checkout?: Dayjs;
  customCheckin: Dayjs | null;
  customCheckout: Dayjs | null;
  daysCount: number;
}

interface IHotelCancellationPenalty {
  id: string;
  hotelVersionId: string;
  from: string;
  base: number;
  total: number;
  readOnly: boolean;
  additional: boolean;
}

export enum EHotelVersionStatus {
  Carted = 'Carted',
  AwaitingBooking = 'AwaitingBooking',
  Booked = 'Booked',
  Canceled = 'Canceled',
}

export interface IHotelEmployee {
  employeeDocumentVersionId: IEmployeeDocumentVersion;
  employeeVersion: IEmployeeVersion;
}

export interface IHotelVersion {
  id: string;
  hotelTripItemId: string;
  employeeVersions: IHotelEmployee[];
  provider: EIHotelProvider;
  createdDate: string;
  creatorUserId: string;
  servicePlatform: EServicePlatform;
  companyId: string;
  checkinDate: string;
  checkoutDate: string;
  rateId: string
  guestCount: number;
  breakfast: boolean;
  breakfastName: string;
  hotelStars: number;
  hotelName: string;
  hotelAddress: string;
  hotelCity: string;
  hotelPhone: string;
  hotelEmail: string;
  hotelCheckinTime: string;
  hotelCheckoutTime: string;
  hotelCountryCode: string;
  hotelClassificatorId: string;
  hotelType: string;
  travelPolicyReason: string | null;
  roomName: string;
  roomFreeCancellation: string;
  roomAmenities: string[];
  hotelCancellationPenalties: IHotelCancellationPenalty[];
  status: EHotelVersionStatus;
  cartPrice: number;
  totalPrice: number;
  readOnly: true
  operationDetails: IOperationDetails;
}
