import React from 'react';
import { Price, Stars } from 'yw-ui';

import ColumnLayout from '../../Layout/ColumnLayout';

import { formattedDateFromTo } from '@/app/bi/utils/trip.ts';
import { getEmployeeFullName } from '@/app/bi/utils/employees.ts';
import { getSearchDatesDiff } from '@/app/bi/utils/hotels.ts';
import { dayjsObject } from '@/app/bi/utils/formatDate.ts';

import { IHotelTripItems } from '@/app/bi/models/tripsTypes.ts';

import styles from './index.module.css';

interface CardHotelProps {
  data: IHotelTripItems
}

const CardHotel = ({ data }: CardHotelProps) => {
  const {
    checkinDate,
    checkoutDate,
    actualVersion: {
      hotelName,
      hotelStars,
      hotelAddress,
      employeeVersions,
      roomName,
      roomAmenities,
      cartPrice,
    },
  } = data;

  const dateFromAndTo = formattedDateFromTo(checkinDate, checkoutDate);
  const countDaysLiving = getSearchDatesDiff(dayjsObject(checkinDate), dayjsObject(checkoutDate));

  const renderPersonInfo = () => (employeeVersions?.map((
    person,
    index,
  ) => {
    const {
      id,
      firstName,
      lastName,
    } = person.employeeVersion;

    const fullName = getEmployeeFullName({
      firstName,
      lastName,
    });

    return (
      <li key={ id }>
        <span className={ styles.passengerName }>
          {`${index + 1}. ${fullName}`}
        </span>
      </li>
    );
  })
  );

  return (
    <div className={ styles.card }>
      {hotelStars > 0 && (
        <div className={ styles.stars_container }>
          <Stars count={ hotelStars } />
        </div>
      )}
      <div className={ styles.tickedInfoContainer }>
        <div className={ styles.travelContainer }>
          <div className={ styles.travelInfo }>
            <h2 className={ styles.travelTime }>
              {hotelName}
            </h2>
            <span className={ styles.infoTrip }>
              {countDaysLiving}, {roomName}
            </span>
          </div>
          <Price value={ cartPrice } color='gray-7' type='bold_18' />
        </div>
        <div className={ styles.routeInfo }>
          <span>
            {hotelAddress}
          </span>
          <span>
            {dateFromAndTo}
          </span>
        </div>
        <ColumnLayout items={ roomAmenities }/>
      </div>
      <div className={ styles.divider }/>
      <ul className={ styles.passengers }>
        {renderPersonInfo()}
      </ul>
    </div>
  );
};

export { CardHotel };
