import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Suggest } from 'yw-ui';

import clsx from 'clsx';
import { getText } from '@/i18n';

import { EmployeeCloneItem } from '@/app/components/EmployeeCloneItem';

import { debounce } from '@/app/bi/utils/debounce.ts';

import { THROTTLE_DELAY_AUTOCOMPLETE } from '@/app/bi/constants/airlineSearch.ts';

import { IEmployeeObj, IEmployeeVersion } from '@/app/bi/models/employees.ts';

import { useEmployeesAutocompleteQuery } from '@/app/bi/api/tripApi.ts';

import { ICartResponseEmployee } from '@/app/bi/models/cart.ts';

import styles from './index.module.css';

const LABELS = {
  EMPLOYEE: getText('components:employeeSuggest.employee'),
  EMPLOYEE_UPPER_CASE: getText('components:employeeSuggest.selectEmployee'),
};

const DEBOUNCE_MS = 200;

const getSuggestionValue = ({ firstName, lastName }: IEmployeeVersion) => `${lastName} ${firstName}`;

interface EmployeeSuggestProps {
  item: string | any,
  items: ICartResponseEmployee[];
  isError?: boolean;
  debounceMs?: number,
  userLoading?: boolean,
  onSelect(item: IEmployeeObj): void,
}

const EmployeeSuggest = ({
  item = {},
  items,
  onSelect,
  isError = false,
  debounceMs = DEBOUNCE_MS,
  userLoading = false,
}: EmployeeSuggestProps) => {
  const [query, setQuery] = useState<string | null>(item?.id
    ? getSuggestionValue(item)
    : null,
  );
  const initiated = useRef(false);

  const { data: list = [], isFetching } = useEmployeesAutocompleteQuery(query as string, {
    skip: query === null,
    selectFromResult: (res) => ({
      ...res,
      data: res.data?.filter(({ actualVersion: { employeeId } }) => !items
        .some((employee) => employeeId === employee.id)),
    }),
  });

  useEffect(() => {
    if (!initiated.current) {
      initiated.current = true;
    }
  }, []);

  const handleInputChange = useCallback(
    debounce(setQuery, THROTTLE_DELAY_AUTOCOMPLETE),
    [query],
  );
  const handleSelect = (suggestion: IEmployeeObj) => {
    if (!suggestion) {
      return null;
    }

    setQuery(getSuggestionValue(suggestion.actualVersion));

    return onSelect(suggestion);
  };

  const renderSuggestion = ({ actualVersion }: IEmployeeObj) => (
    <EmployeeCloneItem
      value={ actualVersion }
      highlitedWord={ query || '' }
      className={ styles.suggestion }
    />
  );

  const inputClassName = clsx({ [styles.suggest]: isError });

  return (
    <div className={ styles.wrapper }>
      <Suggest
        placeholder={ LABELS.EMPLOYEE_UPPER_CASE }
        inputClassName={ inputClassName }
        theme='border'
        value={ query || '' }
        // @ts-ignore
        items={ list }
        onChange={ handleInputChange }
        // @ts-ignore
        renderItem={ renderSuggestion }
        // @ts-ignore
        onSelect={ handleSelect }
        loading={ userLoading }
        withLabel={ false }
        // keyExtractor={ ({ id }) => id || Math.random() * Math.random() }
        shouldFocusItem={ () => item && item.length }
        debounceMs={ debounceMs }
        isFetching={ isFetching }
        buttonTheme='default'
      />
    </div>
  );
};

export { EmployeeSuggest };
