import { Dayjs } from 'dayjs';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { BackLink, Button, IconButton, PageLoader, Text } from 'yw-ui';
import { SuggestItem } from 'yw-ui/components/Suggest/types';

import { currentLng, getText } from '@/i18n';

import {
  useAddEmployeeMutation,
  useLazyGetCitizenshipQuery,
  useLazyGetEmployeeByIdQuery,
  useUpdateEmployeeMutation,
} from '@/app/bi/api/employeeApi.ts';
import { employeeSlice } from '@/app/store/redusers/EmployeeSlice.ts';
import { useAppDispatch, useAppSelector } from '@/app/store/hooks/redux.ts';
import { useGetTravelPoliciesQuery } from '@/app/bi/api/travelPoliciesApi.ts';

import { Document } from '@/app/pages/Employee/components/Document';
import { GeneralStep } from '@/app/pages/Employee/components/GeneralStep';
import { FormContainer } from '@/app/pages/Employee/components/FormContainer';
import { TravelPolicyBlock } from '@/app/pages/Employee/components/TravelPolicyBlock';

import { notification } from '@/app/bi/utils/not.ts';
import { getEmployeeFullName } from '@/app/bi/utils/employees.ts';
import {
  formationDocumentsAddedVersion,
  formationDocumentsUpdateVersion,
  isDocumentsFilled,
  isValidIdCard,
  mapCompaniesToData,
  mapTravelPoliciesToData,
  transformationAddedVersion,
  transformationDocuments,
  transformationUpdateVersion,
} from '@/app/bi/utils/employee.ts';
import { getKeepLocalDate } from '@/app/bi/utils/formatDate.ts';

import ROUTES from '@/app/bi/constants/routes.ts';
import {
  ENotificationActionType,
  MAX_COUNT_DOCUMENT,
  TURKEY_LOCALE_CODE,
} from '@/app/bi/constants/settings.ts';

import { EEmployeeDocumentStatus, EEmployeeDocumentType } from '@/app/bi/models/employees.ts';
import { IAddEmployeeRequest, IEmployeePolicyItem } from '@/app/bi/models/employee.ts';
import { ICitizenshipItem } from '@/app/bi/models/profile.ts';

import styles from './styles/index.module.css';

const LABELS = {
  DOCUMENTS: getText('settings:travelers.documents'),
  PASSWORD: getText('settings:trPassport'),
  INTERNATIONAL_PASSPORT: getText('settings:internationalPassport'),
  ID_CARD: getText('settings:idCard'),
  PASSPORT_FOREIGN_CITIZEN: getText('settings:passportForeignCitizen'),
  EMPLOYEES: getText('settings:dynamicPages.employees'),
  PERSONAL_DATA: getText('settings:personalData'),
  GENERAL_DATA: getText('settings:generalData'),
  CANCEL: getText('settings:companiesData.cancel'),
  ADD_TO_PASSWORD: getText('settings:addToPassword'),
  ADD_EMPLOYEES_PROCESS: getText('settings:addEmployeesProcess'),
  TURKEY: getText('settings:turkey'),
  SAVE: getText('settings:companiesData.save'),
  CARD_EMPLOYEE: {
    SUCCESS_CREATE: {
      TITLE: getText('settings:cardEmployee.successCreate.title'),
      MESSAGE: (fullName: string) => getText(
        'settings:cardEmployee.successCreate.message', { fullName }),
    },
    SUCCESS_UPDATE: {
      TITLE: getText('settings:cardEmployee.successUpdate.title'),
      MESSAGE: (fullName: string) => getText(
        'settings:cardEmployee.successUpdate.message', { fullName }),
    },
    ERROR_NOT_CREATE: {
      TITLE: getText('settings:cardEmployee.errorNotCreate.title'),
      MESSAGE: (fullName: string) => getText(
        'settings:cardEmployee.errorNotCreate.message', { fullName }),
    },
    ERROR_NOT_SAVE: {
      TITLE: getText('settings:cardEmployee.errorNotSave.title'),
      MESSAGE: (fullName: string) => getText(
        'settings:cardEmployee.errorNotCreate.message', { fullName }),
    },
  },
};

function usePrevious<T>(value: T): T | undefined {
  const ref = useRef<T>();

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
}

export const Employee = () => {
  const navigate = useNavigate();
  const { employeeId = '' } = useParams();
  const dispatch = useAppDispatch();

  const isCreate = !employeeId;

  const { EMPLOYEES } = ROUTES.SETTINGS;

  const {
    setEmployeeData,
    setInitialState,
    setOrganization,
    setCitizenship,
    deletePassport,
    addPassport,
    clearDataIdCard,
    clearDocuments,
    setTravelPolicy,
  } = employeeSlice.actions;

  const {
    generalData,
    idCard,
    idCardArchive,
    documents = [],
  } = useAppSelector((state) => state.employeeSlice);

  const {
    account: {
      companies: companiesApp,
    },
  } = useAppSelector((state) => state.appSlice);

  const {
    lastName,
    firstName,
    sex,
    birthday,
    citizenship,
    countryCode,
    contactData: {
      email,
      phoneNumber,
      organization,
    },
    travelPolicyId,
  } = generalData;
  const previousCitizenship = usePrevious(citizenship);
  const isTurkeyCountry = citizenship.code3 === TURKEY_LOCALE_CODE;
  const mainDocumentType = isTurkeyCountry ? EEmployeeDocumentType.IdCard : EEmployeeDocumentType.DomesticPassport;
  const mainDocumentTitle = isTurkeyCountry ? LABELS.ID_CARD : LABELS.PASSPORT_FOREIGN_CITIZEN;

  const [
    getEmployeeById, {
      isLoading: loadingGetEmployee,
      isSuccess: isSuccessLoadingGetEmployee,
    },
  ] = useLazyGetEmployeeByIdQuery();
  const [
    getCitizenship, {
      isLoading: loadingCitizenship,
      isSuccess: isSuccessLoadingCitizenship,
    },
  ] = useLazyGetCitizenshipQuery();
  const {
    data: listTravelPolicies = [],
  } = useGetTravelPoliciesQuery();
  const [addEmployee] = useAddEmployeeMutation();
  const [updateEmployee] = useUpdateEmployeeMutation();

  const [
    companies,
    setCompanies,
  ] = useState<SuggestItem[]>([]);
  const [
    citizenshipData,
    setCitizenshipData,
  ] = useState<ICitizenshipItem[]>([]);
  const [
    travelPolicies,
    setTravelPolicies,
  ] = useState<IEmployeePolicyItem[]>([]);

  const getFullNameEmployee = getEmployeeFullName({
    firstName,
    lastName,
  });

  const formationPageName = !employeeId ? LABELS.ADD_EMPLOYEES_PROCESS : getEmployeeFullName({
    firstName,
    lastName,
  });

  useEffect(() => {
    if (companiesApp.length) {
      const arrayTransformation = mapCompaniesToData(companiesApp);

      setCompanies(arrayTransformation);
    }
  }, [companiesApp]);

  useEffect(() => {
    if (listTravelPolicies.length) {
      const arrayTransformation = mapTravelPoliciesToData(listTravelPolicies);

      arrayTransformation.forEach((policy) => {
        if (policy.value === travelPolicyId.value) {
          dispatch(setTravelPolicy(policy));
        }
      });

      setTravelPolicies(arrayTransformation);
    }
  }, [listTravelPolicies, travelPolicyId.value]);

  const getEmployeeData = async (id: string) => {
    try {
      const employees = await getEmployeeById(id).unwrap();

      dispatch(setEmployeeData(employees));
      dispatch(setOrganization(employees.companies.map(({ id: companyId }) => companyId)));
    } catch (e) {
      dispatch(setInitialState());
    }
  };

  const getCitizenshipData = async () => {
    try {
      const result = await getCitizenship(currentLng).unwrap();

      setCitizenshipData(result);
    } catch (e) {
      setCitizenshipData([]);
    }
  };

  useEffect(() => {
    if (isCreate) {
      dispatch(setCitizenship({
        name: LABELS.TURKEY,
        code: 'TR',
        code3: TURKEY_LOCALE_CODE,
      }));
    }

    if (!isCreate) {
      getEmployeeData(employeeId);
    }

    getCitizenshipData();

    return () => { dispatch(setInitialState()); };
  }, []);

  useEffect(() => {
    if (countryCode) {
      citizenshipData.forEach((item) => {
        if (item.code.toLowerCase() === countryCode.toLowerCase()) {
          dispatch(setCitizenship(item));
        }
      });
    }
  }, [countryCode, dispatch, citizenshipData]);

  useEffect(() => {
    if (previousCitizenship?.code3.length && previousCitizenship.code3 !== citizenship.code3) {
      dispatch(clearDataIdCard());
      dispatch(clearDocuments());
    }
  }, [citizenship, previousCitizenship]);

  if ((loadingGetEmployee && !isSuccessLoadingGetEmployee) || (loadingCitizenship && !isSuccessLoadingCitizenship)) {
    return <PageLoader />;
  }

  const handleAddEmployee = async () => {
    const formationDocumentsVersions = isValidIdCard(idCard) ?
      [...documents, idCard] : documents;

    const requestData: IAddEmployeeRequest = {
      ...generalData,
      travelPolicyId: travelPolicyId.value,
      birthday: getKeepLocalDate(birthday as Dayjs),
      countryCode: citizenship.code,
      status: EEmployeeDocumentStatus.Active,
      documentVersions: transformationDocuments(formationDocumentsVersions, citizenship.code),
      companyIds: organization,
      email,
      phoneNumber,
    };

    try {
      await addEmployee(requestData).unwrap();

      notification({
        title: LABELS.CARD_EMPLOYEE.SUCCESS_CREATE.TITLE,
        content: (
          <Text type='NORMAL_16' color='gray-7'>
            {LABELS.CARD_EMPLOYEE.SUCCESS_CREATE.MESSAGE(getFullNameEmployee)}
          </Text>
        ),
        type: ENotificationActionType.success,
      });

      navigate(EMPLOYEES, { state: { isModal: true, fullName: getFullNameEmployee } });
    } catch (e) {
      notification({
        title: LABELS.CARD_EMPLOYEE.ERROR_NOT_CREATE.TITLE,
        content: (
          <Text type='NORMAL_16' color='gray-7'>
            {LABELS.CARD_EMPLOYEE.ERROR_NOT_CREATE.MESSAGE(getFullNameEmployee)}
          </Text>
        ),
        type: ENotificationActionType.error,
      });
    }
  };

  const handleUpdateEmployeeInfo = async () => {
    const idCardIdEmployeeDocumentId = idCard.actualVersion.employeeDocumentId.length;

    const formationAddedVersion = formationDocumentsAddedVersion(
      idCard.id === '0' ? [...documents, idCard] : documents,
    );

    const formationUpdateVersion = formationDocumentsUpdateVersion(
      idCardIdEmployeeDocumentId && idCard.id ? [
        ...documents, idCardArchive.actualVersion.status === 'Archived' ? idCardArchive : idCard,
      ] : documents,
    );

    const requestData = {
      ...generalData,
      travelPolicyId: travelPolicyId.value,
      employeeId: employeeId as string,
      birthday: getKeepLocalDate(birthday as Dayjs),
      countryCode: citizenship.code,
      companyIds: organization,
      email,
      phoneNumber,
      addedVersions: transformationAddedVersion(formationAddedVersion, citizenship.code),
      updateVersions: transformationUpdateVersion(formationUpdateVersion, citizenship.code),
    };

    try {
      await updateEmployee(requestData).unwrap();

      notification({
        title: LABELS.CARD_EMPLOYEE.SUCCESS_UPDATE.TITLE,
        content: (
          <Text type='NORMAL_16' color='gray-7'>
            {LABELS.CARD_EMPLOYEE.SUCCESS_UPDATE.MESSAGE(getFullNameEmployee)}
          </Text>
        ),
        type: ENotificationActionType.success,
      });

      navigate(-1);
    } catch (e) {
      notification({
        title: LABELS.CARD_EMPLOYEE.ERROR_NOT_SAVE.TITLE,
        content: (
          <Text type='NORMAL_16' color='gray-7'>
            {LABELS.CARD_EMPLOYEE.ERROR_NOT_SAVE.MESSAGE(getFullNameEmployee)}
          </Text>
        ),
        type: ENotificationActionType.error,
      });
    }
  };

  const isCreatingOrUpdatingCard = isCreate ? handleAddEmployee : handleUpdateEmployeeInfo;

  const isGeneralFilled = (): boolean => (
    !!firstName &&
    !!lastName &&
    !!birthday &&
    !!citizenship.name.length &&
    !!sex &&
    !!phoneNumber &&
    !!email &&
    !!organization.length
  );

  const isFilled = isGeneralFilled() && isDocumentsFilled(documents, idCard, !isTurkeyCountry);

  const handleBackLink = () => {
    dispatch(setInitialState());

    navigate(EMPLOYEES);
  };

  const handleAddPassword = () => {
    if (documents.length < MAX_COUNT_DOCUMENT) {
      dispatch(addPassport());
    }
  };

  const handleClearIdCard = () => dispatch(clearDataIdCard());

  const handleDeleteDocument = (index: number) => dispatch(deletePassport(index));

  const renderFormDocuments = () => {
    if (!documents.length) return null;

    const documentTitle = isTurkeyCountry ? LABELS.PASSWORD : LABELS.INTERNATIONAL_PASSPORT;

    return (
      <div className={ styles.document }>
        {documents.map((doc, index) => (
          doc.actualVersion.status !== EEmployeeDocumentStatus.Archived && (
            <FormContainer
              key={ isCreate ? `${doc.actualVersion.type}_${index}` : doc.id || `passport_${index}` }
              subTitle={ documentTitle }
              handleDeletePassword={ () => handleDeleteDocument(index) }
              isDeleteButton
            >
              <Document
                documentData={ doc }
                documentIndex={ index }
                citizenship={ citizenship.code3 }
                documentType={ EEmployeeDocumentType.ForeignPassport }
              />
            </FormContainer>
          )
        ))}
      </div>
    );
  };

  return (
    <div className={ styles.wrap }>
      <BackLink
        textColor='blue-1'
        iconColor='blue1'
        text={ LABELS.EMPLOYEES }
        onClick={ handleBackLink }
        link={ EMPLOYEES }
      />
      <div className={ styles.title }>{formationPageName}</div>
      <div className={ styles.form_container }>
        <Text type='bold_20' className={ styles.title_documents }>
          {LABELS.GENERAL_DATA}
        </Text>
        <FormContainer subTitle={ LABELS.PERSONAL_DATA } isDeleteButton={ false }>
          <GeneralStep
            data={ generalData }
            companies={ companies }
            citizenshipData={ citizenshipData }
          />
        </FormContainer>
      </div>
      <div className={ styles.document_container }>
        <Text type='bold_20' className={ styles.title_documents }>
          {LABELS.DOCUMENTS}
        </Text>
        <FormContainer
          subTitle={ mainDocumentTitle }
          isDeleteButton
          handleDeletePassword={ handleClearIdCard }
        >
          <Document
            documentIndex={ 0 }
            documentData={ idCard }
            citizenship={ citizenship.code3 }
            documentType={ mainDocumentType }
          />
        </FormContainer>
        {renderFormDocuments()}
      </div>
      <div className={ styles.add_passport_button }>
        <IconButton
          iconType='add'
          iconColor='blue1'
          disabled={ documents.length === MAX_COUNT_DOCUMENT }
          onClick={ handleAddPassword }
          className={ styles }
        >
          {LABELS.ADD_TO_PASSWORD}
        </IconButton>
      </div>
      <TravelPolicyBlock
        policyValue={ travelPolicyId }
        travelPolicies={ travelPolicies }
      />
      <div className={ styles.action_panel }>
        <div className={ styles.content }>
          <div className={ styles.actions }>
            <Button
              type='secondary'
              size='small'
              onClick={ isCreatingOrUpdatingCard }
              disabled={ !isFilled }
            >
              {LABELS.SAVE}
            </Button>
            <Button type='text' onClick={ handleBackLink }>
              {LABELS.CANCEL}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
