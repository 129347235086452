import {
  EEmployeeFilterItem,
  EEmployeeSortedBy,
  ICompany,
  IEmployeeFilter,
} from '@/app/bi/models/employees.ts';
import { ITagFilter } from '@/app/bi/models/shared.ts';
import { getText } from '@/i18n';

const LABELS = {
  EMPLOYEE: getText('settings:employee'),
};

const getEmployeeFullName = ({
  firstName = '',
  lastName = '',
}: {
  firstName: string
  lastName: string
}): string => (
  lastName ? `${lastName} ${firstName || ''}`.trim() : ''
);

const DEFAULT_FILTERS_EMPLOYEES: IEmployeeFilter = {
  filterSort: {
    sortedBy: EEmployeeSortedBy.Name,
    descending: true,
  },
  companies: [],
  page: 1,
};

const getUpdateFilterStatusEmployee = (
  filter: IEmployeeFilter,
  companyValues: ICompany[],
  employeeInputValue: string,
): ITagFilter[] => {
  const newFilterStatus: ITagFilter[] = [];

  if (employeeInputValue.length) {
    newFilterStatus.push({
      typeFilter: EEmployeeFilterItem.SearchEmployee,
      value: '',
      label: `${LABELS.EMPLOYEE}: ${employeeInputValue}`,
    });
  }

  if (filter.companies) {
    companyValues.forEach(({ id, name }) => {
      if (filter.companies.includes(id)) {
        newFilterStatus.push({
          typeFilter: EEmployeeFilterItem.Company,
          value: id,
          label: name,
        });
      }
    });
  }

  return newFilterStatus;
};

const deleteFilterStatusEmployee = (
  { typeFilter, value }: ITagFilter,
  query: string,
  filter: IEmployeeFilter,
) => {
  if (typeFilter === EEmployeeFilterItem.SearchEmployee) {
    return { query: '', filter };
  }

  if (typeFilter === EEmployeeFilterItem.Company) {
    const updatedEmployeeCompanies = filter.companies.filter((
      status,
    ) => status !== value);

    return { query, filter: { ...filter, companies: updatedEmployeeCompanies } };
  }

  return { query, filter };
};

export {
  DEFAULT_FILTERS_EMPLOYEES,
  getEmployeeFullName,
  getUpdateFilterStatusEmployee,
  deleteFilterStatusEmployee,
};
