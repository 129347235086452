import React, { Component, ElementType, ReactNode } from 'react';
import { Text, Button } from 'yw-ui';
import {
  useNavigate,
  NavigateFunction,
  useLocation,
} from 'react-router-dom';
import { getText } from '@/i18n';

import ROUTES from '@/app/bi/constants/routes.ts';

import styles from './styles.module.css';

const LABELS = {
  SOMETHING_WRONG: getText('components:errorBoundary.somethingWrong'),
  DESCRIPTION: getText('components:errorBoundary.description'),
  GO_BACK: getText('components:errorBoundary.goBack'),
};

const withRouter = (WrapComponent: ElementType) => {
  const ComponentWithRouterProp = (props: any) => {
    const navigate = useNavigate();
    const location = useLocation();

    return (
      <WrapComponent
        { ...props }
        router={ { navigate, location } }
      />
    );
  };

  return ComponentWithRouterProp;
};
interface Props {
  children?: ReactNode;
  router: {
    navigate: NavigateFunction,
    location: Location,
  }
}

interface State {
  hasError: boolean;
}
class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
  };

  public static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.router.location.pathname !== this.props.router.location.pathname) {
      this.setState({ hasError: false });
    }
  }

  handleBackToHome = (): void => {
    const { router } = this.props;

    this.setState({ hasError: false });
    router.navigate(ROUTES.HOMEPAGE);
  };

  render() {
    if (this.state.hasError) {
      return (
        <div className={ styles.wrapper }>
          <div className={ styles.content }>
            <Text type='bold_22'>
              { LABELS.SOMETHING_WRONG }
            </Text>
            <Text
              className={ styles.description }
              type='NORMAL_16'
            >
              { LABELS.DESCRIPTION }
            </Text>
            <Button
              type={ 'secondary' }
              onClick={ this.handleBackToHome }
            >
              { LABELS.GO_BACK }
            </Button>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}
export default withRouter(ErrorBoundary);
