import React from 'react';
import { Stars, Text } from 'yw-ui';
import { getTextArray } from '@/i18n';

import { dateWithoutDayjs, dayjsObject, diffDays, isSameDate } from '@/app/bi/utils/formatDate.ts';
import toDecline from '@/app/bi/utils/toDecline.ts';

import { PATTERN } from '@/app/bi/constants/dateFormats.ts';

import { ICartResponseHotelItem } from '@/app/bi/models/cart.ts';

import styles from './index.module.css';

const LABELS = {
  DAYS_DECLINE: getTextArray('hotels:regionResult.item.daysDecline'),
};

interface IHotelItem {
  item: ICartResponseHotelItem;
}

const HotelItem = ({
  item,
}: IHotelItem) => {
  const {
    hotelName,
    hotelAddress,
    roomName,
    hotelStars,
    hotelCheckinTime,
    hotelCheckoutTime,
    checkinDate,
    checkoutDate,
  } = item;
  // const renderDetails = () => <HotelDetailsItem />;

  const checkinDateFormat = dateWithoutDayjs(checkinDate, PATTERN.DEFAULT_PATTERN_FILTER);
  const checkoutDateFormat = dateWithoutDayjs(checkoutDate, PATTERN.DEFAULT_PATTERN_FILTER);
  const amountDiffDays = diffDays(checkinDate, checkoutDate);
  const textDays = toDecline(amountDiffDays, LABELS.DAYS_DECLINE);

  const renderdDate = () => (isSameDate(dayjsObject(checkinDate), dayjsObject(checkoutDate))
    ? `${checkinDateFormat}, ${hotelCheckinTime}`
    : `${checkinDateFormat}, ${hotelCheckinTime} — ${checkoutDateFormat}, ${hotelCheckoutTime}`);

  return (
    <div className={ styles.item }>
      <div className={ styles.content }>
        <Text type='bold_20' className={ styles.title }>
          {hotelName}
        </Text>
        <div className={ styles.header }>
          <Stars count={ hotelStars } size={ 16 }/>
        </div>
        <div className={ styles.info }>
          <div className={ styles.travelContainer }>
            <Text type='bold_18' color='gray-7'>
              {`${amountDiffDays} ${textDays}`}, {roomName}
            </Text>
          </div>
          <div className={ styles.routeInfo }>
            <Text type='NORMAL_16' color='gray-6'>
              {hotelAddress}
            </Text>
            <Text type='NORMAL_16' color='gray-6'>
              {renderdDate()}
            </Text>
          </div>
        </div>
      </div>
    </div>
  );
};
export { HotelItem };
