const ROUTES = {
  HOMEPAGE: '/',
  SEARCH: {
    AIR: '/airline',
    AIR_SEARCH: '/airline/search',
    HOTEL: '/hotels',
    HOTELS_REGION: '/hotels/search',
    HOTEL_PAGE: '/hotel',
    TRANSFER: '/transfer',
  },
  CART: {
    MAIN: '/cart',
    SPECIFIC: '/cart/',
    CHECKOUT: '/cart/checkout',
    BOOKING: '/cart/booking',
    SUCCESS: '/cart/success',
  },
  REPORTS: {
    MAIN: '/reports',
  },
  TRIPS: {
    MAIN: '/trips',
  },
  TRIP: {
    MAIN: '/trip',
    DOCUMENTS: '/documents',
  },
  SETTINGS: {
    EMPLOYEES: '/settings/employees',
    COMPANIES: '/settings/companies',
    PROFILE: '/settings/profile',
    EMPLOYEE: '/employee',
    TRAVEL_POLICIES: '/settings/travel-policies',
    TRAVEL_POLICY: '/travel-policy/',
    TRAVEL_POLICY_ID: (id: string) => `/travel-policy/${id}`,
  },
  EMPLOYEE: '/employee/',
  NO_MATCH: '*',
};

export default ROUTES;
