import { ywApiPrivate } from './index.ts';

import { IUpdatePassword } from '@/app/bi/models/profile.ts';

export const profileApi = ywApiPrivate.injectEndpoints({
  endpoints: (build) => ({
    updatePassword: build.mutation({
      query: ({ oldPassword, newPassword }: IUpdatePassword) => ({
        url: '/user/password',
        method: 'PUT',
        body: { oldPassword, newPassword },
      }),
    }),
  }),
});

export const { useUpdatePasswordMutation } = profileApi;
