import { formatDate, getDayjs } from '@/app/bi/utils/formatDate.ts';

import { PATTERN } from '@/app/bi/constants/dateFormats.ts';
import { ID_CARD_NUMBER, PASSPORT_NUMBER } from '@/app/bi/constants/regExp.ts';
import {
  INPUT_PLACEHOLDER_ID_CARD,
  INPUT_PLACEHOLDER_PASSPORT,
  MAX_COUNT_ID_CARD_LENGTH,
  MAX_COUNT_PASSPORT_LENGTH,
} from '@/app/bi/constants/settings.ts';

import {
  EEmployeeDocumentStatus,
  EEmployeeDocumentType,
  ICompany,
  IEmployeeDocument,
  IEmployeeDocumentModified,
} from '@/app/bi/models/employees.ts';
import { ITravelPolicy } from '@/app/bi/models/travelPolicies.ts';

export const mapCompaniesToData = (comp: ICompany[]) => comp.map(({
  name,
  id,
}) => ({
  label: name,
  value: +id,
}));

export const mapTravelPoliciesToData = (comp: ITravelPolicy[]) => comp.map(({
  id,
  name,
}) => ({
  label: name,
  value: id,
}));

export const transformationDocuments = (
  arrayDocuments: IEmployeeDocument[],
  countryCode: string,
) => arrayDocuments.map(({
  actualVersion: {
    type,
    number,
    lastName,
    firstName,
    firstNameTranslit,
    lastNameTranslit,
    expiryDate,
  },
}) => ({
  type,
  number,
  lastName,
  firstName,
  firstNameTranslit,
  lastNameTranslit,
  countryCode,
  expiryDate: formatDate(expiryDate || getDayjs(), PATTERN.YEAR_MONTH_DAY),
}));

export const isValidDocumentNumber = (value: string, documentType: EEmployeeDocumentType) => {
  if (documentType === EEmployeeDocumentType.IdCard) {
    const regExpIdCard = ID_CARD_NUMBER(MAX_COUNT_ID_CARD_LENGTH);

    return regExpIdCard.test(value);
  }

  return PASSPORT_NUMBER.test(value);
};

export const isValidIdCard = ({
  id,
  actualVersion: {
    lastName,
    firstName,
    lastNameTranslit,
    firstNameTranslit,
    countryCode,
    expiryDate,
    number,
  },
}: IEmployeeDocumentModified) => (
  id === '0' &&
  lastName &&
  firstName &&
  lastNameTranslit &&
  firstNameTranslit &&
  countryCode &&
  expiryDate &&
  number.length === MAX_COUNT_ID_CARD_LENGTH
);

export const isDocumentsFilled = (
  documents: IEmployeeDocumentModified[],
  idCard: IEmployeeDocumentModified,
  isNotPassportLength: boolean,
): boolean => {
  const isDocumentFilled = documents.every(({
    actualVersion: {
      firstName,
      lastName,
      lastNameTranslit,
      firstNameTranslit,
      number,
      expiryDate,
    },
  }) => {
    const numberLength = isNotPassportLength ? !!number.length : number.length === MAX_COUNT_PASSPORT_LENGTH;

    return (
      !!firstName &&
      !!lastName &&
      !!lastNameTranslit &&
      !!firstNameTranslit &&
      numberLength &&
      !!expiryDate
    );
  });

  const idCardActual = idCard.actualVersion;
  const idCardPassportLength = idCardActual.number.length;
  const idCardNumberLength = isNotPassportLength ? !!idCardPassportLength : idCardPassportLength === MAX_COUNT_PASSPORT_LENGTH;

  const isIdCardFilled =
    !!idCardActual.firstName &&
    !!idCardActual.lastName &&
    !!idCardActual.firstNameTranslit &&
    !!idCardActual.lastNameTranslit &&
    idCardNumberLength &&
    !!idCardActual.expiryDate;

  const isIdCardUntouchedOrEmpty = !idCard.hasBeenChangedData || (
    !idCardActual.firstName &&
    !idCardActual.lastName &&
    !idCardActual.firstNameTranslit &&
    !idCardActual.lastNameTranslit &&
    !idCardActual.number &&
    !idCardActual.expiryDate
  );

  if (isIdCardUntouchedOrEmpty) {
    return isDocumentFilled;
  }

  return isDocumentFilled && isIdCardFilled;
};

export const transformationUpdateVersion = (
  arrayDocuments: IEmployeeDocumentModified[],
  countryCode: string,
) => arrayDocuments.filter(({
  hasBeenChangedData,
  actualVersion: {
    status,
  } },
) => status === EEmployeeDocumentStatus.Archived || (status === EEmployeeDocumentStatus.Active && hasBeenChangedData)).map(({
  actualVersion: {
    employeeDocumentId,
    status,
    type,
    number,
    lastName,
    firstName,
    lastNameTranslit,
    firstNameTranslit,
    expiryDate,
  },
}) => ({
  employeeDocumentId,
  documentStatus: status,
  type,
  number,
  lastName,
  firstName,
  lastNameTranslit,
  firstNameTranslit,
  countryCode,
  expiryDate: formatDate(expiryDate || getDayjs(), PATTERN.YEAR_MONTH_DAY),
}));

export const transformationAddedVersion = (
  arrayDocuments: IEmployeeDocumentModified[],
  countryCode: string,
) => arrayDocuments.filter(({ id }) => id === '0' || !id.length).map(({
  actualVersion: {
    type,
    number,
    lastName,
    firstName,
    lastNameTranslit,
    firstNameTranslit,
    expiryDate,
  } }) => ({
  type,
  number,
  lastName,
  firstName,
  firstNameTranslit,
  lastNameTranslit,
  countryCode,
  expiryDate: formatDate(expiryDate || getDayjs(), PATTERN.YEAR_MONTH_DAY),
}));

export const formationDocumentsAddedVersion = (
  arrayDocuments: IEmployeeDocumentModified[],
) => arrayDocuments.filter(({ id }) => !id.length || id === '0');

export const formationDocumentsUpdateVersion = (arrayDocuments: IEmployeeDocumentModified[]) =>
  arrayDocuments.filter(({ id, hasBeenChangedData, actualVersion }) =>
    (!!id.length && hasBeenChangedData) ||
    (id.length && actualVersion.status === EEmployeeDocumentStatus.Archived),
  );

export const getPlaceholderPassport = (
  docType: EEmployeeDocumentType,
  isTurkeyCountry: boolean,
): string => {
  if (!isTurkeyCountry) {
    return docType === EEmployeeDocumentType.IdCard ? INPUT_PLACEHOLDER_ID_CARD : INPUT_PLACEHOLDER_PASSPORT;
  }

  return INPUT_PLACEHOLDER_ID_CARD;
};
