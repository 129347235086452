import React from 'react';
import { Text } from 'yw-ui';

import MoneyFormat from 'yw-ui/src/utils/money.ts';
import { getText } from '@/i18n';
import toDecline from '@/app/bi/utils/toDecline.ts';

import { TICKETS } from '@/app/bi/constants/airline.ts';

import { Fares } from '@/app/bi/models/airlineTypes.ts';

import styles from './styles/index.module.css';

const LABELS = {
  BY: getText('components:airlineSearchItem.travellersAmount.by'),
};

interface TravellersAmountProps {
  travellers?: number,
  currentFareId?: string,
  fares?: Fares[],
}
const TravellersAmount = ({
  travellers = 0,
  currentFareId = '',
  fares = [],
}: TravellersAmountProps) => {
  const price = fares.find(({ id }) => currentFareId === id)?.price;

  if (!price) return null;

  const pricePerPerson = price.totalPrice / travellers;

  const priceFormat = `${MoneyFormat.moneyWithDecimal(Number(pricePerPerson))} ₽`;

  return (
    <div className={ styles.wrap_price }>
      <Text
        type='NORMAL_14'
        color='gray-7'
        className={ styles.text }
      >
        {`${travellers} ${toDecline(travellers, TICKETS)} ${LABELS.BY} ${priceFormat}`}
      </Text>
    </div>
  );
};

export { TravellersAmount };
