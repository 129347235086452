import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import dayjs, { Dayjs } from 'dayjs';

import { createRoute } from '../../bi/utils/airlineSearch.ts';
import { validateSearchFields } from '../../bi/utils/airline.ts';

import { IRoutes, ISearchAirlineStore, ISuggestion } from '../../bi/models/airlineSearchTypes.ts';
import { EAirlineClass } from '@/app/bi/models/airlineTypes.ts';

const MAX_ROUTE = 6;

const initialState: ISearchAirlineStore = {
  isComplex: false,
  isDirect: false,
  travellers: 1,
  flightClass: EAirlineClass.Econom,
  routes: [
    {
      from: {
        label: '',
        selected: null,
      },
      to: {
        label: '',
        selected: null,
      },
      minDate: dayjs().startOf('d'),
      date: dayjs().startOf('d'),
      dateBack: null,
    },
  ],
  isValid: false,
};

export const searchAirlineSlice = createSlice({
  name: 'searchAirline',
  initialState,
  reducers: {
    updateForm(state, action: PayloadAction<{
      isDirect: boolean;
      travellers: number;
      flightClass: string;
      routes: IRoutes[],
    }>) {
      const {
        isDirect, travellers, flightClass, routes,
      } = action.payload;

      state.routes = routes;
      state.isDirect = isDirect;
      state.travellers = travellers;
      state.flightClass = flightClass;

      state.isValid = validateSearchFields(state.routes);
    },

    updateLabel(state, action: PayloadAction<{
      idx: number,
      field: 'from' | 'to',
      query: string,
    }>) {
      const { idx, field, query } = action.payload;

      state.routes[idx][field].label = query;
    },

    setTravellers(state, action: PayloadAction<number>) {
      state.travellers = action.payload;
    },

    setFlightClass(state, action: PayloadAction<string>) {
      state.flightClass = action.payload;
    },

    setIsDirect(state, action: PayloadAction<boolean>) {
      state.isDirect = action.payload;
    },

    setIsComplex(state, action: PayloadAction<boolean>) {
      state.isComplex = action.payload;

      if (!state.isComplex && state.routes.length > 1) {
        state.routes = state.routes.splice(0, 1);
      }
    },

    addSearchRoute(state) {
      if (state.routes.length < MAX_ROUTE) {
        const { date } = state.routes[state.routes.length - 1];
        const prevRouteTo = state.routes[state.routes.length - 1].to;

        state.routes.push(createRoute(date, prevRouteTo));
        state.isValid = validateSearchFields(state.routes);
      }
    },
    removeSearchRoute(state, action: PayloadAction<number>) {
      if (state.routes.length > 1) {
        state.routes = state.routes.filter((_, idx) => idx !== action.payload);
        state.isValid = validateSearchFields(state.routes);
      }
    },
    selectSuggest(state, action: PayloadAction<{ ind: number, value: ISuggestion, field: 'from' | 'to'; }>) {
      const { ind, field, value } = action.payload;

      state.routes[ind][field] = {
        selected: {
          city: value.city,
          code: value.code,
        },
        label: `${value.city} (${value.code})`,
      };

      state.isValid = validateSearchFields(state.routes);
    },
    setFlipCity(state, action: PayloadAction<number>) {
      const route = state.routes[action.payload];
      const fromObj = { ...route.from };
      route.from = { ...route.to };
      route.to = fromObj;
    },
    setThereDate(
      state,
      action: PayloadAction<{ value: Dayjs | null; index: number }>,
    ) {
      const { index, value } = action.payload;

      state.routes[index].date = value;
      if (state.routes[index].dateBack && state.routes[index].dateBack?.isBefore(value)) {
        state.routes[index].dateBack = value ? value.clone().add(1, 'd') : value;
      }

      state.isValid = validateSearchFields(state.routes);
    },
    setBackDate(
      state,
      action: PayloadAction<{ value: Dayjs | null; index: number }>,
    ) {
      const { index, value } = action.payload;

      state.routes[index].dateBack = value;
      state.isValid = validateSearchFields(state.routes);
    },
    resetStore: () => initialState,
  },
});

export default searchAirlineSlice.reducer;
