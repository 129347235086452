export const AIR_FILTER_TYPE = {
  PRICE: 'price',
  TRANSFER_DURATION: 'transferDuration',
  TRANSFERS_COUNT: 'transfersCount',
  AIRLINES: 'airlines',
  AIRPORTS: 'airports',
  ROUTE_FILTERS: 'routeFilters',
  FLIGHT_DURATION: 'flightDuration',
  ROUTE_TRAVEL_TIME_FIELD: 'duration',
  FLIGHT_NUMBER: 'flightNumber',
  FLIGHT_NUMBERS: 'flightNumbers',
  BAGGAGE: 'baggage',
  TRANSFER_AIRPORTS: 'transferAirports',
};
// const HOTELFILTERTYPE = {
//   BREAKFAST: 'breakfast',
//   PRICE: 'price',
//   SMARTHOTEL: 'smartHotel',
//   PRICE_GUARANTEED: 'priceGuaranteed',
//   HOTELNAME: 'hotelName',
//   HASCANCELLATION: 'hasCancellation',
//   STARS: 'stars',
//   CHAINHOTELS: 'chainHotels',
//   TYPE: 'type',
//   AMENITIES: 'amenities',
//   FAVORITES: 'favoriteId',
//   VAT: 'hasVat',
//   SELECTEDTRAVELPOLICY: 'selectedTravelPolicy',
//   RATING: 'rating',
//   ONLINE: 'online',
//   RADIUS: 'radius',
// };
