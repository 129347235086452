import React, { ReactNode, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { PageLoader, Paginate } from 'yw-ui';

import { getText } from '@/i18n';

import { useAppDispatch, useAppSelector } from '../../store/hooks/redux.ts';
import { searchAirlineSlice } from '@/app/store/redusers/SearchAirlineSlice.ts';

import { useAddAirlineMutation } from '@/app/bi/api/cartApi.ts';
import { searchAirlineApi } from '@/app/bi/api/searchAirlineApi.ts';
import { useGetTravelPoliciesQuery } from '@/app/bi/api/travelPoliciesApi.ts';

import { AirlineSearchMenu } from '../../components/Menu/AirlineSearchMenu';
import { AirlineFilters } from './components/Filters';
import { AirlineSearchItem } from '../../components/AirlineSearchItem';
import { AirlinesStickyPanel } from '@/app/pages/AirlineResult/components/AirlinesStickyPanel';
import { NotificationItem } from '@/app/pages/AirlineResult/components/NotificationItem';
import { FilterEmptyPanel } from '@/app/pages/AirlineResult/components/FilterEmptyPanel';
import { NoResultsAirline } from '@/app/pages/AirlineResult/components/NoResultsAirline';

import { scrollToTop } from '../../bi/utils/scrollToTop.ts';
import { getPrepareRoutes } from '@/app/bi/utils/airlineSearch.ts';
import {
  createTags,
  getRoutesList,
  updateDefaultFilters,
  updateTags } from '@/app/bi/utils/airline.ts';
import { notification } from '@/app/bi/utils/not.ts';
import parseUnix from '@/app/bi/utils/parseDateTime.ts';
import MoneyFormat from '@/app/bi/utils/money.ts';
import toDecline from '@/app/bi/utils/toDecline.ts';
import { formatDate } from '@/app/bi/utils/formatDate.ts';

import { DEFAULT_PAGING_OBJ } from '@/app/bi/constants/hotelsSearch.ts';
import { DEFAULT_AIRLINE_SEARCH_FILTER, TRANSFER } from '../../bi/constants/airline.ts';
import { ENotificationActionType } from '@/app/bi/constants/settings.ts';
import ROUTES from '@/app/bi/constants/routes.ts';

import { ISearchQuery } from '@/app/bi/models/airlineSearchTypes.ts';
import { IAirlineSearchFilter, Items, Route } from '@/app/bi/models/airlineTypes.ts';
import { IPaging, ITag } from '@/app/bi/models/shared.ts';
import { EServiceTypes } from '@/app/bi/models/serviceTypes.ts';

import styles from './index.module.css';

const LABELS = {
  NOTIFICATIONS_CART: {
    ADD_TO_CART: getText('air:result.notifications.cart.addToCart'),
    NOT_ADDED_TO_CART: getText('air:result.notifications.cart.notAddedToCart'),
    TO_CART: getText('air:result.notifications.cart.toCart'),
  },
  WITHOUT_TRANSFERS: getText('components:airlineSearchItem.airItem.withoutTransfers'),
  LOADING_MESSAGE: getText('common:loadingMessage'),
  NOT_SPECIFIED: getText('settings:travelPolicies.notSpecified'),
};

const AirlineResultPage = () => {
  const navigate = useNavigate();
  const { guid } = useParams();

  const {
    routes,
    travellers,
    flightClass,
    isDirect,
    isComplex,
  } = useAppSelector((state) => state.searchAirlineSlice);

  const travellersCount = useRef<number>(travellers);

  const dispatch = useAppDispatch();
  const {
    updateForm,
    resetStore,
  } = searchAirlineSlice.actions;

  const [loading, setLoading] = useState(true);
  const [paging, setPaging] = useState<IPaging>(DEFAULT_PAGING_OBJ);

  const [
    prepareFilter,
    setPrepareFilter,
  ] = useState<IAirlineSearchFilter>(DEFAULT_AIRLINE_SEARCH_FILTER);
  const [
    filters,
    setFilters,
  ] = useState<IAirlineSearchFilter>(DEFAULT_AIRLINE_SEARCH_FILTER);

  const [sources, setSources] = useState<Items[]>([]);
  const [items, setItems] = useState<Items[]>([]);
  const [tags, setTags] = useState<ITag[]>([]);

  const [search] = searchAirlineApi.useLazySearchQuery();
  const [updateItems] = searchAirlineApi.useLazyUpdateFiltersQuery();
  const [searchBySearchId] = searchAirlineApi.useLazySearchBySearchIdQuery();

  const [addAirline] = useAddAirlineMutation();
  const totalCount = useRef(0);

  const [
    selectPolicy,
    setSelectPolicy,
  ] = useState({
    id: '',
    policyName: LABELS.NOT_SPECIFIED,
    isActive: true,
  });

  const {
    data: listTravelPolicies = [],
    isLoading,
  } = useGetTravelPoliciesQuery();

  useEffect(() => {
    if (!guid && !routes[0].from.selected) {
      return navigate('/airline');
    }

    if (!guid) {
      initSearch();
    } else {
      initSearchBySearchId();
    }

    return () => {
      dispatch(resetStore());
    };
  }, []);

  const initSearch = async () => {
    const routesList = getRoutesList(routes, isComplex);

    const params: ISearchQuery = {
      class: flightClass,
      isDirect,
      travellersCount: travellers,
      // @ts-ignore
      routes: routesList,
    };

    setLoading(true);

    travellersCount.current = travellers;

    try {
      const result = await search(params).unwrap();

      setPrepareFilter(result.filter);
      setFilters(updateDefaultFilters(result.filter));
      setItems(result.search.items);
      setSources(result.search.items);
      setPaging({
        total: result.search.pages,
        current: result.search.page,
        count: result.search.pageLimit,
      });

      totalCount.current = result.search.itemsCount;

      navigate(`${ROUTES.SEARCH.AIR_SEARCH}/${result.search.searchId}`);
    } catch (e) {
      setItems([]);
      setSources([]);
    } finally {
      setLoading(false);
    }
  };

  const initSearchBySearchId = async () => {
    try {
      const result = await searchBySearchId(guid as string).unwrap();

      setPrepareFilter(result.filter);
      setFilters(updateDefaultFilters(result.filter));
      setItems(result.search.items);
      setSources(result.search.items);
      setPaging({
        total: result.search.pages,
        current: result.search.page,
        count: result.search.pageLimit,
      });

      const newRoutes = getPrepareRoutes(result.search.searchRequest.routes);

      dispatch(updateForm({
        isDirect: result.search.searchRequest.isDirect,
        travellers: result.search.searchRequest.travellersCount,
        flightClass: result.search.searchRequest.class,
        routes: newRoutes,
      }));

      totalCount.current = result.search.itemsCount;
    } catch (e) {
      setItems([]);
      setSources([]);
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateFilters = async (values: IAirlineSearchFilter) => {
    updateFilters({ ...values, page: 1 });
  };

  const updateFilters = async (values: IAirlineSearchFilter) => {
    setFilters(values);

    const tagsHtml = createTags(prepareFilter, values);

    setTags(tagsHtml);

    try {
      const result = await updateItems({
        searchId: guid as string,
        data: values },
      ).unwrap();

      setItems(result?.items);

      totalCount.current = result.itemsCount;
    } catch (e) {
      console.log('error', e);
    }
  };

  const deleteTags = (tag: ITag) => {
    const newFilters = updateTags(tag, filters, prepareFilter);

    handleUpdateFilters(newFilters);
  };

  const handleSortSearchResult = (value: string) => {
    handleUpdateFilters({
      ...filters,
      searchSort: {
        ...filters?.searchSort,
        sortedBy: value,
      },
    });
  };

  const handlePageChange = (page: number) => {
    setPaging({
      ...paging,
      current: page,
    });

    updateFilters({ ...filters, page });
    scrollToTop();
  };

  const handleResetFilters = () => {
    const defaultFilters = updateDefaultFilters(prepareFilter);

    setTags([]);
    updateFilters(defaultFilters);
  };

  const renderLoading = () =>
    (
      <div className={ styles.loading_wrap }>
        <PageLoader text={ LABELS.LOADING_MESSAGE }/>
      </div>
    );

  const renderFullPage = (html: ReactNode) => (
    <>
      <AirlineFilters
        filters={ prepareFilter }
        filtersValues={ filters }
        listTravelPolicies={ listTravelPolicies }
        selectPolicy={ selectPolicy }
        setIsSelectPolicy={ setSelectPolicy }
        setFilters={ handleUpdateFilters }
      />
      { html }
    </>
  );

  const renderEmptyFilter = () => renderFullPage(
    <div className={ styles.no_results_wrap }>
      <FilterEmptyPanel showReset onResetFilters={ handleResetFilters }/>
    </div>,
  );

  const goToCart = () => navigate(ROUTES.CART.MAIN);

  const getTransfer = (changeCount: number, route: Route) => {
    if (changeCount) {
      return `${changeCount} ${toDecline(changeCount, TRANSFER)}`;
    }

    if (!route.segments[0].technicalStop?.length) {
      return LABELS.WITHOUT_TRANSFERS;
    }

    return '';
  };

  const handleAddToCart = async ({
    item,
    fareId,
    totalPrice,
  }: { item: Items, fareId: string, totalPrice: number }) => {
    const route = item.routes[0];
    const firstSegment = route.segments[0];
    const lastSegment = route.segments[route.segments.length - 1];

    const getCity = (city: string, airportId: string) => `${city}, ${airportId}`;

    const departure = getCity(firstSegment.departureCity, firstSegment.departureAirport.code);
    const arrival = getCity(lastSegment.arrivalCity, lastSegment.arrivalAirport.code);

    const locations = `${departure} — ${arrival}`;
    const departureDate = formatDate(parseUnix(firstSegment.departureDateTime));

    const formattedPrice = MoneyFormat.money(totalPrice, true);

    const changeCount = route.segments.length - 1;
    const transfer = getTransfer(changeCount, route);

    const body = {
      searchItemId: item.id,
      fareId,
      travellersCount: travellersCount.current,
    };

    try {
      await addAirline(body).unwrap();

      notification({
        title: LABELS.NOTIFICATIONS_CART.ADD_TO_CART,
        buttonName: LABELS.NOTIFICATIONS_CART.TO_CART,
        content: (
          <NotificationItem
            locations={ locations }
            date={ departureDate }
            detail={ transfer }
            price={ formattedPrice }
            serviceType={ EServiceTypes.Airline }
          />
        ),
        onClick: goToCart,
      });

    } catch (e) {
      notification({
        title: LABELS.NOTIFICATIONS_CART.NOT_ADDED_TO_CART,
        buttonName: LABELS.NOTIFICATIONS_CART.TO_CART,
        content: (
          <NotificationItem
            locations={ locations }
            date={ departureDate }
            detail={ transfer }
            price={ formattedPrice }
            serviceType={ EServiceTypes.Airline }
          />
        ),
        type: ENotificationActionType.error,
        onClick: goToCart,
      });
    }
  };

  const renderList = () => {
    const ticketHtml = items.map((ticket) => (
      <AirlineSearchItem
        key={ ticket.id }
        item={ ticket }
        travellers={ travellersCount.current }
        selectPolicy={ selectPolicy }
        loadingPolicy={ isLoading }
        listTravelPolicies={ listTravelPolicies }
        onAddToCart={ handleAddToCart }
      />
    ));

    const html = (
      <div className={ styles.content }>
        <AirlinesStickyPanel
          sortedBy={ filters.searchSort.sortedBy }
          tags={ tags }
          totalCount={ totalCount.current }
          onSortSearchResult={ handleSortSearchResult }
          onDeleteTags={ deleteTags }
          onResetFilters={ handleResetFilters }
        />
        <div className={ styles.list }>
          <div className={ styles.items }>
            { ticketHtml }
          </div>
          <Paginate
            total={ totalCount.current }
            page={ filters.page }
            itemsPerPage={ paging.count }
            onChange={ handlePageChange }
          />
        </div>
      </div>
    );

    return renderFullPage(html);
  };

  const renderResults = (): ReactNode => {
    if (!sources.length) return <NoResultsAirline />;
    if (!items.length && filters) return renderEmptyFilter();

    return renderList();
  };

  const handleReturnToSearch = () => navigate(ROUTES.SEARCH.AIR);

  const renderContent = loading ? renderLoading() : renderResults();

  return (
    <div className={ styles.wrap }>
      <AirlineSearchMenu
        subMenu
        onSearch={ initSearch }
        onReturnToSearch={ handleReturnToSearch }
      />
      <div className={ styles.result }>
        {renderContent}
      </div>
    </div>
  );
};

export { AirlineResultPage };
