import { Dayjs } from 'dayjs';

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  TSortedByFields,
  IInitialStateReportFilter,
} from '@/app/bi/models/reportsTypes.ts';
import { IFilterItem } from '@/app/bi/models/app.ts';

export const initialStateReportsData: IInitialStateReportFilter = {
  filterSort: {
    sortedBy: 'TripName',
    descending: true,
  },
  companies: [],
  operationTypes: [],
  operationDate: {
    min: null,
    max: null,
  },
  page: 1,
};

export const reportsSlice = createSlice({
  name: 'reportsSlice',
  initialState: initialStateReportsData,
  reducers: {
    setPageNumber: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
    setSortedBy: (state, action: PayloadAction<TSortedByFields>) => {
      state.filterSort.sortedBy = action.payload;
    },
    setCompany: (state, action: PayloadAction<IFilterItem>) => {
      state.companies = state.companies.map((company) => {
        if (company.label === action.payload.label) {
          return {
            label: company.label,
            checked: !company.checked,
          };
        }

        return company;
      });
    },
    setCompanies: (state, action: PayloadAction<IFilterItem[]>) => {
      state.companies = action.payload;
    },
    setOperationTypes: (state, action: PayloadAction<IFilterItem>) => {
      state.operationTypes = state.operationTypes.map((type) => {
        if (type.label === action.payload.label) {
          return {
            label: type.label,
            checked: !type.checked,
          };
        }

        return type;
      });
    },
    setOperationTypesArray: (state, action: PayloadAction<IFilterItem[]>) => {
      state.operationTypes = action.payload;
    },
    setFilterMinDate: (state, action: PayloadAction<Dayjs>) => {
      state.operationDate.min = action.payload;
    },
    setFilterMaxDate: (state, action: PayloadAction<Dayjs>) => {
      state.operationDate.max = action.payload;
    },
    setSortedAscendingDescending: (state, action: PayloadAction<boolean>) => {
      state.filterSort.descending = action.payload;
    },
    deleteItemFilterStatus: (state, action: PayloadAction<IFilterItem>) => {
      const itemFilterStatus = action.payload;

      const isItemCompany = state.companies.findIndex(({
        label,
      }) => label === itemFilterStatus.label) !== -1;

      const isItemOperationTypes = state.operationTypes.findIndex(({
        label,
      }) => label === itemFilterStatus.label) !== -1;

      if (isItemCompany) {
        state.companies = state.companies.map((comp) => {
          if (comp.label === itemFilterStatus.label) {
            return { label: comp.label, checked: false };
          }

          return comp;
        });
      }

      if (isItemOperationTypes) {
        state.operationTypes = state.operationTypes.map((type) => {
          if (type.label === itemFilterStatus.label) {
            return { label: type.label, checked: false };
          }

          return type;
        });
      }
    },
    clearAllFilters: (state) => {
      state.companies = state.companies.map(({
        label,
      }) => ({
        label,
        checked: false,
      }));
      state.operationTypes = state.operationTypes.map(({
        label,
      }) => ({
        label,
        checked: false,
      }));
    },
  },
});

export default reportsSlice.reducer;
