import React from 'react';
import { Checkbox, Select, Text } from 'yw-ui';

import { getText } from '@/i18n';

import styles from './index.module.css';
import { CURRENCY_CODE_ITEMS } from '@/i18n/locales/consts';
import { ECurrencyCode } from '@/app/bi/models/hotelSearch/hotelSearchEnum.ts';

const LABELS = {
  ONLINE: getText('hotels:regionResult.filters.onlyOnline'),
  BREAKFAST: getText('hotels:regionResult.filters.mealIncluded'),
  HAS_CANCELLATION: getText('hotels:regionResult.filters.freeCancellation'),
};

interface FiltersProps {
  filters: any,
  disabled: boolean
  updateCurrencyCode(value: ECurrencyCode): void;
}

const Filters = ({ filters, disabled, updateCurrencyCode }: FiltersProps) => {
  const updateOnlineFilter = () => {};

  const updateBreakfastFilter = () => {};

  const updateCancellationFilter = () => {};

  const isDisabledOnline = disabled;

  const renderOnline = () => (
    <div className={ styles.block }>
      <Checkbox
        // @ts-ignore
        id='check_online'
        value={ filters.online }
        onChange={ updateOnlineFilter }
        disabled={ isDisabledOnline }
      >
        <Text type='NORMAL_14'>
          { LABELS.ONLINE }
        </Text>
      </Checkbox>
    </div>
  );

  const renderBreakfast = () => (
    <div className={ styles.block }>
      <Checkbox
        // @ts-ignore
        id='check_breakfast'
        value={ filters.breakfast }
        onChange={ updateBreakfastFilter }
        disabled={ disabled }
      >
        <Text type='NORMAL_14'>
          {LABELS.BREAKFAST}
        </Text>
      </Checkbox>
    </div>
  );

  const renderRefundability = () => (
    <div className={ styles.block }>
      <Checkbox
        // @ts-ignore
        id='has_cancellation'
        value={ filters.hasCancellation }
        onChange={ updateCancellationFilter }
        disabled={ disabled }
      >
        <Text type='NORMAL_14_130'>
          {LABELS.HAS_CANCELLATION}
        </Text>
      </Checkbox>
    </div>
  );

  return (
    <div className={ styles.wrapper }>
      {renderOnline()}
      {renderBreakfast()}
      {renderRefundability()}
      <div>
        <Select
          theme='default-border'
          items={ CURRENCY_CODE_ITEMS }
          value={ filters.currencyCode }
          onChange={ updateCurrencyCode }
        />
      </div>
    </div>
  );
};

export default Filters;
