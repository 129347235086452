import React, { type RefObject, useRef, useState } from 'react';
import { Dayjs } from 'dayjs';
import { Button, Datepicker, Icon, Select, Suggest, Timepicker } from 'yw-ui';

import { getText } from '@/i18n';

import { IPrepareHotelStaticAutocompleteItem } from '@/app/bi/api/searchHotelsApi.ts';
import { useAppDispatch, useAppSelector } from '@/app/store/hooks/redux.ts';
import { searchHotelsSlice } from '@/app/store/redusers/SearchHotelsSlice.ts';

import { HotelSuggestion } from '@/app/components/Menu/HotelSearchMenu/components/HotelSuggestion';

import { dayjsObject, formatDate } from '@/app/bi/utils/formatDate.ts';
import { getKey } from '@/app/bi/utils/hotelsSearch.ts';

import { DATE_FORMATS, PATTERN } from '@/app/bi/constants/dateFormats.ts';
import { SELECT_TRAVELLERS } from '@/app/bi/constants/hotelsSearch.ts';
import TRAVELERS from '@/app/bi/constants/travelers.ts';

import styles from './index.module.css';

const DASH = '-';

const LABELS = {
  SUGGEST_PLACEHOLDER: getText('components:menu.hotel.cityOrHotel'),
  DATE_IN_PLACEHOLDER: getText('components:menu.hotel.dateIn'),
  DATE_OUT_PLACEHOLDER: getText('components:menu.hotel.dateOut'),
  TIME: getText('components:menu.hotel.time'),
  SEARCH: getText('common:search'),
  PLACING: getText('components:menu.hotel.placing'),
  GUESTS: getText('components:menu.hotel.guests'),
  EARLY_IN: getText('components:menu.hotel.earlyIn'),
  LATE_OUT: getText('components:menu.hotel.lateOut'),
  WITHOUT_EARLY_IN_OR_LATE_OUT: getText('components:menu.hotel.withoutEarlyInOrLateOut'),
};

interface ISubHotelSearchMenuProps {
  isFetching: boolean;
  buttonsRefs?: Array<RefObject<HTMLElement>>;
  itemsSuggests: any[];
  onSuggestSelected(focusItem: IPrepareHotelStaticAutocompleteItem): void;
  onChangeTravellersCount(value: number): void;
  onChangeAdult(value: number): void;
  onChangeQuery(query: string): void;
  onSearch(): void;
  onOpenDatePickerFrom(mode: (mode: string) => void): void;
  onOpenDatePickerTo(mode: (mode: string) => void): void;
  onChangeForm(): void;
}

const SubHotelSearchMenu = ({
  isFetching,
  buttonsRefs = [],
  itemsSuggests,
  onSuggestSelected,
  onChangeTravellersCount,
  onChangeAdult,
  onChangeQuery,
  onSearch,
  onOpenDatePickerFrom,
  onOpenDatePickerTo,
  onChangeForm,
}: ISubHotelSearchMenuProps) => {
  const {
    region,
    checkin,
    checkout,
    customCheckin,
    customCheckout,
    checkinMinDate,
    checkoutMinDate,
    travellersCount,
    adult,
    isValid,
  } = useAppSelector((state) => state.searchHotelsReducer);

  const {
    setCheckinDate,
    setCustomCheckinDate,
    setCheckoutDate,
    setCustomCheckoutDate,
  } = searchHotelsSlice.actions;
  const dispatch = useAppDispatch();

  const suggestRef = useRef<HTMLInputElement | null>(null);

  const [isChangedTime, setIsChangedTime] = useState(false);
  const [timePicker, setTimePicker] = useState({
    from: '',
    to: '',
  });

  const preparedCheckin = customCheckin || checkin;
  const preparedCheckout = customCheckout || checkout;
  const checkinFormat = (customCheckin != null) ? PATTERN.FULL_DATE_WITH_TIME : DATE_FORMATS.DATE;
  const checkoutFormat = (customCheckout != null) ? PATTERN.FULL_DATE_WITH_TIME : DATE_FORMATS.DATE;
  const preparedCheckinTime = formatDate(preparedCheckin, DATE_FORMATS.TIME);
  const preparedCheckoutTime = formatDate(preparedCheckout, DATE_FORMATS.TIME);

  const prepareTime = {
    from: (customCheckin != null) ? preparedCheckinTime : DASH,
    to: (customCheckout != null) ? preparedCheckoutTime : DASH,
  };

  const valueTime = isChangedTime ? timePicker : prepareTime;

  const handleChangeSubMenuCheckinDate = (value: Dayjs | null, withTime: boolean) => {
    dispatch(withTime
      ? setCustomCheckinDate(value)
      : setCheckinDate(value));

    if (!withTime) {
      dispatch(setCustomCheckinDate(null));
      setTimePicker({
        from: DASH,
        to: timePicker.to,
      });
    }

    if (withTime) {
      // @ts-ignore
      const preparedTime = formatDate(value, DATE_FORMATS.TIME);

      setTimePicker({
        from: preparedTime,
        to: timePicker.to,
      });
    }
    setIsChangedTime(true);
    onChangeForm();
  };

  const handleChangeSubMenuCheckoutDate = (value: Dayjs | null, withTime: boolean) => {
    dispatch(withTime ? setCustomCheckoutDate(value) : setCheckoutDate(value));

    if (withTime) {
      const preparedTime = formatDate(value, DATE_FORMATS.TIME);

      setTimePicker({
        from: timePicker.from,
        to: preparedTime,
      });
    } else {
      dispatch(setCustomCheckoutDate(null));
      setTimePicker({
        from: timePicker.from,
        to: DASH,
      });
    }

    setIsChangedTime(true);
    onChangeForm();
  };

  const handleChangeTime = (value: { from: string, to: string }) => {
    const preparedCheckinValue = customCheckin || checkin;
    const preparedCheckoutValue = customCheckout || checkout;

    if (value.from) {
      // @ts-ignore
      const momentValue = dayjsObject(`${formatDate(preparedCheckinValue, PATTERN.YEAR_MONTH_DAY)} ${value.from}`);

      dispatch(setCustomCheckinDate(value.from !== DASH ? momentValue : null));
    }

    if (value.to) {
      // @ts-ignore
      const momentValue = dayjsObject(`${formatDate(preparedCheckoutValue, PATTERN.YEAR_MONTH_DAY)} ${value.to}`);
      dispatch(setCustomCheckoutDate(value.to !== DASH ? momentValue : null));
    }

    setTimePicker({
      from: value.from,
      to: value.to,
    });

    setIsChangedTime(true);
  };

  const renderSuggestion = ({
    title = '',
    items = [],
    name = '',
    parentName = '',
  }: any): JSX.Element => {
    const { label: query } = region;

    return (
      <HotelSuggestion
        key={ title }
        isNested={ !!items.length }
        title={ title }
        name={ name }
        parentName={ parentName }
        query={ query }
      />
    );
  };

  return (
    <div className={ styles.sub }>
      <div className={ styles.wrapper }>
        <div className={ styles.it }>
          <Icon
            type='serviceHotel'
            circleColor='white'
            className={ styles.icon }
          />
        </div>
        <div className={ `${styles.suggest} ${styles.it}` }>
          <div className={ styles.suggest_wrapper }>
            <Suggest
              contentClassName={ styles.suggest_animation }
              isFetching={ isFetching }
              ref={ suggestRef }
              theme='dark'
              placeholder={ LABELS.SUGGEST_PLACEHOLDER }
              value={ region.label }
              items={ itemsSuggests }
              // @ts-ignore
              onSelect={ onSuggestSelected }
              onChange={ onChangeQuery }
              renderItem={ renderSuggestion }
              keyExtractor={ getKey }
            />
          </div>
        </div>
        <div className={ `${styles.it} ${styles.date}` }>
          <Datepicker
            placeholder={ LABELS.DATE_IN_PLACEHOLDER }
            withLabel
            type='dateTimeList'
            inputTheme='open'
            direction='from'
            theme='dark'
            value={ preparedCheckin }
            openFromOutside={ onOpenDatePickerFrom }
            onChange={ handleChangeSubMenuCheckinDate }
            min={ checkinMinDate }
            format={ checkinFormat }
            inputClassName={ styles.input }
            wrapperClassName={ styles.date_wrapper }
            isDuration
            durationDates={ [preparedCheckin as Dayjs, preparedCheckout] }
            ref={ buttonsRefs[0] }
          />
        </div>
        <div className={ `${styles.it} ${styles.date}` }>
          <Datepicker
            placeholder={ LABELS.DATE_OUT_PLACEHOLDER }
            withLabel
            min={ checkoutMinDate }
            type='dateTimeList'
            inputTheme='open'
            direction='to'
            theme='dark'
            value={ preparedCheckout }
            openFromOutside={ onOpenDatePickerTo }
            onChange={ handleChangeSubMenuCheckoutDate }
            format={ checkoutFormat }
            inputClassName={ styles.input }
            wrapperClassName={ styles.date_wrapper }
            isDuration
            durationDates={ [preparedCheckin as Dayjs, preparedCheckout] }
            ref={ buttonsRefs[1] }
          />
        </div>
        <div className={ `${styles.it} ${styles.time}` }>
          <Timepicker
            placeholder={ LABELS.TIME }
            value={ valueTime }
            inputClassName={ styles.input }
            wrapperClassName={ styles.time_wrapper }
            onChange={ handleChangeTime }
          />
        </div>
        <div className={ `${styles.it} ${styles.travellers}` }>
          <div className={ styles.travellers_wrapper }>
            <Select
              className={ styles.select }
              placeholder={ LABELS.GUESTS }
              withLabel
              theme='dark'
              items={ TRAVELERS }
              value={ travellersCount }
              onChange={ onChangeTravellersCount }
            />
          </div>
          <Placing
            travellersCount={ travellersCount }
            adult={ adult }
            onChangeAdult={ onChangeAdult }
          />
        </div>
        <div className={ `${styles.action}` }>
          <Button
            type='primary'
            onClick={ onSearch }
            disabled={ !isValid }
          >
            {LABELS.SEARCH}
          </Button>
        </div>
      </div>
    </div>
  );
};

interface IPlacingProps {
  travellersCount: number;
  adult: number;
  onChangeAdult(value: number): void;
}

const Placing = ({
  travellersCount,
  adult,
  onChangeAdult,
}: IPlacingProps) => {
  if (travellersCount <= 1) return null;

  const roomCount = SELECT_TRAVELLERS[travellersCount]
    ?.find((item) => item.value === adult)?.roomCount || 1;

  const placingLabelMinWidth = travellersCount * 24;

  const placingIcon = <Icon type='person' color='white'/>;
  const placingLabel: Array<JSX.Element | string> = [];

  for (let i = 0; i < roomCount; i++) {
    if (adult === 2) {
      placingLabel.push(placingIcon);
      placingLabel.push(placingIcon);
    } else {
      placingLabel.push(placingIcon);
    }
    if (i + 1 < roomCount) {
      placingLabel.push('+');
    }
  }
  if ((travellersCount === 5 || travellersCount === 3) && adult === 2) {
    placingLabel.splice(placingLabel.length - 1, 1);
  }

  const renderPlacingLabel = () => (
    <div className={ styles.placing } style={ { minWidth: `${placingLabelMinWidth}px` } }>
      {placingLabel.map((item, idx) => (
        <React.Fragment key={ `${item}_${idx}` }>
          {item}
        </React.Fragment>
      ))}
    </div>
  );

  return (
    <div className={ styles.travellers_wrapper }>
      <Select
        placeholder={ LABELS.PLACING }
        withLabel
        theme='dark'
        items={ SELECT_TRAVELLERS[travellersCount] }
        value={ adult }
        renderLabel={ renderPlacingLabel }
        onChange={ onChangeAdult }
      />
    </div>
  );
};

export { SubHotelSearchMenu };
