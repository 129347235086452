import { NotificationsManager } from 'yw-ui';
import { INotification } from 'yw-ui/components/Notification/types';

import { ENotificationActionType } from '@/app/bi/constants/settings.ts';

const notification = ({
  title,
  type = ENotificationActionType.success,
  buttonName = '',
  content,
  uid,
  onClick = () => {},
}: INotification) => {
  NotificationsManager.addNotification({
    title,
    type,
    buttonName,
    content,
    uid,
    onClick,
  });
};

export { notification };
