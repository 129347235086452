import React, { useCallback, useEffect, useState } from 'react';
import {
  Button,
  Text,
  Stars,
  ItemPanel,
  NoPhoto,
  Rating,
  Tooltip } from 'yw-ui/src';
import { Link } from 'react-router-dom';
import { getText, getTextArray } from '@/i18n';

import { ShowOnMapButton } from '@/app/components/ShowOnMapButton';
import { ImageItem } from '@/app/components/ImageGallery/components/ImageItem';
import { HotelItemRate } from '@/app/pages/HotelsResultPage/components/HotelItemRate';

import toDecline from '../../../../bi/utils/toDecline';
import { formatCurrency } from '@/app/bi/utils/money.ts';
import { preloadOrigUrl } from '@/app/bi/utils/images.ts';
import { calculatePriceValue } from '@/app/bi/utils/price.ts';

import { RATING_TYPES } from '@/app/bi/constants/hotels.ts';
import { HOTEL_TYPES } from '@/app/bi/constants/hotelsSearch.ts';

import { ITravelPolicy } from '@/app/bi/models/travelPolicies.ts';
import { IRegionSearchResponseItem } from '@/app/bi/models/hotelSearch/hotelSearchTypes.ts';
import { ECurrencyCode, EHotelValidationError } from '@/app/bi/models/hotelSearch/hotelSearchEnum.ts';

import styles from './styles.module.css';

const LABELS = {
  SPECIAL_OFFER: getText('hotels:regionResult.item.specialOffer'),
  DAYS_DECLINE: getTextArray('hotels:regionResult.item.daysDecline'),
  ROOM_DECLINE: getTextArray('hotels:regionResult.item.roomDecline'),
  ON: getText('hotels:regionResult.item.on'),
  PER: getText('hotels:regionResult.item.per'),
  FEE: getText('hotels:regionResult.item.fee'),
  CHOOSE_ROOM: getText('hotels:regionResult.item.chooseRoom'),
  RANGE_TO_CENTER: (city: string, distance: string) =>
    getText('hotels:regionResult.item.rangeToCenter', { city, distance }),
  HEADING_TOOLTIP: (policyName: string) => getText('components:travelPolicies.headingTooltip', { policyName }),
  HEADING_TOOLTIP_CONTENT:
    (policyName: string) => getText('components:travelPolicies.headingTooltipContent', { policyName }),
  POLICIES_TEXTS: {
    MAX_PRICE_HOTEL: (
      maxPrice: number,
    ) => getText('components:travelPolicies.policiesTexts.maxPriceHotel', { maxPrice }),
    REFUNDABLE: getText('components:travelPolicies.policiesTexts.refundable'),
    WEEKDAYS_HOTEL: getText('components:travelPolicies.policiesTexts.weekdaysHotel'),
  },
  TAKING_ARRIVAL: getText('components:travelPolicies.takingArrival'),
  EXCLUDING_EARLY_CHECK_IN: getText('components:travelPolicies.excludingEarlyCheckIn'),
};

interface IHotelItemProps {
  item: IRegionSearchResponseItem;
  listTravelPolicies: ITravelPolicy[];
  selectPolicy: { id: string, policyName: string, isActive: boolean };
  diffDays: number;
  regionName: string;
  travellersCount: number;
  roomCount: number;
  hotelUrl: string;
  currencyCode: ECurrencyCode,
  onOpenMap(item: IRegionSearchResponseItem): void;
  onShowHotelOnMap(): void;
}

const HotelItem = ({
  travellersCount,
  listTravelPolicies,
  selectPolicy,
  roomCount,
  item,
  regionName,
  hotelUrl,
  diffDays,
  currencyCode,
  onOpenMap,
  onShowHotelOnMap,
}: IHotelItemProps) => {
  const {
    address,
    thumbnailUrl,
    rating,
    ratingType,
    distanceToCenter,
    rate,
    type,
  } = item;

  const currencyPrice = rate.prices
    .find(({ currency }) => currency === currencyCode);
  const [animationClass, setAnimationClass] = useState('');
  const [mainImageValid, setMainImageValid] = useState(false);

  const handlePreloadImg = useCallback(async () => {
    const res = await preloadOrigUrl(thumbnailUrl);

    if (res) {
      setMainImageValid(true);
    }
  }, [thumbnailUrl]);

  useEffect(() => {
    handlePreloadImg();
  }, [handlePreloadImg, item]);

  const handleAnimationEnd = () => setAnimationClass('');

  const renderNameHotelDefault = ({ name }: { name: string, stars: number }) => (
    <Text type='NORMAL_16' className={ styles.title }>
      <span>{name}</span>
    </Text>
  );

  const renderNameHotel = ({ name, stars }: IRegionSearchResponseItem) => (
    renderNameHotelDefault({ name, stars })
  );

  const getPolicyText = (typePolicy: EHotelValidationError) => {
    if (!listTravelPolicies.length) return '';

    const policyInfo = listTravelPolicies.find(({
      id,
    }) => id === selectPolicy.id);

    if (!policyInfo || !policyInfo.hotelPolicy) return '';

    const {
      eclc,
      maxPricePerNight,
    } = policyInfo.hotelPolicy;

    switch (typePolicy) {
      case EHotelValidationError.Refundable:
        return LABELS.POLICIES_TEXTS.REFUNDABLE;
      case EHotelValidationError.MaxPricePerNight:
        return LABELS.POLICIES_TEXTS.MAX_PRICE_HOTEL(maxPricePerNight);
      case EHotelValidationError.Weekdays:
        return LABELS.POLICIES_TEXTS.WEEKDAYS_HOTEL;
      case EHotelValidationError.Eclc:
        return eclc ? LABELS.TAKING_ARRIVAL : LABELS.EXCLUDING_EARLY_CHECK_IN;
      default:
        return '';
    }
  };

  const handleShowMap = () => {
    onOpenMap(item);
    onShowHotelOnMap();
  };

  const renderTooltipContent = () => {
    const policyName = selectPolicy.policyName;
    const arrayPolicyErrors = item.travelPolicyValidationErrors;

    const renderListTextErrors = () => arrayPolicyErrors?.map((textPolicy) => (
      <li key={ textPolicy }>
        <Text color='white' type='NORMAL_14'>
          {getPolicyText(textPolicy)}
        </Text>
      </li>
    ));

    return (
      <div className={ styles.tooltip_content }>
        <Text color='white' type='NORMAL_16'>
          {LABELS.HEADING_TOOLTIP_CONTENT(policyName)}
        </Text>
        <ul className={ styles.list_policy_text }>
          {renderListTextErrors()}
        </ul>
      </div>
    );
  };

  const renderPolicyTooltip = () => (
    <Tooltip
      show
      position='bottom'
      renderContent={ () => renderTooltipContent() }
    >
      <Text type='NORMAL_14' className={ styles.name_policy }>
        {LABELS.HEADING_TOOLTIP(selectPolicy.policyName)}
      </Text>
    </Tooltip>
  );

  const renderTravelPolicyError = () => {
    if (!item.travelPolicyValidationErrors.length) return null;

    return (
      <div className={ styles.render_error_policy }>
        {renderPolicyTooltip()}
      </div>
    );
  };

  const renderHeader = () => (
    <div className={ styles.header }>
      <Link
        to={ hotelUrl }
        target='_blank'
        className={ `${styles.name_wrapper}` }
      >
        {renderNameHotel(item)}
      </Link>
      {renderTravelPolicyError()}
    </div>
  );

  const renderDaysAmount = () => {
    if (diffDays === 1 && roomCount < 1) return null;

    const textDays = toDecline(diffDays, LABELS.DAYS_DECLINE);
    const textRoomCount = toDecline(roomCount, LABELS.ROOM_DECLINE);

    return (
      <Text type='NORMAL_12' color='gray-6'>
        {LABELS.PER} {roomCount} {textRoomCount} {LABELS.ON} {diffDays} {textDays}
      </Text>
    );
  };

  const renderImage = () => {
    const itemImage = { original: thumbnailUrl };

    return (
      <div className={ styles.img_wrapper }>
        <ImageItem item={ itemImage } />
      </div>
    );
  };

  const renderNoPhoto = () => (
    <div className={ styles.no_photo }>
      <NoPhoto />
    </div>
  );

  const imageContent = thumbnailUrl && mainImageValid
    ? renderImage()
    : renderNoPhoto();

  const renderRating = () => {
    if (!rating) return null;

    const ratingText = RATING_TYPES[ratingType] || '';

    return (
      <div className={ styles.rating }>
        <Rating text={ ratingText } fullSize number={ rating }/>
      </div>
    );
  };

  const price = formatCurrency(calculatePriceValue(
    currencyPrice?.total as number, travellersCount, roomCount),
  currencyCode as ECurrencyCode,
  );

  const renderActions = () => (
    <div className={ styles.right_block }>
      {renderRating()}
      <div className={ styles.price_wrapper }>
        <div className={ styles.price }>
          <div className={ styles.days_amount }>
            {renderDaysAmount()}
          </div>
          <div className={ styles.price_amount }>
            <Text type='bold_24' color='default'>
              {price}
            </Text>
          </div>
        </div>
        <div className={ styles.action }>
          <Link to={ hotelUrl } target='_blank'>
            <Button type='secondary'>
              {LABELS.CHOOSE_ROOM}
            </Button>
          </Link>
        </div>
      </div>
    </div>
  );

  const renderRate = () => (
    <HotelItemRate rate={ rate }/>
  );

  const renderContent = () => (
    <div className={ styles.content }>
      <div className={ styles.main }>
        <Link target='_blank' to={ hotelUrl }>
          {imageContent}
        </Link>
        <div className={ styles.info }>
          <div className={ styles.location }>
            <div className={ styles.stars }>
              {item.stars > 0 && <Stars count={ item.stars } color='blue1' size={ 8 } />}
              <Text type='SEMIBOLD_14' color='gray-7'>
                {HOTEL_TYPES[type]}
              </Text>
            </div>
            <Text type='NORMAL_14' className={ styles.address }>
              {address}
            </Text>
            <div className={ styles.distance_wrap }>
              <Text type='NORMAL_12' className={ styles.distance }>
                {LABELS.RANGE_TO_CENTER(regionName, distanceToCenter.toFixed(2))}
              </Text>
              <ShowOnMapButton onClick={ handleShowMap } />
            </div>
          </div>
          {renderRate()}
        </div>
      </div>
      {renderActions()}
    </div>
  );

  return (
    <ItemPanel
      animationClass={ animationClass }
      className={ styles.item }
      renderHeader={ renderHeader }
      onAnimationEnd={ handleAnimationEnd }
    >
      { renderContent() }
    </ItemPanel>
  );
};

export { HotelItem };
