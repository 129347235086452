import React from 'react';

import { firstSymbolUpperCase } from '../../bi/utils/text';

import { getEmployeeFullName } from '@/app/bi/utils/employees.ts';

import { IEmployeeVersion } from '@/app/bi/models/employees.ts';

import styles from './index.module.css';

interface EmployeeCloneItemProps {
  value: IEmployeeVersion,
  highlitedWord?: string,
  className?:string,
}

const highlightTextFragment = (highlitedWord: string, fullWord: string) => {
  const highlitedWordUpperCase = firstSymbolUpperCase(highlitedWord);

  return fullWord.startsWith(highlitedWordUpperCase)
    ? `<mark>${highlitedWordUpperCase}</mark>${fullWord.substring(highlitedWord.length, fullWord.length)}`
    : fullWord;
};

const EmployeeCloneItem = ({ value, highlitedWord, className }:EmployeeCloneItemProps) => {
  const fullName = getEmployeeFullName(value);
  let highlightedLine = null;

  if (highlitedWord) {
    const { firstName, lastName } = value;

    const highlightedName = highlightTextFragment(highlitedWord, firstName);
    const highlightedSurname = highlightTextFragment(highlitedWord, lastName);
    highlightedLine = `${highlightedSurname} ${highlightedName}`;
  }

  return (
    <div className={ `${className} ${styles.wrapper}` }>
      <div
        className={ styles.text }
        /* eslint-disable-next-line react/no-danger */
        dangerouslySetInnerHTML={ { __html: highlightedLine ?? fullName } }
      />
    </div>
  );
};

export { EmployeeCloneItem };
