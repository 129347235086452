import { ywApiPrivate } from './index.ts';
import { EBookStatus, IBookResultResponse } from '@/app/bi/models/booking.ts';
import { ITrip } from '@/app/bi/models/tripsTypes.ts';

export const bookingApi = ywApiPrivate.injectEndpoints({
  endpoints: (build) => ({
    getBookingStatus: build.query<{ status: EBookStatus }, void>({
      query: () => ({
        url: '/booking',
      }),
    }),
    booking: build.query<void, void>({
      query: () => ({
        url: '/booking',
        method: 'POST',
      }),
    }),
    getBookingById: build.query<ITrip[], string>({
      query: (bookId: string) => ({
        url: `/booking/${bookId}`,
      }),
    }),
    getBookingAccessStatus: build.query<IBookResultResponse, void>({
      query: () => ({
        url: '/booking/checkout',
      }),
    }),
  }),
});

export const {
  useGetBookingByIdQuery,
  useLazyBookingQuery,
  useLazyGetBookingAccessStatusQuery,
  useLazyGetBookingStatusQuery,
} = bookingApi;
