import React, { useEffect, useState } from 'react';
import { Input, Select, Text } from 'yw-ui';

import { getText } from '@/i18n';

import {
  setEclc,
  setWeekdays,
  setRefundable,
  setMaxPricePerNight,
  setMaxPricePerNightChecked,
  setMaxPricePerNightCurrency,
} from '@/app/store/redusers/TravelPolicy.ts';
import { useAppDispatch, useAppSelector } from '@/app/store/hooks/redux.ts';

import { CheckBoxItem } from '@/app/pages/TravelPolicy/components/CheckBoxItem';

import { CURRENCY_NAME_LIST, DEPARTURES_NAME_LIST } from '@/app/bi/constants/travelPolicy.ts';

import { ECurrencyCode } from '@/app/bi/models/hotelSearch/hotelSearchEnum.ts';

import styles from './styles/index.module.css';

const LABELS = {
  NOT_MORE_EXPENSIVE: getText('settings:travelPolicies.policy.notMoreExpensive'),
  CURRENCY_NIGHT: getText('settings:travelPolicies.policy.hotel.currencyNight'),
  ONLY_REFUNDABLE_TICKETS: getText('settings:travelPolicies.policy.hotel.onlyRefundableTickets'),
  ONLY_ON_WEEKDAY: getText('settings:travelPolicies.policy.hotel.onlyOnWeekdays'),
};

interface HotelPoliciesProps {
  isCreate: boolean;
}

export const HotelPolicies = ({
  isCreate,
}: HotelPoliciesProps) => {
  const dispatch = useAppDispatch();
  const {
    maxPricePerNightChecked,
    hotelPolicy: {
      eclc,
      maxPricePerNight,
      maxPricePerNightCurrency,
      refundable,
      weekdays,
    },
  } = useAppSelector((state) => state.travelPolicySlice);

  const [
    eclcValue,
    setEclcValue,
  ] = useState({
    label: '',
    isActive: false,
  });

  useEffect(() => {
    if (!isCreate) {
      dispatch(setMaxPricePerNightChecked(maxPricePerNight > 0));
    }
  }, [maxPricePerNight]);

  const handleRefundable = () => dispatch(setRefundable(!refundable));

  const handleWeekdays = () => dispatch(setWeekdays(!weekdays));

  const handleMaxPricePerCurrency = (currencyCod: ECurrencyCode) => {
    dispatch(setMaxPricePerNightCurrency(currencyCod));
    dispatch(setMaxPricePerNightChecked(true));
  };

  const handleMaxPricePerNight = (price: string) => {
    const regex = /^[0-9]*$/;

    if (!regex.test(price)) {
      return;
    }

    const newPrice = !price ? 0 : Number(price);

    dispatch(setMaxPricePerNight(newPrice));

    if (newPrice !== 0 && !maxPricePerNightChecked) {
      dispatch(setMaxPricePerNightChecked(true));
    } else if (newPrice === 0) {
      dispatch(setMaxPricePerNightChecked(false));
    }
  };

  const handleMaxPricePerNightActiveChange = () => {
    const newValue = !maxPricePerNightChecked;

    dispatch(setMaxPricePerNightChecked(newValue));

    if (!newValue) {
      dispatch(setMaxPricePerNight(maxPricePerNight));
    }
  };

  const handleEclc = (value: string) => {
    setEclcValue({ label: value, isActive: !eclc });

    if (value !== eclcValue.label) {
      dispatch(setEclc(!eclc));
      dispatch(setMaxPricePerNightChecked(true));
    }
  };

  return (
    <>
      <CheckBoxItem
        checked={ maxPricePerNightChecked }
        onChange={ handleMaxPricePerNightActiveChange }
      >
        <div className={ styles.policy_price }>
          <Text type='NORMAL_14'>
            {LABELS.NOT_MORE_EXPENSIVE}
          </Text>
          <Input
            value={ maxPricePerNight }
            className={ styles.input }
            onChange={ handleMaxPricePerNight }
            max={ 9 }
          />
          <Select
            className={ styles.select_currency }
            theme='open-blue-small'
            items={ CURRENCY_NAME_LIST }
            value={ maxPricePerNightCurrency }
            onChange={ handleMaxPricePerCurrency }
          />
          <Text type='NORMAL_14'>
            {LABELS.CURRENCY_NIGHT}
          </Text>
          <Select
            className={ styles.select_currency }
            theme='open-blue-small'
            items={ DEPARTURES_NAME_LIST }
            value={ String(eclc) }
            onChange={ handleEclc }
          />
        </div>
      </CheckBoxItem>
      <CheckBoxItem checked={ refundable } onChange={ handleRefundable }>
        <Text type='NORMAL_14'>{LABELS.ONLY_REFUNDABLE_TICKETS}</Text>
      </CheckBoxItem>
      <CheckBoxItem checked={ weekdays } onChange={ handleWeekdays }>
        <Text type='NORMAL_14'>{LABELS.ONLY_ON_WEEKDAY}</Text>
      </CheckBoxItem>
    </>
  );
};
