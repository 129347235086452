const CASES = [2, 0, 1, 1, 1, 2];

const toDecline = (number: number, titles: string | object) => {
  const ind = (number % 100 > 4 && number % 100 < 20)
    ? 2 : CASES[(number % 10 < 5) ? number % 10 : 5];

  return titles[ind as keyof typeof titles];
};

export default toDecline;
