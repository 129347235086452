import React, { useMemo } from 'react';
import { Text } from 'yw-ui';
import { getText, getTextArray } from '@/i18n';

import { EmployeeItem } from '../EmployeeItem';

import toDecline from '@/app/bi/utils/toDecline.ts';

import { ECartItemErrorType, ICartErrorsItem, ICartResponseEmployee } from '@/app/bi/models/cart.ts';
import { EServiceTypes } from '@/app/bi/models/serviceTypes.ts';

import styles from './index.module.css';

const LABELS = {
  TITLE: getText('components:employeeList.addEmployee'),
  PERSONS: getTextArray('common:persons'),
};
interface EmployeeListProps {
  employees: ICartResponseEmployee[];
  errors: ICartErrorsItem | null;
  travellersCount: number;
  serviceType: EServiceTypes;
  onAddEmployeeToCard(employeeId: string): void;
  onRemoveEmployee(employeeId: string): void;
  onUpdateDataEmployee(item: {
    employeeId: string;
    employeeDocumentId: string | null;
    companyId: number | null;
  }): void,
}

const EmployeeList = ({
  travellersCount,
  employees,
  errors = null,
  serviceType,
  onAddEmployeeToCard,
  onRemoveEmployee,
  onUpdateDataEmployee,
}: EmployeeListProps) => {
  const isEmptyEmployeeError = !!errors?.errors.some(({ error }) => error === ECartItemErrorType.EmptyEmployee);

  const employeeArray = useMemo(() => new Array(travellersCount)
    .fill(0), [travellersCount]);

  const renderEmployees = () => employeeArray.map((_, index) => {
    if (!employees) return null;

    const item = employees[index] || null;

    return (
      <EmployeeItem
        key={ `employee_${index}_${item?.id}` }
        index={ index + 1 }
        item={ item }
        items={ employees }
        serviceType={ serviceType }
        isError={ isEmptyEmployeeError }
        onAddEmployeeToCard={ onAddEmployeeToCard }
        onRemoveEmployee={ onRemoveEmployee }
        onUpdateDataEmployee={ onUpdateDataEmployee }
      />
    );
  });

  const numberOfEmployees = `${travellersCount} ${toDecline(travellersCount, LABELS.PERSONS)}`;

  return (
    <div className={ styles.list_wrapper }>
      <div className={ styles.wrapper }>
        <div className={ styles.header }>
          <Text type='bold_16'>
            {LABELS.TITLE}
          </Text>
          <Text type='bold_16'>
            {numberOfEmployees}
          </Text>
        </div>
        {renderEmployees()}
      </div>
    </div>
  );
};

export { EmployeeList };
