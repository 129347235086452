import React, { useState } from 'react';
import {
  Text, LinkButton, Button, Tooltip,
} from 'yw-ui';

import { getText } from '@/i18n';

import { useAppSelector } from '@/app/store/hooks/redux.ts';
import { useUpdatePasswordMutation } from '@/app/bi/api/profileApi.ts';

import { FormWrapper } from '@/app/components/FormWrapper';
import { InputPassword } from '@/app/pages/Settings/pages/Profile/components/InputPassword';

import { notification } from '@/app/bi/utils/not.ts';

import {
  MIN_LENGTH_PASSWORD,
  PASSWORD_PLACEHOLDER,
  MAX_LENGTH_PASSWORD,
  ENotificationActionType,
} from '@/app/bi/constants/settings.ts';

import styles from './styles/index.module.css';

const LABELS = {
  INCORRECT_PASSWORD: getText('settings:profile.incorrectPassword'),
  SETUP_NEW_PASSWORD: getText('settings:profile.setupNewPassword'),
  PASSWORD_LESS_THAN_SIX: getText('settings:profile.passwordLessThanSix'),
  OLD_PASSWORD: getText('settings:profile.oldPassword'),
  NEW_PASSWORD: getText('settings:profile.newPassword'),
  NEW_PASSWORD_AGAIN: getText('settings:profile.newPasswordAgain'),
  MISS_MATCH_PASSWORDS: getText('settings:profile.missMatchPasswords'),
  CHANGE_PASSWORD: getText('settings:profile.changePassword'),
  CANCEL: getText('common:undo'),
  EDIT: getText('settings:profile.edit'),
  PASSWORD: getText('settings:profile.password'),
  MY_DATA: getText('settings:profile.myData'),
  EMAIL: getText('settings:profile.email'),
  SAVE_PASSWORD_ERROR: getText('settings:profile.savePasswordError'),
  SAVE_PASSWORD_SUCCESS: getText('settings:profile.savePasswordSuccess'),
  PASSWORD_SUCCESS_CHANGE: getText('settings:passwordSuccessChange'),
  PASSWORD_ERROR_CHANGE: getText('settings:passwordErrorChange'),
  SUBMIT: getText('settings:submit'),
};

const ProfileInfo = () => {
  const { success, error } = ENotificationActionType;

  const [showForm, setShowForm] = useState(false);

  const [password, setPassword] = useState('');
  const [currentPassword, setCurrentPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const [validationMessage, setValidationMessage] = useState('');

  const newPasswordLengthIsTrue = password.length >= MIN_LENGTH_PASSWORD && password.length < MAX_LENGTH_PASSWORD;
  const newAndConfirmPasswordsIsSame = password === confirmPassword;

  const formIsValid = !!currentPassword && !!password && newPasswordLengthIsTrue && newAndConfirmPasswordsIsSame;

  const showTooltip = !newAndConfirmPasswordsIsSame && !formIsValid;

  const { user } = useAppSelector((state) => state.auth);

  const currentEmail = user?.email || '';

  const [updatePassword, {
    isLoading,
    error: codeError,
  }] = useUpdatePasswordMutation();

  const handleChangePassword = async () => {
    try {
      await updatePassword({ oldPassword: currentPassword, newPassword: confirmPassword }).unwrap();

      if (!codeError) {
        setPassword('');
        setCurrentPassword('');
        setConfirmPassword('');
        setShowForm(false);

        notification({
          title: LABELS.PASSWORD_SUCCESS_CHANGE,
          type: success,
        });
      }
    } catch (e) {
      notification({
        title: LABELS.PASSWORD_ERROR_CHANGE,
        type: error,
      });
    }
  };

  const handleTogglePasswordForm = (value: boolean) => {
    setShowForm(value);

    setCurrentPassword('');
    setPassword('');
    setConfirmPassword('');
  };

  const getValidationMessage = () => {
    if (!password) {
      return LABELS.SETUP_NEW_PASSWORD;
    }

    if (password.length < MIN_LENGTH_PASSWORD) {
      return LABELS.PASSWORD_LESS_THAN_SIX;
    }

    return '';
  };

  const handleNewPassword = () => setValidationMessage(getValidationMessage());

  const handleButtonEdit = () => handleTogglePasswordForm(true);

  const renderLabel = (label: string) => (
    <Text className={ styles.label } type='NORMAL_14'>{ label }</Text>
  );

  const renderCurrentPassword = () => (
    <Text type='NORMAL_14' className={ styles.tooltip }>
      { LABELS.MISS_MATCH_PASSWORDS }
    </Text>
  );

  const editFormHtml = (
    <FormWrapper className={ styles.form }>
      <div className={ styles.field }>
        { renderLabel(LABELS.OLD_PASSWORD) }
        <div className={ styles.content }>
          <InputPassword value={ currentPassword } onChange={ setCurrentPassword } />
        </div>
      </div>
      <div className={ styles.field }>
        { renderLabel(LABELS.NEW_PASSWORD) }
        <div className={ styles.content }>
          <InputPassword
            value={ password }
            onChange={ setPassword }
            onBlur={ handleNewPassword }
            error={ validationMessage }
          />
        </div>
      </div>
      <div className={ styles.field }>
        { renderLabel(LABELS.NEW_PASSWORD_AGAIN) }
        <div className={ styles.content }>
          <InputPassword value={ confirmPassword } onChange={ setConfirmPassword } />
        </div>
      </div>
      <div className={ `${styles.actions} ${styles.field}` }>
        <Tooltip
          show={ showTooltip }
          className={ styles.tooltipConteiner }
          renderContent={ renderCurrentPassword }
        >
          <Button
            type='secondary'
            loading={ isLoading }
            className={ styles.button }
            onClick={ handleChangePassword }
            disabled={ !formIsValid }
          >
            { LABELS.CHANGE_PASSWORD }
          </Button>
        </Tooltip>
        <LinkButton
          className={ styles['second-button'] }
          theme='blue-without-border'
          onClick={ () => handleTogglePasswordForm(false) }
        >
          <Text type='NORMAL_14' className={ styles.cancelButton }>{ LABELS.CANCEL }</Text>
        </LinkButton>
      </div>
    </FormWrapper>
  );

  const editButtonHtml = (
    <div className={ styles.field }>
      <Text className={ styles.label } type='NORMAL_14'>{ LABELS.PASSWORD }</Text>
      <div className={ styles.content }>
        <Text className={ styles.passPlaceholder } type='NORMAL_14'>
          { PASSWORD_PLACEHOLDER }
        </Text>
        <LinkButton
          className={ styles['second-button'] }
          theme='blue-without-border'
          onClick={ handleButtonEdit }
        >
          <Text className={ styles.buttonEdit } type='NORMAL_14'>{ LABELS.EDIT }</Text>
        </LinkButton>
      </div>
    </div>
  );

  const contentHtml = showForm ? editFormHtml : editButtonHtml;

  return (
    <div className={ styles.profile }>
      <Text type='bold_20' className={ styles.title }>{ LABELS.MY_DATA }</Text>
      <div className={ styles.container }>
        <div className={ styles.field }>
          { renderLabel(LABELS.EMAIL) }
          <Text className={ styles.content } type='NORMAL_14'>{ currentEmail }</Text>
        </div>
        { contentHtml }
      </div>
    </div>
  );
};

export { ProfileInfo };
