import React, {
  useState, useCallback, MouseEvent, useEffect,
} from 'react';
import {
  getExampleNumber,
  parsePhoneNumberFromString,
  CountryCode,
} from 'libphonenumber-js';
import { Input, Select, Text } from 'yw-ui';

import phoneExamples from 'libphonenumber-js/examples.mobile.json';
import { PHONE_NUMBER } from '@/app/bi/constants/regExp.ts';
import COUNTRIES from '../../bi/constants/countries';
import { countriesList } from './countries';

import styles from './index.module.css';

const preparePhoneNumber = (value: string) => {
  const parsedPhoneNumber = parsePhoneNumberFromString(value);

  const initialRegion = parsedPhoneNumber?.country || COUNTRIES.TR.SHORTNAME;
  const initialNum = parsedPhoneNumber?.nationalNumber || value;

  return {
    prepareRegion: initialRegion,
    prepareNum: initialNum,
  };
};

interface InputPhoneProps {
  value: string,
  onChange(value: string): void,
  onFocus?(): void,
  onBlur?(value: boolean): void,
  className?: string,
  error?: string,
  debounceMs?: number,
}

const InputPhone = ({
  value,
  onFocus,
  className = '',
  onBlur = () => {},
  onChange = () => {},
  error = '',
  debounceMs = 0,
}: InputPhoneProps) => {
  const { prepareRegion, prepareNum } = preparePhoneNumber(value);

  const [
    region,
    setRegion,
  ] = useState<CountryCode>(prepareRegion as CountryCode);
  const [
    num,
    setNum,
  ] = useState(prepareNum);

  const wrapClassNames: string[] = [styles.container];

  useEffect(() => {
    const { prepareRegion: updateRegion, prepareNum: updateNum } = preparePhoneNumber(value);

    setRegion(updateRegion as CountryCode);
    setNum(updateNum);
  }, [value]);

  if (className) {
    wrapClassNames.push(className);
  }

  if (error) {
    wrapClassNames.push(styles['has-error']);
  }

  const handleChange = useCallback((valueNum: string) => {
    const formattedNumber = valueNum.replace(PHONE_NUMBER, '');

    setNum(formattedNumber);

    const phoneNumber = parsePhoneNumberFromString(formattedNumber, region);

    if (phoneNumber) {
      onChange(phoneNumber.number);
    }
  }, [onChange, region]);

  const handleRegionChange = useCallback((newRegion: CountryCode) => {
    setRegion(newRegion);
    setNum('');
  }, [num]);

  const handleBlur = useCallback(() => {
    if (num) {
      const parsedPhoneNumber = parsePhoneNumberFromString(num, region);
      const isValid = parsedPhoneNumber?.isValid() || false;

      onBlur(isValid);

      if (isValid && parsedPhoneNumber) {
        onChange(parsedPhoneNumber.number);
      }
    }
  }, [num, onBlur, onChange, region]);

  const handleClickSelect = (event: MouseEvent) => event.stopPropagation();

  const renderSelectItem = ({ label, name }: { label: string, name: string, value: string }) => (
    <div className={ styles.item }>
      <Text className={ styles.label } type='NORMAL_14'>{ name }</Text>
      <Text color='gray' type='NORMAL_14' className={ styles.code }>{ label }</Text>
    </div>
  );

  const renderSelectLabel = ({ label }: { label: string, value: string }) => (
    <div className={ styles.item }>
      <Text className={ styles.label }>{ label }</Text>
    </div>
  );

  const renderRegion = (
    <Select
      tabIndex={ -1 }
      className={ styles.select }
      theme='dark'
      onChange={ handleRegionChange }
      items={ countriesList }
      value={ region }
      renderItem={ renderSelectItem }
      renderLabel={ renderSelectLabel }
    />
  );

  const renderInput = () => {
    const data = getExampleNumber(region, phoneExamples);
    const placeholder = data?.nationalNumber;

    return (
      <Input
        className={ styles.input }
        onChange={ handleChange }
        placeholder={ placeholder }
        value={ num }
        max={ placeholder?.length }
        debounceMs={ debounceMs }
      />
    );
  };

  const renderError = error && (
    <div className={ styles.error }>
      <Text type='NORMAL_10' className={ styles.text }>
        { error }
        {' '}
      </Text>
    </div>
  );

  return (
    <div
      className={ wrapClassNames.join(' ') }
      onFocus={ onFocus }
      onBlur={ handleBlur }
      onClick={ handleClickSelect }
    >
      { renderRegion }
      { renderInput() }
      { renderError }
    </div>
  );
};

export { InputPhone };
