import React, { useRef } from 'react';

import { HotelResult } from '../HotelsResult';
import { HotelsSearchFilter } from '@/app/pages/HotelsResultPage/components/Filters';
import { Header } from '@/app/pages/HotelsResultPage/components/HotelResultWrap/components/Header';

import { IHotelSearchFilter } from '@/app/bi/models/hotelSearch/hotelSearchTypes.ts';
import { ITag } from '@/app/bi/models/shared.ts';
import { ITravelPolicy } from '@/app/bi/models/travelPolicies.ts';

import styles from './styles.module.css';

interface IHotelResultWrapProps {
  listTravelPolicies: ITravelPolicy[];
  selectPolicy: { id: string, policyName: string, isActive: boolean };
  setIsSelectPolicy(value: { id: string, policyName: string, isActive: boolean }): void;
  onUpdateFilters(valueFilter: IHotelSearchFilter): void;
  onResetFilters(): void;
  onChangePage(page: number): void;
  onDeleteTag(tag: ITag): void;
}

const HotelResultWrap = ({
  listTravelPolicies,
  selectPolicy,
  setIsSelectPolicy,
  onUpdateFilters,
  onResetFilters,
  onDeleteTag,
  onChangePage,
}: IHotelResultWrapProps) => {
  const pageHeaderRef = useRef(null);

  return (
    <div className={ styles.result }>
      <HotelsSearchFilter
        listTravelPolicies={ listTravelPolicies }
        selectPolicy={ selectPolicy }
        setIsSelectPolicy={ setIsSelectPolicy }
        onUpdateFilters={ onUpdateFilters }
      />
      <div className={ styles.items }>
        <Header
          // onUpdateFilters={ onUpdateFilters }
          pageHeaderRef={ pageHeaderRef }
          onResetFilters={ onResetFilters }
          onDeleteTag={ onDeleteTag }
        />
        <div className={ styles.list }>
          <HotelResult
            listTravelPolicies={ listTravelPolicies }
            selectPolicy={ selectPolicy }
            onChangePage={ onChangePage }
            onResetFilters={ onResetFilters }
            pageHeaderRef={ pageHeaderRef }
          />
        </div>
      </div>
    </div>
  );
};
export { HotelResultWrap };
