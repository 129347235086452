import { Dayjs } from 'dayjs';

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { dayjsObject } from '@/app/bi/utils/formatDate.ts';
import transliterate from '@/app/bi/utils/transliterate.ts';

import {
  EEmployeeFormParam,
  EEmployeeNameParam,
  EEmployeeNameTransliterate,
  ETypesSex,
  TURKEY_LOCALE_CODE,
} from '@/app/bi/constants/settings.ts';

import { IEmployeeInitialState, IEmployeePolicyItem } from '@/app/bi/models/employee.ts';
import {
  EEmployeeDocumentType,
  EEmployeeDocumentStatus,
  IEmployeeDocument,
  IEmployeeDocumentModified,
  IEmployeeObj,
} from '@/app/bi/models/employees.ts';
import { ICitizenshipItem } from '@/app/bi/models/profile.ts';

const {
  Active,
  Archived,
} = EEmployeeDocumentStatus;

const initialDocumentPassport: IEmployeeDocumentModified = {
  id: '',
  employeeId: '',
  hasBeenChangedData: false,
  actualVersion: {
    id: '',
    firstName: '',
    lastName: '',
    lastNameTranslit: '',
    firstNameTranslit: '',
    number: '',
    status: Active,
    type: EEmployeeDocumentType.ForeignPassport,
    createdDate: '',
    employeeDocumentId: '',
    creatorUserId: '',
    expiryDate: null,
    countryCode: '',
  },
  versions: null,
};

const initialIdCard = {
  id: '',
  employeeId: '',
  hasBeenChangedData: false,
  actualVersion: {
    id: '',
    firstName: '',
    lastName: '',
    lastNameTranslit: '',
    firstNameTranslit: '',
    number: '',
    status: Active,
    type: EEmployeeDocumentType.IdCard,
    createdDate: '',
    employeeDocumentId: '',
    creatorUserId: '',
    expiryDate: null,
    countryCode: '',
  },
  versions: null,
};

const initialState: IEmployeeInitialState = {
  generalData: {
    employeeId: '',
    firstName: '',
    lastName: '',
    birthday: null,
    countryCode: '',
    citizenship: {
      name: '',
      code: '',
      code3: '',
    },
    sex: null,
    contactData: {
      phoneNumber: '',
      email: '',
      organization: [],
    },
    travelPolicyId: {
      label: '',
      value: null,
    },
  },
  idCard: initialIdCard,
  idCardArchive: initialIdCard,
  documents: [],
};

export const employeeSlice = createSlice({
  name: 'employeeSlice',
  initialState,
  reducers: {
    setBirthDay: (state, action: PayloadAction<Dayjs | null>) => {
      state.generalData.birthday = action.payload;
    },
    setSex: (state, action: PayloadAction<ETypesSex>) => {
      state.generalData.sex = action.payload;
    },
    setExpiredDateIdCard: (state, action: PayloadAction<Dayjs | null>) => {
      state.idCard.hasBeenChangedData = true;
      state.idCard.actualVersion.expiryDate = action.payload;
    },
    setExpiredDateDocument: (
      state,
      action: PayloadAction<{ id: string | number, value: Dayjs | null }>,
    ) => {
      const { id, value } = action.payload;

      if (typeof id === 'number') {
        state.documents[id].hasBeenChangedData = true;
        state.documents[id].actualVersion.expiryDate = value;
      } else {
        state.documents = state.documents.map((document) => {
          if (document.id === id) {
            return {
              ...document,
              hasBeenChangedData: true,
              actualVersion: {
                ...document.actualVersion,
                expiryDate: value,
              },
            };
          }

          return document;
        });
      }
    },
    setNameGeneral: (
      state,
      action: PayloadAction<{ value: string, type: EEmployeeNameParam }>,
    ) => {
      state.generalData[action.payload.type as EEmployeeNameParam] = action.payload.value;
    },
    setContactDataGeneral: (
      state,
      action: PayloadAction<{ value: string, type: EEmployeeFormParam }>,
    ) => {
      state.generalData.contactData = {
        ...state.generalData.contactData,
        [action.payload.type]: action.payload.value,
      };
    },
    setOrganization: (state, action: PayloadAction<number[]>) => {
      state.generalData.contactData.organization = action.payload;
    },
    setCitizenship: (state, action: PayloadAction<ICitizenshipItem>) => {
      state.generalData.citizenship = action.payload;
    },
    setDataNamePassport: (
      state,
      action: PayloadAction<{ index: number, value: string, type: EEmployeeNameParam }>,
    ) => {
      const { index, value, type } = action.payload;

      state.documents[index].actualVersion[type] = value;

      if (type === EEmployeeNameParam.First) {
        state.documents[index].actualVersion.firstNameTranslit = transliterate(value);
      } else {
        state.documents[index].actualVersion.lastNameTranslit = transliterate(value);
      }

      if (state.documents[index].id.length) {
        state.documents[index].hasBeenChangedData = true;
      }
    },
    setFioTransliteratePassport: (
      state,
      action: PayloadAction<{ index: number, value: string, type: EEmployeeNameTransliterate }>,
    ) => {
      const { index, value, type } = action.payload;

      state.documents[index].actualVersion[type] = transliterate(value);

      if (state.documents[index].id.length) {
        state.documents[index].hasBeenChangedData = true;
      }
    },
    setPassportNumber: (
      state,
      action: PayloadAction<{ id: number, value: string }>,
    ) => {
      const { id, value } = action.payload;

      state.documents[id].actualVersion.number = value;

      if (state.documents[id].id.length) {
        state.documents[id].hasBeenChangedData = true;
      }
    },
    addPassport: (state) => {
      state.documents.push({ ...initialDocumentPassport });
    },
    deletePassport: (state, action: PayloadAction<number>) => {
      const indexDoc = action.payload;

      if (!state.documents[indexDoc].id.length) {
        state.documents = state.documents.filter(
          (_, index) => index !== indexDoc,
        );
      } else {
        state.documents = state.documents.map((doc, index) => {
          if (index === indexDoc) {
            return {
              ...doc,
              actualVersion: {
                ...doc.actualVersion,
                status: Archived,
              },
            };
          }

          return doc;
        });
      }
    },
    setDataIdCard: (
      state,
      action: PayloadAction<{
        id: number,
        value: string,
        type: EEmployeeNameParam,
      }>,
    ) => {
      const { type: typeName, value } = action.payload;

      const creationParameter = '0';
      const idCardState = state.idCard;
      const idCardArchive = state.idCardArchive.actualVersion;

      if (idCardArchive.status === Archived) {
        state.idCard = state.idCardArchive;

        if (idCardArchive.status === Archived) {
          state.idCardArchive.actualVersion.status = Active;
        }

        if (idCardArchive.firstName && idCardArchive.lastName && idCardArchive.number) {
          state.idCardArchive.actualVersion = {
            ...state.idCardArchive.actualVersion,
            firstName: '',
            lastName: '',
            number: '',
            expiryDate: null,
          };
        }

        idCardArchive[typeName] = value;
        state.idCardArchive.hasBeenChangedData = true;
      } else {
        if (typeName === EEmployeeNameParam.First) {
          state.idCard.actualVersion.firstNameTranslit = transliterate(value);
        } else {
          state.idCard.actualVersion.lastNameTranslit = transliterate(value);
        }

        idCardState.actualVersion[typeName] = value;

        idCardState.hasBeenChangedData = true;
      }

      const isIdCardArray = state.documents.findIndex(
        ({ actualVersion: { type } }) => type === idCardState.actualVersion.type,
      ) !== 1;

      if (!isIdCardArray) {
        state.documents = state.documents.map((doc) => {
          if (doc.actualVersion.type === EEmployeeDocumentType.IdCard && doc.actualVersion.status === Archived) {
            state.idCard = {
              ...idCardState,
              actualVersion: {
                ...idCardState.actualVersion,
                employeeDocumentId: doc.actualVersion.employeeDocumentId,
              },
            };
          }

          return doc;
        });
      }

      state.documents = state.documents.filter(({
        actualVersion: {
          type,
          status,
        } }) => type !== EEmployeeDocumentType.IdCard && status !== Archived,
      );

      if (idCardState.id.length) {
        idCardState.id = creationParameter;
      }

      if (state.generalData.citizenship.code3 !== TURKEY_LOCALE_CODE) {
        state.idCard.actualVersion.type = EEmployeeDocumentType.DomesticPassport;
      }
    },
    setFioTransliterateIdCard: (
      state,
      action: PayloadAction<{ value: string, type: EEmployeeNameTransliterate }>,
    ) => {
      const { value, type: typeTransliterate } = action.payload;

      const transliterateValue = transliterate(value);

      const creationParameter = '0';
      const idCardState = state.idCard;
      const idCardArchive = state.idCardArchive.actualVersion;

      if (idCardArchive.status === Archived) {
        state.idCard = state.idCardArchive;

        if (idCardArchive.status === Archived) {
          state.idCardArchive.actualVersion.status = Active;
        }

        if (idCardArchive.firstName && idCardArchive.lastName && idCardArchive.number) {
          state.idCardArchive.actualVersion = {
            ...state.idCardArchive.actualVersion,
            firstName: '',
            lastName: '',
            number: '',
            expiryDate: null,
          };
        }

        idCardArchive[typeTransliterate] = transliterateValue;
        state.idCardArchive.hasBeenChangedData = true;
      } else {
        idCardState.actualVersion[typeTransliterate] = transliterateValue;

        idCardState.hasBeenChangedData = true;
      }

      const isIdCardArray = state.documents.findIndex(
        ({ actualVersion: { type } }) => type === idCardState.actualVersion.type,
      ) !== 1;

      if (!isIdCardArray) {
        state.documents = state.documents.map((doc) => {
          if (doc.actualVersion.type === EEmployeeDocumentType.IdCard && doc.actualVersion.status === Archived) {
            state.idCard = {
              ...idCardState,
              actualVersion: {
                ...idCardState.actualVersion,
                employeeDocumentId: doc.actualVersion.employeeDocumentId,
              },
            };
          }

          return doc;
        });
      }

      state.documents = state.documents.filter(({
        actualVersion: {
          type,
          status,
        } }) => type !== EEmployeeDocumentType.IdCard && status !== Archived,
      );

      if (idCardState.id.length === 0) {
        idCardState.id = creationParameter;
      }
    },
    setPassportNumberIdCard: (state, action: PayloadAction<string>) => {
      const creationParameter = '0';
      const idCardState = state.idCard;

      state.idCard.actualVersion.number = action.payload;
      state.idCard.hasBeenChangedData = true;

      const isIdCardArray = state.documents.findIndex(
        ({ actualVersion: { type } }) => type === idCardState.actualVersion.type,
      ) !== 1;

      if (!isIdCardArray) {
        state.documents = state.documents.map((doc) => {
          if (doc.actualVersion.type === EEmployeeDocumentType.IdCard && doc.actualVersion.status === Archived) {
            return {
              ...doc,
              id: idCardState.id,
              actualVersion: {
                ...doc.actualVersion,
                number: idCardState.actualVersion.number,
              },
            };
          }

          return doc;
        });
      }

      if (idCardState.id.length === 0) {
        idCardState.id = creationParameter;
      }
    },
    clearDataIdCard: (state) => {
      const idCardState = state.idCard;

      if (idCardState.id.length && idCardState.id !== '0') {
        state.idCardArchive = {
          ...idCardState,
          hasBeenChangedData: true,
          actualVersion: {
            ...idCardState.actualVersion,
            status: Archived,
          },
        };

        state.idCard = {
          ...initialState.idCard,
          id: idCardState.id,
          actualVersion: {
            ...initialState.idCard.actualVersion,
            employeeDocumentId: idCardState.actualVersion.employeeDocumentId,
          },
        };
      } else {
        state.idCard = initialState.idCard;
      }
    },
    clearDocuments: (state) => {
      state.documents = state.documents.map((doc) => ({
        ...doc,
        actualVersion: {
          ...doc.actualVersion,
          status: EEmployeeDocumentStatus.Archived,
        },
      }));
    },
    setTravelPolicy: (state, action: PayloadAction<IEmployeePolicyItem>) => {
      state.generalData.travelPolicyId = action.payload;
    },
    setEmployeeData: (state, action: PayloadAction<IEmployeeObj>) => {
      const {
        actualVersion: {
          employeeId,
          firstName,
          lastName,
          sex,
          birthday,
          phoneNumber,
          email,
          countryCode,
          travelPolicyId,
        },
        documents,
      } = action.payload;

      state.generalData.employeeId = employeeId;
      state.generalData.firstName = firstName;
      state.generalData.lastName = lastName;
      state.generalData.sex = sex;
      state.generalData.countryCode = countryCode;
      state.generalData.birthday = dayjsObject(birthday);
      state.generalData.contactData.phoneNumber = phoneNumber;
      state.generalData.contactData.email = email;
      state.generalData.travelPolicyId = { label: '', value: travelPolicyId };

      if (documents !== null) {
        const transformDocument = transformArrayDocuments(documents);

        const isDocumentsIdCard = transformDocument?.findIndex(({
          actualVersion: {
            type,
          } }) => type === EEmployeeDocumentType.IdCard || type === EEmployeeDocumentType.DomesticPassport) !== -1;

        if (isDocumentsIdCard) {
          state.idCard = transformDocument.filter(({
            actualVersion: {
              type,
            } }) => type === EEmployeeDocumentType.IdCard || type === EEmployeeDocumentType.DomesticPassport,
          )[0];
        }

        state.documents = transformDocument.filter(({
          actualVersion: {
            type,
          } }) => type === EEmployeeDocumentType.ForeignPassport,
        );
      }
    },
    setInitialState: (state) => {
      state.generalData = initialState.generalData;
      state.idCard = initialState.idCard;
      state.documents = initialState.documents;
    },
  },
});

const transformArrayDocuments = (
  documents: IEmployeeDocument[],
): IEmployeeDocumentModified[] => documents.map((
  doc,
) => ({
  ...doc,
  hasBeenChangedData: false,
}));

export default employeeSlice.reducer;
