import React, {
  ReactNode, useEffect, useState,
} from 'react';
import {
  StyledWrapper, Text, Button,
} from 'yw-ui';

import { getText } from '@/i18n';

import { Rate } from '../Rate';
import { Details } from '@/app/pages/HotelResult/components/Hotel/components/RoomGroup/components/Details';
import { HotelGallery } from '@/app/pages/HotelResult/components/Hotel/components/RoomGroup/components/HotelGallery';

import { preloadOrigUrlAndUrl } from '@/app/bi/utils/images.ts';

import { SearchSettings } from '@/app/bi/models/hotelsTypes.ts';

import {
  IHotelSearchImageResponse,
  IHotelSearchRoomGroupResponse,
  IPrepareHotelProviderRate,
} from '@/app/bi/models/hotelSearch/hotelSearchTypes.ts';
import textAbbreviation from '@/app/bi/utils/textAbbreviation.ts';

import styles from './index.module.css';
import { ECurrencyCode } from '@/app/bi/models/hotelSearch/hotelSearchEnum.ts';

const LABELS = {
  MORE_INFO: getText('hotels:hotelResult.item.roomGroup.moreInfo'),
  // NOINFO: getText('hotels:hotelResult.item.roomGroup.noInfo'),
  // HOTEL_INFO: (stars: string, address: string, rating: string) => getText('hotels:hotelResult.item.roomGroup.hotelInfo', { stars, address, rating }),
  // CANCELLATION_FEE: (date: string) => getText('hotels:hotelResult.item.roomGroup.cancellation.free', { date }),
  // CANCELLATION_FEE_FOREIGN: (date: string) => getText('hotels:hotelResult.item.roomGroup.cancellation.freeForeign', { date }),
  // CANCELLATION_NON_FEE: getText('hotels:hotelResult.item.roomGroup.cancellation.nonFree'),
  // RATES_NAME: getText('hotels:hotelResult.item.roomGroup.rates.name'),
  // RATES_TYPE: getText('hotels:hotelResult.item.roomGroup.rates.type'),
  // CART: getText('hotels:hotelResult.item.roomGroup.rates.cart'),
  // EARLY_IN: (earlyIn: string) => getText('components:hotelItem.earlyIn', { earlyIn }),
  // LATE_OUT: (lateOut: string) => getText('components:hotelItem.lateOut', { lateOut }),
};

interface RoomGroupProps {
  room: IHotelSearchRoomGroupResponse;
  amenities: string[];
  currencyCode: ECurrencyCode;
  onToCart(rate: IPrepareHotelProviderRate): void;
  onChangeCount(bookId: string, value: number): void;
  searchSettings: SearchSettings;
  onGallerySlideLeft(): void;
  onGallerySlideRight(): void;
  onShowRoomDetails(renderFn: ()=> ReactNode): void;
}

const RoomGroup = ({
  room,
  amenities = [],
  onToCart = () => {},
  searchSettings,
  currencyCode,
  onGallerySlideLeft,
  onGallerySlideRight,
  onChangeCount,
  onShowRoomDetails,
}: RoomGroupProps) => {
  const [loadedImgs, setLoadedImgs] = useState<IHotelSearchImageResponse[]>([]);
  const [allImageLoadFailed, setAllImageLoadFailed] = useState(false);

  const preloadAndFilterImages = async () => {
    const { images } = room;
    if (!images?.length) {
      return;
    }

    const preloadedImages: (IHotelSearchImageResponse | null)[] = await Promise.all(images.map(preloadOrigUrlAndUrl));
    const filteredImages: IHotelSearchImageResponse[] = preloadedImages.filter(Boolean) as IHotelSearchImageResponse[];
    const newAllImageLoadFailed = !filteredImages.length;
    setLoadedImgs(filteredImages);
    setAllImageLoadFailed(newAllImageLoadFailed);
  };

  useEffect(() => {
    preloadAndFilterImages();
  }, []);

  const handleShowRoomDetails = () => onShowRoomDetails(renderDetails);

  const renderDetails = () => (
    <Details
      room={ room }
      amenities={ amenities }
      allImageLoadFailed={ allImageLoadFailed }
      Images={ images }
      loadedImgs={ loadedImgs }
      details
      onGallerySlideLeft={ onGallerySlideLeft }
      onGallerySlideRight={ onGallerySlideRight }
      handleShowRoomDetails={ handleShowRoomDetails }
    />
  );
  const { currencyRateGroups, name, images, description } = room;
  const selectStyles = styles.title;

  const newCurrencyRateGroups = currencyRateGroups.filter((item) => item.currencyCode === currencyCode);
  const rateGroupsHtml = newCurrencyRateGroups
    .map(({ rates, currencyCode: rateCurrencyCode }) =>
      rates.map((rate, idx) => (
        <Rate
          key={ `${rate.bookId}_${rateCurrencyCode}_${idx}` }
          rate={ rate as IPrepareHotelProviderRate }
          currencyCode={ currencyCode }
          searchSettings={ searchSettings }
          onChangeCount={ onChangeCount }
          onToCart={ onToCart }
        />
      )));

  const descriptionStr = description
    ? textAbbreviation(description.substring(description.indexOf('<p>')), 300)
    : '';

  return (
    <StyledWrapper className={ styles.wrapper }>
      <div className={ selectStyles }>
        <Text type='NORMAL_16' color='gray-8'>{ name }</Text>
        <Button onClick={ handleShowRoomDetails } type='text'>
          {LABELS.MORE_INFO}
        </Button>
      </div>
      <div className={ styles.body }>
        <div className={ styles.details_wrapper }>
          <div className={ styles.images }>
            <HotelGallery
              allImageLoadFailed={ allImageLoadFailed }
              Images={ images }
              loadedImgs={ loadedImgs }
              onGallerySlideLeft={ onGallerySlideLeft }
              onGallerySlideRight={ onGallerySlideRight }
              handleShowRoomDetails={ handleShowRoomDetails }
            />
          </div>
          <Text type='NORMAL_16_140' className={ styles.description }>
            <div dangerouslySetInnerHTML={ { __html: descriptionStr } }/>
          </Text>
        </div>
        <div className={ styles.info }>
          {rateGroupsHtml}
        </div>
      </div>
    </StyledWrapper>
  );
};

export { RoomGroup };
