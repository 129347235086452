import React from 'react';
import { Text } from 'yw-ui';
import { getText } from '@/i18n';

import { IHotelSearchItemResponse } from '@/app/bi/models/hotelSearch/hotelSearchTypes.ts';

import styles from './index.module.css';

interface ServicesComponentProps {
  info: IHotelSearchItemResponse;
  className?: string;
}

const LABELS = {
  ABOUT_OF_HOTEL: getText('hotels:hotelResult.aboutOfHotel'),
  MORE_ABOUT_OF_HOTEL: getText('hotels:hotelResult.moreAboutOfHotel'),
};

const HotelServices = ({
  info: {
    description = '',
    amenities = [],
  },
  className,
}: ServicesComponentProps) => {
  // const valuesHtml = (values: string[]) => values.map((value, ind) => (
  //   <div key={ `service_${ind}` } className={ styles.item }>
  //     <Icon type='cup' />
  //     <Text type='NORMAL_16_140' className={ styles.text }>{ value }</Text>
  //   </div>
  // ));

  const renderAmenities = () => {
    if (!amenities || !amenities.length) return null;

    const amenitiesHtml = amenities.map((item, idx) => (
      <div key={ `services_${idx}` } className={ styles['amenities-item'] }>
        {/* <Text type='bold_14' className={ styles.title }> */}
        {/*   { item } */}
        {/* </Text> */}
        <div className={ styles['amenities-values'] }>
          {item}
        </div>
      </div>
    ));

    return (
      <div className={ styles.amenities }>
        <Text type='bold_16'>{ LABELS.MORE_ABOUT_OF_HOTEL }</Text>
        <div className={ styles.amenities_content }>
          {amenitiesHtml}
        </div>
      </div>
    );
  };

  const descriptionHtml = !!description.length && (
    <div className={ styles.description }>
      <Text type='bold_24'>{ LABELS.ABOUT_OF_HOTEL }</Text>
      <div
        className={ styles.text }
        dangerouslySetInnerHTML={ { __html: description } }
      />
    </div>
  );

  const classNames = [styles.wrapper];

  if (className) {
    classNames.push(className);
  }

  return (
    <div className={ classNames.join(' ') }>
      { descriptionHtml }
      { renderAmenities() }
    </div>
  );
};

export { HotelServices };
