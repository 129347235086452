import dayjs from 'dayjs';
import { getCurrentLocale } from './formatDate';

export default function parseUnix(value: any, locale = getCurrentLocale()) {
  let time = value;

  if (typeof value === 'number' && value.toString().length === 10) {
    time *= 1000;
  }

  const origin = dayjs.utc(time);
  origin.locale(locale);

  return origin;
}
