import React from 'react';
import { Price } from 'yw-ui';

import { getText } from '@/i18n';

import ColumnLayout from '../../Layout/ColumnLayout';
import { EmployeeInfoList } from '@/app/components/EmployeeInfoList';

import { dayjsObject, formatHourFromHourTo } from '@/app/bi/utils/formatDate.ts';
import { getImageLogo } from '@/app/bi/utils/airline.ts';
import { getTimeDifferenceFormatted } from '@/app/bi/utils/time.ts';
import { preparedFares } from '@/app/bi/utils/fares.ts';
import { formattedDateFromTo, transformEmployeesUniversal } from '@/app/bi/utils/trip.ts';

import { IAirlineTripItems } from '@/app/bi/models/tripsTypes.ts';
import { IAirlineEmployees } from '@/app/bi/models/airline.ts';

import styles from './index.module.css';

const LABELS = {
  CONNECTING_FLIGHT: (countSegments: number) => getText('trip:documents.connectingFlight', { countSegments }),
  NON_STOP_FLIGHT: getText('trip:documents.nonStopFlight'),
};

interface CardAirLineProps {
  data: IAirlineTripItems;
}

const CardAirLine = ({ data }: CardAirLineProps) => {
  const tripActualVersion = data.actualVersion;
  const lastSegmentTrip = tripActualVersion.routes[0].segments.length - 1;

  const {
    departureDate,
    operatingAirlineName,
    flightNumber,
    departureCity,
    departureAirportCityCode,
    operatingAirlineCode,
  } = tripActualVersion.routes[0].segments[0];

  const {
    arrivalCity,
    arrivalDate,
    arrivalAirportCityCode,
  } = tripActualVersion.routes[0].segments[lastSegmentTrip];

  const dateFromAndTo = formattedDateFromTo(departureDate, arrivalDate);

  // TODO переделеать это
  const labelValueArray = preparedFares({
    isBaggageIncluded: tripActualVersion.isBaggageIncluded,
    baggagePlaces: tripActualVersion.baggagePlaces,
    isCarryonIncluded: tripActualVersion.isCarryonIncluded,
    carryonPlaces: tripActualVersion.carryonPlaces,
    isTicketRefundable: tripActualVersion.isTicketRefundable,
    isTicketChangeable: tripActualVersion.isTicketChangeable,
    canRegistrationSeat: tripActualVersion.canRegistrationSeat,
    isBusinessHallIncluded: tripActualVersion.isBusinessHallIncluded,
    isTicketRefundableAfterDeparture: tripActualVersion.isTicketRefundableAfterDeparture,
    canUpgradeRate: tripActualVersion.canUpgradeRate,
    airlineBonusInformation: tripActualVersion.fareAirlineBonusInformation,
    isTicketChangeableAfterDeparture: tripActualVersion.isTicketChangeableAfterDeparture,
    // @ts-ignore
    baggageIncludedWithCondition: tripActualVersion.isBaggageIncluded,
    // @ts-ignore
    refundTicketWithCondition: tripActualVersion.isTicketRefundableAfterDeparture,
    // @ts-ignore
    changeTicketWithCondition: tripActualVersion.isTicketChangeableAfterDeparture,
  });

  const getHourDifference = getTimeDifferenceFormatted(dayjsObject(departureDate), dayjsObject(arrivalDate));

  const typeFlight = () => {
    const segmentsTrip = tripActualVersion ? tripActualVersion.routes[0].segments : [];

    return segmentsTrip.length > 1
      ? LABELS.CONNECTING_FLIGHT(segmentsTrip.length - 1)
      : LABELS.NON_STOP_FLIGHT;
  };

  const departure = `${departureCity}, ${departureAirportCityCode}`;
  const arrival = `${arrivalCity}, ${arrivalAirportCityCode}`;

  const formationHourDate = formatHourFromHourTo(departureDate, arrivalDate);

  const renderEmployees = (airlineEmployees: IAirlineEmployees[]) => {
    if (!airlineEmployees.length) return null;

    const arrayEmployee = transformEmployeesUniversal(data.actualVersion.airlineEmployees);

    return <EmployeeInfoList showHeader={ false } employees={ arrayEmployee }/>;
  };

  return (
    <div className={ styles.card }>
      <div className={ styles.content }>
        <div className={ styles.header }>
          <img
            className={ styles.airlineImg }
            src={ getImageLogo(operatingAirlineCode) }
            title={ operatingAirlineName }
            alt={ operatingAirlineName }
          />
          <span>{`${operatingAirlineName} (${operatingAirlineCode} ${flightNumber})`}</span>
        </div>
        <div className={ styles.tickedInfoContainer }>
          <div className={ styles.travelWrapper }>
            <div className={ styles.travelContainer }>
              <span className={ styles.travelTitle }>{`${departure} — ${arrival}`}</span>
              <Price value={ tripActualVersion.cartPrice } color='gray-7' type='bold_18' />
            </div>
            <div className={ styles.timeInfo }>
              <span className={ `${styles.travelTime} ${styles.dot}` }>
                {formationHourDate}
              </span>
              {/* TODO вынести svg отдельно */}
              <svg width='4' height='4' viewBox='0 0 4 4' xmlns='http://www.w3.org/2000/svg'>
                <circle cx='2' cy='2' r='2' fill='black' />
              </svg>
              <span className={ styles.travelInfo }>
                {getHourDifference}
                <svg width='4' height='4' viewBox='0 0 4 4' xmlns='http://www.w3.org/2000/svg'>
                  <circle cx='2' cy='2' r='2' fill='black' />
                </svg>
                {typeFlight()}
              </span>
            </div>
          </div>
          <div className={ styles.routeInfo }>
            <span>{dateFromAndTo}</span>
          </div>
          <ColumnLayout items={ labelValueArray } />
        </div>
      </div>
      {renderEmployees(tripActualVersion.airlineEmployees)}
    </div>
  );
};

export { CardAirLine };
