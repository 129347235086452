import { combineReducers } from '@reduxjs/toolkit';

import searchHotelsReducer from './redusers/SearchHotelsSlice.ts';
import searchAirlineSlice from './redusers/SearchAirlineSlice.ts';
import airlineSlice from './redusers/AirlineSlice.ts';
import hotelsSlice from './redusers/HotelsSlice.ts';
import hotelSlice from './redusers/HotelSlice.ts';
import sideSlice from './redusers/SideSlice.ts';
import employeeSlice from '@/app/store/redusers/EmployeeSlice.ts';
import authReducer from './redusers/AuthSlice.ts';
import cartSlice from './redusers/CartSlice.ts';
import checkoutSlice from './redusers/СheckoutSlice.ts';
import appSlice from './redusers/AppSlice.ts';
import intercomSlice from './redusers/IntercomSlice.ts';
import reportsSlice from '@/app/store/redusers/ReportsSlice.ts';
import tripsSlice from '@/app/store/redusers/TripsSlice.ts';
import travelPolicySlice from '@/app/store/redusers/TravelPolicy.ts';

import { ywApiPrivate, ywApiPublic } from '../bi/api';

export const rootReducer = combineReducers({
  auth: authReducer,
  appSlice,
  hotelsSlice,
  hotelSlice,
  airlineSlice,
  searchHotelsReducer,
  searchAirlineSlice,
  sideSlice,
  employeeSlice,
  cartSlice,
  checkoutSlice,
  intercomSlice,
  reportsSlice,
  tripsSlice,
  travelPolicySlice,
  [ywApiPrivate.reducerPath]: ywApiPrivate.reducer,
  [ywApiPublic.reducerPath]: ywApiPublic.reducer,
});
