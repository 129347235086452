import { ELanguages } from '@/i18n/locales/consts';

import { EServiceTypes } from '@/app/bi/models/serviceTypes.ts';

export enum EDocumentType {
  Pdf = 'Pdf',
  Xls = 'Xls',
}

export interface IDownloadFileParams {
  fileBlob: Blob;
  fileType: EDocumentType;
  filenamePrefix: string;
  identifier?: string | number;
}

export enum EPrefixFileName {
  Invoice = 'Invoice',
  Trip = 'Trip',
}

export interface ICreateRequestReportDocument {
  operationId: string;
  locale: ELanguages;
  fileType: EDocumentType;
}

export interface ICreateRequestTripDocument {
  itemId: string;
  locale: ELanguages;
  serviceType: EServiceTypes;
  fileType: EDocumentType;
}

export interface ISendAirlineVoucherRequest {
  itemId: string;
  email: string;
  locale: ELanguages;
  serviceType: EServiceTypes;
  fileType: EDocumentType;
}
