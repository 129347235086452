import React from 'react';
import { Text } from 'yw-ui';

import { getPassportValue } from '@/app/bi/utils/trip.ts';

import { EEmployeeDocumentType } from '@/app/bi/models/employees.ts';

interface PassportInfoProps {
  number: string | null;
  type: EEmployeeDocumentType | null;
}

const PassportInfo = ({
  number,
  type,
}: PassportInfoProps) => {
  if (!number && !type) return null;

  const typePassport = type || EEmployeeDocumentType.IdCard;
  const passportNumber = `${number?.substring(0, 4)} ${number?.substring(4)}`;
  const passportType = getPassportValue(typePassport);

  return (
    <Text type='NORMAL_14' fontWeight={ 600 }>
      { `${passportType}: ${passportNumber}` }
    </Text>
  );
};

export { PassportInfo };
