import React from 'react';
import { IconButton, Suggest } from 'yw-ui';
import { SuggestNestedType, SuggestThemes } from 'yw-ui/src/components/Suggest/types.ts';

import { OptsType } from '@/app/bi/models/airlineTypes.ts';
import { ISuggestion } from '@/app/bi/models/airlineSearchTypes.ts';

import styles from './index.module.css';

interface SelectedRoutesProps {
  opts: OptsType,
  theme?: SuggestThemes,
  nestedType?: SuggestNestedType,
  keyExtractor?(i: ISuggestion): string,
  renderItem(item: ISuggestion, value: string): JSX.Element,
  onRevert(): void,
}
const SelectedRoutes = ({
  opts,
  theme = 'light',
  nestedType = 'row',
  // @ts-ignore
  keyExtractor = (i) => i.value,
  renderItem,
  onRevert,
}: SelectedRoutesProps) => {
  const {
    from: {
      value,
      placeholder,
      items,
      loading,
      ref,
      onSelect,
      onChange,
    },
    to: {
      value: valueTo,
      placeholder: placeholderTo,
      items: itemsTo,
      loading: loadingTo,
      ref: refTo,
      onSelect: onSelectTo,
      onChange: onChangeTo,
    },
  } = opts;

  const iconColor = theme !== 'light' ? 'white' : 'blue1';

  const wrapperClassNames = [styles.wrapper, styles[theme]];

  return (
    <div className={ wrapperClassNames.join(' ') }>
      <div className={ styles.suggest }>
        <Suggest
          isFetching={ loading }
          inputClassName={ styles.suggest_border }
          ref={ ref }
          value={ value }
          theme={ theme }
          placeholder={ placeholder }
          nestedType={ nestedType }
          // @ts-ignore
          items={ items }
          // @ts-ignore
          renderItem={ (item: ISuggestion) => renderItem(item, value) }
          // @ts-ignore
          keyExtractor={ keyExtractor }
          // @ts-ignore
          onSelect={ onSelect }
          onChange={ onChange }
        />
      </div>
      <IconButton
        tabIndex={ -1 }
        iconType='arrowReturn'
        iconColor={ iconColor }
        onClick={ (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
          e.preventDefault();
          onRevert();
        } }
      />
      <div className={ styles.suggest }>
        <Suggest
          isFetching={ loadingTo }
          ref={ refTo }
          value={ valueTo }
          theme={ theme }
          placeholder={ placeholderTo }
          nestedType={ nestedType }
          // @ts-ignore
          items={ itemsTo }
          // @ts-ignore
          renderItem={ (item: ISuggestion) => renderItem(item, valueTo) }
          // @ts-ignore
          keyExtractor={ keyExtractor }
          // @ts-ignore
          onSelect={ onSelectTo }
          onChange={ onChangeTo }
        />
      </div>
    </div>
  );
};

export { SelectedRoutes };
