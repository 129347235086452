import { getText } from '@/i18n';

import {
  CHANGE_TICKET,
  REFUND_TICKET,
  VALUE_TICKET,
  BAGGAGE,
} from '../constants/airline';

import { Fares } from '@/app/bi/models/airlineTypes.ts';
import { ICartResponseAirlineItem } from '@/app/bi/models/cart.ts';

const LABELS = {
  INCLUDED: getText('utils:fares.included'),
  CHANGES: getText('utils:fares.changes'),
  REFUND: getText('utils:fares.refund'),
  RATE_UP: getText('utils:fares.rateUp'),
  BAGGAGE: {
    INCLUDED: getText('utils:fares.baggage.included'),
    NOT_DEFINED: getText('utils:fares.baggage.notDefined'),
  },
  CARRY_ON: {
    INCLUDED: getText('utils:fares.carryOn.included'),
    CHARGE: getText('utils:fares.carryOn.included'),
  },
  REFUNDABLE: {
    NOT_OFFERED: getText('utils:fares.refundable.notOffered'),
    INCLUDED: getText('utils:fares.refundable.included'),
    CHARGE: getText('utils:fares.refundable.charge'),
    NOT_DEFINED: getText('utils:fares.refundable.notDefined'),
    NOT_PENALTY: getText('utils:fares.refundable.noPenalty'),
  },
  CHANGEABLE: {
    NOT_OFFERED: getText('utils:fares.changeable.notOffered'),
    INCLUDED: getText('utils:fares.changeable.included'),
    CHARGE: getText('utils:fares.changeable.charge'),
  },
  SEATS: {
    INCLUDED: getText('utils:fares.seats.included'),
    CHARGE: getText('utils:fares.seats.charge'),
  },
  BUSINESS_HALL: {
    NOT_OFFERED: getText('utils:fares.businessHall.notOffered'),
    INCLUDED: getText('utils:fares.businessHall.included'),
    CHARGE: getText('utils:fares.businessHall.charge'),
  },
  REFUND_AFTER_DEPARTURE: {
    NOT_OFFERED: getText('utils:fares.refundAfterDeparture.notOffered'),
    INCLUDED: getText('utils:fares.refundAfterDeparture.included'),
    NO_PENALTY: getText('utils:fares.refundAfterDeparture.noPenalty'),
    CHARGE: getText('utils:fares.refundAfterDeparture.charge'),
  },
  CHANGEABLE_AFTER_DEPARTURE: {
    NOT_OFFERED: getText('utils:fares.changeableAfterDeparture.notOffered'),
    INCLUDED: getText('utils:fares.changeableAfterDeparture.included'),
    CHARGE: getText('utils:fares.changeableAfterDeparture.charge'),
  },
};

const CARRYONPLACES_DEFAULT_VALUE = LABELS.INCLUDED;

const hasRefundWithConditions = (conditions = {}) => Object.keys(conditions).some((key) => conditions[key as keyof typeof conditions] === VALUE_TICKET.CHARGE || VALUE_TICKET.INCLUDED);

const prepareBaggage = (value: string, places: string) => {
  switch (value) {
    case VALUE_TICKET.INCLUDED:
      return `${LABELS.BAGGAGE.INCLUDED}${places ? `: ${places}` : ''}`;
    case VALUE_TICKET.NOT_DEFINED:
      return '';
    default:
      return LABELS.BAGGAGE.NOT_DEFINED;
  }
};

const prepareCarryOn = (value: string, places: string) => {
  switch (value) {
    case VALUE_TICKET.INCLUDED:
      return `${LABELS.CARRY_ON.INCLUDED} ${places || CARRYONPLACES_DEFAULT_VALUE}`;
    case VALUE_TICKET.CHARGE:
      return LABELS.CARRY_ON.CHARGE;
    default:
      return '';
  }
};

const prepareRefundable = (value: string) => {
  switch (value) {
    case VALUE_TICKET.NOT_OFFERED:
      return LABELS.REFUNDABLE.NOT_OFFERED;
    case VALUE_TICKET.INCLUDED:
      return LABELS.REFUNDABLE.INCLUDED;
    case VALUE_TICKET.CHARGE:
      return LABELS.REFUNDABLE.CHARGE;
    case VALUE_TICKET.NOT_DEFINED:
      return LABELS.REFUNDABLE.NOT_DEFINED;
    case VALUE_TICKET.NOT_PENALTY:
      return LABELS.REFUNDABLE.NOT_PENALTY;
    default:
      return '';
  }
};

const prepareChangeable = (value: string) => {
  switch (value) {
    case VALUE_TICKET.NOT_OFFERED:
      return LABELS.CHANGEABLE.NOT_OFFERED;
    case VALUE_TICKET.INCLUDED:
      return LABELS.CHANGEABLE.INCLUDED;
    case VALUE_TICKET.CHARGE:
      return LABELS.CHANGEABLE.CHARGE;
    default:
      return '';
  }
};

const prepareRegistrationSeat = (value: string, description?: string) => {
  const desc = description ? ` (${description})` : '';

  switch (value) {
    case VALUE_TICKET.INCLUDED:
      return `${LABELS.SEATS.INCLUDED}${desc}`;
    case VALUE_TICKET.CHARGE:
      return LABELS.SEATS.CHARGE;
    default:
      return '';
  }
};

const prepareBusinessHallIncluded = (value: string) => {
  switch (value) {
    case VALUE_TICKET.INCLUDED:
      return LABELS.BUSINESS_HALL.INCLUDED;
    case VALUE_TICKET.NOT_OFFERED:
      return LABELS.BUSINESS_HALL.NOT_OFFERED;
    case VALUE_TICKET.CHARGE:
      return LABELS.BUSINESS_HALL.CHARGE;
    default:
      return '';
  }
};

const prepareRefundableAfterDeparture = (value: string) => {
  switch (value) {
    case VALUE_TICKET.INCLUDED:
      return LABELS.REFUND_AFTER_DEPARTURE.INCLUDED;
    case VALUE_TICKET.NOT_OFFERED:
      return LABELS.REFUND_AFTER_DEPARTURE.NOT_OFFERED;
    case VALUE_TICKET.NOT_PENALTY:
      return LABELS.REFUND_AFTER_DEPARTURE.NO_PENALTY;
    case VALUE_TICKET.CHARGE:
      return LABELS.REFUND_AFTER_DEPARTURE.CHARGE;
    default:
      return '';
  }
};

const prepareChangeableAfterDeparture = (value: string) => {
  switch (value) {
    case VALUE_TICKET.INCLUDED:
      return LABELS.CHANGEABLE_AFTER_DEPARTURE.INCLUDED;
    case VALUE_TICKET.CHARGE:
      return LABELS.CHANGEABLE_AFTER_DEPARTURE.CHARGE;
    case VALUE_TICKET.NOT_OFFERED:
      return LABELS.CHANGEABLE_AFTER_DEPARTURE.NOT_OFFERED;
    default:
      return '';
  }
};

const preparedFares = ({
  isBaggageIncluded,
  baggagePlaces,
  isCarryonIncluded,
  carryonPlaces,
  isTicketRefundable,
  isTicketChangeable,
  canRegistrationSeat,
  isBusinessHallIncluded,
  isTicketRefundableAfterDeparture,
  canUpgradeRate,
  airlineBonusInformation,
  isTicketChangeableAfterDeparture,
  baggageIncludedWithCondition,
  refundTicketWithCondition,
  changeTicketWithCondition,
}: Fares) => {
  const details = [];

  const baggage = prepareBaggage(isBaggageIncluded, baggagePlaces || '');
  const carryOn = prepareCarryOn(isCarryonIncluded, carryonPlaces || '');
  const refundable = hasRefundWithConditions(refundTicketWithCondition) ? '' : prepareRefundable(isTicketRefundable);
  const changeable = prepareChangeable(isTicketChangeable);
  const registrationSeat = prepareRegistrationSeat(canRegistrationSeat);
  const businessHallIncluded = prepareBusinessHallIncluded(isBusinessHallIncluded);
  const refundableAfterDeparture = prepareRefundableAfterDeparture(isTicketRefundableAfterDeparture);
  const changeableAfterDeparture = prepareChangeableAfterDeparture(isTicketChangeableAfterDeparture);

  if (baggage.length) {
    details.push(baggage);
  }

  if (carryOn.length) {
    details.push(carryOn);
  }

  if (refundable.length) {
    details.push(refundable);
  }

  if (changeable.length) {
    details.push(changeable);
  }

  if (registrationSeat.length) {
    details.push(registrationSeat);
  }

  if (businessHallIncluded.length) {
    details.push(businessHallIncluded);
  }

  if (refundableAfterDeparture.length) {
    details.push(refundableAfterDeparture);
  }

  if (canUpgradeRate === VALUE_TICKET.INCLUDED) {
    details.push(LABELS.RATE_UP);
  }

  if (airlineBonusInformation) {
    details.push(airlineBonusInformation);
  }

  if (changeableAfterDeparture.length) {
    details.push(changeableAfterDeparture);
  }

  if (baggageIncludedWithCondition) {
    Object.keys(baggageIncludedWithCondition).forEach((field) => {
      if (BAGGAGE[baggageIncludedWithCondition[field as keyof typeof baggageIncludedWithCondition] as keyof typeof CHANGE_TICKET]) {
        details.push(`${BAGGAGE[baggageIncludedWithCondition[field as keyof typeof baggageIncludedWithCondition] as keyof typeof CHANGE_TICKET]} ${field}`);
      }
    });
  }

  if (refundTicketWithCondition) {
    Object.keys(refundTicketWithCondition).forEach((field) => {
      if (REFUND_TICKET[refundTicketWithCondition[field as keyof typeof refundTicketWithCondition] as keyof typeof CHANGE_TICKET]) {
        details.push(`${LABELS.REFUND} ${field}: ${REFUND_TICKET[refundTicketWithCondition[field as keyof typeof refundTicketWithCondition] as keyof typeof CHANGE_TICKET]}`);
      }
    });
  }

  if (changeTicketWithCondition) {
    Object.keys(changeTicketWithCondition).forEach((field) => {
      if (CHANGE_TICKET[changeTicketWithCondition[field as keyof typeof changeTicketWithCondition] as keyof typeof CHANGE_TICKET]) {
        details.push(`${LABELS.CHANGES} ${field}: ${CHANGE_TICKET[changeTicketWithCondition[field as keyof typeof changeTicketWithCondition] as keyof typeof CHANGE_TICKET]}`);
      }
    });
  }

  return details;
};

export const preparedDetailsByAirlineVersion = ({
  isBaggageIncluded,
  baggagePlaces,
  isCarryonIncluded,
  carryonPlaces,
  isTicketChangeable,
  canRegistrationSeat,
  isBusinessHallIncluded,
  isTicketRefundableAfterDeparture,
  canUpgradeRate,
  isTicketChangeableAfterDeparture,
  isTicketRefundable,
}: ICartResponseAirlineItem) => {
  const details = [];

  const baggage = prepareBaggage(isBaggageIncluded, baggagePlaces || '');
  const carryOn = prepareCarryOn(isCarryonIncluded, carryonPlaces || '');
  const refundable = prepareRefundable(isTicketRefundable);
  const changeable = prepareChangeable(isTicketChangeable);
  const registrationSeat = prepareRegistrationSeat(canRegistrationSeat);
  const businessHallIncluded = prepareBusinessHallIncluded(isBusinessHallIncluded);
  const refundableAfterDeparture = prepareRefundableAfterDeparture(isTicketRefundableAfterDeparture);
  const changeableAfterDeparture = prepareChangeableAfterDeparture(isTicketChangeableAfterDeparture);

  if (baggage.length) {
    details.push(baggage);
  }

  if (carryOn.length) {
    details.push(carryOn);
  }

  if (refundable.length) {
    details.push(refundable);
  }

  if (changeable.length) {
    details.push(changeable);
  }

  if (registrationSeat.length) {
    details.push(registrationSeat);
  }

  if (businessHallIncluded.length) {
    details.push(businessHallIncluded);
  }

  if (refundableAfterDeparture.length) {
    details.push(refundableAfterDeparture);
  }

  if (canUpgradeRate === VALUE_TICKET.INCLUDED) {
    details.push(LABELS.RATE_UP);
  }

  if (changeableAfterDeparture.length) {
    details.push(changeableAfterDeparture);
  }

  if (changeableAfterDeparture.length) {
    details.push(changeableAfterDeparture);
  }

  return details;
};

export {
  preparedFares,
  hasRefundWithConditions,
};
