import React from 'react';

import styles from './index.module.css';

interface ImageItemProps {
  item: any;
  slideStyle?: any;
  theme?: string;
  onClick?(): void;
  onTouchStart?(): void;
  onTouchEnd?(): void;
  onImageLoad?(): void;
  onImageError?(): void;
  originalImageStyles?: object | null;
}
const ImageItem = ({
  item,
  slideStyle = {},
  theme = 'default',
  originalImageStyles,
  onClick = () => {},
  onTouchStart = () => {},
  onTouchEnd = () => {},
  onImageLoad = () => {},
  onImageError = () => {},
}: ImageItemProps) => {
  const originalClass = item.originalClass
    ? ` ${item.originalClass}` : '';

  const slideCursorClass = !onClick ? '' : styles.clickable;

  const smallStyles = theme === 'small' ? styles.small : '';

  const slideImgStyles = originalImageStyles || {};

  return (
    <div
      key={ item.original }
      className={ `${styles.image_gallery_slide} ${originalClass} ${slideCursorClass}` }
      style={ slideStyle }
      // @ts-ignore
      onClick={ onClick }
      onTouchStart={ onTouchStart }
      onTouchEnd={ onTouchEnd }
    >
      <div className={ styles.image_gallery_image }>
        <img
          src={ item.original }
          className={ styles.background }
          alt={ item.originalAlt }
        />
        <img
          style={ slideImgStyles }
          src={ item.original }
          alt={ item.originalAlt }
          className={ smallStyles }
          onLoad={ onImageLoad }
          onError={ onImageError }
        />
        {
          item.description
          && (
            <span className={ styles.image_gallery_description }>
              {item.description}
            </span>
          )
        }
      </div>
    </div>
  );

};

export { ImageItem };
