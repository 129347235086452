import { useEffect, useRef } from 'react';
import * as signalR from '@microsoft/signalr';
import { IBookingProgress } from '@/app/bi/models/booking.ts';
import { useAppDispatch } from '@/app/store/hooks/redux.ts';
import { appSlice } from '@/app/store/redusers/AppSlice.ts';
import { throttleQueue } from '@/app/bi/utils/throttle.ts';
import { checkoutSlice } from '@/app/store/redusers/СheckoutSlice.ts';

const UPDATE_INTERVAL = 2000;

const useSignalRConnection = (url: string, token: string | null): signalR.HubConnection | null => {
  const connection = useRef<signalR.HubConnection | null>(null);

  useEffect(() => {
    if (token) {
      connection.current = new signalR.HubConnectionBuilder()
        .withUrl(url, {
          accessTokenFactory: () => token as string,
          transport: signalR.HttpTransportType.WebSockets,
          skipNegotiation: true,
        })
        .configureLogging(signalR.LogLevel.Information)
        .withAutomaticReconnect()
        .build();

      connection.current
        .start()
        .then(() => console.log('SignalR connected'))
        .catch((error: any) => console.error('SignalR connection error: ', error));
    }

    return () => {
      if (connection?.current) {
        connection.current.stop()
          .then(() => console.log('Connection stopped successfully.'))
          .catch((error) => console.error(error));
      }
    };
  }, [token]);

  return connection.current;
};

const useSignalROnHandlers = (connection: signalR.HubConnection | null) => {
  const dispatch = useAppDispatch();
  const { updateCartItemsCount } = appSlice.actions;
  const { updateBookingProgress } = checkoutSlice.actions;
  const handleUpdateBookingProgress = (data: IBookingProgress): void => {
    dispatch(updateBookingProgress(data));
  };

  const throttledBookingProgress = throttleQueue<IBookingProgress>(handleUpdateBookingProgress, UPDATE_INTERVAL);

  useEffect(() => {
    if (connection) {
      connection.on('UpdateCartHeader', (data: { cartItemsCount: number }) => {
        console.log('--- UpdateCartHeader:', data);
        dispatch(updateCartItemsCount(data.cartItemsCount));
      });

      connection.on('BookingProgress', (data: IBookingProgress) => {
        console.log('--- BookingProgress:', data);
        console.log('--- BookingProgress progressStatus:', data.progressStatus);
        throttledBookingProgress(data);
      });
    }

    return () => {
      if (connection) {
        connection.off('UpdateCartHeader', () => {});
        connection.off('BookingProgress', () => {});
      }
    };
  }, [connection]);
};

export {
  useSignalRConnection,
  useSignalROnHandlers,
};
