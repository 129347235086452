import React from 'react';
import { Collapse } from 'yw-ui';
import { getText } from '@/i18n';

import { PriceSlider } from '@/app/components/PriceSlider';

import { IFilterPrice } from '@/app/bi/models/airlineTypes.ts';

import styles from '../../index.module.css';

const LABELS = {
  PRICE: getText('air:result.filters.price'),
};

interface PriceFilterProps {
  price: IFilterPrice,
  values: IFilterPrice,
  onChange(value: number[]): void;
}

const PriceFilter = ({
  price: {
    min,
    max,
  },
  values,
  onChange,
}: PriceFilterProps) => {
  const handleChangePrice = (array: number[]) => {
    const newArray = array.map((item) => Number(item.toFixed(2)));

    onChange(newArray);
  };

  return (
    <Collapse
      opened
      className={ styles.item }
      label={ LABELS.PRICE }
    >
      <PriceSlider
        min={ min }
        max={ max }
        start={ values.min }
        end={ values.max }
        onSlide={ handleChangePrice }
      />
    </Collapse>
  );
};

export { PriceFilter };
