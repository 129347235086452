import React from 'react';
import { ItemLoader, Text, TripItem } from 'yw-ui';
import { getText } from '@/i18n';

import { useGetLatestEditTripsQuery } from '@/app/bi/api/homeApi.ts';

import ROUTES from '@/app/bi/constants/routes.ts';

import styles from './index.module.css';

const LABELS = {
  TITLE: getText('components:recentTrips.title'),
};

const RecentTrips = () => {
  const { data = [], isLoading } = useGetLatestEditTripsQuery();

  if (!data.length) return null;

  if (isLoading) {
    return (
      <div className={ styles.loading_trips }>
        <ItemLoader />
      </div>
    );
  }

  const renderTripsItems = () => data?.map((item) => (
    <TripItem
      key={ item.id }
      // @ts-ignore
      value={ item }
      url={ `${ROUTES.TRIP.MAIN}/${item.id}` }
    />
  ));

  return (
    <div className={ styles.wrapper }>
      <Text type='bold_24' color='blue-2'>
        {LABELS.TITLE}
      </Text>
      <div className={ styles.content }>
        {renderTripsItems()}
      </div>
    </div>
  );
};

export { RecentTrips };
