export enum ERatingType {
  Perfectly = 'Perfectly',
  Great = 'Great',
  Good = 'Good',
  GoodEnough = 'GoodEnough',
  Other = 'Other',
}

export enum EHotelTypes {
  Undefined = 'Undefined',
  Property = 'Property',
  Hotel = 'Hotel',
  Motel = 'Motel',
  Resort = 'Resort',
  Inn = 'Inn',
  BedAndBreakfast = 'BedAndBreakfast',
  Guesthouse = 'Guesthouse',
  Condo = 'Condo',
  AllInclusiveProperty = 'AllInclusiveProperty',
  Cabin = 'Cabin',
  Chalet = 'Chalet',
  Cottage = 'Cottage',
  HostelOrBackpackerAccommodation = 'HostelOrBackpackerAccommodation',
  Ranch = 'Ranch',
  Villa = 'Villa',
  Lodge = 'Lodge',
  Apartment = 'Apartment',
  PrivateVacationHome = 'PrivateVacationHome',
  Houseboat = 'Houseboat',
  Ryokan = 'Ryokan',
  TreeHouseProperty = 'TreeHouseProperty',
  Aparthotel = 'Aparthotel',
  CondominiumResort = 'CondominiumResort',
  Campsite = 'Campsite',
  Riad = 'Riad',
  Hostal = 'Hostal',
  CountryHouse = 'CountryHouse',
  Pension = 'Pension',
  PousadaPortugal = 'PousadaPortugal',
  PousadaBrazil = 'PousadaBrazil',
  Residence = 'Residence',
  Affittacamere = 'Affittacamere',
  Castle = 'Castle',
  SafariOrTentalow = 'SafariOrTentalow',
  Palace = 'Palace',
  AgritourismProperty = 'AgritourismProperty',
  Cruise = 'Cruise',
  HolidayPark = 'HolidayPark',
  CapsuleHotel = 'CapsuleHotel',
  MobileHome = 'MobileHome',
}

export enum EBedTypes {
  Other = 'Other',
  Single = 'Single',
  Twin = 'Twin',
  Double = 'Double',
}

export enum ERoomTypes {
  Undefined = 'Undefined',
  RoomTypeStandart = 'RoomTypeStandart',
  RoomTypeSuperior = 'RoomTypeSuperior',
  RoomTypeComfort = 'RoomTypeComfort',
  RoomTypeMiniSuite = 'RoomTypeMiniSuite',
  RoomTypeSuite = 'RoomTypeSuite',
  RoomTypeFamily = 'RoomTypeFamily',
  RoomTypeBussines = 'RoomTypeBussines',
  RoomTypeStudio = 'RoomTypeStudio',
  RoomTypePresident = 'RoomTypePresident',
  RoomTypeDelux = 'RoomTypeDelux',
  RoomTypeEconomy = 'RoomTypeEconomy',
  RoomTypeApartament = 'RoomTypeApartament',
  RoomTypeBudget = 'RoomTypeBudget',
  RoomTypeExecutive = 'RoomTypeExecutive',
  RoomTypePremium = 'RoomTypePremium',
  RoomTypePrestige = 'RoomTypePrestige',
  RoomTypePremier = 'RoomTypePremier',
  RoomTypeDorm = 'RoomTypeDorm',
  RoomTypeDormMale = 'RoomTypeDormMale',
  RoomTypeDormFemale = 'RoomTypeDormFemale',
  RoomTypeCottage = 'RoomTypeCottage',
  RoomTypeVilla = 'RoomTypeVilla',
  RoomTypeLoft = 'RoomTypeLoft',
  RoomTypePrivilege = 'RoomTypePrivilege',
  RoomTypePenthouse = 'RoomTypePenthouse',
  RoomTypeVIP = 'RoomTypeVIP',
  RoomTypeRomantic = 'RoomTypeRomantic',
  RoomTypeDuplex = 'RoomTypeDuplex',
  RoomTypeWedding = 'RoomTypeWedding',
  RoomTypePanoramic = 'RoomTypePanoramic',
}

export enum ECurrencyCode {
  TRY = 'TRY',
  USD = 'USD',
  EUR = 'EUR',
  RUB = 'RUB',
  Percent = 'Percent',
}

export enum EHotelSortedBystring {
  Price = 'Price',
  DistanceToCenter = 'DistanceToCenter',
}

export enum EHotelValidationError {
  MaxPricePerNight = 'MaxPricePerNight',
  Eclc = 'Eclc',
  Refundable = 'Refundable',
  Weekdays = 'Weekdays',
}
