import development from '../api/api.config.development.json';
import production from '../api/api.config.production.json';

const configs = {
  prod: production,
  production,
  development,
};
const config = configs[process.env.NODE_ENV as keyof typeof configs];

export default config;
